import React, {useContext, useEffect, useState} from 'react';
import {Container, Col, Button, Form, Modal, Spinner, Alert} from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import { AppContext } from '../App';

export const ExpenseParticular=()=>{
	const { setPage, postCall, getLoad, load, expenseParti, setExpenseParti  } = useContext(AppContext);
	const [ updateModal, setUpdateModal ] = useState(false);
	const [ updateLoad, setUpdateLoad ] = useState(false);
	const [ editable, setEditable ] = useState(false);
	useEffect(()=>{
		if (!updateModal) {
			setEditable(false);
		}
	},[updateModal]);
	const addPurticular=(e)=>{
		e.preventDefault();
		let name = e.target.name.value;
		if (name==="") {
			document.querySelector('#name_err').classList.add('bdr-red');
			setTimeout(()=>{document.querySelector('#name_err').classList.remove('bdr-red');},5000);
		}else{document.querySelector('#name_err').classList.remove('bdr-red')}
		if (name!=="") {
			if (editable) {
					setUpdateLoad(true)
					postCall('api/em/update/expense/particular/',{e_id:activeItem.substring(1),name:name}).then(res=>{
						setUpdateLoad(false);
						setUpdateModal(false);
						setExpenseParti(res.data);
						setActiveItem();
					}).catch(err=>{
						setUpdateLoad(false);
					});
				}else{
					setUpdateLoad(true)
					postCall('api/em/add/expense/particular/',{name:name}).then(res=>{
						setUpdateLoad(false);
						setUpdateModal(false);
						setExpenseParti(res.data);
						setContent(Object.keys(res.data));
					}).catch(err=>{
						setUpdateLoad(false);
					});
			}
		}
	}
	// Default search...
	const [ content, setContent ] = useState();
	useEffect(()=>{
		if (expenseParti!==undefined&&content===undefined) {
			setContent(Object.keys(expenseParti));
		}
	},[expenseParti]);
	const search=(key)=>{
		if (key!==""&&key!==undefined) {
			if(expenseParti!==undefined){
			    if(Object.keys(expenseParti).length>0){
			    	setContent(prev=>([]));
			    	Object.keys(expenseParti).map(item=>{
			    		if (expenseParti[item].name.toLowerCase().includes(key.toLowerCase())) {
			    			setContent(prev=>([...prev,item]));
			    		}
			    	});
			    }
			}
		}else{
			setContent(Object.keys(expenseParti));
		}
	}
	// Pagination
	const [ numPage, setNumPage ] = useState();
	const [ activePage, setActivePage ] = useState();
	const [ start, setStart ] = useState(0);
	const [ indexStart, setIndexStart ] = useState(0);
	// number of items per page
	const itemCount=10;	
	// number of pages in pagination
	const pageCount = 10;
	useEffect(()=>{
		if (content!==undefined) {
			let total_length = Object.keys(content).length;
			setNumPage(Math.ceil(total_length/itemCount));
			setActivePage(0);
		}
	},[content]);
	useEffect(()=>{
		setStart(0);
		setIndexStart(0);
	},[numPage]);
	// #Pagination	
	const [ changeStatLoad, setChangeStatLoad ] = useState(false);
	const [ activeItem, setActiveItem ] = useState();
	const changeStatus=(id,status)=>{
		if (id!==""&&status!=="") {
			setChangeStatLoad(true);
			setActiveItem(id);
			postCall('api/em/change/particular/status/',{e_id:id.substring(1),status:status}).then(res=>{
				setExpenseParti(res.data);
				setChangeStatLoad(false);
				setActiveItem();				
			}).catch(err=>{
				setChangeStatLoad(false);
				setActiveItem();
			});
		}
	}
	return (
		<Container className="my-3" >

    		<Col  xs={12} sm={12} md={12} lg={12} className='bdr2 br-3' >
				<Col xs="12" sm="12" md="12" lg="12" className="staff-type-header bgs1 d-grid p-2 bdrb2" >
					<span>{getLoad?(<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>):'Expense Particulars'}</span>
					<Button onClick={()=>{setUpdateModal(true)}} className="btn-t1"  ><i className="bi bi-plus-circle"></i> Add new</Button>
				</Col>
				<div className="dash-body-content">
					{
						content!==undefined&&expenseParti!==undefined?(
							content.length>0?(
								content.map((item,ii)=>(
									activePage!==undefined&&ii>=activePage*itemCount&&ii<activePage*itemCount+itemCount?(
										<div className="vendor-item grid-split-2 bdr-1b px-3 py-2" key={`vend_${ii}`} >
											<div className="d-grid" >
												<span className="f18 tc-blue f200 d-flex al-end gap-1" >
												{
													expenseParti[item].status?(
														<span className="sucess-tag f16 px-1 br3" ><i className="bi bi-check-circle-fill"></i></span>
													):(
														<span className="error-tag f16 px-1 br3" ><i className="bi bi-x-circle-fill"></i></span>
													)
												}
												{ expenseParti[item].name }</span>
											</div>
											<div className="d-flex just-end al-center gap-1">
													<Button onClick={()=>{
													setUpdateModal(true);
													setEditable(true);
													setActiveItem(item);
													}} className="btn-t1-r br3" size="sm" ><i className="bi bi-pencil-square"></i> Edit</Button>
													{
														activeItem!==undefined&&activeItem===item&&changeStatLoad?(
															<Button className="btn-t1-r br3" size="sm"><span className="please-wait-spinner d-flex al-center gap-1" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
														):(
															expenseParti[item].status?(
																<Button onClick={()=>{changeStatus(item,false)}} className="btn-disable br3 d-flex gap-1" size="sm" ><i className="bi bi-x-circle-fill d-flex al-center"></i> Disable</Button>																
															):(																
																<Button onClick={()=>{changeStatus(item,true)}} className="btn-enable br3 d-flex gap-1" size="sm" ><i className="bi bi-check-circle-fill d-flex al-center"></i> Activate</Button>
															)
														)
													}														
											</div>
										</div>
									):''
								))
							):(<Alert variant="info m-3" >NOT found!</Alert>)
						):(<Alert variant="warning m-3" className="t-red br-3" >Something went wrong!</Alert>)
					}
				</div>
			</Col>
			<Modal size="xl" show={updateModal} backdrop="static" onHide={()=>{setUpdateModal(false)}}>
	        	<Modal.Header closeButton><span className="tc-blue f18 f200" >Add Expense Particular</span></Modal.Header>
	        	<Modal.Body>
	        		<Form onSubmit={addPurticular} >				        		
		        		<Form.Group className="mb-3">
		        			<Form.Label className="tc2" >Particular name</Form.Label>
		        			<Form.Control type="text" id="name_err" defaultValue={editable!==undefined&&activeItem!==undefined&&editable?expenseParti[activeItem].name:''} name="name" className="shadow-none br3" />
		        		</Form.Group>
		        		<div  className="d-grid">
					    {
					    	updateLoad?(
					    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
					    	):(
					    	<Button type="submit" className="btn-t1 br3">SAVE</Button>
					    	)
					    }
					    </div>
	        		</Form>
	        	</Modal.Body>
	        	<Modal.Footer>
	        	</Modal.Footer>
    		</Modal>
		</Container>
		)
}