import React, {useContext} from 'react';
import {Container, Table, Spinner, Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import { AppContext } from '../App';


export const ServiceReport=()=>{
	const { getServiceReportLoad, serviceReport, getDate, getServiceReport } = useContext(AppContext);
	const getReport=(e)=>{
		e.preventDefault();
		let start=e.target.start.value;
		let end = e.target.end.value;
		if (start!==""&&end!=="") {
			getServiceReport(start,end);
		}
	}
	return(
			<Container className="py-3">
			
			<Form onSubmit={getReport} >
				<Row className="mb-3" >
					<Col xs={12} sm={4} md={4} >
						<InputGroup  className="br-3">
					        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">From</InputGroup.Text>
					        <Form.Control name="start" type="date" defaultValue={getDate()}  className="br-3" />
					    </InputGroup>
					</Col>
					<Col xs={12} sm={4} md={4} >
						<InputGroup  className="br-3">
					        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">To</InputGroup.Text>
					        <Form.Control name="end" type="date" defaultValue={getDate()}  className="br-3" />
					    </InputGroup>
					</Col>
					<Col xs={12} sm={4} md={4} className="d-grid" >
						<Button type="submit" className="btn-t1" >Get Report</Button>
					</Col>
				</Row>
			</Form> 
			
			{
				getServiceReportLoad?(
					<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
				):(
				serviceReport!==undefined?(
					serviceReport.bills!==undefined?(
							Object.keys(serviceReport.bills).length>0?(
								<Table bordered className="tbl-bg-blue" >
								    <thead>
								        <tr>
								          <th><span className="tp2" >Service name</span></th>
								          <th className="text-end tp2" ><span>Total Count</span></th>
								          <th className="text-end tp2" ><span>TC</span></th>
								          <th className="text-end tp2" ><span>SC</span></th>
								          <th className="text-end tp2" >Total</th>
								        </tr>
								    </thead>
								    <tbody>
								    {
										Object.keys(serviceReport.bills).map((service,s_i)=>(
											<tr key={`servi_${s_i}`} >
												<td><span className="tp2">{serviceReport.bills[service].service_name}</span></td>
												<td className="tp2 text-end"><span>{serviceReport.bills[service].count}</span></td>
												<td className="tp2 text-end"><span>{serviceReport.bills[service].gov_service_charge}</span></td>
												<td className="tp2 text-end"><span>{serviceReport.bills[service].akshaya_charge}</span></td>
												<td className="tp2 text-end"><span>{serviceReport.bills[service].total_amount}</span></td>
											</tr>
										))
									}
									<tr>
										<td colSpan={2} className="text-end f18 tp2 bgy" ><span>Total</span></td>
										<td className="text-end tp2 bgy">{serviceReport.main.transaction_total}</td>
										<td className="text-end tp2 bgy">{serviceReport.main.service_total}</td>
										<td className="text-end tp2 bgy">{serviceReport.main.grand_total}</td>
									</tr>
									</tbody>
								</Table>
							):'Not found'
						):''
					):'Not found!'
				)
			}
			</Container>
		)
}