import React, {useState, useEffect, useContext} from 'react';
import {Container, Row, Col, Button, Form, Modal, Spinner, Accordion, InputGroup } from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import { AppContext } from '../App';

 export const SpecialTokens=()=>{
 	const { link, user, accessToken, tokenServices, setTokenServices,
 	tokenServiceLoad, tokenCustomers, getTokenCustomers, setTokenCustomers, getTokenCustomerLoad, setAuthForm, getSpecialTokens,
 	specialTokens, setSpecialTokens, specialTokenLoad, date, setDate, account } = useContext(AppContext);
 	const [ newTokenModel, setNewTokenModel ] = useState(false);
 	useEffect(()=>{
 		if (!newTokenModel) {
 			setSelectedService();
 			setTokenBookingState('start');
 		}
 	},[newTokenModel]);
 	const [ tokenBookingState, setTokenBookingState ] = useState('start');
 	const [ phoneReqErr, setPhoneReqErr ] = useState(false);
	const [ nameReqErr, setNameReqErr ] = useState(false);
	const [ qtyReqErr, setQtyReqErr ] = useState(false);
 	const [ selectCount, setSelectCount ] = useState(-1);
	const [ showAutoSuggestion, setShowAutoSuggestion ] = useState(false);
 	const [ selectedService, setSelectedService ] = useState();
 	const [ selectedItem, setSelectedItem ] = useState();
	useEffect(()=>{
		if(selectedItem!==undefined){
			$('#cus_phone').val(selectedItem.profile.phone);
			$('#cus_name').val(selectedItem.profile.name);
			$('#cus_address').val(selectedItem.profile.address);
		}else{
			$('#cus_name').val('');
			$('#cus_address').val('');
		}
	},[selectedItem]);
	const [ searchResult, setSearchResult ] = useState({});
	const searchCustomer=(c_num)=>{
		setSearchResult(prev=>{});
		if (c_num!=="") {
			if (tokenCustomers!==undefined){
				setSelectedItem();
				if (Object.keys(tokenCustomers).length>0){
					setSelectCount(-1);
					setShowAutoSuggestion(true);
					let count = 0;
					Object.keys(tokenCustomers).map(customer=>{
						if (count<20) {
							if(tokenCustomers[customer].phone.match(c_num)){
								setSearchResult(prev=>({...prev,[customer]:tokenCustomers[customer]}));
								count+=1;
							}
						}
					});
				}
			}
		}else{
			setSearchResult(prev=>{});
		}
	}
	$('#cus_phone').off().on("keydown", function (e) {
	    var code = e.which || e.keyCode;
	    if (code === 40) {
	    	e.preventDefault();
	    	if (selectCount<Object.keys(searchResult).length-1) {
	    		setSelectCount(prev=>prev+1);
	    	}else{
	    		setSelectCount(0);
	    	}
	    }else if(code === 38){
	    	e.preventDefault();
	    	if (selectCount>0) {
	    		setSelectCount(prev=>prev-1);
	    	}else{
	    		setSelectCount(Object.keys(searchResult).length-1);
	    	}
	    }else if(code === 13){
	    	e.preventDefault();
	    	setShowAutoSuggestion(false);
	    	if(selectCount>=0){
	    		setSelectedItem({id: Object.keys(searchResult)[selectCount], profile:searchResult[Object.keys(searchResult)[selectCount]]});
	    	}
	    }
	});
	const [ addTokenLoad, setAddTokenLoad ] = useState(false);
	const [ tokenAddSuccess, setTokenAddSuccess ] = useState(false);
	const [ tokenToPrint, setTokenToPrint ] = useState();
	const generateToken=(e)=>{
		e.preventDefault();
		let phone = e.target.phone.value;
		let name = e.target.name.value;
		let address = e.target.address.value;
		let remarks = e.target.remarks.value;
		let documents = e.target.documents.value;
		if (name==="") {
			setNameReqErr(true);
			setTimeout(()=>{setNameReqErr(false)},5000);
		}
		if (name!=="") {
			setAddTokenLoad(true);
			axios.post(`${link}api/at/add/token/`,{
					customer_id:selectedItem!==undefined?selectedItem.id.substring(1):'',
					service_id:selectedService!==undefined?selectedService.id.substring(1):'',
					name:name,
					phone:phone,
					address:address,
					remarks:remarks,
					documents:documents,
					date:date,
		        },{
		          headers:{
		            Authorization: 'Bearer '+accessToken
		          }
		        }).then((response)=>{
		        	setAddTokenLoad(false);
		        	setSpecialTokens(response.data.tokens);
		        	if (response.data.print!==undefined) {
		        		setTokenBookingState('print');
		        		setTokenToPrint(response.data.print);
		        	}
		        	getTokenCustomers();
		        }).catch((err)=>{
		        	setAddTokenLoad(false);
			        if(err.response.status === 401){
			            setAuthForm(true);
			        }else if(err.response.status === 404){
			            console.log('Add_special_Token - ',err.response.status,':',err.response.statusText);
			        }else if(err.response.status === 500){
			            console.log('Add_special_Token - ',err.response.status,':',err.response.statusText);
			        }else{
			            console.log('Add_special_Token - ',err.response.status,':',err.response.data);
			        }
		    });
		}
	}
	const [ liveToken, setLiveToken ] = useState();
	const printToken=()=>{
		$('#root').hide();
		$('#printable').html($('#print_special_token').html());		
		setNewTokenModel(false);
		setTimeout(()=>{window.print()},200);
		setTimeout(()=>{
			$('#root').show();
			$('#printable').html('');
		},400);
	}
	const [ serviceSearchResults, setServiceSearchResults  ] = useState({});
	const searchServices=(s_name)=>{
		if(s_name!==""){
			setServiceSearchResults(prev=>{});
			if(tokenServices!==undefined){
			    if(Object.keys(tokenServices).length>0){
			    	let count = 0;
			    	Object.keys(tokenServices).map(service=>{
			    		if (count<50) {
				    		if (tokenServices[service].name.toLowerCase().includes(s_name.toLowerCase())) {
				    			setServiceSearchResults(prev=>({...prev,[service]:tokenServices[service]}));
				    			count+=1;
				    		}
				    	}
			    	});
			    }
			}
		}else{setServiceSearchResults(prev=>{})}
	}
 	return(
 		<Container className="my-3" >
 			<Col  xs={12} sm={12} md={12} lg={12} className='bdr2 br-3 mb-3' >
				<Col xs="12" sm="12" md="12" lg="12" className="staff-type-header bgs1 d-grid p-2 bdrb2" >
					<div className="special-token-taskbar">
						<span>{specialTokenLoad?(<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>):'Tokens'}</span>
						<div className="px-1">
							<Form.Control type="date" onChange={(e)=>{setDate(e.target.value)}} defaultValue={date} className="br-2 shadow-none"  />
						</div>
					</div>
					<Button className="btn-t1" onClick={()=>{setNewTokenModel(true)}}><i className="bi bi-plus-circle"></i> Add new</Button>
				</Col>
				<Col>
		    		<div className="p-2" >
		    		{
		    			specialTokens!==undefined?(
		    				Object.keys(specialTokens).length>0?(
		    					Object.keys(specialTokens).reverse().map((tok,tok_i)=>(
		    							<Row className="mx-0" key={`tok_${tok_i}`} >
							    			<Col xs={12} sm={12} md={12} className="new-token-item bdr1 br-3 mb-2 px-0">
							    				<div className="item-tid bdr2-r">
									          		<span className="tp1 f18" >No : {specialTokens[tok].token_number}</span>
									          		<span className="tp3 f16" >Id : {tok.substring(1)}</span>
									          	</div>
									          	<div className="item-tservice bdr2-r">
									          		{
									          			specialTokens[tok].service!==undefined&&Object.keys(specialTokens[tok].service).length>0?(
									          				<span className="tp1 f18" >{specialTokens[tok].service[Object.keys(specialTokens[tok].service)[0]].name}</span>
									          			):'No service added with this token'
									          		}
									          		<span className="tp3 f14" >{specialTokens[tok].customer_name} / {specialTokens[tok].phone} / {specialTokens[tok].time} /  {`[ Created by ${specialTokens[tok].created_username} ]`} </span>
									          	</div>
											    <div className="item-toption">
											    <Button className='btn-t1' onClick={()=>{
											    	setTokenBookingState('print');
											    	setNewTokenModel(true);
											    	setTokenToPrint({id:tok,details:specialTokens[tok]});
											    }} ><i className="bi bi-printer"></i> Print Token</Button>
											    </div>
							    			</Col>
						    			</Row>
		    						))
		    					):'Nothing new!'
		    				):'Permission denied'
		    		}
		    		</div>
		    	</Col>
			</Col>
		    <Modal size="xl" show={newTokenModel} backdrop="static" onHide={()=>{setNewTokenModel(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>
		          	<div className="d-grid">
		          	{
		          		tokenBookingState!==undefined?(
				        	tokenBookingState==='start'?(
				        		tokenAddSuccess?(
				        				<span className="tp1 mb-1 sucess-alert" >Success!</span>
					        		):(
					        			<span className="tp1 f18 mb-1" >Available services</span>
					        		)
			          			):tokenBookingState==='token'?(
			          				selectedService!==undefined?(
			          					<span className="tp1 f18" >{selectedService.details.name}</span>
			          				):''
			          				
			          			):''
		          			):''
		          	}
		          	</div>
		          </Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        {
		        	tokenBookingState!==undefined?(
			        	tokenBookingState==='start'?(
			        	<Row>
			        		<Col xs={12} sm={12} md={12} lg={12} className="service-search mb-3" >
			        			<Form onSubmit={(e)=>{e.preventDefault()}} >
			        				<InputGroup>
			        					<Form.Control onChange={(e)=>{searchServices(e.target.value)}} className="br-2" size="lg" type="text" placeholder="Search here" />
			        					<InputGroup.Text  className="br-3 btn-t1 px-4" ><i className="bi bi-search"></i></InputGroup.Text>
			        				</InputGroup>
			        			</Form>
			        		</Col>
			        		{
			        			tokenServices!==undefined?(			        				
			        				Object.keys(tokenServices).length>0?(
			        					serviceSearchResults!==undefined&&Object.keys(serviceSearchResults).length>0?(
				        					Object.keys(serviceSearchResults).map((t_serice,ts_i)=>(
				        						<Col xs={12} sm={12} md={12} lg={12} className="mb-3" key={`t_serice_${ts_i}`} >
									        		<div className="token-add-service-show br-3" >
									        			<Accordion flush>
													      <Accordion.Item eventKey="0">
													        <Accordion.Header><span className="tp1 f18" >{tokenServices[t_serice].name}</span></Accordion.Header>
													        <Accordion.Body>
													          <Col>
													          	<p className="ts1 f16 mb-2" >Service Charge <i className="bi bi-currency-rupee"></i>{
													    			parseInt(tokenServices[t_serice].gov_service_charge)+parseInt(tokenServices[t_serice].akshaya_charge)}</p>
													    		<h5 className="ts1" >Required documents</h5>
													          	<p className="p-multi-line ts1" >{tokenServices[t_serice].required_doc}</p>

													          </Col>
													        </Accordion.Body>
													      </Accordion.Item>
													    </Accordion>
													    <Button className="btn-t1" onClick={()=>{
													    	setTokenBookingState('token');
													    	setSelectedService({id:t_serice, details:tokenServices[t_serice]});
													    }} >Book now</Button>
									        		</div>
									        	</Col>
				        					))
			        					):(
				        					Object.keys(tokenServices).map((t_serice,ts_i)=>(
				        						<Col xs={12} sm={12} md={12} lg={12} className="mb-3" key={`t_serice_${ts_i}`} >
									        		<div className="token-add-service-show br-3" >
									        			<Accordion flush>
													      <Accordion.Item eventKey="0">
													        <Accordion.Header><span className="tp1 f18" >{tokenServices[t_serice].name}</span></Accordion.Header>
													        <Accordion.Body>
													          <Col>
													          	<p className="ts1 f16 mb-2" >Service Charge <i className="bi bi-currency-rupee"></i>{
													    			parseInt(tokenServices[t_serice].gov_service_charge)+parseInt(tokenServices[t_serice].akshaya_charge)}</p>
													    		<h5 className="ts1" >Required documents</h5>
													          	<p className="p-multi-line ts1" >{tokenServices[t_serice].required_doc}</p>

													          </Col>
													        </Accordion.Body>
													      </Accordion.Item>
													    </Accordion>
													    <Button className="btn-t1" onClick={()=>{
													    	setTokenBookingState('token');
													    	setSelectedService({id:t_serice, details:tokenServices[t_serice]});
													    }} >Book now</Button>
									        		</div>
									        	</Col>
				        					))
				        				)

			        				):'Services not found'
			        			):'Permission denied'
			        		}
			        		
				        </Row>
				    	):tokenBookingState==='token'?(
				    	<Row>
				    		<Col>
				    			<Form onSubmit={generateToken}>
					        		<Row>
					        			<Col xs={12} sm={12} md={12} lg={6} >
					        				<Form.Group className="mb-3">
							        			<Form.Label>Contact number {phoneReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
							        			<div className="serach-customer d-grid pos-rel">
							        				<Form.Control onBlur={()=>{
							        					setTimeout(()=>{
							        						setShowAutoSuggestion(false);
							        					},200);
							        				}} autoComplete="off" onWheel={(e) => {e.target.blur()} } id="cus_phone" onChange={(e)=>{searchCustomer(e.target.value)}} type="number" name="phone" className="shadow-none br-2" placeholder="Contact number" />
							        				
						        					{
					        						searchResult!==undefined&&showAutoSuggestion?(
					        							Object.keys(searchResult).length > 0 ?(
					        								<div className="customer-search-results bdr1">
					        								{
					        								Object.keys(searchResult).map((cus,cus_index)=>(
					        									<span onClick={()=>{setSelectedItem({ id : cus, profile : searchResult[cus] }) }} className={cus===Object.keys(searchResult)[selectCount]?'d-grid bdrb1 active':'d-grid bdrb1'}  key={`cust_${cus_index}`}>{searchResult[cus].name} - {searchResult[cus].phone} </span>
					        									))
					        								}
					        								</div>
					        								):''
					        								
					        							):''
						        					}
							        			</div>
							        		</Form.Group>
					        			</Col>
					        			<Col xs={12} sm={12} md={12} lg={6} >
					        				<Form.Group className="mb-3">
							        			<Form.Label>Name {nameReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
							        			<Form.Control type="text" id="cus_name" name="name" className="shadow-none br-2" placeholder="Customer Name" />
							        		</Form.Group>
					        			</Col>
					        		</Row>
					        		<Row>
					        			<Col xs={12} sm={12} md={12} lg={6} >
					        				<Form.Group className="mb-3">
							        			<Form.Label>Address</Form.Label>
							        			<Form.Control as="textarea" id="cus_address" className="shadow-none br-2" name="address" />
							        		</Form.Group>
					        			</Col>
					        			<Col xs={12} sm={12} md={12} lg={6} >
					        				<Form.Group className="mb-3">
							        			<Form.Label>Remarks</Form.Label>
							        			<Form.Control as="textarea" id="cus_address" className="shadow-none br-2" name="remarks" />
							        		</Form.Group>
					        			</Col>
					        		</Row>					        		
					        		<Form.Group className="mb-3">
					        			<Form.Label>Documents</Form.Label>
					        			<Form.Control as="textarea" id="cus_address" className="shadow-none br-2" name="documents" />
					        		</Form.Group>
								    <div className="d-grid">
								    {
								    	addTokenLoad?(
					    					<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
								    	):(
								    		<Button className="btn-t1" type="submit" >Generate Token</Button>
								    	)
								    }
								    </div>
							    </Form>
				    		</Col>
				    	</Row>
				    	):tokenBookingState==='print'?(
				    	<Row id="print_special_token" className="print_special_token mx-0">
					    	<Col className="bdr0 mx-0 px-0" >
					    		{
					    			account!==undefined?(
						        		<div className="print-token-header bdr0-b" >
						        			{
							    				account.name!==""||account.address!==""||account.phone_numbers?(
							    					<div className="print-token-address">
									    				<div className="px-3 py-1">
									    					<span className="f20 tp0 d-grid"><b>{account.name}</b></span>
									    					<span className="f16 tp0 d-grid" >{account.address}</span>
									    					<span className="f16 tp0 d-grid" >{account.phone_numbers}</span>
									    				</div>
									    			</div>
							    				):''
							    			}
						        			{
						        				account.logo!==""?(
									    			<div className="print-token-header-logo d-grid" >
									    				<img className="px-3 py-1" src={account.logo} />
									    			</div>
							    				):''
							    			}
							    												    			
							    		</div>
						    		):''
					    		}
					    		<div className="print-token-body px-0" >
						    		<div className="print-token">
						    			<span className="print-token-name pt-1 tp0">TOKEN NUMBER</span>
						    			<span className="print-token-number tp0">{tokenToPrint!==undefined?tokenToPrint.details.token_number:''}</span>
						    		</div>
						    		<div className="print-token-details bdr0-l">
						    			<div className="print-token-detail-item px-2 py-1 bdrb1">
						    				<span className="f16 tp0" >Service</span>
						    				<span className="f16 tp0">:</span>
						    				<span className="f16 tp0">{tokenToPrint!==undefined?tokenToPrint.details.service[Object.keys(tokenToPrint.details.service)[0]].name:''}</span>
						    			</div>
						    			<div className="print-token-detail-item px-2 py-1 bdrb1">
						    				<span className="f16 tp0">Name</span>
						    				<span className="f16 tp0">:</span>
						    				<span className="f16 tp0">{tokenToPrint!==undefined?tokenToPrint.details.customer_name:''}</span>
						    			</div>
						    			<div className="print-token-detail-item px-2 py-1 bdrb1">
						    				<span className="f16 tp0">Phone</span>
						    				<span className="f16 tp0">:</span>
						    				<span className="f16 tp0">{tokenToPrint!==undefined?tokenToPrint.details.phone:''}</span>
						    			</div>
						    			<div className="print-token-detail-item px-2 py-1 bdrb1">
						    				<span className="f16 tp0">Date</span>
						    				<span className="f16 tp0">:</span>
						    				<span className="f16 tp0">{tokenToPrint!==undefined?tokenToPrint.details.date:''}</span>
						    			</div>
						    			<div className="print-token-detail-item px-2 py-1 bdrb1">
						    				<span className="f16 tp0">Documents</span>
						    				<span className="f16 tp0">:</span>
						    				<span className="f16 tp0">{tokenToPrint!==undefined?tokenToPrint.details.documents:''}</span>
						    			</div>
						    			<div className="print-token-detail-item px-2 py-1">
						    				<span className="f16 tp0">Remarks</span>
						    				<span className="f16 tp0">:</span>
						    				<span className="f16 tp0">{tokenToPrint!==undefined?tokenToPrint.details.remarks:''}</span>
						    			</div>
						    		</div>
					    		</div>
					    	</Col>
				    	</Row>
				    	):''
			    	):''
		        }
		        </Modal.Body>
		        <Modal.Footer>
		        {
		        	tokenBookingState!==undefined?(
			        	tokenBookingState==='token'?(
			        		<Button onClick={()=>{
			        			setTokenBookingState('start');
			        			setServiceSearchResults(prev=>{});
			        		}} className="btn-t1" ><i className="bi bi-arrow-left-circle"></i> Back</Button>
			        		
			        	):tokenBookingState==='print'?(
			        		<Button onClick={()=>{
			        			printToken();
			        		}} className="btn-t1" ><i className="bi bi-printer"></i> Print</Button>

			        	):''
			        ):''
		        }
		        </Modal.Footer>
		    </Modal>
		</Container>
 		)
 }