import React, {useState, useEffect, useContext} from 'react';
import {Row, Col, Button, Form, Modal, Table, Spinner, Accordion, InputGroup } from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../App';

 export const Token=()=>{
 	const { link, user, accessToken, tokens, setTokens, tokenServices, payModes, tokenCustomers, setAuthForm, dayClose, payWallet, postCall,
 	account, getTokenCustomers, setStateSettle, setStateSettleAll } = useContext(AppContext); 
 	const [ tokenModel, setTokenModel  ] = useState(false);
 	const [ manageTokenState, setManageTokenState ] = useState('add_service');
 	const [ paymentWallet, setPaymentWallet ] = useState({});
 	useEffect(()=>{
 		if (!tokenModel) {
 			setLiveToken();
 			setSelectedServiceItem();
 			setManageTokenState('add_service');
 			setRecService({});
 			setBillAmount({});
 			setTotalCashEntry();
 			setPaymentWallet({});
 			setWalletEntry();
 			setWallBalance();
 			setPS(false);
 			setEditToken(false);
 		}
 	},[tokenModel]);
	const [ openTokenLoad, setOpenTokenLoad ] = useState(false);
	const [ liveToken, setLiveToken ] = useState();
	const [ subTotal, setSubTotal ] = useState(0);
	const [ recStatus, setRecStatus ] = useState(false);
	const checkRecStatus=()=>{
		let p = new Promise((resolve, reject)=>{
			if(liveToken!==undefined){
				if (Object.keys(liveToken.details.service).length>0) {
					Object.keys(liveToken.details.service).map(servi=>{
						if (liveToken.details.service[servi].reccurent) {
							resolve();
						}
					});
				}
			}
			reject();
		});
		p.then((res)=>{
			setRecStatus(true);
		}).catch((err)=>{
			setRecStatus(false);
		});
	}
	useEffect(()=>{
		if(liveToken!==undefined){
			console.log(liveToken);
			setSubTotal(prev=>(0));
			Object.keys(liveToken.details.service).map(item=>{
				setSubTotal(prev=>(
					prev+(parseInt(liveToken.details.service[item].gov_service_charge)+parseInt(liveToken.details.service[item].akshaya_charge))*parseInt(liveToken.details.service[item].quantity)
					));
			});
			checkRecStatus();
		}
	},[liveToken]);
	const openToken=(tok)=>{
		setLiveToken({id:tok,details:tokens[tok]});
		setOpenTokenLoad(true);
		setTokenModel(true);
		setManageTokenState('add_service');
		postCall('api/mt/open/token/',{token_id:tok.substring(1)}).then((response)=>{
        	setTokens(response.data.tokens);	        	
	        setStateSettle(response.data.settle);
	        setStateSettleAll(response.data.settle_all);
        	setLiveToken({id:tok,details:response.data.tokens[tok]});	        	
        	setOpenTokenLoad(false);
        }).catch((err)=>{
        	setOpenTokenLoad(false);
	    });
	}
	const [ serviceSearchResult, setServiceSearchResult ] = useState({});
	const [ serviceSelectCount, setServiceSelectCount ] = useState(-1);
	const [ selectedServiceItem, setSelectedServiceItem ] = useState();
	useEffect(()=>{
		if(selectedServiceItem!==undefined){
			$('#serv_search').val(selectedServiceItem.service.name);
			$('#service_qty').focus();
		}
	},[selectedServiceItem]);
	$('#service_qty').off().on("keydown", function (e) {
		let code = e.which || e.keyCode;
		if(code === 13){
			e.preventDefault();
			$('#add_item').focus();
		}
	});
	const [ showAutoSuggestion, setShowAutoSuggestion ] = useState(false);	
	const serviceSearch=(search_key)=>{
		setServiceSearchResult(prev=>({}));
		if (search_key!=="") {
			if (tokenServices!==undefined){
				setSelectedServiceItem();
				if (Object.keys(tokenServices).length>0){
					setServiceSelectCount(-1);
					setShowAutoSuggestion(true);
					let count = 0;
					Object.keys(tokenServices).map(service=>{
						if (count<50) {
							if(tokenServices[service].name.toLowerCase().includes(search_key.toLowerCase())){
								setServiceSearchResult(prev=>({...prev,[service]:tokenServices[service]}));
								count+=1;
							}
						}
					});
				}
			}
		}else{
			setServiceSearchResult(prev=>({}));
		}
	}
	$('#serv_search').off().on("keydown", function (e) {
	    var code = e.which || e.keyCode;
	    if (code === 40) {
	    	e.preventDefault();
	    	if (serviceSelectCount<Object.keys(serviceSearchResult).length-1) {
	    		setServiceSelectCount(prev=>prev+1);
	    	}else{
	    		setServiceSelectCount(0);
	    	}
	    }else if(code === 38){
	    	e.preventDefault();
	    	if (serviceSelectCount>0) {
	    		setServiceSelectCount(prev=>prev-1);
	    	}else{
	    		setServiceSelectCount(Object.keys(serviceSearchResult).length-1);
	    	}
	    }else if(code === 13){
	    	e.preventDefault();
	    	setShowAutoSuggestion(false);
	    	if(serviceSelectCount>=0){
	    		setSelectedServiceItem({id: Object.keys(serviceSearchResult)[serviceSelectCount], service:serviceSearchResult[Object.keys(serviceSearchResult)[serviceSelectCount]]});
	    	}
	    }
	});
	const [ addServiceToTokenLoad, setAddServiceToTokenLoad ] = useState(false);
	const serviceAdd=(e)=>{
		e.preventDefault();
		if (liveToken!==undefined&&selectedServiceItem!==undefined) {
			setAddServiceToTokenLoad(true);
			postCall('api/mt/add/service/token/',{
				token_id:liveToken.id.substring(1),
				service_id:selectedServiceItem.id.substring(1),
				quantity:e.target.qty.value,
	        }).then((response)=>{
	        	setAddServiceToTokenLoad(false);
	        	setTokens(response.data.tokens);	        	
		        setStateSettle(response.data.settle);
		        setStateSettleAll(response.data.settle_all);
	        	setLiveToken({ id:liveToken.id, details:response.data.tokens[liveToken.id] });
	        	$('#serv_search').val('');
				$('#serv_search').focus();
				setSelectedServiceItem();
				setServiceSelectCount(-1);
	        }).catch((err)=>{
	        	setAddServiceToTokenLoad(false);
		    });
		}
	}
	const [ serviceEdit, setServiceEdit ] = useState(false);
	const [ serviceEditLoad, setServiceEditLoad ] = useState(false);
	const [ editServiceItem, setEditServiceItem ] = useState();
	const updateServiceQty=(e)=>{
		e.preventDefault();
		let qty=e.target.qty.value;
		if (qty!==""&&qty>0) {
			setServiceEditLoad(true);
			postCall('api/mt/edit/service/token/',{
				token_id:liveToken.id.substring(1),
				tokenservice_id:editServiceItem.id.substring(1),
				quantity:qty,
	        }).then((response)=>{
	        	setServiceEditLoad(false);
	        	setTokens(response.data.tokens);	        	
		        setStateSettle(response.data.settle);
		        setStateSettleAll(response.data.settle_all);
	        	setLiveToken({ id:liveToken.id, details:response.data.tokens[liveToken.id] });
	        	setServiceEdit(false);
	        	setEditServiceItem();
	        }).catch((err)=>{
	        	setServiceEditLoad(false);
		    });
		}
	}
	const [ deleteServiceId, setDeleteServiceId ] = useState()
	const [ serviceDeleteLoad, setServiceDeleteLoad ] = useState(false);
	const deleteTokenService=(did)=>{
		if (liveToken!==undefined&&did!=="") {
			setServiceDeleteLoad(true);
			postCall('api/mt/delete/service/token/',{
				token_id:liveToken.id.substring(1),
				tokenservice_id:did.substring(1),
	        }).then((response)=>{
	        	setServiceDeleteLoad(false);
	        	setTokens(response.data.tokens);	        	
		        setStateSettle(response.data.settle);
		        setStateSettleAll(response.data.settle_all);
	        	setLiveToken({ id:liveToken.id, details:response.data.tokens[liveToken.id] });
	        	setDeleteServiceId();
	        }).catch((err)=>{
	        	setServiceDeleteLoad(false);
		    });
		}
	}
	const [ cancelTokenLoad, setCancelTokenLoad ] = useState(false);
	const [ reasonREqErr, setReasonReqErr ] = useState(false);
	const cancelToken=(e)=>{
		e.preventDefault();
		let reason=e.target.reason.value;
		if (reason==="") {
			setReasonReqErr(true);
			setTimeout(()=>{setReasonReqErr(false)},5000);
		}
		if(liveToken!==undefined&&reason!==""){
			setCancelTokenLoad(true);
			postCall('api/mt/cancel/token/',{
				token_id:liveToken.id.substring(1),
				reason:reason,
	        }).then((response)=>{
	        	setCancelTokenLoad(false);
	        	setTokens(response.data.tokens);	        	
		        setStateSettle(response.data.settle);
		        setStateSettleAll(response.data.settle_all);
	        	setTokenModel(false);
	        }).catch((err)=>{
	        	setCancelTokenLoad(false);
			});
		}
	}
	const [ recService, setRecService ] = useState({});
	useEffect(()=>{
		setCusSelectCount(-1);
	},[recService]);
	const setReccurent=()=>{
		setRecService(prev=>({}));
		if(liveToken!==undefined){
			if (Object.keys(liveToken.details.service).length>0) {
				Object.keys(liveToken.details.service).map(servi=>{
					if (liveToken.details.service[servi].reccurent) {
						setRecService(prev=>({...prev,[servi]:liveToken.details.service[servi]}));
						setRecService(prev=>({...prev,[servi]:{
							...prev[servi], notify:{
								...prev[servi].notify, expiry_date:liveToken.details.service[servi].expiry_date
								}
							}}
						));
						if (tokenCustomers[`_${liveToken.details.customer_id}`]!==undefined) {
							setRecService(prev=>({...prev,[servi]:{
								...prev[servi], notify:{
									...prev[servi].notify, phone:tokenCustomers[`_${liveToken.details.customer_id}`].phone
									}
								}}
							));
							setRecService(prev=>({...prev,[servi]:{
								...prev[servi], notify:{
									...prev[servi].notify, customer_id:liveToken.details.customer_id
									}
								}}
							));
							setRecService(prev=>({...prev,[servi]:{
								...prev[servi], notify:{
									...prev[servi].notify, name:tokenCustomers[`_${liveToken.details.customer_id}`].name
									}
								}}
							));
							setRecService(prev=>({...prev,[servi]:{
								...prev[servi], notify:{
									...prev[servi].notify, address:tokenCustomers[`_${liveToken.details.customer_id}`].address
									}
								}}
							));
						}
					}
				});
			}
		}
	}
	const [ wallBalance, setWallBalance ] = useState();
	const [ getWbLoad, setGetWbLoad ] = useState(false);
	const [ walletEntry, setWalletEntry ] = useState();
	const checkReccurent=()=>{
		setGetWbLoad(true);
		postCall('api/ap/check/wallet/balance/from/token/id/',{t_id:liveToken.id.substring(1)}).then(res=>{
			setGetWbLoad(false);
			if (res.data.status) {
				setWallBalance(res.data.balance);
			}
		}).catch(err=>{
			setGetWbLoad(false);
		});
		let p = new Promise((resolve, reject)=>{
			if(liveToken!==undefined){
				if (Object.keys(liveToken.details.service).length>0) {
					Object.keys(liveToken.details.service).map(servi=>{
						if (liveToken.details.service[servi].reccurent) {
							resolve();
						}
					});
				}
			}
			reject();
		});
		p.then((res)=>{
			setManageTokenState('reccurent_service');
			setReccurent();
		}).catch((err)=>{
			setManageTokenState('payment_token');
		});
	}
	const backCheckReccurent=()=>{
		let p = new Promise((resolve, reject)=>{
			if(liveToken!==undefined){
				if (Object.keys(liveToken.details.service).length>0) {
					Object.keys(liveToken.details.service).map(servi=>{
						if (liveToken.details.service[servi].reccurent) {
							resolve();
						}
					});
				}
			}
			reject();
		});
		p.then((res)=>{
			setManageTokenState('reccurent_service');
		}).catch((err)=>{
			setManageTokenState('add_service');
		});
	}
	const [ nRecId,setNrecId ] = useState();
	const [ recAutoSuggest, setRecAutoSuggest ] = useState(false);
	const [ customerSearchResult, setCustomerSearchResult ] = useState();
	const [ cusSelectCount, setCusSelectCount ] = useState(-1);
	const searchCustomer=(c_num,recid)=>{
		setCustomerSearchResult(prev=>{});
		if (c_num!=="") {
			if (tokenCustomers!==undefined){
				if(recid!==undefined){
					$(`#name${recid}`).val('');
					$(`#address${recid}`).val('');
					if (recService[recid]!==undefined) {
						setRecService(prev=>({...prev,[recid]:{...prev[recid], notify:{...prev[recid].notify, name:""}}}));
						setRecService(prev=>({...prev,[recid]:{...prev[recid], notify:{...prev[recid].notify, address:""}}}));
						setRecService(prev=>({...prev,[recid]:{...prev[recid], notify:{...prev[recid].notify, customer_id:""}}}));
					}
				}
				if (Object.keys(tokenCustomers).length>0){
					setCusSelectCount(-1);
					setRecAutoSuggest(true);
					let count = 0;
					Object.keys(tokenCustomers).map(customer=>{
						if (count<20) {
							if(tokenCustomers[customer].phone.includes(c_num)){
								setCustomerSearchResult(prev=>({...prev,[customer]:tokenCustomers[customer]}));
								count+=1;
							}
						}
					});
				}
			}
		}else{
			setCustomerSearchResult(prev=>{});
		}
	}
	$('.cus_phone').off().on("keydown", function (e) {
	    var code = e.which || e.keyCode;
	    if (customerSearchResult!==undefined&&Object.keys(customerSearchResult).length>0) {
		    if (code === 40) {
		    	e.preventDefault();
		    	if (cusSelectCount<Object.keys(customerSearchResult).length-1) {
		    		setCusSelectCount(prev=>prev+1);
		    	}else{
		    		setCusSelectCount(0);
		    	}
		    }else if(code === 38){
		    	e.preventDefault();
		    	if (cusSelectCount>0) {
		    		setCusSelectCount(prev=>prev-1);
		    	}else{
		    		setCusSelectCount(Object.keys(customerSearchResult).length-1);
		    	}
		    }else if(code === 13){
		    	if(cusSelectCount>=0){
			    	e.preventDefault();
			    	setRecAutoSuggest(false);
			    	if(cusSelectCount>=0){
			    		$(`#phone${nRecId}`).val(customerSearchResult[Object.keys(customerSearchResult)[cusSelectCount]].phone);
						$(`#name${nRecId}`).val(customerSearchResult[Object.keys(customerSearchResult)[cusSelectCount]].name);
						$(`#address${nRecId}`).val(customerSearchResult[Object.keys(customerSearchResult)[cusSelectCount]].address);
						setRecService(prev=>({...prev,[nRecId]:{
							...prev[nRecId], notify:{
								...prev[nRecId].notify, customer_id:Object.keys(customerSearchResult)[cusSelectCount].substring(1),
								}
							}}
						));
						setRecService(prev=>({...prev,[nRecId]:{
							...prev[nRecId], notify:{
								...prev[nRecId].notify, phone:tokenCustomers[Object.keys(customerSearchResult)[cusSelectCount]].phone,
								}
							}}
						));
						setRecService(prev=>({...prev,[nRecId]:{
							...prev[nRecId], notify:{
								...prev[nRecId].notify, name:customerSearchResult[Object.keys(customerSearchResult)[cusSelectCount]].name,
								}
							}}
						));
						setRecService(prev=>({...prev,[nRecId]:{
							...prev[nRecId], notify:{
								...prev[nRecId].notify, address:customerSearchResult[Object.keys(customerSearchResult)[cusSelectCount]].address,
								}
							}}
						));
			    	}
			    }
		    }
		}
	});
	const changeNotify=(keyword,recS,parm)=>{
		setRecService(prev=>({...prev,[recS]:{
						...prev[recS], notify:{
							...prev[recS].notify, [parm]:keyword
							}
						}}
					));
		if (parm==='phone') {
			setNrecId(recS);
			searchCustomer(keyword,recS);
		}
	}
	const [ billAmount, setBillAmount ] = useState({});
	const [ totalCashEntry, setTotalCashEntry ] = useState(0);
	useEffect(()=>{
		setTotalCashEntry(0);
		if (billAmount!==undefined&&Object.keys(billAmount).length>0) {
			Object.values(billAmount).map(amt=>{
				setTotalCashEntry(prev=>(parseInt(prev)+parseInt(amt)));
			});
		}
	},[billAmount]);
	const addBillAmount=(amt,mode)=>{
		if(amt!==""){
			setBillAmount(prev=>({...prev,[mode]:amt}));
		}else{
			setBillAmount(prev=>({...prev,[mode]:0}));
		}
	}
	const [ billSettleLoad, setBillSettleLoad ] = useState(false);
	const formatRec=()=>{
		let ret=[];
		if (recService!==undefined&&Object.keys(recService).length>0) {
			Object.keys(recService).map(recs=>{
				ret.push({id:recs.substring(1),notify:recService[recs].notify});
			});
		}
		return(ret);
	}
	const [ editToken, setEditToken ] = useState(false);
	const [ editTokId, setEditTokId ] = useState();
	const settleBill=()=>{
		if (subTotal!==undefined&&totalCashEntry!==undefined&&subTotal===totalCashEntry) {
			setBillSettleLoad(true)
			if (editToken!==undefined&&editToken) {
				postCall('api/mt/edit/token/end/',{
					token_id:liveToken.id.substring(1),
					rec_service : formatRec(),
					payment : billAmount,
					wallet : paymentWallet,
		        }).then((response)=>{
		        	setTokens(response.data.tokens);	        	
			        setStateSettle(response.data.settle);
			        setStateSettleAll(response.data.settle_all);
		        	setBillSettleLoad(false);
		        	if (!pS) {
		        		setTokenModel(false);
		        	}
		        }).catch((err)=>{
		        	setBillSettleLoad(false);
			    });
			}else{
				postCall('api/mt/close/token/',{
					token_id:liveToken.id.substring(1),
					rec_service : formatRec(),
					payment : billAmount,
					wallet : paymentWallet,
		        }).then((response)=>{
		        	setTokens(response.data.tokens);	        	
			        setStateSettle(response.data.settle);
			        setStateSettleAll(response.data.settle_all);
		        	setBillSettleLoad(false);
		        	if (!pS) {
		        		setTokenModel(false);
		        	}
		        }).catch((err)=>{
		        	setBillSettleLoad(false);
			    });
		    }
		}
	}
	const [ submitTokenLoad, setSubmitTokenLoad ] = useState(false);
	const submitToken=()=>{
		setSubmitTokenLoad(true)
		postCall('api/mt/submit/token/',{
			token_id:liveToken.id.substring(1),
			rec_service : formatRec(),
			wallet : paymentWallet,
        }).then((response)=>{
        	setTokens(response.data.tokens);	        	
	        setStateSettle(response.data.settle);
	        setStateSettleAll(response.data.settle_all);
        	setSubmitTokenLoad(false);
        	setTokenModel(false);
        }).catch((err)=>{
        	setSubmitTokenLoad(false);
	    });
	}
 	const [ newTokenModel, setNewTokenModel ] = useState(false);
 	useEffect(()=>{
 		if (!newTokenModel) {
 			setSelectedService();
 			setTokenBookingState('start');
 			setServiceSearchResults(prev=>{});
 		}
 	},[newTokenModel]);
 	const [ tokenBookingState, setTokenBookingState ] = useState('start');
 	const [ phoneReqErr, setPhoneReqErr ] = useState(false);
	const [ nameReqErr, setNameReqErr ] = useState(false);
	const [ qtyReqErr, setQtyReqErr ] = useState(false);
 	const [ selectCount, setSelectCount ] = useState(-1);
	const [ showAutoSuggestionNew, setShowAutoSuggestionNew ] = useState(false);
 	const [ selectedService, setSelectedService ] = useState();
 	const [ selectedItem, setSelectedItem ] = useState();
	useEffect(()=>{
		if(selectedItem!==undefined){
			$('#cus_phone').val(selectedItem.profile.phone);
			$('#cus_name').val(selectedItem.profile.name);
			$('#cus_address').val(selectedItem.profile.address);
		}else{
			$('#cus_name').val('');
			$('#cus_address').val('');
		}
	},[selectedItem]);
	const [ searchResult, setSearchResult ] = useState({});
	const searchCustomerNew=(c_num)=>{
		setSearchResult(prev=>{});
		if (c_num!=="") {
			if (tokenCustomers!==undefined){
				setSelectedItem();
				if (Object.keys(tokenCustomers).length>0){
					setSelectCount(-1);
					setShowAutoSuggestionNew(true);
					let count=0;
					Object.keys(tokenCustomers).map(customer=>{
						if (count<20) {
							if(tokenCustomers[customer].phone.match(c_num)){
								setSearchResult(prev=>({...prev,[customer]:tokenCustomers[customer]}));
								count+=1;
							}
						}
					});
				}
			}
		}else{
			setSearchResult(prev=>{});
		}
	}
	$('#cus_phone').off().on("keydown", function (e) {
	    var code = e.which || e.keyCode;
	    if (code === 40) {
	    	e.preventDefault();
	    	if (selectCount<Object.keys(searchResult).length-1) {
	    		setSelectCount(prev=>prev+1);
	    	}else{
	    		setSelectCount(0);
	    	}
	    }else if(code === 38){
	    	e.preventDefault();
	    	if (selectCount>0) {
	    		setSelectCount(prev=>prev-1);
	    	}else{
	    		setSelectCount(Object.keys(searchResult).length-1);
	    	}
	    }else if(code === 13){
	    	e.preventDefault();
	    	setShowAutoSuggestionNew(false);
	    	if(selectCount>=0){
	    		setSelectedItem({id: Object.keys(searchResult)[selectCount], profile:searchResult[Object.keys(searchResult)[selectCount]]});
	    	}
	    }
	});
	const [ addTokenLoad, setAddTokenLoad ] = useState(false);
	const [ tokenAddSuccess, setTokenAddSuccess ] = useState(false);
	const [ tokenToPrint, setTokenToPrint ] = useState();
	const generateToken=(e)=>{
		e.preventDefault();
		let phone = e.target.phone.value;
		let name = e.target.name.value;
		let address = e.target.address.value;
		let qty = e.target.qty.value;
		let remarks = e.target.remarks.value;
		let documents = e.target.documents.value;
		if (name==="") {
			setNameReqErr(true);
			setTimeout(()=>{setNameReqErr(false)},5000);
		}
		if(qty === ""){
			setQtyReqErr(true);
			setTimeout(()=>{setQtyReqErr(false)},5000);
		}
		if (name!==""&&qty!=="") {
			setAddTokenLoad(true);
			postCall('api/mt/add/token/',{
				customer_id:selectedItem!==undefined?selectedItem.id.substring(1):'',
				service_id:selectedService!==undefined?selectedService.id.substring(1):'',
				name:name,
				phone:phone,
				address:address,
				quantity:qty,
				remarks:remarks,
				documents:documents,
	        }).then((response)=>{
	        	setAddTokenLoad(false);
	        	setTokens(response.data.tokens);
	        	setStateSettle(response.data.settle);
		        setStateSettleAll(response.data.settle_all);
	        	// if (response.data.print!==undefined) {
	        	// 	setTokenBookingState('print');
	        	// 	setTokenToPrint(response.data.print);
	        	// }
	        	setNewTokenModel(false);
	        	getTokenCustomers();
	        }).catch((err)=>{
	        	setAddTokenLoad(false);
	        });
		}
	}
	const printBill=(live_tkn)=>{
		$('#root').hide();
		$('#printable').html($('#bill_print').html());
		setTokenModel(false);
		setTimeout(()=>{window.print()},200);
		setTimeout(()=>{
			$('#root').show();
			$('#printable').html('');
			if (tokens!==undefined) {
				setLiveToken({id:live_tkn,details:tokens[live_tkn]});
				setTokenModel(true);
				setManageTokenState('print');
			}
		},400);
	}
	const [ pS, setPS ] = useState(false);
	const prinntAndSettle=(live_tkn)=>{
		if (subTotal!==undefined&&totalCashEntry!==undefined&&subTotal===totalCashEntry) {
			settleBill();
			setManageTokenState('print');
			setTimeout(()=>{
				$('#root').hide();
				$('#printable').html($('#bill_print').html());
				setTokenModel(false);
				setTimeout(()=>{window.print()},200);
				setTimeout(()=>{
					$('#root').show();
					$('#printable').html('');
				},400);
			},100);
		}
	}
	const printOldBill=(tok)=>{
		setLiveToken({id:tok,details:tokens[tok]});
		setTokenModel(true);
		setManageTokenState('print');
		setTimeout(()=>{
			$('#root').hide();
			$('#printable').html($('#bill_print').html());
			setTokenModel(false);
			setTimeout(()=>{window.print()},200);
			setTimeout(()=>{
				$('#root').show();
				$('#printable').html('');
				setTokenModel(false);
			},400);
		},100);
	}
	const printToken=()=>{
		$('#root').hide();
		$('#printable').html($('#print_special_token').html());		
		setNewTokenModel(false);
		setTimeout(()=>{window.print()},200);
		setTimeout(()=>{
			$('#root').show();
			$('#printable').html('');
		},400);
	}
	const [ serviceSearchResults, setServiceSearchResults  ] = useState({});
	const searchServices=(s_name)=>{
		if(s_name!==""){
			setServiceSearchResults(prev=>{});
			if(tokenServices!==undefined){
			    if(Object.keys(tokenServices).length>0){
			    	let count = 0;
			    	Object.keys(tokenServices).map(service=>{
			    		if (count<20) {
				    		if (tokenServices[service].name.toLowerCase().includes(s_name.toLowerCase())) {
				    			setServiceSearchResults(prev=>({...prev,[service]:tokenServices[service]}));
				    			count+=1;
				    		}
				    	}
			    	});
			    }
			}
		}else{setServiceSearchResults(prev=>{})}
	}
	const [ ediTc, setEditTc ] = useState();
	const [ editTcLoad, setEditTcLoad ] = useState(false);
	const updateTc=(e)=>{
		e.preventDefault();
		let tc=e.target.tc.value;
		if (tc!=="") {
			setEditTcLoad(true);
			postCall('api/mt/edit/transaction/charge/',{
				token_id:liveToken.id.substring(1),
				tokenservice_id:ediTc.id.substring(1),
				gov_service_charge:tc,
	        }).then((response)=>{
	        	setEditTcLoad(false);
	        	setTokens(response.data.tokens);	        	
		        setStateSettle(response.data.settle);
		        setStateSettleAll(response.data.settle_all);
	        	setLiveToken({ id:liveToken.id, details:response.data.tokens[liveToken.id] });
	        	setEditTc();
	        }).catch((err)=>{
	        	setEditTcLoad(false);
			});
		}
	}
	const [ ediSc, setEditSc ] = useState();
	const [ editScLoad, setEditScLoad ] = useState(false);
	const updateSc=(e)=>{
		e.preventDefault();
		let sc=e.target.sc.value;
		if (sc!=="") {
			setEditScLoad(true);
			postCall('api/mt/edit/service/charge/',{
				token_id:liveToken.id.substring(1),
				tokenservice_id:ediSc.id.substring(1),
				akshaya_charge:sc,
	        }).then((response)=>{
	        	setEditScLoad(false);
	        	setTokens(response.data.tokens);	        	
		        setStateSettle(response.data.settle);
		        setStateSettleAll(response.data.settle_all);
	        	setLiveToken({ id:liveToken.id, details:response.data.tokens[liveToken.id] });
	        	setEditSc();
	        }).catch((err)=>{
	        	setEditScLoad(false);
		    });
		}
	}
	
 	return(
 		<div className="container-fluid px-0 mt-3">
	    	<Row className="mx-0" >
	    		<Col xs={12} sm={12} md={6} lg={6} className="d-grid mb-3">
			    	<Col className="token-item px-0">
			    		<div className="token-item-header-new">
			    			<span className="f18" >New tokens</span>
			    			<div className="d-grid" >
			    			{
				            	user!==undefined?(
				            		user.type==='admin'?(
			    						<Button onClick={()=>{setNewTokenModel(true)}} className="btn btn-add-token br-3 d-grid">Add new</Button>
			    						):(
						            	user.permission!==undefined?(
					            			user.permission.token.A?(
			    								<Button onClick={()=>{setNewTokenModel(true)}} className="btn btn-add-token br-3 d-grid">Add new</Button>
					            			):''
				            			):''
				            		):''
				            	):''
				            }
			    			</div>
			    		</div>
			    		<div className="token-item-body p-2" >
				    		{
				    			tokens!==undefined?(
				    				Object.keys(tokens).length>0?(
				    					Object.keys(tokens).map((tok,tok_i)=>(
					    						tokens[tok].status==='create'?(
					    							<Row className="mx-0" key={`tok_${tok_i}`} >
										    			<Col xs={12} sm={12} md={12} className="new-token-service-item bdr1 br-3 mb-2 px-0">
										    				<div className="item-tid bdr2-r">
												          		<span className="tp1 f18" >No : {tokens[tok].token_number}</span>
												          		<span className="tp3 f16" >Id : {tok.substring(1)}</span>
												          	</div>
												          	<div className="item-tservice bdr2-r">
												          		{
												          			tokens[tok].service!==undefined&&Object.keys(tokens[tok].service).length>0?(
												          				<span className="tp1 f18" >{tokens[tok].service[Object.keys(tokens[tok].service)[0]].name}</span>
												          			):'No service added with this token'
												          		}
												          		<span className="tp3 f14" >{tokens[tok].customer_name} / {tokens[tok].phone} / {tokens[tok].time} /  {`[ Created by ${tokens[tok].created_username} ]`} </span>
												          	</div>
												          	<div className="item-toption">
												          		<Button className='btn-t1' onClick={()=>{
															    	setTokenBookingState('print');
															    	setNewTokenModel(true);
															    	setTokenToPrint({id:tok,details:tokens[tok]});
															    }} ><i className="bi bi-printer"></i> Print</Button>
												          	</div>
														    <div className="item-toption">
														    {
														    	user!==undefined?(
					            									user.type==='admin'?(
					            										dayClose!==undefined&&dayClose?(
																	    	liveToken!==undefined&&liveToken.id===tok&&openTokenLoad?(
											    								<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /></span></Button>
																    		):(
																    			<Button className="open-btn" onClick={()=>{openToken(tok)}} >Open</Button>
																    		)
																    	):''
					            									):(
						            									user.permission!==undefined?(
							            									user.permission.token.E?(
							            										dayClose!==undefined&&dayClose?(
																			    	liveToken!==undefined&&liveToken.id===tok&&openTokenLoad?(
													    								<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /></span></Button>
																		    		):(
																		    			<Button className="open-btn" onClick={()=>{openToken(tok)}} >Open</Button>
																		    		)
																		    	):''
							            									):''
							            								):''
					            									)
					            								):''											    	
														    }
														    </div>
										    			</Col>
									    			</Row>
									    		):''
				    						))
				    					):'Nothing new!'
				    				):'Permission denied'
				    		}
			    		</div>
			    	</Col>
		    	</Col>
		    	<Col xs={12} sm={12} md={6} lg={6} className="d-grid mb-3" >
			    	<Col className="token-item px-0">
			    		<div className="token-item-header"><span className="f18" >Ongoing tokens</span></div>
			    		<div className="token-item-body p-2" >
				    		{
				    			tokens!==undefined?(
				    				Object.keys(tokens).length>0?(
				    					Object.keys(tokens).reverse().map((tok,tok_i)=>(
					    						tokens[tok].status==='open'?(
					    							<Row className="mx-0" key={`toko_${tok_i}`} >
										    			<Col xs={12} sm={12} md={12} className="token-service-item bdr1 br-3 mb-2 px-0">
										    				<div className="item-tid bdr2-r">
												          		<span className="tp1 f18" >No : {tokens[tok].token_number}</span>
												          		<span className="tp3 f16" >Id : {tok.substring(1)}</span>
												          	</div>
												          	<div className="item-tservice bdr2-r">
												          		{
												          			tokens[tok].service!==undefined&&Object.keys(tokens[tok].service).length>0?(
												          				<span className="tp1 f18" >{tokens[tok].service[Object.keys(tokens[tok].service)[0]].name}</span>
												          			):'No service added with this token'
												          		}
												          		<span className="tp3 f14" >{tokens[tok].customer_name} / {tokens[tok].phone} / {tokens[tok].time} / {`[ Open by ${tokens[tok].username} ]`}</span>
												          	</div>
														    {
														    	user!==undefined?(
					            									user.type==='admin'?(
					            										dayClose!==undefined&&dayClose?(
																	    	liveToken!==undefined&&liveToken.id===tok&&openTokenLoad?(
																	    		<div className="item-toption">
											    								<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /></span></Button>
																    			</div>
																    		):(
																    			<div className="item-toption">
																	    			<Button className="open-btn" onClick={()=>{
																	    				setLiveToken({id:tok,details:tokens[tok]});
																						setTokenModel(true);
																						setManageTokenState('add_service');
																	    			}} >Edit</Button>
																    			</div>
																    		)
																	    ):''
					            									):(
						            									user.permission!==undefined?(
						            										user.profile.user_id===tokens[tok].user_id?(
								            									user.permission.token.E||user.permission.token.X?(
								            										dayClose!==undefined&&dayClose?(
																				    	liveToken!==undefined&&liveToken.id===tok&&openTokenLoad?(
																				    	<div className="item-toption">
														    								<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /></span></Button>
																			    		</div>
																			    		):(
																			    			<div className="item-toption">
																			    			<Button className="open-btn" onClick={()=>{
																			    				setLiveToken({id:tok,details:tokens[tok]});
																								setTokenModel(true);
																								setManageTokenState('add_service');
																			    			}} >Edit</Button>
																			    			</div>
																			    		)
																				    ):''
								            									):''
							            									):(
							            										user.permission.token.X?(
							            											dayClose!==undefined&&dayClose?(
																				    	liveToken!==undefined&&liveToken.id===tok&&openTokenLoad?(
																				    	<div className="item-toption">
														    								<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /></span></Button>
																			    		</div>
																			    		):(
																			    			<div className="item-toption">
																			    			<Button className="open-btn" onClick={()=>{
																			    				setLiveToken({id:tok,details:tokens[tok]});
																								setTokenModel(true);
																								setManageTokenState('add_service');
																			    			}} >Edit</Button>
																			    			</div>
																			    		)
																				    ):''
							            										):(
									            									<div className="item-toption-add">
									            										{
										            										tokens[tok].status=='create'?(
																				    			<span className="t-blue f18">New token</span>
																				    		):tokens[tok].status=='open'?(
																				    			<span className="t-yellow f18">Processing</span>
																				    		):tokens[tok].status=='close'?(
																				    			<span className="sucess-alert">Completed</span>
																				    		):tokens[tok].status=='cancel'?(
																				    			<span className="t-red f18">Cancelled</span>
																				    		):''
																				    	}
																			    	</div>
																			    	)
						            										)
							            								):''
					            									)
					            								):''											    	
														    }
										    			</Col>
									    			</Row>
									    		):''
				    						))
				    					):'Nothing new!'
				    				):'Permission denied'
				    		}
			    		</div>
			    	</Col>
		    	</Col>
	    	</Row>
	    	<Row className="mx-0" >
	    		<Col xs={12} sm={12} md={6} lg={6} className="d-grid mb-3" >
			    	<Col className="token-item px-0">
			    		<div className="token-item-header"><span className="f18" >Cancelled tokens</span></div>
			    		<div className="token-item-body p-2" >
				    		{
				    			tokens!==undefined?(
				    				Object.keys(tokens).length>0?(
				    					Object.keys(tokens).reverse().map((tok,tok_i)=>(
				    						tokens[tok].status==='cancel'?(
				    							<Row className="mx-0" key={`toko_${tok_i}`} >
									    			<Col xs={12} sm={12} md={12} className="token-service-item-cancel bdr1 br-3 mb-2 px-0">
									    				<div className="item-tid bdr2-r">
											          		<span className="tp1 f18" >No : {tokens[tok].token_number}</span>
											          		<span className="tp3 f16" >Id : {tok.substring(1)}</span>
											          	</div>
											          	<div className="item-tservice bdr2-r">
											          		<span className="tp1 f18" >{tokens[tok].service[Object.keys(tokens[tok].service)[0]].name}</span>
											          		<span className="tp3 f14" >{tokens[tok].customer_name} / {tokens[tok].phone} / {tokens[tok].time} / {`[ Cancelled by ${tokens[tok].username} ]`}</span>
											          		<span className="tp3 f14 d-grid">Reason : {tokens[tok].reason}</span>
											          	</div>
													    <div className="item-toption">
													    {
													    	user!==undefined?(
				            									user.type==='admin'?(
				            										dayClose!==undefined&&dayClose?(
																    	liveToken!==undefined&&liveToken.id===tok&&openTokenLoad?(
										    								<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /></span></Button>
															    		):(
															    			<Button className="open-btn" onClick={()=>{openToken(tok)}} >Reopen</Button>
															    		)
															    	):''
				            									):(
					            									user.permission!==undefined?(
						            									user.permission.token.E?(
						            										dayClose!==undefined&&dayClose?(
																		    	liveToken!==undefined&&liveToken.id===tok&&openTokenLoad?(
												    								<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /></span></Button>
																	    		):(
																	    			<Button className="open-btn" onClick={()=>{openToken(tok)}} >Reopen</Button>
																	    		)
																	    	):''
						            									):''
						            								):''
				            									)
				            								):''											    	
													    }
													    </div>
									    			</Col>
								    			</Row>
								    		):''
			    						))
			    					):'Nothing new!'
			    				):'Permission denied'
				    		}
			    		</div>
			    	</Col>
		    	</Col>
		    	<Col xs={12} sm={12} md={6} lg={6} className="d-grid mb-3" >
			    	<Col className="token-item px-0">
			    		<div className="token-item-header"><span className="f18" >Completed tokens</span></div>
			    		<div className="token-item-body p-2" >
			    		{
			    			tokens!==undefined?(
			    				Object.keys(tokens).length>0?(
			    					Object.keys(tokens).reverse().map((tok,tok_i)=>(
				    						tokens[tok].status==='close'?(
				    							<Row className="mx-0" key={`toko_${tok_i}`} >
									    			<Col xs={12} sm={12} md={12} className="token-service-item bdr1 br-3 mb-2 px-0">
									    				<div className="item-tid bdr2-r">
											          		<span className="tp1 f18" >No : {tokens[tok].token_number}</span>
											          		<span className="tp3 f16" >Id : {tok.substring(1)}</span>
											          	</div>
											          	<div className="item-tservice bdr2-r">
											          		{
											          			tokens[tok].service!==undefined&&Object.keys(tokens[tok].service).length>0?(
											          				<span className="tp1 f18" >{tokens[tok].service[Object.keys(tokens[tok].service)[0]].name}</span>
											          			):'No service added with this token'
											          		}
											          		<span className="tp3 f14" >{tokens[tok].customer_name} / {tokens[tok].phone} / {tokens[tok].time} / {`[ Closed by ${tokens[tok].username} ]`}</span>
											          	</div>
													    <div className="item-toption gap-1">
													    {
													    	editTokId!==undefined&&editTokId===tok?(
													    		<Button  className="btn-t1" size="sm" ><Spinner animation="border" size="sm" /> Wait...</Button>
													    	):(
															    <Button onClick={()=>{
															    	$.confirm({
																	    title: 'Are you sure to edit?',
																	    content: 'Edit bill will remove previous settlement!',
																	    type: 'orange',
																	    buttons: {   
																	        ok: {
																	            text: "Confirm",
																	            btnClass: 'btn-primary',
																	            keys: ['enter'],
																	            action: function(){
																	            	setEditTokId(tok);															                
																					postCall('api/mt/edit/token/start/',{tid:tok.substring(1)}).then(res=>{
																						setLiveToken({id:tok,details:tokens[tok]});
																						setTokenModel(true);
																						setManageTokenState('add_service');
																						setEditToken(true);
																						setEditTokId();
																					}).catch(err=>{
																						setEditTokId();
																						$.alert({
																					        title: 'Alert!',
																					        content: 'Something Wrong!',
																					    });
																					})
																	            }
																	        },
																	        cancel: function(){}
																	    }
																	});
												    				
												    			}} className="btn-t1" size="sm" ><i className="bi bi-pencil-square"></i> Edit</Button>
											    			)
													    }
													    {
													    	user!==undefined?(
				            									user.type==='admin'?(
				        											<Button onClick={()=>{printOldBill(tok)}} className="btn-t1" size="sm" ><i className="bi bi-printer"></i> Print</Button>
												    			):''
												    		):''
													    }
													    </div>
									    			</Col>
								    			</Row>
								    		):''
			    						))
			    					):'Nothing new!'
			    				):'Permission denied'
			    		}
			    		</div>
			    	</Col>
		    	</Col>
	    	</Row>


			<Modal size="xl" show={tokenModel} backdrop="static" onHide={()=>{setTokenModel(false)}}>
				{
					editToken!==undefined&&editToken?(
						<Modal.Header>
				          <Modal.Title>
				          	<div className="d-grid">
				          		<span className="tp1 f18 mb-1" >Token No : {liveToken!==undefined?liveToken.details.token_number:''}</span>
				          		{
				          			liveToken!==undefined?(
				          				<span className="tp3 f14" >{liveToken.details.customer_name} / {liveToken.details.phone} / {liveToken.details.time}</span>
					          		):''
					          	}
				          	</div>
				          </Modal.Title>
				        </Modal.Header>
					):(
				        <Modal.Header closeButton>
				          <Modal.Title>
				          	<div className="d-grid">
				          		<span className="tp1 f18 mb-1" >Token No : {liveToken!==undefined?liveToken.details.token_number:''}</span>
				          		{
				          			liveToken!==undefined?(
				          				<span className="tp3 f14" >{liveToken.details.customer_name} / {liveToken.details.phone} / {liveToken.details.time}</span>
					          		):''
					          	}
				          	</div>
				          </Modal.Title>
				        </Modal.Header>
		        	)
				}
		        {
		        	manageTokenState!==undefined?(
		        		manageTokenState==='pay-w'?(
				        	<Modal.Body>
				        		{
				        			liveToken!==undefined&&payWallet!==undefined&&liveToken.details&&liveToken.details.service!==undefined?(
				        				Object.keys(liveToken.details.service).length>0?(
				        					Object.keys(liveToken.details.service).map((pays,paysi)=>(
				        						<div className="pay-wall-wrap mb-2" key={`paysi_${paysi}`} >
				        							<span>{liveToken.details.service[pays].name}</span>
				        							<Form.Select onChange={(e)=>{
				        								setPaymentWallet(prev=>({...prev,[pays]:e.target.value}));
				        							}} className="shadow-none br-2">
								        				<option value="">Choose wallet</option>
								        				{
								        					payWallet!==undefined&&Object.keys(payWallet).length>0?(
								        						Object.keys(payWallet).map((ven,v_i)=>(
								        							<option value={ven} key={`wall_${v_i}`}>{payWallet[ven].name}</option>
								        						))
								        					):''													
								        				}	        				
													</Form.Select>
				        						</div>
				        					))
				        				):'Payment wallet NOT found!'
				        			):'Payment wallet NOT found!'
				        		}
				        	</Modal.Body>
				        ):manageTokenState==='add_service'?(
					        <Modal.Body>
					        	{
					        		user!==undefined?(
		            					user.type==='admin'?(
		            						<Row className="mb-2" >
								        		<Col className="service-search-wrapper mx-2 p-2" >
								        			<Form className="service-add-form" onSubmit={serviceAdd} >
								        				<div className="pos-rel">
								        					<Form.Control type="text" id="serv_search" onChange={(e)=>{serviceSearch(e.target.value)}} autoComplete="off" placeholder="Add new service" />
								        					{
							        						serviceSearchResult!==undefined&&showAutoSuggestion?(
							        							Object.keys(serviceSearchResult).length > 0 ?(
							        								<div className="customer-search-results bdr1">
							        								{
							        								Object.keys(serviceSearchResult).map((serv,serv_i)=>(
							        									<span onClick={()=>{
							        										setSelectedServiceItem({ id:serv, service:serviceSearchResult[serv] });
							        										setShowAutoSuggestion(false);
							        									}} className={serv===Object.keys(serviceSearchResult)[serviceSelectCount]?'d-grid bdrb1 active':'d-grid bdrb1'}  key={`servi_${serv_i}`}>{serviceSearchResult[serv].name}</span>
							        									))
							        								}
							        								</div>
							        								):''
							        								
							        							):''
								        					}
								        				</div>
								        				<Form.Control type="number" name="qty"  id="service_qty" defaultValue="1" min="1" placeholder="Qty" />
								        				{
								        					addServiceToTokenLoad?(
											    				<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /></span></Button>
								        						):(
								        						<Button className="btn-t1" id="add_item" type="submit">Add Item</Button>
								        						)
								        				}
								        			</Form>
								        		</Col>
									        </Row>
	            						):(
	            							user.permission!==undefined?(
	            								user.permission.token_service!==undefined?(
	            									user.permission.token_service.A?(
	            										<Row className="mb-2" >
											        		<Col className="service-search-wrapper mx-2 p-2" >
											        			<Form className="service-add-form" onSubmit={serviceAdd} >
											        				<div className="pos-rel">
											        					<Form.Control type="text" id="serv_search" onChange={(e)=>{serviceSearch(e.target.value)}} autoComplete="off" placeholder="Add new service" />
											        					{
										        						serviceSearchResult!==undefined&&showAutoSuggestion?(
										        							Object.keys(serviceSearchResult).length > 0 ?(
										        								<div className="customer-search-results bdr1">
										        								{
										        								Object.keys(serviceSearchResult).map((serv,serv_i)=>(
										        									<span onClick={()=>{
										        										setSelectedServiceItem({ id:serv, service:serviceSearchResult[serv] });
										        										setShowAutoSuggestion(false);
										        									}} className={serv===Object.keys(serviceSearchResult)[serviceSelectCount]?'d-grid bdrb1 active':'d-grid bdrb1'}  key={`servi_${serv_i}`}>{serviceSearchResult[serv].name}</span>
										        									))
										        								}
										        								</div>
										        								):''
										        								
										        							):''
											        					}
											        				</div>
											        				<Form.Control type="number" name="qty"  id="service_qty" defaultValue="1" min="1" placeholder="Qty" />
											        				{
											        					addServiceToTokenLoad?(
														    				<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /></span></Button>
											        						):(
											        						<Button className="btn-t1" id="add_item" type="submit">Add Item</Button>
											        						)
											        				}
											        			</Form>
											        		</Col>
												        </Row>
	            									):''
	            								):''
	            							):''
	            						)
	            					):''
					        	}				        	

						        <Row>
						        	<Col className="p-2" >
						        		<Table bordered className="tbl-bill tbl-bg-blue" >
									      <thead>
									        <tr>
									          <th><span className="tp2" >Index</span></th>
									          <th><span className="tp2" >Services</span></th>
									          <th className="text-end" ><span className="tp2" >TC</span></th>
									          <th className="text-end" ><span className="tp2" >SC</span></th>
									          <th className="text-end" ><span className="tp2" >Price</span></th>
									          <th className="text-end" ><span className="tp2" >Qty</span></th>
									          <th className="text-end" ><span className="tp2" >Total</span></th>
									        </tr>
									      </thead>
									      <tbody>
									      	{
									      		liveToken!==undefined?(
									      			Object.keys(liveToken.details.service).length>0?(
									      				Object.keys(liveToken.details.service).map((t_ser,ts_i)=>(
										      				<tr key={`bill_s_${ts_i}`} >
													          <td><span className="tp1 f16 mb-1" >{ts_i+1}</span></td>
													          <td>
													          	<Accordion>
															      <Accordion.Item eventKey="0">
															        <Accordion.Header><span className="tp1 f18" >{liveToken.details.service[t_ser].name}</span></Accordion.Header>
															        <Accordion.Body>
															          <Col>
															          	<p className="ts1 f16 mb-2" >Service Charge <i className="bi bi-currency-rupee"></i>{
															    			parseInt(liveToken.details.service[t_ser].gov_service_charge)+parseInt(liveToken.details.service[t_ser].akshaya_charge)}</p>
															    		<h5 className="ts1" >Required documents</h5>
															          	<p className="p-multi-line ts1" >{liveToken.details.service[t_ser].required_doc}</p>
															          </Col>
															        </Accordion.Body>
															      </Accordion.Item>
															    </Accordion>
													          </td>
													          <td className="text-end pos-rel td-tc" >												          	
													          	{
													          		ediTc!==undefined&&ediTc.id===t_ser?(
													          			editTcLoad?(
														          			<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
														          		):(
															          		<div className="qty-update-div">
																          		<Form onSubmit={updateTc} className="qty-update-form">
																          			<Form.Control className="shadow-none br-2" name="tc" type="number" min="1" defaultValue={liveToken.details.service[t_ser].gov_service_charge} />
																          			<Button type="submit" className="btn-t1">Ok</Button>
																          		</Form>
																          		<Button onClick={()=>{
																          			setEditTc();
																          		}} className="btn-t1">Cancel</Button>
															          		</div>
														          		)
													          		):(
														          		liveToken.details.service[t_ser].variable_charge?(
														          			<>
														          			<span className="tp1 f18" >{liveToken.details.service[t_ser].gov_service_charge}</span>
														          			<span onClick={()=>{setEditTc({ id:t_ser, details:liveToken.details.service[t_ser] })}} className="pos-abs qty-edit-icon f20 tp1"><i className="bi bi-pencil-square"></i></span>
																			</>
																		):(
																			<span className="tp1 f18" >{liveToken.details.service[t_ser].gov_service_charge}</span>
																		)
																	)
													          	}												          
													          </td>
													          <td className="text-end pos-rel td-sc" >												          	
													          	{
													          		ediSc!==undefined&&ediSc.id===t_ser?(
													          			editScLoad?(
														          			<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
														          		):(
															          		<div className="qty-update-div">
																          		<Form onSubmit={updateSc} className="qty-update-form">
																          			<Form.Control className="shadow-none br-2" name="sc" type="number" min="1" defaultValue={liveToken.details.service[t_ser].akshaya_charge} />
																          			<Button type="submit" className="btn-t1">Ok</Button>
																          		</Form>
																          		<Button onClick={()=>{
																          			setEditSc();
																          		}} className="btn-t1">Cancel</Button>
															          		</div>
														          		)
													          		):(
														          		liveToken.details.service[t_ser].variable_charge?(
														          			<>
														          			<span className="tp1 f18" >{liveToken.details.service[t_ser].akshaya_charge}</span>
														          			<span onClick={()=>{setEditSc({ id:t_ser, details:liveToken.details.service[t_ser] })}} className="pos-abs qty-edit-icon f20 tp1"><i className="bi bi-pencil-square"></i></span>
																			</>
																		):(
																			<span className="tp1 f18" >{liveToken.details.service[t_ser].akshaya_charge}</span>
																		)
																	)
													          	}
													          </td>
													          <td className="text-end" ><span className="tp1 f18" >{
															    			parseInt(liveToken.details.service[t_ser].gov_service_charge)+parseInt(liveToken.details.service[t_ser].akshaya_charge)}</span>
															  </td>
													          <td className="text-end pos-rel td-qty" >
														          {
														          	editServiceItem!==undefined&&serviceEdit&&editServiceItem.id===t_ser?(
														          		serviceEditLoad?(
														          			<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
														          		):(
															          		<div className="qty-update-div">
																          		<Form onSubmit={updateServiceQty} className="qty-update-form">
																          			<Form.Control className="shadow-none br-2" name="qty" type="number" min="1" defaultValue={liveToken.details.service[t_ser].quantity} />
																          			<Button type="submit" className="btn-t1">Ok</Button>
																          		</Form>
																          		<Button onClick={()=>{
																          			setServiceEdit(false);
																          			setEditServiceItem();
																          		}} className="btn-t1">Cancel</Button>
															          		</div>
														          		)
														          	):(
														          	<>
														          		<span className="tp1 f18" >{liveToken.details.service[t_ser].quantity}</span>
														          		{
															        		user!==undefined?(
												            					user.type==='admin'?(
												            						<span onClick={()=>{
																		          		setEditServiceItem({ id:t_ser, details:liveToken.details.service[t_ser] });
																		          		setServiceEdit(true);
																		          	}} className="pos-abs qty-edit-icon f20 tp1"><i className="bi bi-pencil-square"></i></span>
												            					):(
												            						user.permission!==undefined?(
											            								user.permission.token_service!==undefined?(
											            									user.permission.token_service.E?(
											            										<span onClick={()=>{
																					          		setEditServiceItem({ id:t_ser, details:liveToken.details.service[t_ser] });
																					          		setServiceEdit(true);
																					          	}} className="pos-abs qty-edit-icon f20 tp1"><i className="bi bi-pencil-square"></i></span>
											            									):''
											            								):''
											            							):''
												            					)
												            				):''
												            			}
														          	</>
														          	)
														          }									          	
													          </td>
													          <td className="text-end pos-rel" >
													          	{
													        		user!==undefined?(
										            					user.type==='admin'?(
										            						<span onClick={()=>{
																          		deleteTokenService(t_ser);
																          		setDeleteServiceId(t_ser);
																          	}} className="pos-abs remove-service" >
																          		{
																          			deleteServiceId!==undefined&&deleteServiceId===t_ser&&serviceDeleteLoad?(
																          				<Spinner animation="border" size="sm" />
																          				):(<i className="bi bi-x-lg"></i>)
																          		}
																          	</span>
										            					):(
										            						user.permission!==undefined?(
									            								user.permission.token_service!==undefined?(
									            									user.permission.token_service.D?(
									            										<span onClick={()=>{
																			          		deleteTokenService(t_ser);
																			          		setDeleteServiceId(t_ser);
																			          	}} className="pos-abs remove-service" >
																			          		{
																			          			deleteServiceId!==undefined&&deleteServiceId===t_ser&&serviceDeleteLoad?(
																			          				<Spinner animation="border" size="sm" />
																			          				):(
																			          				<i className="bi bi-x-lg"></i>
																			          				)
																			          		}
																			          	</span>
									            									):''
									            								):''
									            							):''
										            					)
										            				):''
										            			}
													          	<span className="tp1 f18" >{(parseInt(liveToken.details.service[t_ser].gov_service_charge)+parseInt(liveToken.details.service[t_ser].akshaya_charge))*parseInt(liveToken.details.service[t_ser].quantity)}</span>
													          </td>
													        </tr>
									      				))
									      			):''
									      		):''
									      	}									        
									        <tr>
									        	<td colSpan={4} ></td>
									        	<td colSpan={2} className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
									        	<td className="text-end bgy" >{subTotal!==undefined?subTotal:'--'}</td>
									        </tr>									        
									      </tbody>
									    </Table>
						        	</Col>
						        </Row>
					        </Modal.Body>
				        ):manageTokenState==='cancel_token'?(
				        	<Modal.Body>
				        		<Form onSubmit={cancelToken} >
				        			<Form.Group className="mb-3" >
				        				<Form.Label>Reason for cancellation {reasonREqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
				        				<Form.Control as="textarea" className="shadow-none br-2" name="reason" />
				        			</Form.Group>
				        			<div className="d-grid">
				        			{
				        				cancelTokenLoad?(
						    				<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /></span></Button>
				           				):(
				        					<Button type="submit" className="btn-t1">Confirm Cancel</Button>
				        				)
				        			}
				        			</div>
				        		</Form>
				        	</Modal.Body>
		        		):manageTokenState==='reccurent_service'?(
		        			<Modal.Body>
			        			{
			        				recService!==undefined?(
			        					Object.keys(recService).length>0?(
			        						Object.keys(recService).map((recS,recS_i)=>(
			        							<Row className="bdr1 p-2 m-1 mb-3 br-2" key={`recs_${recS_i}`}>
				        							<Col xs={12} sm={12} md={12} lg={12} >
				        								<span className="f20 tp2 py-2 d-grid" >{recService[recS].name}{recService[recS].quantity>1?(<> ( {recService[recS].quantity} )</>):''}</span>
				        								<Form >
				        									<Row>
				        										<Col xs={12} sm={12} md={4} lg={4} className="mb-3" >
				        											<Form.Group >
				        												<Form.Label className="f16 ts2" >Contact number</Form.Label>
				        												<div className="serach-customer d-grid pos-rel">
					        												<Form.Control defaultValue={recService[recS].notify.phone!==undefined?recService[recS].notify.phone:''}
					        												onBlur={()=>{
														        					setTimeout(()=>{
														        						setRecAutoSuggest(false);
														        						setCusSelectCount(-1);
														        					},200);

														        				}}
														        			onChange={(e)=>{
														        				changeNotify(e.target.value,recS,"phone")
														        			}} autoComplete="off" onWheel={(e) => {e.target.blur()} } name="phone" id={`phone${recS}`} type="number" min="1" className="shadow-none br-2 cus_phone" />
				        													{
											        						customerSearchResult!==undefined&&recAutoSuggest&&nRecId===recS?(
											        							Object.keys(customerSearchResult).length > 0 ?(
											        								<div className="customer-search-results bdr1">
											        								{
											        								Object.keys(customerSearchResult).map((cus,cus_index)=>(
											        									<span onClick={()=>{ 
											        										$(`#phone${recS}`).val(tokenCustomers[cus].phone);
																							$(`#name${recS}`).val(tokenCustomers[cus].name);
																							$(`#address${recS}`).val(tokenCustomers[cus].address);
																							setRecService(prev=>({...prev,[recS]:{
																								...prev[recS], notify:{
																									...prev[recS].notify, customer_id:cus.substring(1),
																									}
																								}}
																							));
																							setRecService(prev=>({...prev,[recS]:{
																								...prev[recS], notify:{
																									...prev[recS].notify, phone:tokenCustomers[cus].phone,
																									}
																								}}
																							));
																							setRecService(prev=>({...prev,[recS]:{
																								...prev[recS], notify:{
																									...prev[recS].notify, name:tokenCustomers[cus].name,
																									}
																								}}
																							));
																							setRecService(prev=>({...prev,[recS]:{
																								...prev[recS], notify:{
																									...prev[recS].notify, address:tokenCustomers[cus].address,
																									}
																								}}
																							));
											        									 }} className={cus===Object.keys(customerSearchResult)[cusSelectCount]?'d-grid bdrb1 active':'d-grid bdrb1'}  key={`cust_${cus_index}`}>{customerSearchResult[cus].name} - {customerSearchResult[cus].phone} </span>
											        									))
											        								}
											        								</div>
											        								):''
											        								
											        							):''
												        					}
				        												</div>
				        											</Form.Group>
				        										</Col>
				        										<Col xs={12} sm={12} md={4} lg={4} className="mb-3">
				        											<Form.Group>
				        												<Form.Label className="f16 ts2" >Name</Form.Label>
				        												<Form.Control defaultValue={recService[recS].notify.name!==undefined?recService[recS].notify.name:''} onChange={(e)=>{changeNotify(e.target.value,recS,"name")}} name="name" id={`name${recS}`} type="text" className="shadow-none br-2" />
				        											</Form.Group>
				        										</Col>
				        										<Col xs={12} sm={12} md={4} lg={4} className="mb-3">
				        											<Form.Group>
				        												<Form.Label className="f16 ts2" >Expiry date</Form.Label>
				        												{
				        													recService[recS].variable_expiry_period?(
				        														<Form.Control onChange={(e)=>{changeNotify(e.target.value,recS,"expiry_date")}} defaultValue={recService[recS].notify.expiry_date!==undefined?recService[recS].notify.expiry_date:''} name="expiry" type="date" className="shadow-none br-2" />
				        													):(
				        														<Form.Control defaultValue={recService[recS].notify.expiry_date!==undefined?recService[recS].notify.expiry_date:''} disabled name="expiry" type="date" className="shadow-none br-2" />
				        													)
				        												}
				        											</Form.Group>
				        										</Col>
				        									</Row>
				        									<Row>
				        										<Col xs={12} sm={12} md={6} lg={6} className="mb-3">
				        											<Form.Group>
				        												<Form.Label className="f16 ts2" >Address</Form.Label>
				        												<Form.Control defaultValue={recService[recS].notify.address!==undefined?recService[recS].notify.address:''} onChange={(e)=>{changeNotify(e.target.value,recS,"address")}} name="address" id={`address${recS}`} as="textarea" className="shadow-none br-2" />
				        											</Form.Group>
				        										</Col>
				        										<Col xs={12} sm={12} md={6} lg={6} className="mb-3">
				        											<Form.Group>
				        												<Form.Label className="f16 ts2" >Other Contact details</Form.Label>
				        												<Form.Control defaultValue={recService[recS].notify.other_detail!==undefined?recService[recS].notify.other_detail:''} onChange={(e)=>{changeNotify(e.target.value,recS,"other_detail")}} name="other_details" as="textarea" className="shadow-none br-2" />
				        											</Form.Group>
				        										</Col>
				        									</Row>
				        									
				        								</Form>
				        							</Col>
				        						</Row>
			        						))
			        					):''
			        				):''
			        			}
		        			</Modal.Body>
		        		):manageTokenState==='payment_token'?(
		        			<Modal.Body>
		        				<span className="d-grid f18 tp2 mb-3" >Total bill amount : {subTotal!==undefined?subTotal:'--'}</span>
		        				{
		        					getWbLoad?(
		        						<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Checking wallet please wait...</span>
		        					):(
				        				wallBalance!==undefined?(
				        					<span className="d-grid f18 tp2 mb-3" >Cash available in wallet = {wallBalance}</span>
				        				):(<span className="d-grid f18 tp2 mb-3" >Wallet NOT found!</span>)
			        				)
		        				}
		        				<Row>
		        					{
		        						payModes!==undefined?(
		        							Object.keys(payModes).length>0?(
		        								Object.keys(payModes).map((payMode,payMode_i)=>(
		        									payModes[payMode].cw?(
		        										wallBalance!==undefined?(
		        											<Col xs={12} sm={6} md={3} lg={3} key={`pay_${payMode_i}`}>
					        									<InputGroup className="mb-3 br-2">
															        <InputGroup.Text >{payModes[payMode].name}</InputGroup.Text>
															        <Form.Control onChange={(e)=>{
															        	addBillAmount(e.target.value,payMode);
															        	setWalletEntry(e.target.value);
															        	}} name="coin" type="number" min="1" />
															    </InputGroup>
															</Col>
		        										):''
		        									):(
			        								<Col xs={12} sm={6} md={3} lg={3} key={`pay_${payMode_i}`}>
			        									<InputGroup className="mb-3 br-2">
													        <InputGroup.Text >{payModes[payMode].name}</InputGroup.Text>
													        <Form.Control onChange={(e)=>{addBillAmount(e.target.value,payMode)}} name="coin" type="number" min="1" />
													    </InputGroup>
													</Col>
													)
		        								))		        								
		        							):''
		        						):''
		        					}
		        				</Row>
		        			</Modal.Body>
		        		):manageTokenState==='print'?(
		        			<Modal.Body>
					        	<Row id="bill_print">
						        	<Col  className="mx-0 px-0 bdr0" >
						        		{
							    			account!==undefined?(
								        		<div className="print-token-header bdr0-b" >
								        			{
									    				account.name!==""||account.address!==""||account.phone_numbers?(
									    					<div className="print-token-address">
											    				<div className="px-3 py-1">
											    					<span className="f20 tp0 d-grid"><b>{account.name}</b></span>
											    					<span className="f16 tp0 d-grid" >{account.address}</span>
											    					<span className="f16 tp0 d-grid" >{account.phone_numbers}</span>
											    				</div>
											    			</div>
									    				):''
									    			}
								        			{
								        				account.logo!==""?(
											    			<div className="print-token-header-logo d-grid" >
											    				<img className="px-3 py-1" src={account.logo} />
											    			</div>
									    				):''
									    			}
									    												    			
									    		</div>
								    		):''
							    		}
							    		{
							    			liveToken!==undefined?(
							    				<div className="px-3 py-1 invoice-cus-details" >
							    					<div>
							    						<span className="f16 tp0 d-grid ">Customer details</span>
							    						<span className="f14 tp0 d-grid">{liveToken.details.customer_name}</span>
							    						<span className="f14 tp0 d-grid">{liveToken.details.phone}</span>
							    					</div>
							    					<div className="text-end">							    						
							    						<span className="f16 tp0 d-grid ">Invoice details</span>
							    						<span className="f14 tp0 d-grid">{liveToken.details.bill_id} : Invoice number</span>
							    						<span className="f14 tp0 d-grid">{liveToken.details.date} / {liveToken.details.time} : Date</span>
							    					</div>
							    				</div>
							    			):''
							    		}
						        		<Table className="tbl-bill-print" >
										    <thead>
										        <tr className="bdr0-b bdr0-t" >
										          <th className="bdr0-r" ><span className="tp0" >No</span></th>
										          <th className="bdr0-r" ><span className="tp0" >Services</span></th>
										          <th className="text-end bdr0-r" ><span className="tp0" >Price</span></th>
										          <th className="text-end bdr0-r" ><span className="tp0" >Qty</span></th>
										          <th className="text-end" ><span className="tp0" >Total</span></th>
										        </tr>
										    </thead>
										    <tbody>
										      	{
										      		liveToken!==undefined?(
											      		Object.keys(liveToken.details.service).length>0?(
											      			Object.keys(liveToken.details.service).map((t_ser,ts_i)=>(
											      				<tr key={`bill_s_${ts_i}`} className="bdr0-b" >
														          <td className="bdr0-r"><span className="tp0 f16 mb-1" >{ts_i+1}</span></td>
														          <td className="bdr0-r"><span className="tp0 f18" >{liveToken.details.service[t_ser].name}</span></td>
														          <td className="text-end bdr0-r" ><span className="tp0 f18" >{
																    			parseInt(liveToken.details.service[t_ser].gov_service_charge)+parseInt(liveToken.details.service[t_ser].akshaya_charge)}</span></td>
														          <td className="text-end pos-rel td-qty bdr0-r" >
															          <span className="tp0 f18" >{liveToken.details.service[t_ser].quantity}</span>									          	
														          </td>
														          <td className="text-end pos-rel" >
														          	<span className="tp0 f18" >{(parseInt(liveToken.details.service[t_ser].gov_service_charge)+parseInt(liveToken.details.service[t_ser].akshaya_charge))*parseInt(liveToken.details.service[t_ser].quantity)}</span>
														          </td>
														        </tr>
										      				))
										      			):''
										      		):''
										      	}
										        
									        	<tr className="bdr0-b" >
									        		<td colSpan={2} ></td>
									        		<td colSpan={2} className="text-end bdr0-r" ><span className="tp0 f18" ><b>Total</b></span></td>
									        		<td className="text-end tp0" ><b>{subTotal!==undefined?subTotal:'--'}</b></td>
									        	</tr>
										    </tbody>
									   	</Table>
									   	<div className="d-grid bill-signature px-3 pt-2 pb-5">
									   		<span className="f16 tp0" >System generated invoice. by {user!==undefined?user.profile.first_name:''}</span>
									   	</div>
						        	</Col>
						        </Row>
					        </Modal.Body>
		        		):''

		        	):''

		        }
		        
		        {
		        	manageTokenState!==undefined?(
		        		manageTokenState==='add_service'?(
			        		<Modal.Footer>
			        			{
				        			user!==undefined&&liveToken!==undefined?(
				        				user.profile.user_id===liveToken.details.user_id?(
				        				<>
				        					{
												editToken!==undefined&&editToken?'':(
				        							<Button onClick={()=>{setManageTokenState('cancel_token')}} className="btn-t1" ><i className="bi bi-x-circle"></i> Cancel</Button>
					        					)
					        				}
					        				<Button onClick={()=>{setManageTokenState('print')}} className="btn-t1" ><i className="bi bi-receipt"></i> Bill</Button>
					        				<Button onClick={()=>{
					        					setManageTokenState('pay-w');
					        				}} className="btn-t1" >Next <i className="bi bi-arrow-right-circle"></i></Button>
					        				
				        				</>
				        				):''
				        			):''
		        				}
			        		</Modal.Footer>
				        ):manageTokenState==='pay-w'?(
				        	<Modal.Footer>
				        		<Button onClick={()=>{setManageTokenState('add_service')}} className="btn-t1" ><i className="bi bi-arrow-left-circle"></i> Back</Button>
				        		{
		        					user!==undefined?(
        								user.type==='admin'?(
		        							<Button onClick={()=>{checkReccurent()}} className="btn-t1" >Next <i className="bi bi-arrow-right-circle"></i></Button>
        								):(
        									user.permission!==undefined?(
    											user.permission.token_settle!==undefined?(
    												user.permission.token_settle.E?(
						        						<Button onClick={()=>{checkReccurent()}} className="btn-t1" >Next <i className="bi bi-arrow-right-circle"></i></Button>
    												):(
    													recStatus!==undefined&&recStatus?(
							        						<Button onClick={()=>{checkReccurent()}} className="btn-t1" >Next <i className="bi bi-arrow-right-circle"></i></Button>
														):(
							        						submitTokenLoad?(
											    				<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
								        					):(
								       							<Button onClick={()=>{submitToken()}} className="btn-t1" >Submit</Button>
								       						)
														)
    												)
    											):''
    										):''
    									)
        							):''					        					
		        				}
				        	</Modal.Footer>
				        ):manageTokenState==='cancel_token'?(
				        	<Modal.Footer>
				        		<Button onClick={()=>{setManageTokenState('add_service')}} className="btn-t1" ><i className="bi bi-arrow-left-circle"></i> Back</Button>
				        	</Modal.Footer>
				        ):manageTokenState==='reccurent_service'?(
		        			<Modal.Footer>
		        				<Button onClick={()=>{setManageTokenState('pay-w')}} className="btn-t1" ><i className="bi bi-arrow-left-circle"></i> Back</Button>
		        				{
		        					user!==undefined?(
	            						user.type==='admin'?(
				        					<Button onClick={()=>{setManageTokenState('payment_token')}} className="btn-t1" >Next <i className="bi bi-arrow-right-circle"></i></Button>
	            						):(
	            							user.permission!==undefined?(
            									user.permission.token_settle!==undefined?(
            										user.permission.token_settle.E?(
								       					<Button onClick={()=>{setManageTokenState('payment_token')}} className="btn-t1" >Next <i className="bi bi-arrow-right-circle"></i></Button>
							       					):(
							       						submitTokenLoad?(
										    				<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
							        					):(
							       							<Button onClick={()=>{submitToken()}} className="btn-t1" >Submit</Button>
							       						)
							       					)
            									):''
            								):''
            							)
	            					):''
	            				}
		        			</Modal.Footer>
		        		):manageTokenState==='payment_token'?(
		        			<Modal.Footer>
		        				<Button className="btn-t1" onClick={()=>{
		        					backCheckReccurent();
		        					setBillAmount({});
		        					setTotalCashEntry();
		        				}} ><i className="bi bi-arrow-left-circle"></i> Back</Button>
		        				{
		        					billSettleLoad?(
					    				<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
		        					):(
			        					subTotal!==undefined&&totalCashEntry!==undefined&&subTotal===totalCashEntry?(
					        				<>
					        				<Button onClick={()=>{					        					
					        					if(wallBalance!==undefined&&walletEntry!==undefined){
					        						if(walletEntry<=wallBalance){
					        							setPS(true);
					        							prinntAndSettle(liveToken.id);
					        						}else{
					        							    $.alert({
														        title: 'Something went wrong',
														        content: 'Wallet amount is greater than the balance available!',
														        type:'red',
														    });
					        						}
					        					}else{
					        						setPS(true);
					        						prinntAndSettle(liveToken.id);
					        					}
					        					}} className="btn-t1" ><i className="bi bi-printer"></i> Print & Settle</Button>
			        						<Button onClick={()=>{
			        							if(wallBalance!==undefined&&walletEntry!==undefined){
					        						if(walletEntry<=wallBalance){
					        							settleBill();
					        						}else{
					        							    $.alert({
														        title: 'Something went wrong',
														        content: 'Wallet amount is greater than the balance available!',
														        type:'red',
														    });
					        						}
					        					}else{
					        						settleBill();
					        					}       							

			        							}} className="btn-t1" >Settle</Button>
			        						</>
			        					):(
			        					<>
					        				<Button className="btn-t1" disabled><i className="bi bi-printer"></i> Print & Settle</Button>
			        						<Button className="btn-t1" disabled>Settle</Button>
			        					</>
			        					)
		        					)
		        				}
		        			</Modal.Footer>
		        		):manageTokenState==='print'?(
		        			<Modal.Footer>
				        		<Button onClick={()=>{setManageTokenState('add_service')}} className="btn-t1" ><i className="bi bi-arrow-left-circle"></i> Back</Button>
					        	<Button onClick={()=>{printBill(liveToken.id)}} className="btn-t1" ><i className="bi bi-printer"></i> Print</Button>
				           	</Modal.Footer>
		        		):''
		        	):''
		        }
		    </Modal>

		    <Modal size="xl" show={newTokenModel} backdrop="static" onHide={()=>{setNewTokenModel(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>
		          	<div className="d-grid">
			          	{
			          		tokenBookingState!==undefined?(
					        	tokenBookingState==='start'?(
				        			tokenAddSuccess?(
				        				<span className="tp1 mb-1 sucess-alert" >Success!</span>
					        		):(
					        			<span className="tp1 f18 mb-1" >Available services</span>
					        		)
			          			):tokenBookingState==='token'?(
			          				selectedService!==undefined?(
			          					<span className="tp1 f18" >{selectedService.details.name}</span>
			          				):''		
			          			):''
		          			):''
			          	}
			        </div>
		          </Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        {
		        	tokenBookingState!==undefined?(
			        	tokenBookingState==='start'?(
				        	<Row>
				        		<Col xs={12} sm={12} md={12} lg={12} className="service-search mb-3" >
				        			<Form onSubmit={(e)=>{e.preventDefault()}} >
				        				<InputGroup>
				        					<Form.Control onChange={(e)=>{searchServices(e.target.value)}} className="br-2" size="lg" type="text" placeholder="Search here" />
				        					<InputGroup.Text className="br-3 btn-t1 px-4" id="basic-addon2"><i className="bi bi-search"></i></InputGroup.Text>
				        				</InputGroup>
				        			</Form>
				        		</Col>
				        		{
				        			tokenServices!==undefined?(			        				
				        				Object.keys(tokenServices).length>0?(
				        					serviceSearchResults!==undefined&&Object.keys(serviceSearchResults).length>0?(
					        					Object.keys(serviceSearchResults).map((t_serice,ts_i)=>(
					        						<Col xs={12} sm={12} md={12} lg={12} className="mb-3" key={`t_serice_${ts_i}`} >
										        		<div className="token-add-service-show br-3" >
										        			<Accordion flush>
														      <Accordion.Item eventKey="0">
														        <Accordion.Header><span className="tp1 f18" >{tokenServices[t_serice].name}</span></Accordion.Header>
														        <Accordion.Body>
														          <Col>
														          	<p className="ts1 f16 mb-2" >Service Charge <i className="bi bi-currency-rupee"></i>{
														    			parseInt(tokenServices[t_serice].gov_service_charge)+parseInt(tokenServices[t_serice].akshaya_charge)}</p>
														    		<h5 className="ts1" >Required documents</h5>
														          	<p className="p-multi-line ts1" >{tokenServices[t_serice].required_doc}</p>

														          </Col>
														        </Accordion.Body>
														      </Accordion.Item>
														    </Accordion>
														    <Button className="btn-t1" onClick={()=>{
														    	setTokenBookingState('token');
														    	setSelectedService({id:t_serice, details:tokenServices[t_serice]});
														    }} >Book now</Button>
										        		</div>
										        	</Col>
					        					))
				        					):(
					        					Object.keys(tokenServices).map((t_serice,ts_i)=>(
					        						<Col xs={12} sm={12} md={12} lg={12} className="mb-3" key={`t_serice_${ts_i}`} >
										        		<div className="token-add-service-show br-3" >
										        			<Accordion flush>
														      <Accordion.Item eventKey="0">
														        <Accordion.Header><span className="tp1 f18" >{tokenServices[t_serice].name}</span></Accordion.Header>
														        <Accordion.Body>
														          <Col>
														          	<p className="ts1 f16 mb-2" >Service Charge <i className="bi bi-currency-rupee"></i>{
														    			parseInt(tokenServices[t_serice].gov_service_charge)+parseInt(tokenServices[t_serice].akshaya_charge)}</p>
														    		<h5 className="ts1" >Required documents</h5>
														          	<p className="p-multi-line ts1" >{tokenServices[t_serice].required_doc}</p>

														          </Col>
														        </Accordion.Body>
														      </Accordion.Item>
														    </Accordion>
														    <Button className="btn-t1" onClick={()=>{
														    	setTokenBookingState('token');
														    	setSelectedService({id:t_serice, details:tokenServices[t_serice]});
														    }} >Book now</Button>
										        		</div>
										        	</Col>
					        					))
					        				)
				        				):'Services not found'
				        			):'Permission denied'
				        		}				        		
					        </Row>
				    	):tokenBookingState==='token'?(
				    	<Row>
				    		<Col>
				    			<Form onSubmit={generateToken}>
				    				<Form.Group className="mb-3">
						        		<Form.Label>Quantity {qtyReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
						        		<Form.Control type="number" name="qty" min="1" className="shadow-none br-2" placeholder="Number of service" defaultValue="1" />
						        	</Form.Group>
				    				<Row>
					        			<Col xs={12} sm={12} md={12} lg={6} >
						        			<Form.Group className="mb-3">
							        			<Form.Label>Contact number {phoneReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
							        			<div className="serach-customer d-grid pos-rel">
							        				<Form.Control onBlur={()=>{
							        					setTimeout(()=>{
							        						setShowAutoSuggestionNew(false);
							        					},200);
							        				}} autoComplete="off" onWheel={(e) => {e.target.blur()} } id="cus_phone" onChange={(e)=>{searchCustomerNew(e.target.value)}} type="number" name="phone" className="shadow-none br-2" placeholder="Contact number" />
							        				
						        					{
					        						searchResult!==undefined&&showAutoSuggestionNew?(
					        							Object.keys(searchResult).length > 0 ?(
					        								<div className="customer-search-results bdr1">
					        								{
					        								Object.keys(searchResult).map((cus,cus_index)=>(
					        									<span onClick={()=>{setSelectedItem({id:cus, profile:searchResult[cus]})}} className={cus===Object.keys(searchResult)[selectCount]?'d-grid bdrb1 active':'d-grid bdrb1'}  key={`cust_${cus_index}`}>{searchResult[cus].name} - {searchResult[cus].phone} </span>
					        									))
					        								}
					        								</div>
					        								):''
					        								
					        							):''
						        					}
							        			</div>
						        			</Form.Group>
					        			</Col>
					        			<Col xs={12} sm={12} md={12} lg={6} >
							        		<Form.Group className="mb-3">
							        			<Form.Label>Name {nameReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
							        			<Form.Control type="text" id="cus_name" name="name" className="shadow-none br-2" placeholder="Customer Name" />
							        		</Form.Group>
					        			</Col>
							        </Row>
							        <Row>
							        	<Col xs={12} sm={12} md={12} lg={6} >
							        		<Form.Group className="mb-3">
							        			<Form.Label>Address</Form.Label>
							        			<Form.Control as="textarea" id="cus_address" className="shadow-none br-2" name="address" />
							        		</Form.Group>
							        	</Col>
							        	<Col xs={12} sm={12} md={12} lg={6} >
							        		<Form.Group className="mb-3">
							        			<Form.Label>Remarks</Form.Label>
							        			<Form.Control as="textarea" id="cus_address" className="shadow-none br-2" name="remarks" />
							        		</Form.Group>
							        	</Col>
							        </Row>
					        		<Form.Group className="mb-3">
					        			<Form.Label>Collected documents</Form.Label>
					        			<Form.Control as="textarea" id="cus_address" className="shadow-none br-2" name="documents" />
					        		</Form.Group>
								    <div className="d-grid">
								    {
								    	addTokenLoad?(
					    					<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
								    	):(
								    		<Button className="btn-t1" type="submit" >Generate Token</Button>
								    	)
								    }
								    </div>
							    </Form>
				    		</Col>
				    	</Row>
				    	):tokenBookingState==='print'?(
				    	<Row id="print_special_token" className="print_special_token mx-0">
					    	<Col className="bdr0 mx-0 px-0" >
					    		{
					    			account!==undefined?(
						        		<div className="print-token-header bdr0-b" >
						        			{
							    				account.name!==""||account.address!==""||account.phone_numbers?(
							    					<div className="print-token-address">
									    				<div className="px-3 py-1">
									    					<span className="f20 tp0 d-grid"><b>{account.name}</b></span>
									    					<span className="f16 tp0 d-grid" >{account.address}</span>
									    					<span className="f16 tp0 d-grid" >{account.phone_numbers}</span>
									    				</div>
									    			</div>
							    				):''
							    			}
						        			{
						        				account.logo!==""?(
									    			<div className="print-token-header-logo d-grid" >
									    				<img className="px-3 py-1" src={account.logo} />
									    			</div>
							    				):''
							    			}
							    												    			
							    		</div>
						    		):''
					    		}
					    		<div className="print-token-body px-0" >
						    		<div className="print-token">
						    			<span className="print-token-name pt-1 tp0">TOKEN NUMBER</span>
						    			<span className="print-token-number tp0">{tokenToPrint!==undefined?tokenToPrint.details.token_number:''}</span>
						    		</div>
						    		<div className="print-token-details bdr0-l">
						    			<div className="print-token-detail-item px-2 py-1 bdrb1">
						    				<span className="f16 tp0" >Service</span>
						    				<span className="f16 tp0">:</span>
						    				<span className="f16 tp0">{tokenToPrint!==undefined?tokenToPrint.details.service[Object.keys(tokenToPrint.details.service)[0]].name:''}</span>
						    			</div>
						    			<div className="print-token-detail-item px-2 py-1 bdrb1">
						    				<span className="f16 tp0">Name</span>
						    				<span className="f16 tp0">:</span>
						    				<span className="f16 tp0">{tokenToPrint!==undefined?tokenToPrint.details.customer_name:''}</span>
						    			</div>
						    			<div className="print-token-detail-item px-2 py-1 bdrb1">
						    				<span className="f16 tp0">Phone</span>
						    				<span className="f16 tp0">:</span>
						    				<span className="f16 tp0">{tokenToPrint!==undefined?tokenToPrint.details.phone:''}</span>
						    			</div>
						    			<div className="print-token-detail-item px-2 py-1 bdrb1">
						    				<span className="f16 tp0">Date</span>
						    				<span className="f16 tp0">:</span>
						    				<span className="f16 tp0">{tokenToPrint!==undefined?tokenToPrint.details.date:''}</span>
						    			</div>
						    			<div className="print-token-detail-item px-2 py-1 bdrb1">
						    				<span className="f16 tp0">Documents</span>
						    				<span className="f16 tp0">:</span>
						    				<span className="f16 tp0">{tokenToPrint!==undefined?tokenToPrint.details.documents:''}</span>
						    			</div>
						    			<div className="print-token-detail-item px-2 py-1 bdrb1">
						    				<span className="f16 tp0">Remarks</span>
						    				<span className="f16 tp0">:</span>
						    				<span className="f16 tp0">{tokenToPrint!==undefined?tokenToPrint.details.remarks:''}</span>
						    			</div>
						    		</div>
					    		</div>
					    	</Col>
				    	</Row>
				    	):''
			    	):''
		        }
		        </Modal.Body>
		        <Modal.Footer>
		        {
		        	tokenBookingState!==undefined?(
			        	tokenBookingState==='token'?(
			        		<Button onClick={()=>{
			        		setTokenBookingState('start');
			        		setServiceSearchResults(prev=>{});
			        		}} className="btn-t1" ><i className="bi bi-arrow-left-circle"></i> Back</Button>
			        	):tokenBookingState==='print'?(
			        		<Button onClick={()=>{
			        			printToken();
			        		}} className="btn-t1" ><i className="bi bi-printer"></i> Print</Button>
			        	):''
			        ):''
		        }
		        </Modal.Footer>
		    </Modal>


	    	
		</div>
 		)
 }