import React, {useContext, useEffect, useState} from 'react';
import {Container, Col, Button, Form, Modal, Spinner, Row, InputGroup, Table} from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import { AppContext } from '../App';

export const CustomerWallet=()=>{
	const { accessToken, user, setAuthForm, link, postCall, getLoad, customers, walletCus, setWalletCus, payModes  } = useContext(AppContext);

	const [ newCustomerModel, setNewCustomerModel ] = useState(false);
	useEffect(()=>{
		if(!newCustomerModel){
			setSearchResult(prev=>{});
			setSelectedItem();
			setEditCus(false);
			setBillAmount(prev=>({}));
			setTotalCashEntry(0);
		}		
	},[newCustomerModel]);
	const [ addCustomerLoad, setAddCustomerLoad ] = useState(false);
	const [ nameReqErr, setNameReqErr ] = useState(false);
	const [ amountReqErr, setAmountReqErr ] = useState(false);
	const [ selectCount, setSelectCount ] = useState(-1);
	const [ showAutoSuggestion, setShowAutoSuggestion ] = useState(false);
	const [ selectedItem, setSelectedItem ] = useState();
	useEffect(()=>{
		if(selectedItem!==undefined){
			$('#cus_phone').val(selectedItem.profile.phone);
			$('#cus_name').val(selectedItem.profile.name);
			$('#cus_address').val(selectedItem.profile.address);
		}else{
			$('#cus_name').val('');
			$('#cus_address').val('');
		}
	},[selectedItem]);
	const [ editCus, setEditCus ] = useState(false);
	const [ searchResult, setSearchResult ] = useState({});
	const addCustomer=(e)=>{
		e.preventDefault();
		let name = e.target.name.value;
		let phone = e.target.phone.value;
		let address = e.target.address.value;
		if (name==="") {
			setNameReqErr(true);
			setTimeout(()=>{setNameReqErr(false)},5000);
		}
		if(name!==""&&billAmount!==undefined&&Object.keys(billAmount).length>0){
			setAddCustomerLoad(true);
			postCall('api/ap/add/wallet/',{name:name, phone:phone, address:address, payment:billAmount}).then((res)=>{
		        	setAddCustomerLoad(false);
		        	setWalletCus(res.data);
		        	setNewCustomerModel(false);
		        }).catch((err)=>{
		        	setAddCustomerLoad(false);
		    });
		}
	}
	const searchCustomer=(c_num)=>{
		if (editCus!==undefined&&!editCus) {
			setSearchResult(prev=>{});
			if (c_num!=="") {
				if (customers!==undefined){
					setSelectedItem();
					if (Object.keys(customers).length>0){
						setSelectCount(-1);
						setShowAutoSuggestion(true);
						let count = 0;
						Object.keys(customers).map((customer,custi)=>{
							if(customers[customer].phone.match(c_num)){
								if (count<20) {
									setSearchResult(prev=>({...prev,[customer]:customers[customer]}));
									count+=1;
								}
							}
						});
					}
				}
			}else{
				setSearchResult(prev=>{});
			}
		}
	}	
	$('#cus_phone').off().on("keydown", function (e) {
	    var code = e.which || e.keyCode;
	    if (code === 40) {
	    	e.preventDefault();
	    	if (selectCount<Object.keys(searchResult).length-1) {
	    		setSelectCount(prev=>prev+1);
	    	}else{
	    		setSelectCount(0);
	    	}
	    }else if(code === 38){
	    	e.preventDefault();
	    	if (selectCount>0) {
	    		setSelectCount(prev=>prev-1);
	    	}else{
	    		setSelectCount(Object.keys(searchResult).length-1);
	    	}
	    }else if(code === 13){
	    	e.preventDefault();
	    	setShowAutoSuggestion(false);
	    	if(selectCount>=0){
	    		setSelectedItem({id: Object.keys(searchResult)[selectCount], profile:searchResult[Object.keys(searchResult)[selectCount]]});
	    	}
	    }
	});
	const [ currentWalletAmount, setCurrentWalletAmount ] = useState(0);	
	const [ billAmount, setBillAmount ] = useState({});
	const [ totalCashEntry, setTotalCashEntry ] = useState(0);
	useEffect(()=>{
		setTotalCashEntry(0);
		if (billAmount!==undefined&&Object.keys(billAmount).length>0) {
			Object.values(billAmount).map(amt=>{
				setTotalCashEntry(prev=>(parseInt(prev)+parseInt(amt)));
			});
		}
	},[billAmount]);
	const addBillAmount=(amt,mode)=>{
		if(amt!==""){
			setBillAmount(prev=>({...prev,[mode]:amt}));
		}else{
			setBillAmount(prev=>({...prev,[mode]:0}));
		}
	}
	const [ tranModal, setTranModal ] = useState(false);
	const [ addToModal, setAddToModal ] = useState(false);
	const [ windrawModal, setWidrawModal ] = useState(false);
	const [ tran, setTran ] = useState();
	const [ tranLoad, setTranLoad ] = useState(false);
	const [ traId, setTranId ] = useState();
	useEffect(()=>{
		if (!tranModal) {
			setTran();
			setTranId();
		}
	},[tranModal]);
	useEffect(()=>{
		if (!addToModal) {
			setBillAmount(prev=>({}));
			setTotalCashEntry(0);
		}
	},[addToModal])
	const addMore=(e)=>{
		e.preventDefault();
		if (traId!==undefined&&billAmount!==undefined&&Object.keys(billAmount).length>0) {
			setAddCustomerLoad(true);
			postCall('api/ap/add/to/wallet/',{w_id:traId.substring(1),payment:billAmount}).then(res=>{
				setWalletCus(res.data);
				setAddCustomerLoad(false);
				setAddToModal(false);
			}).catch(err=>{
				setAddCustomerLoad(false);
			});
		}
	}
	const [ wcBalance, setWcBalance  ] = useState();
	useEffect(()=>{
		if (!windrawModal) {
			setWcBalance();
			setTranId();
			setBillAmount(prev=>({}));
			setBillAmount(prev=>({}));
			setTotalCashEntry(0);
		}
	},[windrawModal]);
	const widrawMoney=(e)=>{
		e.preventDefault();
		setAddCustomerLoad(true);
		postCall('api/ap/withdraw/from/wallet/',{w_id:traId.substring(1),payment:billAmount}).then(res=>{			
			setAddCustomerLoad(false);
			setWidrawModal(false);
			setWalletCus(res.data);
		}).catch(err=>{
			setAddCustomerLoad(false);
		})
	}
	return (
		<Container className="my-3" >

    		<Col  xs={12} sm={12} md={12} lg={12} className='bdr2 br-3 mb-3' >
				<Col xs="12" sm="12" md="12" lg="12" className="staff-type-header bgs1 d-grid p-2 bdrb2" >
					<span>{getLoad?(<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>):'Wallet Customers'}</span>
					<Button onClick={()=>{setNewCustomerModel(true)}} className="btn-t1"  ><i className="bi bi-plus-circle"></i> Add new</Button>
				</Col>
			</Col>
			<div className="d-grid bdr2">
				{
					walletCus!==undefined&&Object.keys(walletCus).length>0?(
						Object.keys(walletCus).map((wcs,wcsi)=>(
							<div className="d-grid al-center bgs1 px-2 py-1 bdr2-b " key={`wcs_${wcsi}`} >
								<div className="grid-split-2 al-center">
									<div className="d-flex gap-1 al-center" >
										<span>{`${walletCus[wcs].name}  ${walletCus[wcs].phone},`}</span>
										<span className="f18" >Balance = <b>{walletCus[wcs].balance}</b></span>
									</div>
									<div className="d-flex just-end gap-1">
										<Button onClick={()=>{
											setTranLoad(true);
											setTranModal(true);
											setTranId(wcs);
											postCall('api/ap/get/wallet/transactions/',{w_id:wcs.substring(1)}).then(res=>{
												setTran(res.data);
												setTranLoad(false);
											}).catch(err=>{
												setTranLoad(false);
											})
										}} className="btn-t1" size="sm" >Transactios</Button>
										<Button onClick={()=>{
											setAddToModal(true);
											setTranId(wcs);
										}} className="btn-t1" size="sm" >Add more amount</Button>
										<Button onClick={()=>{
											setWidrawModal(true);
											setTranId(wcs);
											setTranLoad(true);
											postCall('api/ap/check/wallet/balance/',{w_id:wcs.substring(1)}).then(res=>{
												setWcBalance(res.data.balance);
												setTranLoad(false);
											}).catch(err=>{
												setTranLoad(false);
											})
										}} className="btn-t1" size="sm" >Withdraw</Button>
									</div>
								</div>
							</div>
						))
					):'Customers NOT found!'
				}
			</div>
			<Modal size="xl" show={windrawModal} backdrop="static" onHide={()=>{setWidrawModal(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>{traId!==undefined&&walletCus!==undefined&&walletCus[traId]!==undefined?(<span className="f16" >{`${walletCus[traId].name} / ${walletCus[traId].phone}`}</span>):''}</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<span className="d-grid f18 tp2 mb-3" >Balance = {wcBalance!==undefined?wcBalance:'Fetching...'}</span>
		        	{
		        		tranLoad?(
		        			<span className="please-wait-spinner" ><Spinner animation="border" size="sm" />Fetching, Please wait...</span>
		        		):(
		        			<Form onSubmit={widrawMoney} >
				        		<span className="d-grid f18 tp2 mb-3" >Total = {totalCashEntry}</span>
				        		<Row>
			    					{
			    						payModes!==undefined?(
			    							Object.keys(payModes).length>0?(
			    								Object.keys(payModes).map((payMode,payMode_i)=>(
			    									!payModes[payMode].credit&&!payModes[payMode].cw?(
					    								<Col xs={12} sm={6} md={3} lg={3} key={`pay_${payMode_i}`}>
					    									<InputGroup className="mb-3 br-2">
														        <InputGroup.Text >{payModes[payMode].name}</InputGroup.Text>
														        <Form.Control onChange={(e)=>{addBillAmount(e.target.value,payMode)}} name="coin" type="number" min="1" />
														    </InputGroup>
														</Col>
													):''
			    								))		        								
			    							):''
			    						):''
			    					}
			    				</Row>
			    				<div className="d-grid">
					        		{
								    	addCustomerLoad?(
								    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
								    	):(
									    	wcBalance!==undefined&&wcBalance < totalCashEntry?(
									    		<Button className="btn-t1"  disabled>SAVE</Button>
									    	):(
									    		<Button type="submit" className="btn-t1">SAVE</Button>
									    	)
								    	)
								    }
				        		</div>
				        	</Form>
		        		)
		        	}
		        </Modal.Body>
		    </Modal>
			<Modal size="xl" show={addToModal} backdrop="static" onHide={()=>{setAddToModal(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>{traId!==undefined&&walletCus!==undefined&&walletCus[traId]!==undefined?(<span className="f16" >{`${walletCus[traId].name} / ${walletCus[traId].phone}`}</span>):''}</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={addMore} >
		        		<span className="d-grid f18 tp2 mb-3" >Total = {totalCashEntry}</span>
		        		<Row>
	    					{
	    						payModes!==undefined?(
	    							Object.keys(payModes).length>0?(
	    								Object.keys(payModes).map((payMode,payMode_i)=>(
	    									!payModes[payMode].credit&&!payModes[payMode].cw?(
			    								<Col xs={12} sm={6} md={3} lg={3} key={`pay_${payMode_i}`}>
			    									<InputGroup className="mb-3 br-2">
												        <InputGroup.Text >{payModes[payMode].name}</InputGroup.Text>
												        <Form.Control onChange={(e)=>{addBillAmount(e.target.value,payMode)}} name="coin" type="number" min="1" />
												    </InputGroup>
												</Col>
											):''
	    								))		        								
	    							):''
	    						):''
	    					}
	    				</Row>
	    				<div className="d-grid">
			        		{
						    	addCustomerLoad?(
						    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
						    	):(
						    	
						    	<Button type="submit" className="btn-t1">SAVE</Button>
						    	)
						    }
		        		</div>
		        	</Form>
		        </Modal.Body>
		    </Modal>
			<Modal size="xl" show={tranModal} backdrop="static" onHide={()=>{setTranModal(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>{traId!==undefined&&walletCus!==undefined&&walletCus[traId]!==undefined?(<span className="f16" >{`${walletCus[traId].name} / ${walletCus[traId].phone}`}</span>):''}</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	{
		        		tranLoad?(
		        			<span className="please-wait-spinner" ><Spinner animation="border" size="sm" />Fetching, Please wait...</span>
		        		):(
		        			tran!==undefined&&Object.keys(tran).length>0?(
		        				<Table bordered className="tbl-bg-blue" >
								    <thead>
								        <tr>
								          <th><span className="tp2" >Date & Time</span></th>
								          <th className="tp2" >Staff</th>
								          <th className="tp2 text-end" >Payment</th>
								          <th className="text-end tp2" >Amount</th>
								          <th className="text-end tp2" >Balance</th>
								        </tr>
								    </thead>
								    <tbody>
									    {
					        				Object.keys(tran).map((tra,trai)=>(
					        					<tr key={`tra_${trai}`} className="tp2" > 
					        						<td>
					        							<span className="d-grid" >{tran[tra].date}</span>
					        							<span className="d-grid f14" >{tran[tra].time}</span>
					        						</td>
					        						<td>{tran[tra].user}</td>
					        						<td>
					        							{
					        								tran[tra].payments!==undefined&&Object.keys(tran[tra].payments).length>0?(
					        									Object.keys(tran[tra].payments).map((tpay,tpayi)=>(
					        										<div class="grid-auto-100 bdrb2 mb-1 px-1" >
													          		  	<span className="tp3 f16" >{tran[tra].payments[tpay].name}</span>
													          			<span className="tp3 f16 grid-justify-end" >{tran[tra].payments[tpay].amount}</span>
													          		</div>
					        									))
					        								):(<span className="tp3 f16 grid-justify-end" >Bill payment</span>)
					        							}
					        						</td>
					        						<td className="text-end">
					        							{
					        								tran[tra].transaction_type!==undefined?(
						        								tran[tra].transaction_type==='debit'?(
						        									<span className="sucess-text">+{tran[tra].amount}</span>
							        							):tran[tra].transaction_type==='credit'||tran[tra].transaction_type==='withdraw'?(
							        								<span className="error-text">-{tran[tra].amount}</span>
							        							):''
						        							):''
						        						}
					        						</td>
					        						<td className="text-end">{tran[tra].balance}</td>
					        					</tr>
					        				))
					        			}
		        					</tbody>
		        				</Table>
		        			):'Transactios NOT found!'
		        		)
		        	}
		        </Modal.Body>
		    </Modal>
			<Modal size="xl" show={newCustomerModel} backdrop="static" onHide={()=>{setNewCustomerModel(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Add New</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={addCustomer} >		        		
		        		<Row>
		        			<Col>
		        				<Form.Group className="mb-3">
			        			<Form.Label>Contact number </Form.Label>
				        			<div className="serach-customer d-grid pos-rel">
				        				<Form.Control onBlur={(e)=>{
				        					setTimeout(()=>{
				        						setShowAutoSuggestion(false);
				        					},200);
				        				}} autoComplete="off" onWheel={(e) => {e.target.blur()} } id="cus_phone" onChange={(e)=>{searchCustomer(e.target.value)}} type="number" name="phone" className="shadow-none br-2" placeholder="Contact number" required/>
				        				
			        					{
		        						searchResult!==undefined&&showAutoSuggestion?(
		        							Object.keys(searchResult).length > 0 ?(
		        								<div className="customer-search-results bdr1">
		        								{
		        								Object.keys(searchResult).map((cus,cus_index)=>(
		        									<span onClick={()=>{setSelectedItem({ id : cus, profile : searchResult[cus] }) }} className={cus===Object.keys(searchResult)[selectCount]?'d-grid bdrb1 active':'d-grid bdrb1'}  key={`cust_${cus_index}`}>{searchResult[cus].name} - {searchResult[cus].phone} </span>
		        									))
		        								}
		        								</div>
		        								):''
		        								
		        							):''
			        					}

				        			</div>
				        		</Form.Group>
				        		<Form.Group className="mb-3">
				        			<Form.Label>Name {nameReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
				        			<Form.Control type="text" id="cus_name" name="name"  className="shadow-none br-2" placeholder="Customer Name" />
				        		</Form.Group>
				        	</Col>
				        	<Col>
				        		<Form.Group className="mb-3">
				        			<Form.Label>Address</Form.Label>
				        			<Form.Control as="textarea" rows={4} id="cus_address" className="shadow-none br-2" name="address" />
				        		</Form.Group>				        		
		        			</Col>
		        		</Row>
		        		<span className="d-grid f18 tp2 mb-3" >Total = {totalCashEntry}</span>
		        		<Row>
	    					{
	    						payModes!==undefined?(
	    							Object.keys(payModes).length>0?(
	    								Object.keys(payModes).map((payMode,payMode_i)=>(
	    									!payModes[payMode].credit&&!payModes[payMode].cw?(
			    								<Col xs={12} sm={6} md={3} lg={3} key={`pay_${payMode_i}`}>
			    									<InputGroup className="mb-3 br-2">
												        <InputGroup.Text >{payModes[payMode].name}</InputGroup.Text>
												        <Form.Control onChange={(e)=>{addBillAmount(e.target.value,payMode)}} name="coin" type="number" min="1" />
												    </InputGroup>
												</Col>
											):''
	    								))		        								
	    							):''
	    						):''
	    					}
	    				</Row>
		        		<div className="d-grid">
			        		{
						    	addCustomerLoad?(
						    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
						    	):(
						    	
						    	<Button type="submit" className="btn-t1">SAVE</Button>
						    	)
						    }
		        		</div>
		        	</Form>
		        </Modal.Body>
		        <Modal.Footer></Modal.Footer>
		    </Modal>
		</Container>
		)
}