import React, {useState, useEffect, useContext} from 'react';
import {Row, Col, Button, Form, Modal, Table, Spinner, InputGroup, Container } from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import { AppContext } from '../App';

export const Cashier=()=>{
 	const { link, user, accessToken, payModes, setAuthForm, getSubitLoad, submittedTokens, setSubmittedTokens, account, postCall,
 	setStateSettle, setStateSettleAll, getSubmitted } = useContext(AppContext);
 	const [ liveToken, setLiveToken ] = useState();
 	const [ subTotal, setSubTotal ] = useState(0);
 	const [ manageTokenState, setManageTokenState ] = useState('payment');
 	useEffect(()=>{
		if(liveToken!==undefined){
			setSubTotal(prev=>(0));
			Object.keys(liveToken.details.service).map(item=>{
				setSubTotal(prev=>(
					prev+(parseInt(liveToken.details.service[item].gov_service_charge)+parseInt(liveToken.details.service[item].akshaya_charge))*parseInt(liveToken.details.service[item].quantity)
					));
			});
		}
	},[liveToken]);
	const [ tokenModel, setTokenModel ] = useState(false);
	const [ paymentWallet, setPaymentWallet ] = useState({});
	useEffect(()=>{
 		if (!tokenModel) {
 			setLiveToken();
 			setManageTokenState('payment');
 			setTotalCashEntry();
 			setPaymentWallet({});
 			setWalletEntry();
 			setPS(false);
 			setWallBalance();
 			setBillAmount(prev=>({}));
 		}
 	},[tokenModel]);
	const [ billAmount, setBillAmount ] = useState({});
	const [ totalCashEntry, setTotalCashEntry ] = useState(0);
	useEffect(()=>{
		console.log(billAmount);
		setTotalCashEntry(prev=>(0));
		if (billAmount!==undefined&&Object.keys(billAmount).length>0) {
			Object.values(billAmount).map(amt=>{
				setTotalCashEntry(prev=>(parseInt(prev)+parseInt(amt)));
			});
		}
	},[billAmount]);
	const addBillAmount=(amt,mode)=>{
		if(amt!==""){
			setBillAmount(prev=>({...prev,[mode]:amt}));
		}else{
			setBillAmount(prev=>({...prev,[mode]:0}));
		}
	}
	const [ billSettleLoad, setBillSettleLoad ] = useState(false);
	const [ pS, setPS ] = useState(false);
	const settleBill=()=>{
		if (subTotal!==undefined&&totalCashEntry!==undefined&&subTotal===totalCashEntry) {
			setBillSettleLoad(true)
			axios.post(`${link}api/mt/close/token/cashier/`,{
				token_id:liveToken.id.substring(1),
				payment : billAmount,
	        },{
	          headers:{
	            Authorization: 'Bearer '+accessToken
	          }
	        }).then((response)=>{
	        	setSubmittedTokens(response.data);
	        	setBillSettleLoad(false);
	        	if (!pS) {
	        		setTokenModel(false);	        		
	        	}
	        	setPS(false);
	        }).catch((err)=>{
	        	setBillSettleLoad(false);
		        if(err.response.status === 401){
		            setAuthForm(true);
		        }else if(err.response.status === 404){
		            console.log('Close_token_cashier - ',err.response.status,':',err.response.statusText);
		        }else if(err.response.status === 500){
		            console.log('Close_token_cashier - ',err.response.status,':',err.response.statusText);
		        }else{
		            console.log('Close_token_cashier - ',err.response.status,':',err.response.data);
		        }
	    	});
		}
	}
	const prinntAndSettle=(live_tkn)=>{
		if (subTotal!==undefined&&totalCashEntry!==undefined&&subTotal===totalCashEntry) {
			settleBill();
			setManageTokenState('print');
			setTimeout(()=>{
				$('#root').hide();
				$('#printable').html($('#bill_print').html());
				setTokenModel(false);
				setTimeout(()=>{window.print()},200);
				setTimeout(()=>{
					$('#root').show();
					$('#printable').html('');
					getSubmitted();
				},400);
			},100);
		}
	}
	const printBill=(bill)=>{
		$('#root').hide();
		$('#printable').html($('#bill_print').html());
		setTokenModel(false);
		setTimeout(()=>{window.print()},200);
		setTimeout(()=>{
			$('#root').show();
			$('#printable').html('');
			if (submittedTokens!==undefined) {
				setLiveToken({id:bill,details:submittedTokens[bill]});
				setTokenModel(true);
				setManageTokenState('print');
			}
		},400);
	}
	const [ wallBalance, setWallBalance ] = useState();
	const [ getWbLoad, setGetWbLoad ] = useState(false);
	const [ walletEntry, setWalletEntry ] = useState();
 	return (
 			<Container className="my-3" >

	    		<Col  xs={12} sm={12} md={12} lg={12} className='bdr2 br-3' >
					<Col xs="12" sm="12" md="12" lg="12" className="staff-type-header bgs1 d-grid p-2 bdrb2" >
						<span>{getSubitLoad?(<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Refreshing...</span>):'Bills.'}</span>
					</Col>
				</Col>
				<Table bordered className="tbl-bg-blue" >
				      <thead>
				        <tr>
				          <th><span className="tp2" >Token</span></th>
				          <th><span className="tp2" >Customer name</span></th>
				          <th><span className="tp2" >Customer phone</span></th>
				          <th><span className="tp2" >Bill created by</span></th>
				          <th className="text-end" ><span className="tp2" >Total</span></th>
				          <th className="text-end" ><span className="tp2" >Options</span></th>
				        </tr>
				      </thead>
				      <tbody>
						{
							submittedTokens!==undefined?(
								Object.keys(submittedTokens).length>0?(
									Object.keys(submittedTokens).map((bill,b_i)=>(
										<tr key={`bii_${b_i}`} >
									      		<td>
									      			<div className="d-grid">
										          		<span className="tp3 f16" >{submittedTokens[bill].token_number}</span>
										          	</div>
									      		</td>
									      		<td>
										          	<div className="d-grid">
										          		<span className="tp1 f16" >{submittedTokens[bill].customer_name}</span>
										          	</div>
										        </td>
										        <td>
										          	<div className="d-grid">
										          		<span className="tp1 f16" >{submittedTokens[bill].phone}</span>
										          	</div>
										        </td>
										        <td>
										          	<div className="d-grid">
										          		<span className="tp1 f16" >{submittedTokens[bill].user_name}</span>
										          	</div>
										        </td>
										        <td className="text-end pos-rel" >
										        	<span className="tp1 f18" >{submittedTokens[bill].total}</span>
										        </td>
										        <td className="text-end pos-rel" >
										        	{
	            										submittedTokens[bill].status=='create'?(
	            											<span className="t-blue f18">New token</span>
											    		):submittedTokens[bill].status=='open'?(
											    			<span className="t-yellow f18">Processing</span>
											    		):submittedTokens[bill].status=='submit'?(
											    			<>
											    				<Button onClick={()=>{
													        		setLiveToken({id:bill,details:submittedTokens[bill]});
													        		setTokenModel(true);
																	setManageTokenState('print');
													        	}} className="btn-t1 mx-1" size="sm"><i className="bi bi-receipt"></i> Bill</Button>
													        	<Button onClick={()=>{
												    				setLiveToken({id:bill,details:submittedTokens[bill]});
																	setTokenModel(true);
																	setManageTokenState('payment');
																	setGetWbLoad(true);
																	postCall('api/ap/check/wallet/balance/from/token/id/',{t_id:bill.substring(1)}).then(res=>{
																		setGetWbLoad(false);
																		if (res.data.status) {
																			setWallBalance(res.data.balance);
																		}
																	}).catch(err=>{
																		setGetWbLoad(false);
																	});
												    			}} size="sm" className="btn-t1"><i className="bi bi-credit-card"></i> Payment</Button>
											    			</>
											    		):''
											    	}
										        	
										        </td>
									    </tr>
									))
								):'Nothing found!'
							):''
						}
					</tbody>
				</Table>
				<Modal size="xl" show={tokenModel} backdrop="static" onHide={()=>{setTokenModel(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>
		          	<div className="d-grid">
		          		<span className="tp1 f18 mb-1" >Token No : {liveToken!==undefined?liveToken.details.token_number:''}</span>
		          		{
		          			liveToken!==undefined?(
		          				<span className="tp3 f14" >{liveToken.details.customer_name} / {liveToken.details.phone} / {liveToken.details.time}</span>
			          		):''
			          	}
		          	</div>
		          </Modal.Title>
		        </Modal.Header>
		        {
		        	manageTokenState!==undefined?(
		        		manageTokenState==='payment'?(
					        <Modal.Body>
			    				<span className="d-grid f18 tp2 mb-3" >Total bill amount : {subTotal!==undefined?subTotal:'--'}</span>
			    				{
		        					getWbLoad?(
		        						<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Checking wallet please wait...</span>
		        					):(
				        				wallBalance!==undefined?(
				        					<span className="d-grid f18 tp2 mb-3" >Cash available in wallet = {wallBalance}</span>
				        				):(<span className="d-grid f18 tp2 mb-3" >Wallet NOT found!</span>)
			        				)
		        				}
			    				<Row>
			    					{
			    						payModes!==undefined?(
			    							Object.keys(payModes).length>0?(
			    								Object.keys(payModes).map((payMode,payMode_i)=>(
			    								payModes[payMode].cw?(
		        										wallBalance!==undefined?(
		        											<Col xs={12} sm={6} md={3} lg={3} key={`pay_${payMode_i}`}>
					        									<InputGroup className="mb-3 br-2">
															        <InputGroup.Text >{payModes[payMode].name}</InputGroup.Text>
															        <Form.Control onChange={(e)=>{
															        	addBillAmount(e.target.value,payMode);
															        	setWalletEntry(e.target.value);
															        	}} name="coin" type="number" min="1" />
															    </InputGroup>
															</Col>
		        										):''
		        									):(
			        								<Col xs={12} sm={6} md={3} lg={3} key={`pay_${payMode_i}`}>
			        									<InputGroup className="mb-3 br-2">
													        <InputGroup.Text >{payModes[payMode].name}</InputGroup.Text>
													        <Form.Control onChange={(e)=>{addBillAmount(e.target.value,payMode)}} name="coin" type="number" min="1" />
													    </InputGroup>
													</Col>
													)
			    								))		        								
			    							):''
			    						):''
			    					}
			    				</Row>
			    			</Modal.Body>
			    		):manageTokenState==='print'?(
		        			<Modal.Body>
					        	<Row id="bill_print">
						        	<Col  className="mx-0 px-0 bdr0" >
						        		{
							    			account!==undefined?(
								        		<div className="print-token-header bdr0-b" >
								        			{
									    				account.name!==""||account.address!==""||account.phone_numbers?(
									    					<div className="print-token-address">
											    				<div className="px-3 py-1">
											    					<span className="f20 tp0 d-grid"><b>{account.name}</b></span>
											    					<span className="f16 tp0 d-grid" >{account.address}</span>
											    					<span className="f16 tp0 d-grid" >{account.phone_numbers}</span>
											    				</div>
											    			</div>
									    				):''
									    			}
								        			{
								        				account.logo!==""?(
											    			<div className="print-token-header-logo d-grid" >
											    				<img className="px-3 py-1" src={account.logo} />
											    			</div>
									    				):''
									    			}
									    												    			
									    		</div>
								    		):''
							    		}
							    		{
							    			liveToken!==undefined?(
							    				<div className="px-3 py-1 invoice-cus-details" >
							    					<div>
							    						<span className="f16 tp0 d-grid ">Customer details</span>
							    						<span className="f14 tp0 d-grid">{liveToken.details.customer_name}</span>
							    						<span className="f14 tp0 d-grid">{liveToken.details.phone}</span>
							    					</div>
							    					<div className="text-end">							    						
							    						<span className="f16 tp0 d-grid ">Invoice details</span>
							    						<span className="f14 tp0 d-grid">{liveToken.details.bill_id} : Invoice number</span>
							    						<span className="f14 tp0 d-grid">{liveToken.details.date} / {liveToken.details.time} : Date</span>
							    					</div>
							    				</div>
							    			):''
							    		}
						        		<Table className="tbl-bill-print" >
										    <thead>
										        <tr className="bdr0-b bdr0-t" >
										          <th className="bdr0-r" ><span className="tp0" >No</span></th>
										          <th className="bdr0-r" ><span className="tp0" >Services</span></th>
										          <th className="text-end bdr0-r" ><span className="tp0" >Price</span></th>
										          <th className="text-end bdr0-r" ><span className="tp0" >Qty</span></th>
										          <th className="text-end" ><span className="tp0" >Total</span></th>
										        </tr>
										    </thead>
										    <tbody>
										      	{
										      		liveToken!==undefined?(
											      		Object.keys(liveToken.details.service).length>0?(
											      			Object.keys(liveToken.details.service).map((t_ser,ts_i)=>(
											      				<tr key={`bill_s_${ts_i}`} className="bdr0-b" >
														          <td className="bdr0-r"><span className="tp0 f16 mb-1" >{ts_i+1}</span></td>
														          <td className="bdr0-r"><span className="tp0 f18" >{liveToken.details.service[t_ser].name}</span></td>
														          <td className="text-end bdr0-r" ><span className="tp0 f18" >{
																    			parseInt(liveToken.details.service[t_ser].gov_service_charge)+parseInt(liveToken.details.service[t_ser].akshaya_charge)}</span></td>
														          <td className="text-end pos-rel td-qty bdr0-r" >
															          <span className="tp0 f18" >{liveToken.details.service[t_ser].quantity}</span>									          	
														          </td>
														          <td className="text-end pos-rel" >
														          	<span className="tp0 f18" >{(parseInt(liveToken.details.service[t_ser].gov_service_charge)+parseInt(liveToken.details.service[t_ser].akshaya_charge))*parseInt(liveToken.details.service[t_ser].quantity)}</span>
														          </td>
														        </tr>
										      				))
										      			):''
										      		):''
										      	}
										        
									        	<tr className="bdr0-b" >
									        		<td colSpan={2} ></td>
									        		<td colSpan={2} className="text-end bdr0-r" ><span className="tp0 f18" ><b>Total</b></span></td>
									        		<td className="text-end tp0" ><b>{subTotal!==undefined?subTotal:'--'}</b></td>
									        	</tr>
										    </tbody>
									   	</Table>
									   	<div className="d-grid bill-signature px-3 pt-2 pb-5">
									   		<span className="f16 tp0" >System generated invoice. by {user!==undefined?user.profile.first_name:''}</span>
									   	</div>
						        	</Col>
						        </Row>
					        </Modal.Body>
		        		):''
			    	):''
			    }
			    {
		        	manageTokenState!==undefined?(
		        		manageTokenState==='payment'?(
			    			<Modal.Footer>
			    				{
			    					billSettleLoad?(
					    				<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
			    					):(
			        					subTotal!==undefined&&totalCashEntry!==undefined&&subTotal===totalCashEntry?(
					        				<>
					        				<Button onClick={()=>{
					        					if(wallBalance!==undefined&&walletEntry!==undefined){
					        						if(walletEntry<=wallBalance){
					        							setPS(true);
					        							prinntAndSettle(liveToken.id);
					        						}else{
					        							    $.alert({
														        title: 'Something went wrong',
														        content: 'Wallet amount is greater than the balance available!',
														        type:'red',
														    });
					        						}
					        					}else{
					        						setPS(true);
					        						prinntAndSettle(liveToken.id);
					        					}
					        					}} className="btn-t1" ><i className="bi bi-printer"></i> Print & Settle</Button>
			        						<Button onClick={()=>{
			        							if(wallBalance!==undefined&&walletEntry!==undefined){
					        						if(walletEntry<=wallBalance){
					        							settleBill();
					        						}else{
					        							    $.alert({
														        title: 'Something went wrong',
														        content: 'Wallet amount is greater than the balance available!',
														        type:'red',
														    });
					        						}
					        					}else{
					        						settleBill();
					        					}
			        						}} className="btn-t1" >Settle</Button>
			        						</>
			        					):(
			        					<>
					        				<Button className="btn-t1" disabled><i className="bi bi-printer"></i> Print & Settle</Button>
			        						<Button className="btn-t1" disabled>Settle</Button>
			        					</>
			        					)
			    					)
			    				}
			    			</Modal.Footer>
			    		):manageTokenState==='print'?(
		        			<Modal.Footer>
					        	<Button onClick={()=>{printBill(liveToken.id)}} className="btn-t1" ><i className="bi bi-printer"></i> Print</Button>
				           	</Modal.Footer>
		        		):''
			    	):''
			    }
		    </Modal>
			</Container>
 		)
 }