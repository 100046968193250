import React, {useContext, useEffect, useState} from 'react';
import {Container, Row, Col, Button, Form, Modal, Spinner, Accordion, Tabs, Tab, InputGroup, Table } from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import { AppContext } from '../App';


export const Credits=()=>{
	const { accessToken, user, setAuthForm, link, credits, getCredits, setCredits, getCreditLoad, payModes, postCall,
	getBill, setViewBillModal} = useContext(AppContext);
	const [ creditSettleModal, setCreditSettleModal ] = useState(false);
	const [ paymentWallet, setPaymentWallet ] = useState({});
	useEffect(()=>{
		if (!creditSettleModal) {
			setCreditItemId();
			setBillAmount(prev=>{});
			setPaymentWallet({});
 			setWalletEntry();
 			setWallBalance();
		}
	},[creditSettleModal]);
	const [ billAmount, setBillAmount ] = useState({});
	const [ creditItemId, setCreditItemId ] = useState();
	const [ totalCashEntry, setTotalCashEntry ] = useState(0);
	useEffect(()=>{
		setTotalCashEntry(0);
		if (billAmount!==undefined&&Object.keys(billAmount).length>0) {
			Object.values(billAmount).map(amt=>{
				setTotalCashEntry(prev=>(parseInt(prev)+parseInt(amt)));
			});
		}
	},[billAmount]);
	const addBillAmount=(amt,mode)=>{
		if(amt!==""){
			setBillAmount(prev=>({...prev,[mode]:amt}));
		}else{
			setBillAmount(prev=>({...prev,[mode]:0}));
		}
	}
	const [ billSettleLoad, setBillSettleLoad ] = useState(false);
	const [ wallBalance, setWallBalance ] = useState();
	const [ getWbLoad, setGetWbLoad ] = useState(false);
	const [ walletEntry, setWalletEntry ] = useState();
	const settleBill=()=>{
		if (totalCashEntry!==undefined) {
			setBillSettleLoad(true)
			axios.post(`${link}api/bnr/collect/debit/`,{
				payment : billAmount,
				credit_id : creditItemId.substring(1),
	        },{
	          headers:{
	            Authorization: 'Bearer '+accessToken
	          }
	        }).then((response)=>{
	        	setBillSettleLoad(false);
	        	setCredits(response.data);
	        	setCreditSettleModal(false);
	        }).catch((err)=>{
	        	setBillSettleLoad(false);
		        if(err.response.status === 401){
		            setAuthForm(true);
		        }else if(err.response.status === 404){
		            console.log('Collect_debit - ',err.response.status,':',err.response.statusText);
		        }else if(err.response.status === 500){
		            console.log('Collect_debit - ',err.response.status,':',err.response.statusText);
		        }else{
		            console.log('Collect_debit - ',err.response.status,':',err.response.data);
		        }
	    	});
		}
	}
	const [ creditHistory, setCreditHistory ] = useState();
	const [ creditHistoryLoad, setCreditHistoryLoad ] = useState();
	const getCreditHistory=()=>{
		setCreditHistoryLoad(true);
	    axios.get(`${link}api/bnr/get/debits/`,{
	        headers:{
	                Authorization: 'Bearer '+accessToken
	              }
	      }).then(response=>{
	        setCreditHistoryLoad(false);
	        setCreditHistory(response.data);
	      }).catch(err=>{
	        setCreditHistoryLoad(false);
	        if(err.response.status === 401){
	              setAuthForm(true);
	          }else if(err.response.status === 404){
	              console.log('Get_debits - ',err.response.status,':',err.response.statusText);
	          }else if(err.response.status === 500){
	              console.log('Get_debits - ',err.response.status,':',err.response.statusText);
	          }else{
	              console.log('Get_debits - ',err.response.status,':',err.response.data);
	          }
	      });
	}
	const [ tabState, setTabState ] = useState('home');
	const getResolved=(key)=>{
		setTabState(key);
		if (key==='history') {
			getCreditHistory();
		}else if(key==='home'){
			getCredits();
		}
	}
	const [ creditSearchResult, setCreditSearchResult ] = useState();
	const [ historySearchResult, setHistorySearchResult ] = useState();
	const search=(s_name)=>{
		if(s_name!==""){
			if (tabState==='home') {
				if(credits!==undefined){
				    if(Object.keys(credits).length>0){
				    	setCreditSearchResult(prev=>{});
				    	let count=0;
				    	Object.keys(credits).map(cred=>{
				    		if (count<50) {
					    		if (credits[cred].customer_name.toLowerCase().includes(s_name.toLowerCase())||credits[cred].customer_phone.includes(s_name)) {
					    			setCreditSearchResult(prev=>({...prev,[cred]:credits[cred]}));
					    			count+=1;
					    		}
					    	}
				    	});
				    }
				}			    		
	    	}else if(tabState==='history'){
	    		if(creditHistory!==undefined){
				    if(Object.keys(creditHistory).length>0){
				    	setHistorySearchResult(prev=>{});
				    	let count=0;
				    	Object.keys(creditHistory).map(cred=>{
				    		if (count<50) {
					    		if (creditHistory[cred].customer_name.toLowerCase().includes(s_name.toLowerCase())||creditHistory[cred].customer_phone.includes(s_name)) {
					    			setHistorySearchResult(prev=>({...prev,[cred]:creditHistory[cred]}));
					    			count+=1;
					    		}
					    	}
				    	});
				    }
				}
	    	}
		}else{
			setCreditSearchResult(prev=>{});
			setHistorySearchResult(prev=>{});
		}
	}
	useEffect(()=>{
		if (tabState==='home') {
			if ($('#credit_search_box').val()!=="") {
				search($('#credit_search_box').val());
			}
		}else if (tabState === 'history') {
			if ($('#credit_search_box').val()!=="") {
				search($('#credit_search_box').val());
			}
		}
	},[credits, creditHistory]);
	return(
		<Container className="my-3" >
			<Col xs={12} sm={12} md={12} lg={12} className="service-search mb-3" >
    			<Form onSubmit={(e)=>{e.preventDefault()}}>
    				<InputGroup>
    					<Form.Control id="credit_search_box"  onChange={(e)=>{search(e.target.value)}} className="br-2" size="lg" type="text" placeholder="Search here" />
    					<InputGroup.Text className="br-3 btn-t1 px-4" id="basic-addon2"><i className="bi bi-search"></i></InputGroup.Text>
    				</InputGroup>
    			</Form>
    		</Col>
    		<Col  xs={12} sm={12} md={12} lg={12} >
				<Tabs defaultActiveKey="home" className="mt-2" onSelect={(e)=>{getResolved(e)}} >
					<Tab eventKey="home" title="Credits" className="tab-border p-3" >
						{
							getCreditLoad?(
								<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
							):(
								credits!==undefined?(
									Object.keys(credits).length>0?(
										creditSearchResult!==undefined&&Object.keys(creditSearchResult).length>0?(
											Object.keys(creditSearchResult).map((pk,pk_i)=>(
												<Row className="closed-item bgs1 mx-0 mb-1 bdr2 br-3 py-2" key={`cred_${pk_i}`}>
													<Col xs={12} sm={4} md={8} lg={8} xl={10} className="closed-item-staff" >
														<span className="f18 tp2 d-grid" >{creditSearchResult[pk].customer_name}, Amount = {creditSearchResult[pk].amount} </span>
														<span className="f14 ts2" >Date : {creditSearchResult[pk].created_date}, Time - {creditSearchResult[pk].created_time} by {creditSearchResult[pk].user_name}</span>
													</Col>
													<Col xs={12} sm={4} md={2} lg={2} xl={1} className="d-grid" >
														<span onClick={()=>{
															getBill(creditSearchResult[pk].bill_id);
															setViewBillModal(true);
														}} className="view-bill-link-credit d-grid" >Bill</span>
													</Col>
													<Col xs={12} sm={4} md={2} lg={2} xl={1} className="d-grid" >
														<Button onClick={()=>{
															setCreditSettleModal(true);
															setCreditItemId(pk);
															setGetWbLoad(true);
															postCall('api/ap/check/wallet/balance/from/bill/id/',{b_id:creditSearchResult[pk].bill_id}).then(res=>{
																setGetWbLoad(false);
																if (res.data.status) {
																	setWallBalance(res.data.balance);
																}
															}).catch(err=>{
																setGetWbLoad(false);
															});
														}} className="btn-t1" >Settle</Button>
													</Col>
												</Row>
											))
										):(
											Object.keys(credits).map((pk,pk_i)=>(
												<Row className="closed-item bgs1 mx-0 mb-1 bdr2 br-3 py-2" key={`cred_${pk_i}`}>
													<Col xs={12} sm={4} md={8} lg={8} xl={10} className="closed-item-staff" >
														<span className="f18 tp2 d-grid" >{credits[pk].customer_name}, Amount = {credits[pk].amount} </span>
														<span className="f14 ts2" >Date : {credits[pk].created_date}, Time - {credits[pk].created_time}  by {credits[pk].user_name} </span>
													</Col>
													<Col xs={12} sm={4} md={2} lg={2} xl={1} className="d-grid" >
														<span onClick={()=>{
															getBill(credits[pk].bill_id);
															setViewBillModal(true);
														}} className="view-bill-link-credit d-grid" >Bill</span>
													</Col>
													<Col xs={12} sm={4} md={2} lg={2} xl={1} className="d-grid" >
														<Button onClick={()=>{
															setCreditSettleModal(true);
															setCreditItemId(pk);
															setGetWbLoad(true);
															postCall('api/ap/check/wallet/balance/from/bill/id/',{b_id:credits[pk].bill_id}).then(res=>{
																setGetWbLoad(false);
																if (res.data.status) {
																	setWallBalance(res.data.balance);
																}
															}).catch(err=>{
																setGetWbLoad(false);
															});
														}} className="btn-t1" >Settle</Button>
													</Col>
												</Row>
											))
										)

									):'Nothing found!'
								):''
							)
						}
					</Tab>
					<Tab eventKey="history" title="History" className="tab-border p-3" >
						{
							creditHistoryLoad?(
								<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
							):(
								creditHistory!==undefined?(
									Object.keys(creditHistory).length>0?(
										historySearchResult!==undefined&&Object.keys(historySearchResult).length>0?(
											Object.keys(historySearchResult).map((pk,pk_i)=>(
												<Row className="closed-item bgs1 mx-0 mb-1 bdr2 br-3 py-2" key={`cred_${pk_i}`}>
													<Col xs={12} sm={12} md={12} className="closed-item-staff" >
														<span className="f18 tp2 d-grid" >{historySearchResult[pk].customer_name}, Amount = {historySearchResult[pk].amount} </span>
														<span className="f14 ts2 d-grid" >Date : {historySearchResult[pk].created_date}, Time - {historySearchResult[pk].created_time}</span>
														<span className="f14 ts2 d-grid" >Settled by {historySearchResult[pk].settled_by} on {historySearchResult[pk].settled_date} at {historySearchResult[pk].settled_time}</span>
														
													</Col>
												</Row>
											))
										):(
											Object.keys(creditHistory).map((pk,pk_i)=>(
												<Row className="closed-item bgs1 mx-0 mb-1 bdr2 br-3 py-2" key={`cred_${pk_i}`}>
													<Col xs={12} sm={12} md={12} className="closed-item-staff" >
														<span className="f18 tp2 d-grid" >{creditHistory[pk].customer_name}, Amount = {creditHistory[pk].amount} </span>
														<span className="f14 ts2 d-grid" >Date : {creditHistory[pk].created_date}, Time - {creditHistory[pk].created_time}</span>
														<span className="f14 ts2 d-grid" >Settled by {creditHistory[pk].settled_by} on {creditHistory[pk].settled_date} at {creditHistory[pk].settled_time}</span>
														
													</Col>
												</Row>
											))
										)

									):''
								):''
							)
						}
					</Tab>
				</Tabs>
			</Col>
			<Modal size="xl" show={creditSettleModal} backdrop="static" onHide={()=>{setCreditSettleModal(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Settle</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Row>
		        		{
		        					getWbLoad?(
		        						<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Checking wallet please wait...</span>
		        					):(
				        				wallBalance!==undefined?(
				        					<span className="d-grid f18 tp2 mb-3" >Cash available in wallet = {wallBalance}</span>
				        				):(<span className="d-grid f18 tp2 mb-3" >Wallet NOT found!</span>)
			        				)
		        				}
    					{
    						payModes!==undefined?(
    							Object.keys(payModes).length>0?(
    								Object.keys(payModes).map((payMode,payMode_i)=>(
	    								payModes[payMode].name!=='Credit'?(
		    								payModes[payMode].cw?(
        										wallBalance!==undefined?(
        											<Col xs={12} sm={6} md={3} lg={3} key={`pay_${payMode_i}`}>
			        									<InputGroup className="mb-3 br-2">
													        <InputGroup.Text >{payModes[payMode].name}</InputGroup.Text>
													        <Form.Control onChange={(e)=>{
													        	addBillAmount(e.target.value,payMode);
													        	setWalletEntry(e.target.value);
													        	}} name="coin" type="number" min="1" />
													    </InputGroup>
													</Col>
        										):''
        									):(
	        								<Col xs={12} sm={6} md={3} lg={3} key={`pay_${payMode_i}`}>
	        									<InputGroup className="mb-3 br-2">
											        <InputGroup.Text >{payModes[payMode].name}</InputGroup.Text>
											        <Form.Control onChange={(e)=>{addBillAmount(e.target.value,payMode)}} name="coin" type="number" min="1" />
											    </InputGroup>
											</Col>
											)
										):''
    								))		        								
    							):''
    						):''
    					}
    				</Row>
		        </Modal.Body>
		        <Modal.Footer>
		        {
		        	billSettleLoad?(
					    <Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
		        	):(
			        	creditItemId!==undefined?(
			        		credits[creditItemId]!==undefined?(
			        			credits[creditItemId].amount===totalCashEntry?(
			        				<Button onClick={()=>{
			        					if(wallBalance!==undefined&&walletEntry!==undefined){
			        						if(walletEntry<=wallBalance){
			        							settleBill();
			        						}else{
			        							    $.alert({
												        title: 'Something went wrong',
												        content: 'Wallet amount is greater than the balance available!',
												        type:'red',
												    });
			        						}
			        					}else{
			        						settleBill();
			        					}
			        				}} className="btn-t1" >Settle</Button>
			        			):(
			        				<Button className="btn-t1" disabled>Settle</Button>
			        			)
			        		):''
			        	):''
		        	)
		        }
		        </Modal.Footer>
		    </Modal>

		    
		</Container>
		);
}