import React, {useState, useEffect, useContext} from 'react';
import {Container, Row, Col, Button, Form, Modal, Spinner,Table} from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../App';


export const StaffsAttandence=()=>{
	const { user, load, setLoad, postCall, attendence, setAttendence, getDate } = useContext(AppContext);
	const [ updateModal, setUpdateModal ] = useState(false);
	const [ updateLoad, setUpdateLoad ] = useState(false);
	const [ atDate, setAtDate ] = useState(getDate());
	useEffect(()=>{
		if (atDate!=="") {
			setLoad(true);
			postCall('api/sm/get/attendance/',{ date:atDate }).then(res=>{
				setAttendence(res.data);
				setLoad(false);
			}).catch(err=>{
				setLoad(false);
			});
		}
	},[atDate]);
	const [ activeStaff, setActiveStaff ] = useState();
	const updateAttandance=(e)=>{
		e.preventDefault();
		let att = e.target.attendence.value;
		let ot = e.target.overtime.value;
		if (att!==""&&parseInt(ot)>-1&&activeStaff!==undefined) {
			setUpdateLoad(true);
			postCall('api/sm/update/attendance/',{w_id:activeStaff.details.wallet_id,att:att,ot:ot,date:atDate}).then(res=>{				
				if (res.data.status) {
					setUpdateLoad(false);
					setAttendence(res.data.attendance);
					setUpdateModal(false);
				}else{
					$.alert({
				        title: 'Error!',
				        content: 'Salary already calculated! you cannot update attendance.',
				        type: 'red',
				    });
				    setUpdateLoad(false);
					setAttendence(res.data.attendance);
				}
			}).catch(err=>{
				setUpdateLoad(false);
			});
		}
	}
	useEffect(()=>{
		if (!updateModal) {
			setActiveStaff();
		}
	},[updateModal]);
	return(
		<Container className="my-3 bdr2 br-3 p-0" >
			<div className='grid-date gap-1 al-center bdrb2 bgs1 px-2 py-1' >
				<span className="f16 tp2" >{load?(<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>):'Attendance'}</span>
				<Form.Control onChange={(e)=>{setAtDate(e.target.value)}} type="date" defaultValue={atDate}  className="br-3" />													
			</div>
			<Table bordered className="tbl-bg-blue" >
			    <thead>
			        <tr className="tp2">
			          <th>Staff name</th>
			          <th>Updated by</th>
			          <th>Login time</th>
			          <th>Log out time</th>
			          <th>Overtime</th>
			          <th>Attendance</th>
			        </tr>
			    </thead>			    
			    <tbody>	
			    {
			    	attendence!==undefined&&Object.keys(attendence).length>0?(
			    		Object.keys(attendence).map((item,i)=>(
			    			<tr key={`att_${i}`} className="tp2" >
			    				<td>{attendence[item].name}</td>
			    				<td>{attendence[item].updated_by}</td>
			    				<td>{attendence[item].login}</td>
			    				<td>{attendence[item].log_out}</td>
			    				<td>{attendence[item].over_time}</td>
			    				<td>
			    					<div className="grid-split-2 al-center">
			    						<span>{attendence[item].attendance}</span>
			    						<div className="d-flex just-end">
			    							<Button onClick={()=>{
			    								setActiveStaff({id:item,details:attendence[item]});
			    								setUpdateModal(true);
			    							}} className="btn-t1" size="sm" ><i className="bi bi-pencil-square"></i> Edit</Button>
			    						</div>
			    					</div>
			    				</td>
			    			</tr>
			    		))
			    	):''
			    }		    
			    </tbody>
			</Table>
			<Modal size="xl" show={updateModal} backdrop="static" onHide={()=>{setUpdateModal(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Update Attendance</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={updateAttandance} >		        		
				    	<Row>
					    	<Col xs={12} sm={12} md={6} >
					    		<Form.Group className="mb-3">
					    			<Form.Label>Attendance</Form.Label>
					    			<Form.Select name="attendence" defaultValue={activeStaff!==undefined&&activeStaff.details.attendance!=='--'?activeStaff.details.attendance:'F'}  className="shadow-none br-2" required>
								      <option value="F" >Full day</option>
								      <option value="H" >Half day</option>
								      <option value="L" >Absend</option>
								    </Form.Select>
					    		</Form.Group>
					    	</Col>
					    	<Col xs={12} sm={12} md={6} >
					    		<Form.Group className="mb-3">
					    			<Form.Label>Overtime</Form.Label>
					    			<Form.Control defaultValue={activeStaff!==undefined&&activeStaff.details.over_time!=="--"?activeStaff.details.over_time:0} type="number" step=".01" min="0" name="overtime" className="shadow-none br-2" placeholder="First name" required/>
					    		</Form.Group>
					    	</Col>
				    	</Row>					    
					    <div  className="d-grid">
					    {
					    	updateLoad?(
					    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
					    	):(
					    	<Button type="submit" className="btn-t1" ><i className="bi bi-check2-square"></i> Update</Button>
					    	)
					    }
					    </div>
		        	</Form>
		        	
		        </Modal.Body>
		        <Modal.Footer>
		        	
		        </Modal.Footer>
		    </Modal>
		</Container>
		)
}