import React, {useContext} from 'react';
import {Container, Table, Spinner, Form, Row, Col, InputGroup, Button, Accordion } from 'react-bootstrap';
import { AppContext } from '../App';


export const StaffReport=()=>{
	const { getStaffReport, staffReport, staffReportLoad, getDate, getBill, setViewBillModal } = useContext(AppContext);
	const getReport=(e)=>{
		e.preventDefault();
		let start=e.target.start.value;
		let end = e.target.end.value;
		if (start!==""&&end!=="") {
			getStaffReport(start,end);
		}
	}
	return(
			<Container className="py-3">
			
			<Form onSubmit={getReport} >
				<Row className="mb-3" >
					<Col xs={12} sm={4} md={4} >
						<InputGroup  className="br-3">
					        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">From</InputGroup.Text>
					        <Form.Control name="start" type="date" defaultValue={getDate()}  className="br-3" />
					    </InputGroup>
					</Col>
					<Col xs={12} sm={4} md={4} >
						<InputGroup  className="br-3">
					        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">To</InputGroup.Text>
					        <Form.Control name="end" type="date" defaultValue={getDate()}  className="br-3" />
					    </InputGroup>
					</Col>
					<Col xs={12} sm={4} md={4} className="d-grid" >
						<Button type="submit" className="btn-t1" >Get Report</Button>
					</Col>
				</Row>
			</Form> 
			
			{
				staffReportLoad?(
					<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
				):(
				staffReport!==undefined?(
						Object.keys(staffReport).length>0?(
							Object.keys(staffReport).map((staff,s_i)=>(
								<Accordion key={`custo_${s_i}`} className="mb-2" >
								      <Accordion.Item eventKey="0">
								        <Accordion.Header><span className="tp1 f18" >{`${staffReport[staff].first_name} ${staffReport[staff].last_name}, Username : ${staffReport[staff].username}`}</span></Accordion.Header>
								        <Accordion.Body>
								          <Table bordered className="tbl-bg-blue" >
										    <thead>
										        <tr>
										          <th><span className="tp2" >Date</span></th>
										          <th><span className="tp2" >Customer</span></th>
										          <th><span className="tp2" >Invoice</span></th>
										          <th className="text-end tp2" ><span>TC</span></th>
										          <th className="text-end tp2" ><span>SC</span></th>
										          <th className="text-end tp2" ><span>Credit</span></th>
										          <th className="text-end tp2" >Total</th>
										        </tr>
										    </thead>
										    <tbody>
										    {
										    	staffReport[staff].bills!==undefined?(
										    		Object.keys(staffReport[staff].bills).length>0?(
										    			Object.keys(staffReport[staff].bills).map((bill,bill_i)=>(
										    				<tr key={`bill_${bill_i}`}>
													    		<td>
													    			<span className="tp2 mb-1 d-grid">Invoice : {staffReport[staff].bills[bill].bill_id}</span>
																	<span onClick={()=>{
																	getBill(staffReport[staff].bills[bill].bill_id);
																	setViewBillModal(true);
																		}} className="view-bill-link mb-1 mx-0" >View bill</span>
													    			<span className="tp2 d-grid py-1 f14">{staffReport[staff].bills[bill].bill_date}, {staffReport[staff].bills[bill].bill_time}</span>

																</td>
													    		<td>
													    			<span className="tp2 d-grid">{staffReport[staff].bills[bill].customer_name}</span>
													    			<span className="tp2 d-grid">{staffReport[staff].bills[bill].customer_phone}</span>
													    			
													    		</td>
													    		<td><span className="tp2">{staffReport[staff].bills[bill].bill_id}</span></td>
													    		<td className="tp2 text-end"><span>{staffReport[staff].bills[bill].transaction_total}</span></td>
													    		<td className="tp2 text-end"><span>{staffReport[staff].bills[bill].service_amount_total}</span></td>
													    		<td className="tp2 text-end">												    			
													    			{
													    				staffReport[staff].bills[bill].credit_amount!==null&&staffReport[staff].bills[bill].credit_amount!==""&&staffReport[staff].bills[bill].credit_amount>0?(
													    					staffReport[staff].bills[bill].credit_status?(
													    						<span className="sucess-tag mx-2"><i className="bi bi-check-circle"></i> Paid</span>
													    					):(

													    						<span className="error-message mx-2"><i className="bi bi-x-circle"></i> Pending</span>
													    					)	
													    				):''
													    			}
													    			<span>{staffReport[staff].bills[bill].credit_amount}</span>
													    		</td>
													    		<td className="tp2 text-end"><span>{staffReport[staff].bills[bill].bill_total}</span></td>
													    	</tr>
										    			))
										    		):''
										    	):''
										    }
										    <tr>
										    	<td></td>
										    	<td colSpan={2} className="f16 text-end bgy">Total</td>
										    	<td className="text-end bgy" ><span>{staffReport[staff].trans_bills_total}</span></td>
										    	<td className="text-end bgy" ><span>{staffReport[staff].serv_bills_total}</span></td>
										    	<td className="text-end bgy" ><span>{staffReport[staff].credit_bills_total}</span></td>
										    	<td className="text-end bgy" ><span>{staffReport[staff].bills_total}</span></td>
										    </tr>		        
										    </tbody>
										</Table>
								        </Accordion.Body>
								      </Accordion.Item>
								    </Accordion>
							))
						):'Not found'
					):'Not found!'
				)
			}
			</Container>
		)
}