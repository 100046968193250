import React, {useState, useEffect, useContext} from 'react';
import {Container, Row, Col, Button, Form, Modal, Spinner, Accordion, InputGroup } from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import * as XLSX from "xlsx";
import { AppContext } from '../App';


export const Services=()=>{
	const { accessToken, user, setAuthForm, link, services, setServices, getServiceLoad} = useContext(AppContext);
	const [ newServiceModel, setNewServiceModel ] = useState(false);
	const [ newReccuringService, setNewReccuringService ] = useState(false);
	const [ nameRequiredErr, setNameRequiredErr ] = useState(false);
	const [ akshChargeRequireErr, setAkshChargeRequireErr ] = useState(false);
	const [ durationRequireErr, setDurationRequireErr ] = useState(false);
	const [ nfPeriodReqErr, setNfPeriodReqErr ] = useState(false);
	const [ addServiceLoad, setAddServiceLoad ] = useState(false);
	const postNewService=(service,gov_charge,akshaya_charge,req_doc,reccurent,var_renew,duration,notification,variable_charge)=>{
		setAddServiceLoad(true);
		axios.post(`${link}api/snp/add/service/`,{
				name:service,
				gov_service_charge:gov_charge,
				akshaya_charge:akshaya_charge,
				required_doc:req_doc,
				reccurent:reccurent,
				variable_expiry_period:var_renew,
				default_expiry_period:duration,
				notification_period:notification,
				variable_charge:variable_charge,
	        },{
	          headers:{
	            Authorization: 'Bearer '+accessToken
	          }
	        }).then((response)=>{
	        	setAddServiceLoad(false);
	        	setNewServiceModel(false);
	        	setServices(response.data);
	        }).catch((err)=>{
	        	setAddServiceLoad(false);
		        if(err.response.status === 401){
		            setAuthForm(true);
		        }else if(err.response.status === 404){
		            console.log('Add_service - ',err.response.status,':',err.response.statusText);
		        }else if(err.response.status === 500){
		            console.log('Add_service - ',err.response.status,':',err.response.statusText);
		        }else{
		            console.log('Add_service - ',err.response.status,':',err.response.data);
		        }
	    });
	}
	const addService=(e)=>{
		e.preventDefault();
		let service = e.target.name.value;
		let gov_charge = e.target.gov_charge.value;
		let akshaya_charge = e.target.akshaya_charge.value;
		let req_doc = e.target.req_doc.value;
		let reccurent = e.target.reccurent.checked;
		let variable_charge = e.target.variable_charge.checked;
		let var_renew = e.target.var_renew!==undefined?e.target.var_renew.checked:false;
		let duration = e.target.duration!==undefined?e.target.duration.value:'';
		let notification = e.target.notification!==undefined?e.target.notification.value:'';
		if (service===""){
			setNameRequiredErr(true);
			setTimeout(()=>{setNameRequiredErr(false)},5000);
		}
		if (akshaya_charge==="") {
			setAkshChargeRequireErr(true);
			setTimeout(()=>{setAkshChargeRequireErr(false)},5000);
		}
		if (reccurent&&duration==="") {
			setDurationRequireErr(true);
			setTimeout(()=>{setDurationRequireErr(false)},5000);
		}
		if (reccurent&&notification==="") {
			setNfPeriodReqErr(true);
			setTimeout(()=>{setNfPeriodReqErr(false)},5000);
		}
		if (service!==""&&akshaya_charge!==""&&serviceNameInvalid!==undefined&&!serviceNameInvalid) {
			if (reccurent) {
				if (duration!==""&&notification!=="") {
					postNewService(service,gov_charge,akshaya_charge,req_doc,reccurent,var_renew,duration,notification,variable_charge);
				}
			}else{
					postNewService(service,gov_charge,akshaya_charge,req_doc,reccurent,var_renew,duration,notification,variable_charge);
			}
		}
	}
	const [ checkServiceNameLoad, setCheckServiceNameLoad ] = useState(false);
	const [ serviceNameInvalid, setServiceNameInvalid ] = useState();
	useEffect(()=>{
		if (!newServiceModel) {
			setServiceNameInvalid();
		}
	},[newServiceModel]);
	const checkServiceName=(name)=>{
		if (name!=="") {
			setCheckServiceNameLoad(true);
			axios.post(`${link}api/snp/check/service/`,{
					name:name,
		        },{
		          headers:{
		            Authorization: 'Bearer '+accessToken
		          }
		        }).then((response)=>{
		        	setCheckServiceNameLoad(false);
		        	setServiceNameInvalid(response.data);
		        }).catch((err)=>{
		        	setCheckServiceNameLoad(false);
			        if(err.response.status === 401){
			            setAuthForm(true);
			        }else if(err.response.status === 404){
			            console.log('Add_check_service_name - ',err.response.status,':',err.response.statusText);
			        }else if(err.response.status === 500){
			            console.log('Add_check_service_name - ',err.response.status,':',err.response.statusText);
			        }else{
			            console.log('Add_check_service_name - ',err.response.status,':',err.response.data);
			        }
		    });
	    }
	}
	const editCheckServiceName=(id,name)=>{
		if (name!==""&&id!=="") {
			setCheckServiceNameLoad(true);
			axios.post(`${link}api/snp/edit/check/service/`,{
					name:name,
					service_id:id.substring(1),
		        },{
		          headers:{
		            Authorization: 'Bearer '+accessToken
		          }
		        }).then((response)=>{
		        	setCheckServiceNameLoad(false);
		        	setServiceNameInvalid(response.data);
		        }).catch((err)=>{
		        	setCheckServiceNameLoad(false);
			        if(err.response.status === 401){
			            setAuthForm(true);
			        }else if(err.response.status === 404){
			            console.log('Edit_check_service_name - ',err.response.status,':',err.response.statusText);
			        }else if(err.response.status === 500){
			            console.log('Edit_check_service_name - ',err.response.status,':',err.response.statusText);
			        }else{
			            console.log('Edit_check_service_name - ',err.response.status,':',err.response.data);
			        }
		    });
	    }
	}
	const [ editServiceModel, setEditServiceModel ] = useState(false);
	useEffect(()=>{
		if (!editServiceModel) {
			setServiceNameInvalid();
		}
	},[editServiceModel]);
	const [ editService, setEditService ] = useState();
	const [ editServiceLoad, setEditServiceLoad ] = useState(false);
	const postUpdateService=(service,gov_charge,akshaya_charge,req_doc,reccurent,var_renew,duration,notification,variable_charge)=>{
		setEditServiceLoad(true);
		axios.post(`${link}api/snp/edit/service/`,{
				service_id:editService.id.substring(1),
				name:service,
				gov_service_charge:gov_charge,
				akshaya_charge:akshaya_charge,
				required_doc:req_doc,
				reccurent:reccurent,
				variable_expiry_period:var_renew,
				default_expiry_period:duration,
				notification_period:notification,
				variable_charge:variable_charge,
	        },{
	          headers:{
	            Authorization: 'Bearer '+accessToken
	          }
	        }).then((response)=>{
	        	setEditServiceLoad(false);
	        	setEditServiceModel(false);
	        	setServices(response.data);
	        }).catch((err)=>{
	        	setEditServiceLoad(false);
		        if(err.response.status === 401){
		            setAuthForm(true);
		        }else if(err.response.status === 404){
		            console.log('Edit_service - ',err.response.status,':',err.response.statusText);
		        }else if(err.response.status === 500){
		            console.log('Edit_service - ',err.response.status,':',err.response.statusText);
		        }else{
		            console.log('Edit_service - ',err.response.status,':',err.response.data);
		        }
	    });
	}
	const updateService=(e)=>{
		e.preventDefault();
		let service = e.target.name.value;
		let gov_charge = e.target.gov_charge.value;
		let akshaya_charge = e.target.akshaya_charge.value;
		let req_doc = e.target.req_doc.value;
		let reccurent = e.target.reccurent.checked;
		let variable_charge = e.target.variable_charge.checked;
		let var_renew = e.target.var_renew!==undefined?e.target.var_renew.checked:false;
		let duration = e.target.duration!==undefined?e.target.duration.value:'';
		let notification = e.target.notification!==undefined?e.target.notification.value:'';
		if (service===""){
			setNameRequiredErr(true);
			setTimeout(()=>{setNameRequiredErr(false)},5000);
		}
		if (akshaya_charge==="") {
			setAkshChargeRequireErr(true);
			setTimeout(()=>{setAkshChargeRequireErr(false)},5000);
		}
		if (reccurent&&duration==="") {
			setDurationRequireErr(true);
			setTimeout(()=>{setDurationRequireErr(false)},5000);
		}
		if (reccurent&&notification==="") {
			setNfPeriodReqErr(true);
			setTimeout(()=>{setNfPeriodReqErr(false)},5000);
		}
		if (service!==""&&akshaya_charge!==""&&serviceNameInvalid!==undefined&&!serviceNameInvalid) {
			if (reccurent) {
				if (duration!==""&&notification!=="") {
					postUpdateService(service,gov_charge,akshaya_charge,req_doc,reccurent,var_renew,duration,notification,variable_charge);
				}
			}else{
					postUpdateService(service,gov_charge,akshaya_charge,req_doc,reccurent,var_renew,duration,notification,variable_charge);
			}
		}
	}
	const [ serviceId, setServiceId ] = useState();
	const [ serviceStatusChangeLoad, setServiceStatusChangeLoad ] = useState(false);
	const changeServiceStatus=(id,status)=>{
		setServiceStatusChangeLoad(true);
		axios.post(`${link}api/snp/change/service/status/`,{
				service_id:id.substring(1),
				status:status,
	        },{
	          headers:{
	            Authorization: 'Bearer '+accessToken
	          }
	        }).then((response)=>{
	        	setServiceStatusChangeLoad(false);
	        	setServices(response.data);
	        	setServiceId();	        	
	        }).catch((err)=>{
	        	setServiceStatusChangeLoad(false);
		        if(err.response.status === 401){
		            setAuthForm(true);
		        }else if(err.response.status === 404){
		            console.log('Service_status_change - ',err.response.status,':',err.response.statusText);
		        }else if(err.response.status === 500){
		            console.log('Service_status_change - ',err.response.status,':',err.response.statusText);
		        }else{
		            console.log('Service_status_change - ',err.response.status,':',err.response.data);
		        }
	    });
	}
	const [ serviceSearchResults, setServiceSearchResults  ] = useState({});
	const [ searchPageCount, setSearchPageCount ] = useState(0);
	const [ currentSearchPage, setCurrentSearchPage ] = useState(0);
	useEffect(()=>{
		if (serviceSearchResults!==undefined) {
			setCurrentSearchPage(prev=>(0));
			let len = Object.keys(serviceSearchResults).length;
			setSearchPageCount(Math.ceil(len/30));
		}
	},[serviceSearchResults]);
	const searchServices=(s_name)=>{
		if(s_name!==""){
			setServiceSearchResults(prev=>{});
			if(services!==undefined){
			    if(Object.keys(services).length>0){
			    	let count=0;
			    	Object.keys(services).map(service=>{
			    		if (services[service].name.toLowerCase().includes(s_name.toLowerCase())) {
			    			if (count<800) {
				    			setServiceSearchResults(prev=>({...prev,[service]:services[service]}));
				    			count+=1;
				    		}
			    		}
			    	});
			    }
			}
		}else{setServiceSearchResults(prev=>{})}
	}
	const [ pageCount, setPageCount ] = useState(0);
	const [ currentPage, setCurrentPage ] = useState(0);
	useEffect(()=>{
		if (services!==undefined&&serviceSearchResults!==undefined) {
			if (Object.keys(serviceSearchResults).length>0&&$('#search_box').val()!=="") {
				searchServices($('#search_box').val());			
			}
		}
		if (services!==undefined) {
			let len = Object.keys(services).length;
			setPageCount(Math.ceil(len/50));
		}
	},[services]);
	const [ downloadLoad, setDownLoadLoad ] = useState(false);
	const downloadServices=()=>{
		setDownLoadLoad(true);
		axios.get(`${link}api/snp/download/service/json/`,{
	          headers:{
	            Authorization: 'Bearer '+accessToken
	          }
	        }).then((response)=>{
	        	setDownLoadLoad(false);
	        	const worksheet = XLSX.utils.json_to_sheet(response.data);
			    const workbook = XLSX.utils.book_new();
			    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
			    XLSX.writeFile(workbook, "Akshaya_service.xlsx");
	        }).catch((err)=>{
	        	setDownLoadLoad(false);
	        	if(err!==undefined){
			        if(err.response.status === 401){
			            setAuthForm(true);
			        }else if(err.response.status === 404){
			            console.log('Download_service_excel - ',err.response.status,':',err.response.statusText);
			        }else if(err.response.status === 500){
			            console.log('Download_service_excel - ',err.response.status,':',err.response.statusText);
			        }else{
			            console.log('Download_service_excel - ',err.response.status,':',err.response.data);
			        }
			    }
	    });
	}
	const [ fileLoad, setFileLoad ] = useState(false);
	const uploadExcelContact=(event)=>{
		setFileLoad(true);
	    axios.post(`${link}api/snp/add/service/excel/`,{
	          files:event.target.files[0],
	        },{
	          headers:{
	            Authorization: 'Bearer '+accessToken,
	            "Content-Type": "multipart/form-data",
	          }
	        }).then((response)=>{
	          setFileLoad(false);
	          setServices(response.data);
	        }).catch((err)=>{
	          setFileLoad(false);
	          if(err.response.status === 401){
		            setAuthForm(true);
		        }else if(err.response.status === 404){
		            console.log('Upload_service_excel - ',err.response.status,':',err.response.statusText);
		        }else if(err.response.status === 500){
		            console.log('Upload_service_excel - ',err.response.status,':',err.response.statusText);
		        }else{
		            console.log('Upload_service_excel - ',err.response.status,':',err.response.data);
		        }	          
	    });
	}
	return(
		<Container className="my-3" >
			<Col xs={12} sm={12} md={12} lg={12} className="service-search mb-3" >
    			<Form onSubmit={(e)=>{e.preventDefault()}}>
    				<InputGroup>
    					<Form.Control id="search_box" onChange={(e)=>{searchServices(e.target.value)}} className="br-2" size="lg" type="text" placeholder="Search here" />
    					<InputGroup.Text className="br-3 btn-t1 px-4" id="basic-addon2"><i className="bi bi-search"></i></InputGroup.Text>
    				</InputGroup>
    			</Form>
    		</Col>
			<Col  xs={12} sm={12} md={12} lg={12} className='bdr2 br-3' >
				<Col xs="12" sm="12" md="12" lg="12" className="service-type-header bgs1 d-grid p-2 bdrb2" >
					<span>{getServiceLoad?(<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>):'Services'}</span>
					{
						downloadLoad?(
	        				<span className="please-wait-spinner p-2" ><Spinner animation="border" size="sm" /> Downloading...</span>
						):(
							<Button onClick={()=>{downloadServices()}} className="btn-t1"><i className="bi bi-download"></i> Download</Button>
						)
					}
					<input type="file" id="upload" hidden onChange={(e)=>{uploadExcelContact(e)}} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
					{
						fileLoad?(
	        				<span className="please-wait-spinner p-2" ><Spinner animation="border" size="sm" /> Uploading...</span>
						):(
							<Button onClick={()=>{$('#upload').trigger( "click" )}} className="btn-t1"><i className="bi bi-upload"></i> Upload excel</Button>
						)
					}

					{
						user!==undefined?(
							user.type==='admin'?(
								<Button className="btn-t1" onClick={()=>{setNewServiceModel(true)}}  ><i className="bi bi-plus-circle"></i> Add new</Button>
							):(
							user.permission!==undefined?(
								user.permission.service!==undefined&&user.permission.service.A?(
									<Button className="btn-t1" onClick={()=>{setNewServiceModel(true)}}  ><i className="bi bi-plus-circle"></i> Add new</Button>
									):''
								):''
							)
						):''
					}
				</Col>
				{
					services!==undefined&&Object.keys(services).length>0?(						
							serviceSearchResults!==undefined&&Object.keys(serviceSearchResults).length>0?(
								<Col  xs="12" sm="12" md="12" lg="12">
									{
										Object.keys(serviceSearchResults).map((service,ts_i)=>(
											searchPageCount!==undefined&&ts_i>=currentSearchPage&&ts_i<currentSearchPage+30?(
												<Col xs="12" sm="12" md="12" lg="12" className="service-item d-grid p-2 bdrb2" key={`servce_${ts_i}`} >
													<Accordion>
												      <Accordion.Item eventKey="0">
												        <Accordion.Header><span className="tp1 f18" >{serviceSearchResults[service].name}</span></Accordion.Header>
												        <Accordion.Body>
												          <Col>
												    		<p className="ts1 f16 mb-2" >Service Charge <i className="bi bi-currency-rupee"></i>{
												    			parseInt(serviceSearchResults[service].gov_service_charge)+parseInt(serviceSearchResults[service].akshaya_charge)}</p>
												    		<h5 className="ts1" >Required documents</h5>
												          	<p className="p-multi-line ts1" >{serviceSearchResults[service].required_doc}</p>
												          </Col>
												        </Accordion.Body>
												      </Accordion.Item>
												    </Accordion>
												    {
														user!==undefined?(
															user.type==='admin'?(
															<>
															<Button onClick={()=>{
																setEditServiceModel(true);
																setEditService({id:service,service:serviceSearchResults[service]});
																setNewReccuringService(serviceSearchResults[service].reccurent);
																editCheckServiceName(service,serviceSearchResults[service].name);
															}} className="btn-t1-r" ><i className="bi bi-pencil-square"></i> Edit</Button>
															{
																serviceId!==undefined&&serviceId===service&&serviceStatusChangeLoad?(
													    			<Button className="btn-t1" disabled><Spinner animation="border" size="sm" /></Button>
																	):(
																	serviceSearchResults[service].status?(
																		<Button onClick={()=>{
																			changeServiceStatus(service,false);
																			setServiceId(service);
																		}} className="btn-disable" ><i className="bi bi-eye-slash"></i> Disable</Button>
																	):(
																		<Button onClick={()=>{
																			changeServiceStatus(service,true);
																			setServiceId(service);
																		}} className="btn-enable" ><i className="bi bi-eye-slash"></i> Enable</Button>
																	)
																)
															}
															</>
															):(
															user.permission!==undefined?(
																	user.permission.service!==undefined&&user.permission.service.E?(
																	<>
																	<Button onClick={()=>{
																		setEditServiceModel(true);
																		setEditService({id:service,service:serviceSearchResults[service]});
																		setNewReccuringService(serviceSearchResults[service].reccurent);
																		editCheckServiceName(service,serviceSearchResults[service].name);
																	}} className="btn-t1-r" ><i className="bi bi-pencil-square"></i> Edit</Button>
																	{
																		serviceId!==undefined&&serviceId===service&&serviceStatusChangeLoad?(
															    			<Button className="btn-t1" disabled><Spinner animation="border" size="sm" /></Button>
																			):(
																			serviceSearchResults[service].status?(
																				<Button onClick={()=>{
																					changeServiceStatus(service,false);
																					setServiceId(service);
																				}} className="btn-disable" ><i className="bi bi-eye-slash"></i> Disable</Button>
																			):(
																				<Button onClick={()=>{
																					changeServiceStatus(service,true);
																					setServiceId(service);
																				}} className="btn-enable" ><i className="bi bi-eye-slash"></i> Enable</Button>
																			)
																		)
																	}
																	</>
																	):''
																):''
															)
														):''
													}
												</Col>
											):''
										))
									}
									<div className="py-2 pagination-wrap" >
			    					{
			    						searchPageCount!==undefined&&services!==undefined?(
			    							 Object.keys(services).map((c,ci)=>(
			    							 	ci<searchPageCount?(
			    							 		<div onClick={()=>{setCurrentSearchPage(ci*30)}} className={currentSearchPage!==undefined&&currentSearchPage===ci*30?'pageination-index m-2 active':'pageination-index m-2'} >
			    							 			<span>{ci+1}</span>
			    							 		</div>
			    							 	):''
			    							 ))
			    						):''
			    					}
			    					</div>		    					
		    					</Col>
							):(
								<Col  xs="12" sm="12" md="12" lg="12">
									{
									Object.keys(services).map((service,s_index)=>(
										pageCount!==undefined&&s_index>=currentPage&&s_index<currentPage+50?(
											<Col xs="12" sm="12" md="12" lg="12" className="service-item d-grid p-2 bdrb2" key={`service_${s_index}`} >
												<Accordion>
											      <Accordion.Item eventKey="0">
											        <Accordion.Header><span className="tp1 f18" >{services[service].name}</span></Accordion.Header>
											        <Accordion.Body>
											          <Col>
											    		<p className="ts1 f16 mb-2" >Service Charge <i className="bi bi-currency-rupee"></i>{
											    			parseInt(services[service].gov_service_charge)+parseInt(services[service].akshaya_charge)}</p>
											    		<h5 className="ts1" >Required documents</h5>
											          	<p className="p-multi-line ts1" >{services[service].required_doc}</p>
											          </Col>
											        </Accordion.Body>
											      </Accordion.Item>
											    </Accordion>
											    {
													user!==undefined?(
														user.type==='admin'?(
														<>
														<Button onClick={()=>{
															setEditServiceModel(true);
															setEditService({id:service,service:services[service]});
															setNewReccuringService(services[service].reccurent);
															editCheckServiceName(service,services[service].name);
														}} className="btn-t1-r" ><i className="bi bi-pencil-square"></i> Edit</Button>
														{
															serviceId!==undefined&&serviceId===service&&serviceStatusChangeLoad?(
												    			<Button className="btn-t1" disabled><Spinner animation="border" size="sm" /></Button>
																):(
																services[service].status?(
																	<Button onClick={()=>{
																		changeServiceStatus(service,false);
																		setServiceId(service);
																	}} className="btn-disable" ><i className="bi bi-eye-slash"></i> Disable</Button>
																):(
																	<Button onClick={()=>{
																		changeServiceStatus(service,true);
																		setServiceId(service);
																	}} className="btn-enable" ><i className="bi bi-eye-slash"></i> Enable</Button>
																)
															)
														}
														</>
														):(
														user.permission!==undefined?(
																user.permission.service!==undefined&&user.permission.service.E?(
																<>
																<Button onClick={()=>{
																	setEditServiceModel(true);
																	setEditService({id:service,service:services[service]});
																	setNewReccuringService(services[service].reccurent);
																	editCheckServiceName(service,services[service].name);
																}} className="btn-t1-r" ><i className="bi bi-pencil-square"></i> Edit</Button>
																{
																	serviceId!==undefined&&serviceId===service&&serviceStatusChangeLoad?(
														    			<Button className="btn-t1" disabled><Spinner animation="border" size="sm" /></Button>
																		):(
																		services[service].status?(
																			<Button onClick={()=>{
																				changeServiceStatus(service,false);
																				setServiceId(service);
																			}} className="btn-disable" ><i className="bi bi-eye-slash"></i> Disable</Button>
																		):(
																			<Button onClick={()=>{
																				changeServiceStatus(service,true);
																				setServiceId(service);
																			}} className="btn-enable" ><i className="bi bi-eye-slash"></i> Enable</Button>
																		)
																	)
																}
																</>
																):''
															):''
														)
													):''
												}
											</Col>
										):''
									))
									}
									<div className="py-2 pagination-wrap" >
			    					{
			    						pageCount!==undefined&&services!==undefined?(
			    							 Object.keys(services).map((c,ci)=>(
			    							 	ci<pageCount?(
			    							 		<div onClick={()=>{setCurrentPage(ci*50)}} className={currentPage!==undefined&&currentPage===ci*50?'pageination-index m-2 active':'pageination-index m-2'} >
			    							 			<span>{ci+1}</span>
			    							 		</div>
			    							 	):''
			    							 ))
			    						):''
			    					}
			    					</div>
		    					</Col>
							)						
					):'Not found'
				}				
			</Col>


			<Modal size="xl" show={newServiceModel} backdrop="static" onHide={()=>{setNewServiceModel(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Add new service</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={addService} >
		        		<Form.Group className="mb-3">
		        			<Form.Label>Service name {
		        				nameRequiredErr?(<span className="error-message" >This field is required</span>):''
		        			}{
        						checkServiceNameLoad?(
        							<span className="please-wait-spinner p-2" ><Spinner animation="border" size="sm" /> Checking availability...</span>
        						):(
		        					serviceNameInvalid!==undefined?(
		        						serviceNameInvalid?(
		        						<span className="error-text p-2" ><i className="bi bi-exclamation-circle-fill"></i> Service name already exist</span>
		        						):(
		        						<span className="sucess-text p-2"><i className="bi bi-check-circle-fill"></i> Available</span>
		        						)
		        					):''
	        					)
	        				}</Form.Label>
					    	<Form.Control onBlur={(e)=>{checkServiceName(e.target.value)}} type="text" name="name" className="shadow-none br-2" placeholder="Service name" />
					    </Form.Group>
					    <Form.Group className="mb-3">
					    	<Row>
						    	<Col xs={12} sm={12} md={6} >
						    		<Form.Label>Transaction Charge</Form.Label>
						    		<Form.Control type="number" step="any" name="gov_charge" className="shadow-none br-2" placeholder="Transaction Charge" />
						    	</Col>
						    	<Col xs={12} sm={12} md={6} >
						    		<Form.Label>Service charge{akshChargeRequireErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
						    		<Form.Control type="number" step="any" name="akshaya_charge" className="shadow-none br-2" placeholder="Service Charge" />
						    	</Col>
					    	</Row>
					    </Form.Group>
					    <Form.Group className="mb-3">
					    	<Form.Check name="variable_charge" className="shadow-none br-2" type="checkbox" label="Variable price" />
					    </Form.Group>
					    <Form.Group className="mb-3">
		        			<Form.Label>Document required</Form.Label>
					    	<Form.Control as="textarea" rows={3} name="req_doc" className="shadow-none br-2" />
					    </Form.Group >
					    <Form.Group className="mb-3">
					    	<Form.Check name="reccurent" onChange={(e)=>{setNewReccuringService(e.target.checked)}} className="shadow-none br-2" type="checkbox" label="Recurring Service" />
					    </Form.Group>
					    {
					    	newReccuringService!==undefined&&newReccuringService?(
					    		<Form.Group className="mb-3">
					    			<Form.Check name="var_renew" className="shadow-none br-2 mb-3" type="checkbox" label="Variable renewal time" />
					    			<Row>
						    			<Col xs={12} sm={12} md={6} >
					    					<Form.Label>Default Renewal duration {durationRequireErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
						    				<Form.Control type="number" name="duration" className="shadow-none br-2" placeholder="Number of months" />
										</Col>
						    			<Col xs={12} sm={12} md={6} >
						    				<Form.Label>Notification period before expiry {nfPeriodReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
						    				<Form.Control type="number" name="notification" className="shadow-none br-2" placeholder="Number of days" />
					    				</Col>
					    			</Row>
					    		</Form.Group>
					    	):''
					    }
					    <div className="d-grid">
					    {
					    	addServiceLoad?(
					    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
					    	):(
					    	<Button type="submit" className="btn-t1">SAVE</Button>
					    	)
					    }
					    </div>

		        	</Form>
		        </Modal.Body>
		        <Modal.Footer>
		        	
		        </Modal.Footer>
		    </Modal>

		    <Modal size="xl" show={editServiceModel} backdrop="static" onHide={()=>{setEditServiceModel(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Update service</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        {
		        	editService!==undefined?(
			        	<Form onSubmit={updateService} >
			        		<Form.Group className="mb-3">
			        			<Form.Label>Service name {
			        				nameRequiredErr?(<span className="error-message" >This field is required</span>):''
			        			}{
	        						checkServiceNameLoad?(
	        							<span className="please-wait-spinner p-2" ><Spinner animation="border" size="sm" /> Checking availability...</span>
	        						):(
			        					serviceNameInvalid!==undefined?(
			        						serviceNameInvalid?(
			        						<span className="error-text p-2" ><i className="bi bi-exclamation-circle-fill"></i> Service name already exist</span>
			        						):(
			        						<span className="sucess-text p-2"><i className="bi bi-check-circle-fill"></i> Available</span>
			        						)
			        					):''
		        					)
		        				}</Form.Label>
						    	<Form.Control defaultValue={editService.service.name} onBlur={(e)=>{editCheckServiceName(editService.id,e.target.value)}} type="text" name="name" className="shadow-none br-2" placeholder="Service name" />
						    </Form.Group>
						    <Form.Group className="mb-3">
						    	<Row>
							    	<Col xs={12} sm={12} md={6} >
							    		<Form.Label>Transaction Charge</Form.Label>
							    		<Form.Control defaultValue={editService.service.gov_service_charge} type="number" step="any" name="gov_charge" className="shadow-none br-2" placeholder="Transaction Charge" />
							    	</Col>
							    	<Col xs={12} sm={12} md={6} >
							    		<Form.Label>Service charge{akshChargeRequireErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
							    		<Form.Control defaultValue={editService.service.akshaya_charge} type="number" step="any" name="akshaya_charge" className="shadow-none br-2" placeholder="Service Charge" />
							    	</Col>
						    	</Row>
						    </Form.Group>						    
						    <Form.Group className="mb-3">
						    	<Form.Check defaultChecked={editService.service.variable_charge} name="variable_charge" className="shadow-none br-2" type="checkbox" label="Variable price" />
						    </Form.Group>
						    <Form.Group className="mb-3">
			        			<Form.Label>Document required</Form.Label>
						    	<Form.Control defaultValue={editService.service.required_doc} as="textarea" rows={3} name="req_doc" className="shadow-none br-2" />
						    </Form.Group >
						    <Form.Group className="mb-3">
						    	<Form.Check defaultChecked={editService.service.reccurent} name="reccurent" onChange={(e)=>{setNewReccuringService(e.target.checked)}} className="shadow-none br-2" type="checkbox" label="Recurring Service" />
						    </Form.Group>
						    {
						    	newReccuringService!==undefined&&newReccuringService?(
						    		<Form.Group className="mb-3">
						    			<Form.Check name="var_renew" defaultChecked={editService.service.variable_expiry_period} className="shadow-none br-2 mb-3" type="checkbox" label="Variable renewal time" />
						    			<Row>
							    			<Col xs={12} sm={12} md={6} >
						    					<Form.Label>Default Renewal duration {durationRequireErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
							    				<Form.Control defaultValue={editService.service.default_expiry_period} type="number" name="duration" className="shadow-none br-2" placeholder="Number of months" />
											</Col>
							    			<Col xs={12} sm={12} md={6} >
							    				<Form.Label>Notification period before expiry {nfPeriodReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
							    				<Form.Control defaultValue={editService.service.notification_period} type="number" name="notification" className="shadow-none br-2" placeholder="Number of days" />
						    				</Col>
						    			</Row>
						    		</Form.Group>
						    	):''
						    }
						    <div className="d-grid">
						    {
						    	editServiceLoad?(
					    		<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
						    	):(
						    	<Button type="submit" className="btn-t1">UPDATE</Button>
						    	)
						    }
						    </div>

			        	</Form>
		        	):'Somethig went wrong!'
		        }
		        </Modal.Body>
		        <Modal.Footer>
		        	
		        </Modal.Footer>
		    </Modal>


		</Container>
		)
}