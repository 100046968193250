import React, {useContext, useEffect, useState} from 'react';
import {Container, Row, Col, Button, Form, Modal, Spinner, Accordion, Tabs, Tab, InputGroup, Table } from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../App';


export const VerifyDayClose=()=>{
	const { accessToken, user, setAuthForm, link, dayCloseToVerify, setDayCloseToVerify, dayCloseToVerifyLoad, getDate } = useContext(AppContext);
	const [ verifyModal, setVerifyModal ] = useState(false);
	useEffect(()=>{
		if (!verifyModal) {
			setVerificationId();
		}
	},[verifyModal])
	const [ staffReport, setStaffReport ] = useState();
	const [ getStaffReportLoad, setGetStaffReportLoad ] = useState(false);
	const getStaffReport=(id)=>{
		setGetStaffReportLoad(true);
	    axios.post(`${link}api/pnp/day/close/user/report/view/`,{
	        day_close_id:id.substring(1),
	          },{
	            headers:{
	              Authorization: 'Bearer '+accessToken
	            }
	          }).then((response)=>{
	            setGetStaffReportLoad(false);
	            setStaffReport(response.data);
	          }).catch((err)=>{
	            setGetStaffReportLoad(false);
	            if(err.response.status === 401){
	                setAuthForm(true);
	            }else if(err.response.status === 404){
	                console.log('Get_day_close_user_report_view - ',err.response.status,':',err.response.statusText);
	            }else if(err.response.status === 500){
	                console.log('Get_day_close_user_report_view - ',err.response.status,':',err.response.statusText);
	            }else{
	                console.log('Get_day_close_user_report_view - ',err.response.status,':',err.response.data);
	            }
	      });
	}
	const [ verificationId, setVerificationId ] = useState();
	const [ verificationLoad, setVerificationLoad ] = useState(false);
	const verification=(e)=>{
		e.preventDefault();
		let n_500 = e.target.n_500.value;
		let n_200 = e.target.n_200.value;
		let n_100 = e.target.n_100.value;
		let n_50 = e.target.n_50.value;
		let n_20 = e.target.n_20.value;
		let n_10 = e.target.n_10.value;
		let n_5 = e.target.n_5.value;
		let n_2 = e.target.n_2.value;
		let n_1 = e.target.n_1.value;
		let remarks = e.target.remarks.value;
		let opening = e.target.opening.value;
		if(verificationId!==undefined){
		if (n_500!==""||n_200!==""||n_100!==""||n_50!==""||n_20!==""||n_10!==""||n_5!==""||n_2!==""||n_1!=="") {
			$.confirm({
				    title: 'Confirm Day Close!',
				    content: 'Are you sure you want to close day?',
				    type: 'blue',
				    typeAnimated: true,
				    buttons: {
				        confirm: {
				            text: 'Confirm',
				            btnClass: 'btn-blue',
				            action: function(){				            
								setVerificationLoad(true);
								axios.post(`${link}api/pnp/day/close/user/report/verify/`,{
									n_500:n_500,
									n_200:n_200,
									n_100:n_100,
									n_50:n_50,
									n_20:n_20,
									n_10:n_10,
									n_5:n_5,
									n_2:n_2,
									n_1:n_1,
									remarks:remarks,
									opening_balance:opening,
									day_close_id:verificationId.substring(1),
							        },{
							          headers:{
							            Authorization: 'Bearer '+accessToken
							          }
							        }).then((response)=>{
							        	setVerificationLoad(false);
							        	setDayCloseToVerify(response.data);
							        	setVerifyModal(false);      	
							        }).catch((err)=>{
							        	setVerificationLoad(false);
								        if(err.response.status === 401){
								            setAuthForm(true);
								        }else if(err.response.status === 404){
								            console.log('Day_close_user_report_verify- ',err.response.status,':',err.response.statusText);
								        }else if(err.response.status === 500){
								            console.log('Day_close_user_report_verify - ',err.response.status,':',err.response.statusText);
								        }else{
								            console.log('Day_close_user_report_verify - ',err.response.status,':',err.response.data);
								        }
							    });
					        }
				        },
				        cancel: function () {
				        }
				    }
			});
		}}
	}
	const [ verified, setVerified ] = useState();
	const [ getVerifiedLoad, setGetVerifiedLoad ] = useState(false);
	const [ historyDate, setHistoryDate ] = useState(getDate());
	useEffect(()=>{
		if (historyDate!==undefined) {
			getVerified('history');
		}
	},[historyDate])
	const getVerified=(key)=>{
		if (key==='history') {
			setGetVerifiedLoad(true);
		    axios.post(`${link}api/pnp/day/close/verified/reports/`,{day:historyDate},{
		        headers:{
		                Authorization: 'Bearer '+accessToken
		              }
		      }).then(response=>{
		        setGetVerifiedLoad(false);
		        setVerified(response.data);
		      }).catch(err=>{
		        setGetVerifiedLoad(false);
		        if(err.response.status === 401){
		              setAuthForm(true);
		          }else if(err.response.status === 404){
		              console.log('Day_close_verified_reports - ',err.response.status,':',err.response.statusText);
		          }else if(err.response.status === 500){
		              console.log('Day_close_verified_reports - ',err.response.status,':',err.response.statusText);
		          }else{
		              console.log('Day_close_verified_reports - ',err.response.status,':',err.response.data);
		          }
		    });
		}
	}
	const [ verifiedModal, setVerifiedModal ] = useState(false);
	const [ verifiedReport, setVerifiedReport ] = useState();
	const [ verifiedReportLoad, setVerifiedReportLoad ] = useState(false);
	const getVerifiedReport=(id)=>{
		setVerifiedReportLoad(true);
	    axios.post(`${link}api/pnp/day/close/user/finalreport/view/`,{
	        day_close_id:id.substring(1),
	          },{
	            headers:{
	              Authorization: 'Bearer '+accessToken
	            }
	          }).then((response)=>{
	            setVerifiedReportLoad(false);
	            setVerifiedReport(response.data);
	          }).catch((err)=>{
	            setVerifiedReportLoad(false);
	            if(err.response.status === 401){
	                setAuthForm(true);
	            }else if(err.response.status === 404){
	                console.log('Get_verified_day_close_user_report_view - ',err.response.status,':',err.response.statusText);
	            }else if(err.response.status === 500){
	                console.log('Get_verified_day_close_user_report_view - ',err.response.status,':',err.response.statusText);
	            }else{
	                console.log('Get_verified_day_close_user_report_view - ',err.response.status,':',err.response.data);
	            }
	      });
	}
	const [ reopenPk, setReopenPk ] = useState();
	const [ reopenModal, setReopenModal ] = useState(false);
	useEffect(()=>{
		if(!reopenModal){
			setReopenPk();
		}
	},[reopenModal]);
	const [ reopenLoad, setReopenLoad ] = useState(false);
	const [ reasonErr, setReasonErr ] = useState(false);
	const reopen=(e)=>{
		e.preventDefault();
		let reason = e.target.reason.value;
		if(reason!==""&&reopenPk!==undefined){
			setReopenLoad(true);
		    axios.post(`${link}api/pnp/day/close/reopen/`,{
				remarks:reason,
				dc_id:reopenPk.substring(1),
			},{
		        headers:{
		                Authorization: 'Bearer '+accessToken
		              }
		      }).then(response=>{
		        setReopenLoad(false);
		        setDayCloseToVerify(response.data);
				setReopenModal(false);
		      }).catch(err=>{
		        setReopenLoad(false);
		        if(err.response.status === 401){
					setAuthForm(true);
				}else if(err.response.status === 404){
					console.log('Day_close_reopen - ',err.response.status,':',err.response.statusText);
				}else if(err.response.status === 500){
					console.log('Day_close_reopen - ',err.response.status,':',err.response.statusText);
				}else{
					console.log('Day_close_reopen - ',err.response.status,':',err.response.data);
				}
		    });
		}else{
			setReasonErr(true);
			setTimeout(()=>{
				setReasonErr(false);
			},5000);
		}
	}
	const getReport=(e)=>{
		e.preventDefault();
		let start=e.target.start.value;
	}
	return(
		<Container className="my-3" >

    		<Tabs defaultActiveKey="home" className="mt-2" onSelect={(e)=>{getVerified(e)}} >
				<Tab eventKey="home" title="Pending Verifications" className="tab-border p-3" >
					{
						dayCloseToVerifyLoad?(
							<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
						):(
							dayCloseToVerify!==undefined?(
								Object.keys(dayCloseToVerify).length>0?(
									Object.keys(dayCloseToVerify).map((pk,pk_i)=>(
										<Row className="closed-item bgs1 mx-0 mb-1 bdr2 br-3 py-2" key={`ver_${pk_i}`}>
											<Col xs={12} sm={4} md={8} lg={8} xl={10} className="closed-item-staff" >
												<span className="f18 tp2" >{dayCloseToVerify[pk].user_name}<span className="f14 ts2" > / {dayCloseToVerify[pk].close_date} / {dayCloseToVerify[pk].close_time}</span></span>
											</Col>
											<Col xs={12} sm={4} md={2} lg={2} xl={1} className="d-grid" >
												{
													dayCloseToVerify[pk].today?(
														<Button onClick={()=>{
															setReopenPk(pk);
															setReopenModal(true);
														}} className="btn-t1" >Reopen</Button>
													):''
												}												
											</Col>
											<Col xs={12} sm={4} md={2} lg={2} xl={1} className="d-grid" >
												<Button onClick={()=>{
													getStaffReport(pk);
													setVerificationId(pk);
													setVerifyModal(true);
												}} className="btn-t1" >Verify</Button>
											</Col>
										</Row>
									))
								):''
							):''
						)
					}
				</Tab>
				<Tab eventKey="history" title="History" className="tab-border p-3"  >
				<div className="date-select mb-3">
					<InputGroup  className="br-3">
				        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">Date</InputGroup.Text>
				        <Form.Control onChange={(e)=>{setHistoryDate(e.target.value)}} name="start" type="date" defaultValue={getDate()}  className="br-3" />
				    </InputGroup>
			    </div>
				{
					getVerifiedLoad?(
						<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
					):(
						verified!==undefined?(
								Object.keys(verified).length>0?(
									Object.keys(verified).map((pk,pk_i)=>(
										<Row className="closed-item bgs1 mx-0 mb-1 bdr2 br-3 py-2" key={`ver_${pk_i}`}>
											<Col xs={12} sm={8} md={10} lg={10} xl={11} className="closed-item-staff" >
												<span className="f18 tp2" >{verified[pk].user_name}<span className="f14 ts2" > / {verified[pk].close_date} / {verified[pk].close_time}</span></span>
												 
											</Col>
											<Col xs={12} sm={4} md={2} lg={2} xl={1} className="d-grid" >
												<Button onClick={()=>{
													getVerifiedReport(pk);
													setVerifiedModal(true);
												}} className="btn-t1" >Report</Button>
											</Col>
										</Row>
									))
								):''
							):''
					)
				}
				</Tab>
			</Tabs>
			<Modal size="md" show={reopenModal} backdrop="static" onHide={()=>{setReopenModal(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Day close reopen</Modal.Title>
		        </Modal.Header>	
				<Modal.Body>
				<Form onSubmit={reopen} >										
					<Form.Group className="mb-3" >
						<Form.Label className="f16 tp2" >Reason {reasonErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
						<Form.Control as="textarea" className="shadow-none br-2" name="reason" />
					</Form.Group>
					<div className="d-grid mb-3">
					{
						reopenLoad?(
							<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
						):(
							<Button type="submit" className="btn-t1">SUBMIT</Button>
						)
					}
					</div>
				</Form>
				</Modal.Body>
			</Modal>
			<Modal size="xl" show={verifyModal} backdrop="static" onHide={()=>{setVerifyModal(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Verification</Modal.Title>
		        </Modal.Header>		        
				{
					getStaffReportLoad?(
						<Modal.Body>
							<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
						</Modal.Body>
						):(
							staffReport!==undefined?(
								<Modal.Body>
									<Row>
					        			<Col xs={12} sm={6} md={3} lg={2} className="mb-3"  >
					        				<span className="f16 d-grid tp2 py-2 px-3 diff bdr1 br-3" >Diffrence = {staffReport.main.diffrence}</span>
					        			</Col>
					        			<Col xs={12} sm={6} md={4} lg={3} className="mb-3"  >
							        		<span className="f16 d-grid tp2 py-2" >Closing total = {staffReport.main.denomination_total}</span>
					        			</Col>
					        			<Col xs={12} sm={6} md={4} lg={3} className="mb-3"  >
					        				<span className="f16 d-grid tp2 py-2" >Total Cash Settled = {staffReport.main.primary_total}</span>
					        			</Col>
					        			<Col xs={12} sm={6} md={4} lg={3} className="mb-3"  >
					        				<span className="f16 d-grid tp2 py-2" >Opening Balance = {staffReport.deno_values.opening_balance}</span>
					        			</Col>
					        		</Row>
					        		<Row>
					        			<p><b>Reason</b> : {staffReport.main.reason}</p>
					        		</Row>
									<Form onSubmit={verification} >
										<Row >
											<Col xs={12} sm={12} md={12} className="mb-3">
												<InputGroup className="mb-3 br-2">
											        <InputGroup.Text >Opening Balance</InputGroup.Text>
											        <Form.Control defaultValue={staffReport.deno_values.opening_balance} name="opening" type="number" min="0" />
											    </InputGroup>
											</Col>
						        			<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
												<InputGroup className="mb-3 br-2">
											        <InputGroup.Text >500</InputGroup.Text>
											        <Form.Control defaultValue={staffReport.deno_values.n_500} name="n_500" type="number" min="0" />
											    </InputGroup>
											</Col>
											<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
												<InputGroup className="mb-3 br-2">
											        <InputGroup.Text >200</InputGroup.Text>
											        <Form.Control defaultValue={staffReport.deno_values.n_200} name="n_200" type="number" min="0" />
											    </InputGroup>
											</Col>
											<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
												<InputGroup className="mb-3 br-2">
											        <InputGroup.Text >100</InputGroup.Text>
											        <Form.Control defaultValue={staffReport.deno_values.n_100} name="n_100" type="number" min="0" />
											    </InputGroup>
											</Col>
											<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
												<InputGroup className="mb-3 br-2">
											        <InputGroup.Text >50</InputGroup.Text>
											        <Form.Control defaultValue={staffReport.deno_values.n_50} name="n_50" type="number" min="0" />
											    </InputGroup>
											</Col>
											<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
												<InputGroup className="mb-3 br-2">
											        <InputGroup.Text >20</InputGroup.Text>
											        <Form.Control defaultValue={staffReport.deno_values.n_20} name="n_20" type="number" min="0" />
											    </InputGroup>
											</Col>
											<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
												<InputGroup className="mb-3 br-2">
											        <InputGroup.Text >10</InputGroup.Text>
											        <Form.Control defaultValue={staffReport.deno_values.n_10} name="n_10" type="number" min="0" />
											    </InputGroup>
											</Col>
											<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
												<InputGroup className="mb-3 br-2">
											        <InputGroup.Text >5</InputGroup.Text>
											        <Form.Control defaultValue={staffReport.deno_values.n_5} name="n_5" type="number" min="0" />
											    </InputGroup>
											</Col>
											<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
												<InputGroup className="mb-3 br-2">
											        <InputGroup.Text >2</InputGroup.Text>
											        <Form.Control defaultValue={staffReport.deno_values.n_2} name="n_2" type="number" min="0" />
											    </InputGroup>
											</Col>
											<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
												<InputGroup className="mb-3 br-2">
											        <InputGroup.Text >1</InputGroup.Text>
											        <Form.Control defaultValue={staffReport.deno_values.n_1} name="n_1" type="number" min="0" />
											    </InputGroup>
											</Col>
											
						        		</Row>
						        		<Form.Group className="mb-3" >
					        				<Form.Label className="f16 tp2" >Remarks</Form.Label>
					        				<Form.Control as="textarea" className="shadow-none br-2" name="remarks" />
					        			</Form.Group>
					        			<div className="d-grid mb-3">
					        			{
					        				verificationLoad?(
									    		<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
					        				):(
					        					<Button type="submit" className="btn-t1">Verify and Submit</Button>
					        				)
					        			}
					        			</div>
									</Form>
									<Row>
					        			<Col>
					        				<Accordion>
										      <Accordion.Item eventKey="0">
										        <Accordion.Header><span className="tp1 f18" >Bill details</span></Accordion.Header>
										        <Accordion.Body>
										          <Col>
										          	{
										          		staffReport!==undefined?(
										          			staffReport!==undefined&&staffReport.wallet_values!==undefined?(
																<>
																	<span className="d-grid f20 bgs1 bdr1 bdrb0 px-2 tp2 py-2" ><b>Wallet details</b></span>
														          	<Table bordered className="tbl-bg-blue" >
																      <thead>
																        <tr>
																          <th><span className="tp2" >Date</span></th>
																          <th><span className="tp2" >Customer</span></th>
																          <th><span className="tp2" >Staff</span></th>
																          <th><span className="tp2" >Payment Methods</span></th>
																          <th className="text-end" ><span className="tp2" >Total</span></th>
																        </tr>
																      </thead>
																      <tbody>
																      {
																      	Object.keys(staffReport.wallet_values).length>0?(
																      		Object.keys(staffReport.wallet_values).map((wc,wci)=>(
																      			<tr key={`wc_$wci`} className="tp2" >
																      				<td>{staffReport.wallet_values[wc].collected_date}
																      				</td>
																      				<td>
																      					<div className="d-grid">
																			          		<span className="tp3 f16" >{staffReport.wallet_values[wc].customer_name}</span>
																			          		<span className="tp3 f16" >{staffReport.wallet_values[wc].customer_phone}</span>								          		
																			          	</div>
																      				</td>
																      				<td>
																			          	<div class="grid-auto-100 bdrb2 mb-1 px-1" >
																		          		  	<span className="tp3 f16" >Collected by {staffReport.wallet_values[wc].cash_collected}</span>
																		          			<span className="tp3 f16 grid-justify-end" >{staffReport.wallet_values[wc].collected_time}</span>
																		          		</div>
																			        </td>
																			        <td>
																			          	<div className="d-grid">
																			          	{
																			          		staffReport.wallet_values[wc].ap_payments!==undefined?(
																			          			Object.keys(staffReport.wallet_values[wc].ap_payments).length>0?(
																			          				Object.keys(staffReport.wallet_values[wc].ap_payments).map((b_pay,b_pay_i)=>(
																			          					<div class="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
																						          		  	<span className="tp3 f16" >{staffReport.wallet_values[wc].ap_payments[b_pay].name}</span>
																						          			<span className="tp3 f16 grid-justify-end" >{staffReport.wallet_values[wc].ap_payments[b_pay].amount}</span>
																						          		</div>
																			          				))
																			          			):''
																			          		):''
																			          	}
																			          	</div>
																			          </td>
																			          <td className="text-end" >
																			          	{
																		        			staffReport.wallet_values[wc].amount>0?(
																		        				<span className="sucess-text">{staffReport.wallet_values[wc].amount}</span>
																		        			):(
																		        				<span className="error-text">{staffReport.wallet_values[wc].amount}</span>
																		        			)
																		        		}
																			          </td>
																      			</tr>
																      		))
																      	):'Wallet Not found!'
																      }
																      {
																      	staffReport!==undefined?(
																      		staffReport.main.advance_total!==undefined?(
																      			Object.keys(staffReport.main.advance_total).length>0?(
																      				Object.keys(staffReport.main.advance_total).map((r_pay,r_pay_i)=>(
																      					staffReport.main.advance_total[r_pay].amount!==0?(
																		      				<tr key={`r_pay_x${r_pay_i}`}>
																					        	<td colSpan={3} ></td>
																					        	<td className="text-end" ><span className="tp1 f16" >{staffReport.main.advance_total[r_pay].name}</span></td>
																					        	<td className="text-end" >
																					        		{
																					        			staffReport.main.advance_total[r_pay].amount>0?(
																					        				<span className="sucess-text">{staffReport.main.advance_total[r_pay].amount}</span>
																					        			):(
																					        				<span className="error-text">{staffReport.main.advance_total[r_pay].amount}</span>
																					        			)
																					        		}
																					        	</td>
																					        </tr>
																				        ):''
																			        ))
																      			):''
																      		):''
																      	):''
																      }
																      {
																      	staffReport!==undefined?(
																      		staffReport.main!==undefined?(
																      			<tr>
																      				<td colSpan={3} ></td>
																		        	<td className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
																		        	<td className="text-end bgy" >{staffReport.main.advance_day_total}</td>
																		        </tr>
																      		):''
																      	):''
																      }	
																      </tbody>
																	</Table>
																</>
																):'Wallet Not found!'
										          		):''
										          	}
										          	{
										          		staffReport!==undefined?(
										          			staffReport.debit_values!==undefined&&Object.keys(staffReport.debit_values).length>0?(
										          				<>
											          				<span className="d-grid f18 tp2 py-2" >Credit details</span>
														          	<Table bordered className="tbl-bg-blue" >
																      <thead>
																        <tr>
																          <th><span className="tp2" >Customer</span></th>
																          <th><span className="tp2" >Bill details</span></th>
																          <th><span className="tp2" >Times</span></th>
																          <th><span className="tp2" >Payment Methods</span></th>
																          <th className="text-end" ><span className="tp2" >Total</span></th>
																        </tr>
																      </thead>
																      <tbody>
																      {
																      	Object.keys(staffReport.debit_values).map((debit,deb_i)=>(
																      	<tr key={`debi_${deb_i}`} >
																      		<td>
																      			<div className="d-grid">
																	          		<span className="tp3 f16" >{staffReport.debit_values[debit].customer_name}</span>
																	          		<span className="tp3 f16" >{staffReport.debit_values[debit].customer_phone}</span>
																	          	</div>
																      		</td>
																      		<td>
																	          	<div className="d-grid">
																	          		<span className="tp1 f16" >Token No : {staffReport.debit_values[debit].token_no}</span>
																	          		<span className="tp3 f16" >Bill No : {staffReport.debit_values[debit].bill_no}</span>
																	          		<span className="tp3 f16" >Date : {staffReport.debit_values[debit].collected_date}</span>
																	          	</div>
																	        </td>
																	        <td>
																	          	<div className="grid-auto-100 bdrb2 mb-1 px-1" >
																          		  	<span className="tp3 f16" >Collected</span>
																          			<span className="tp3 f16 grid-justify-end" >{staffReport.debit_values[debit].collected_time}</span>
																          		</div>
																	        </td>
																	        <td>
																	          	<div className="d-grid">
																	          	{
																	          		staffReport.debit_values[debit].debit_payments!==undefined?(
																	          			Object.keys(staffReport.debit_values[debit].debit_payments).length>0?(
																	          				Object.keys(staffReport.debit_values[debit].debit_payments).map((b_pay,b_pay_i)=>(
																	          					<div className="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
																				          		  	<span className="tp3 f16" >{staffReport.debit_values[debit].debit_payments[b_pay].name}</span>
																				          			<span className="tp3 f16 grid-justify-end" >{staffReport.debit_values[debit].debit_payments[b_pay].amount}</span>
																				          		</div>
																	          				))
																	          			):''
																	          		):''
																	          	}
																	          	</div>
																	          </td>
																	          <td className="text-end pos-rel" >
																	          	<span className="tp1 f18" >{staffReport.debit_values[debit].debit_total}</span>
																	          </td>
																      	</tr>
																      	))
																      }
																      {
																      	staffReport!==undefined?(
																      		staffReport.main.debit_total!==undefined?(
																      			Object.keys(staffReport.main.debit_total).length>0?(
																      				Object.keys(staffReport.main.debit_total).map((r_pay,r_pay_i)=>(
																	      				staffReport.main.debit_total[r_pay].amount>0?(
																		      				<tr key={`r_pay_${r_pay_i}`}>
																					        	<td colSpan={3} ></td>
																					        	<td className="text-end" ><span className="tp1 f16" >{staffReport.main.debit_total[r_pay].name}</span></td>
																					        	<td className="text-end" >{staffReport.main.debit_total[r_pay].amount}</td>
																					        </tr>
																					    ):''
																			        ))
																      			):''
																      		):''
																      	):''
																      }
																      {
																      	staffReport!==undefined?(
																      		staffReport.main!==undefined?(
																      			<tr>
																      				<td colSpan={3} ></td>
																		        	<td className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
																		        	<td className="text-end bgy" >{staffReport.main.debit_amount_total}</td>
																		        </tr>
																      		):''
																      	):''
																      }		
																      </tbody>
																    </Table>
															    </>
														    ):''
										          		):''
											        }
										          	<span className="d-grid f18 tp2 py-2" >Collection details</span>
										          	<Table bordered className="tbl-bg-blue" >
												      <thead>
												        <tr>
												          <th><span className="tp2" >Token</span></th>
												          <th><span className="tp2" >Services</span></th>
												          <th><span className="tp2" >Customer</span></th>
												          <th><span className="tp2" >Times</span></th>
												          <th><span className="tp2" >Payment Methods</span></th>
												          <th className="text-end" ><span className="tp2" >Total</span></th>
												        </tr>
												      </thead>
												      <tbody>
												      {
												      	staffReport!==undefined?(
												      		staffReport.bill_values!==undefined?(
												      			Object.keys(staffReport.bill_values).map((tkn,tkn_i)=>(
												      				<tr key={`trep_${tkn_i}`} >
															          <td>
															          	<div className="d-grid">
															          		<span className="tp1 f18" >No : {staffReport.bill_values[tkn].token_no}</span>
															          		<span className="tp3 f16" >Bill No : {staffReport.bill_values[tkn].bill_no}</span>
															          	</div>
															          </td>
															          <td>
															          	<div className="d-grid">
															          	{
															          		staffReport.bill_values[tkn].bill_services!==undefined?(
															          			Object.keys(staffReport.bill_values[tkn].bill_services).length>0?(
															          				Object.keys(staffReport.bill_values[tkn].bill_services).map((t_ser,t_ser_i)=>(
															          					<span className="tp3 f16" key={`tkrpt_${t_ser_i}`} >{staffReport.bill_values[tkn].bill_services[t_ser].service_name}{staffReport.bill_values[tkn].bill_services[t_ser].quantity!==undefined&&staffReport.bill_values[tkn].bill_services[t_ser].quantity>1?(<> ( {staffReport.bill_values[tkn].bill_services[t_ser].quantity} )</>):''}</span>
															          				))
															          			):''
															          		):''
															          	}
															          	</div>
															          </td>
															          <td>
															          	<div className="d-grid">
															          		<span className="tp3 f16" >{staffReport.bill_values[tkn].customer_name}</span>
															          		<span className="tp3 f16" >{staffReport.bill_values[tkn].customer_phone}</span>
															          	</div>
															          </td>
															          <td>
															          	<div className="d-grid">
															          		  <div className="grid-auto-100  bdrb2 mb-1 px-1">
															          		  	<span className="tp3 f16" >Open</span>
															          		  	<span className="tp3 f16 grid-justify-end" >{staffReport.bill_values[tkn].open_time}</span>
															          		  </div>
															          		  <div className="grid-auto-100 bdrb2 mb-1 px-1">
															          		  	<span className="tp3 f16" >Close</span>
															          		  	<span className="tp3 f16 grid-justify-end" >{staffReport.bill_values[tkn].close_time}</span>
															          		  </div>
															          	</div>
															          </td>
															          <td>
															          	<div className="d-grid">
															          	{
															          		staffReport.bill_values[tkn].bill_payments!==undefined?(
															          			Object.keys(staffReport.bill_values[tkn].bill_payments).length>0?(
															          				Object.keys(staffReport.bill_values[tkn].bill_payments).map((b_pay,b_pay_i)=>(
															          					<div className="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
																		          		  	<span className="tp3 f16" >{staffReport.bill_values[tkn].bill_payments[b_pay].name}</span>
																		          			<span className="tp3 f16 grid-justify-end" >{staffReport.bill_values[tkn].bill_payments[b_pay].amount}</span>
																		          		</div>
															          				))
															          			):''
															          		):''
															          	}
															          	</div>
															          </td>
															          <td className="text-end pos-rel" >
															          	<span className="tp1 f18" >{staffReport.bill_values[tkn].bill_total}</span>
															          </td>
															        </tr>
												      			))
												      		):''
												      	):''
												      }
												      {
												      	staffReport!==undefined?(
												      		staffReport.main.payments_total!==undefined?(
												      			Object.keys(staffReport.main.payments_total).length>0?(
												      				Object.keys(staffReport.main.payments_total).map((r_pay,r_pay_i)=>(
													      				<tr key={`r_pay_${r_pay_i}`}>
																        	<td colSpan={4} ></td>
																        	<td className="text-end" ><span className="tp1 f16" >{staffReport.main.payments_total[r_pay].name}</span></td>
																        	<td className="text-end" >{staffReport.main.payments_total[r_pay].amount}</td>
																        </tr>
															        ))
												      			):''
												      		):''
												      	):''
												      }
												      {
												      	staffReport!==undefined?(
												      		staffReport.main!==undefined?(
												      			<tr>
												      				<td colSpan={4} ></td>
														        	<td className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
														        	<td className="text-end bgy" >{staffReport.main.bill_amount_total}</td>
														        </tr>
												      		):''
												      	):''
												      }											        
												      </tbody>
												    </Table>
												    {
												    	staffReport!==undefined&&staffReport.expense_values!==undefined&&Object.keys(staffReport.expense_values).length>0?(
												    		<>
												    			<span className="d-grid f18 tp2 py-2" >Expense details</span>
												    			<Table bordered className="tbl-bg-blue" >
																    <thead>
															        <tr>
															          <th className="tp2" >Particulars</th>
															          <th className="tp2" >Remarks</th>
															          <th className="tp2" >Staff</th>
															          <th className="tp2" >Payment method</th>
															          <th className="text-end tp2" >Amount</th>
															        </tr>
																    </thead>
																    <tbody>
																    {
																    	Object.keys(staffReport.expense_values).map((exp,expi)=>(
																    		<tr key={`expi_${expi}`} className="tc2" > 
											        						<td>
											        							<span className="d-grid" >{staffReport.expense_values[exp].particulars}</span>
											        						</td>
											        						<td>{staffReport.expense_values[exp].remarks}</td>
											        						<td>{staffReport.expense_values[exp].user}</td>
											        						<td>{staffReport.expense_values[exp].payment_type}</td>
											        						<td className="text-end">{staffReport.expense_values[exp].expense_total}</td>
											        					</tr>
																    	))
																    }
																    <tr>
																			<td colSpan={3} className="tp2 text-end f20 f-400">Total</td>
																			<td>
																				{
																					staffReport.main.expense_total!==undefined&&Object.keys(staffReport.main.expense_total).length>0?(
																						Object.keys(staffReport.main.expense_total).map((ex,exi)=>(
																							staffReport.main.expense_total[ex].amount>0?(
																								<div class="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${exi}`} >
																				          		  	<span className="tp2 f16" >{staffReport.main.expense_total[ex].name}</span>
																				          			<span className="tp2 f16 grid-justify-end" >{staffReport.main.expense_total[ex].amount}</span>
																				          		</div>
																			          		):''
																						))
																					):''
																				}
																			</td>
																			<td className="text-end f20 f-400">{staffReport.main!==undefined?staffReport.main.expense_day_total:''}</td>
																		</tr>
																    </tbody>
																   </Table>
												    		</>
												    	):''
												    }
										          </Col>
										        </Accordion.Body>
										      </Accordion.Item>
										    </Accordion>
					        			</Col>
					        		</Row>
								</Modal.Body>
							):(
								<Modal.Body>
									<span className="tp2 f18" >Not found!</span>
								</Modal.Body>
							)
						)
				}		        
		        <Modal.Footer></Modal.Footer>
		    </Modal>
		    <Modal size="xl" show={verifiedModal} backdrop="static" onHide={()=>{setVerifiedModal(false)}}>
		    	<Modal.Header closeButton>
		          <Modal.Title>Day close report</Modal.Title>
		        </Modal.Header>
		        {
		        	verifiedReportLoad?(
						<Modal.Body>
							<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
						</Modal.Body>
					):(
						verifiedReport!==undefined?(
							<Modal.Body>
								<Row>
				        			<span className="d-grid f16 ts2 pb-2" >Verified by {`${verifiedReport.main.verified_by} on ${verifiedReport.main.verified_date} at ${verifiedReport.main.verified_time}`}</span>

				        			<Col xs={12} sm={6} md={3} lg={3} className="mb-3"  >
				        				<span className="f16 d-grid tp2 py-2 px-3 diff bdr1 br-3" >Diffrence Final = {verifiedReport.main.final_diffrence}</span>
				        			</Col>
				        			<Col xs={12} sm={6} md={4} lg={3} className="mb-3"  >
						        		<span className="f16 d-grid tp2 py-2" >Closing total Final = {verifiedReport.main.final_cash_total}</span>
				        			</Col>
				        			<Col xs={12} sm={6} md={4} lg={3} className="mb-3"  >
				        				<span className="f16 d-grid tp2 py-2" >Total Cash Settled = {verifiedReport.main.primary_total}</span>
				        			</Col>
				        			<Col xs={12} sm={6} md={4} lg={3} className="mb-3"  >
				        				<span className="f16 d-grid tp2 py-2" >Opening Balance = {verifiedReport.verified_deno_values.opening_balance}</span>
				        			</Col>
				        		</Row>
				        		<Row>
				        			<span><b>Remarks</b> : {verifiedReport.main.remarks}</span>
				        		</Row>
								<Row>
									<Col xs={12} md={12} >
									{
						          		verifiedReport!==undefined?(
						          			verifiedReport!==undefined&&verifiedReport.wallet_values!==undefined?(
												<>
													<span className="d-grid f20 bgs1 bdr1 bdrb0 px-2 tp2 py-2" ><b>Wallet details</b></span>
										          	<Table bordered className="tbl-bg-blue" >
												      <thead>
												        <tr>
												          <th><span className="tp2" >Date</span></th>
												          <th><span className="tp2" >Customer</span></th>
												          <th><span className="tp2" >Staff</span></th>
												          <th><span className="tp2" >Payment Methods</span></th>
												          <th className="text-end" ><span className="tp2" >Total</span></th>
												        </tr>
												      </thead>
												      <tbody>
												      {
												      	Object.keys(verifiedReport.wallet_values).length>0?(
												      		Object.keys(verifiedReport.wallet_values).map((wc,wci)=>(
												      			<tr key={`wc_$wci`} className="tp2" >
												      				<td>{verifiedReport.wallet_values[wc].collected_date}
												      				</td>
												      				<td>
												      					<div className="d-grid">
															          		<span className="tp3 f16" >{verifiedReport.wallet_values[wc].customer_name}</span>
															          		<span className="tp3 f16" >{verifiedReport.wallet_values[wc].customer_phone}</span>								          		
															          	</div>
												      				</td>
												      				<td>
															          	<div class="grid-auto-100 bdrb2 mb-1 px-1" >
														          		  	<span className="tp3 f16" >Collected by {verifiedReport.wallet_values[wc].cash_collected}</span>
														          			<span className="tp3 f16 grid-justify-end" >{verifiedReport.wallet_values[wc].collected_time}</span>
														          		</div>
															        </td>
															        <td>
															          	<div className="d-grid">
															          	{
															          		verifiedReport.wallet_values[wc].ap_payments!==undefined?(
															          			Object.keys(verifiedReport.wallet_values[wc].ap_payments).length>0?(
															          				Object.keys(verifiedReport.wallet_values[wc].ap_payments).map((b_pay,b_pay_i)=>(
															          					<div class="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
																		          		  	<span className="tp3 f16" >{verifiedReport.wallet_values[wc].ap_payments[b_pay].name}</span>
																		          			<span className="tp3 f16 grid-justify-end" >{verifiedReport.wallet_values[wc].ap_payments[b_pay].amount}</span>
																		          		</div>
															          				))
															          			):''
															          		):''
															          	}
															          	</div>
															          </td>
															          <td className="text-end" >
															          	{
														        			verifiedReport.wallet_values[wc].amount>0?(
														        				<span className="sucess-text">{verifiedReport.wallet_values[wc].amount}</span>
														        			):(
														        				<span className="error-text">{verifiedReport.wallet_values[wc].amount}</span>
														        			)
														        		}
															          </td>
												      			</tr>
												      		))
												      	):'Wallet Not found!'
												      }
												      {
												      	verifiedReport!==undefined?(
												      		verifiedReport.main.advance_total!==undefined?(
												      			Object.keys(verifiedReport.main.advance_total).length>0?(
												      				Object.keys(verifiedReport.main.advance_total).map((r_pay,r_pay_i)=>(
												      					verifiedReport.main.advance_total[r_pay].amount!==0?(
														      				<tr key={`r_pay_x${r_pay_i}`}>
																	        	<td colSpan={3} ></td>
																	        	<td className="text-end" ><span className="tp1 f16" >{verifiedReport.main.advance_total[r_pay].name}</span></td>
																	        	<td className="text-end" >
																	        		{
																	        			verifiedReport.main.advance_total[r_pay].amount>0?(
																	        				<span className="sucess-text">{verifiedReport.main.advance_total[r_pay].amount}</span>
																	        			):(
																	        				<span className="error-text">{verifiedReport.main.advance_total[r_pay].amount}</span>
																	        			)
																	        		}
																	        	</td>
																	        </tr>
																        ):''
															        ))
												      			):''
												      		):''
												      	):''
												      }
												      {
												      	verifiedReport!==undefined?(
												      		verifiedReport.main!==undefined?(
												      			<tr>
												      				<td colSpan={3} ></td>
														        	<td className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
														        	<td className="text-end bgy" >{verifiedReport.main.advance_day_total}</td>
														        </tr>
												      		):''
												      	):''
												      }	
												      </tbody>
													</Table>
												</>
												):'Wallet Not found!'
						          		):''
						          	}
						          	</Col>
				        			<span className="d-grid f18 tp2 py-2" >Verified Denominations</span>
				        			{
				        				verifiedReport!==undefined?(
				        					verifiedReport.verified_deno_values!==undefined?(
				        					<>
				        					{
				        						verifiedReport.verified_deno_values.n_500>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 500</span>
												        	<span className="denomin-val p-2">{verifiedReport.verified_deno_values.n_500} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						verifiedReport.verified_deno_values.n_200>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 200</span>
												        	<span className="denomin-val p-2">{verifiedReport.verified_deno_values.n_200} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						verifiedReport.verified_deno_values.n_100>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 100</span>
												        	<span className="denomin-val p-2">{verifiedReport.verified_deno_values.n_100} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						verifiedReport.verified_deno_values.n_50>0?(
				        							<Col xs={12} sm={6} md={4} lg={3} xl={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 50</span>
												        	<span className="denomin-val p-2">{verifiedReport.verified_deno_values.n_50} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						verifiedReport.verified_deno_values.n_20>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 20</span>
												        	<span className="denomin-val p-2">{verifiedReport.verified_deno_values.n_20} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						verifiedReport.verified_deno_values.n_10>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 10</span>
												        	<span className="denomin-val p-2">{verifiedReport.verified_deno_values.n_10} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						verifiedReport.verified_deno_values.n_5>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 5</span>
												        	<span className="denomin-val p-2">{verifiedReport.verified_deno_values.n_5} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						verifiedReport.verified_deno_values.n_2>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name  bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 2</span>
												        	<span className="denomin-val p-2">{verifiedReport.verified_deno_values.n_2} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						verifiedReport.verified_deno_values.n_1>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 1</span>
												        	<span className="denomin-val p-2">{verifiedReport.verified_deno_values.n_1} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					</>
				        					):''
				        				):''
				        			}
							    </Row>
								<Row>
				        			<Col xs={12} sm={6} md={3} lg={2} className="mb-3"  >
				        				<span className="f16 d-grid tp2 py-2 px-3 diff bdr1 br-3" >Diffrence = {verifiedReport.main.diffrence}</span>
				        			</Col>
				        			<Col xs={12} sm={6} md={4} lg={3} className="mb-3"  >
						        		<span className="f16 d-grid tp2 py-2" >Closing total = {verifiedReport.main.denomination_total}</span>
				        			</Col>
				        			<Col xs={12} sm={6} md={4} lg={3} className="mb-3"  >
				        				<span className="f16 d-grid tp2 py-2" >Total Cash Settled = {verifiedReport.main.primary_total}</span>
				        			</Col>
				        			<Col xs={12} sm={6} md={4} lg={3} className="mb-3"  >
				        				<span className="f16 d-grid tp2 py-2" >Opening Balance = {verifiedReport.deno_values.opening_balance}</span>
				        			</Col>
				        		</Row>
				        		<Row>
				        			<span><b>Reason</b> : {verifiedReport.main.reason}</span>
				        		</Row>
				        		<Row>
				        			<span className="d-grid f18 tp2 py-2" >Denominations submitted by staff</span>
				        			{
				        				verifiedReport!==undefined?(
				        					verifiedReport.deno_values!==undefined?(
				        					<>
				        					{
				        						verifiedReport.deno_values.n_500>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 500</span>
												        	<span className="denomin-val p-2">{verifiedReport.deno_values.n_500} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						verifiedReport.deno_values.n_200>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 200</span>
												        	<span className="denomin-val p-2">{verifiedReport.deno_values.n_200} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						verifiedReport.deno_values.n_100>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 100</span>
												        	<span className="denomin-val p-2">{verifiedReport.deno_values.n_100} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						verifiedReport.deno_values.n_50>0?(
				        							<Col xs={12} sm={6} md={4} lg={3} xl={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 50</span>
												        	<span className="denomin-val p-2">{verifiedReport.deno_values.n_50} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						verifiedReport.deno_values.n_20>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 20</span>
												        	<span className="denomin-val p-2">{verifiedReport.deno_values.n_20} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						verifiedReport.deno_values.n_10>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 10</span>
												        	<span className="denomin-val p-2">{verifiedReport.deno_values.n_10} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						verifiedReport.deno_values.n_5>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 5</span>
												        	<span className="denomin-val p-2">{verifiedReport.deno_values.n_5} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						verifiedReport.deno_values.n_2>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name  bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 2</span>
												        	<span className="denomin-val p-2">{verifiedReport.deno_values.n_2} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						verifiedReport.deno_values.n_1>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 1</span>
												        	<span className="denomin-val p-2">{verifiedReport.deno_values.n_1} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					</>
				        					):''
				        				):''
				        			}
							    </Row>
								<Row>
				        			<Col>
				        				<Accordion>
									      <Accordion.Item eventKey="0">
									        <Accordion.Header><span className="tp1 f18" >Bill details</span></Accordion.Header>
									        <Accordion.Body>
									          <Col>
									          	{
									          		verifiedReport!==undefined?(
									          			verifiedReport!==undefined&&verifiedReport.wallet_values!==undefined?(
															<>
																<span className="d-grid f20 bgs1 bdr1 bdrb0 px-2 tp2 py-2" ><b>Wallet details</b></span>
													          	<Table bordered className="tbl-bg-blue" >
															      <thead>
															        <tr>
															          <th><span className="tp2" >Date</span></th>
															          <th><span className="tp2" >Customer</span></th>
															          <th><span className="tp2" >Staff</span></th>
															          <th><span className="tp2" >Payment Methods</span></th>
															          <th className="text-end" ><span className="tp2" >Total</span></th>
															        </tr>
															      </thead>
															      <tbody>
															      {
															      	Object.keys(verifiedReport.wallet_values).length>0?(
															      		Object.keys(verifiedReport.wallet_values).map((wc,wci)=>(
															      			<tr key={`wc_$wci`} className="tp2" >
															      				<td>{verifiedReport.wallet_values[wc].collected_date}
															      				</td>
															      				<td>
															      					<div className="d-grid">
																		          		<span className="tp3 f16" >{verifiedReport.wallet_values[wc].customer_name}</span>
																		          		<span className="tp3 f16" >{verifiedReport.wallet_values[wc].customer_phone}</span>								          		
																		          	</div>
															      				</td>
															      				<td>
																		          	<div class="grid-auto-100 bdrb2 mb-1 px-1" >
																	          		  	<span className="tp3 f16" >Collected by {verifiedReport.wallet_values[wc].cash_collected}</span>
																	          			<span className="tp3 f16 grid-justify-end" >{verifiedReport.wallet_values[wc].collected_time}</span>
																	          		</div>
																		        </td>
																		        <td>
																		          	<div className="d-grid">
																		          	{
																		          		verifiedReport.wallet_values[wc].ap_payments!==undefined?(
																		          			Object.keys(verifiedReport.wallet_values[wc].ap_payments).length>0?(
																		          				Object.keys(verifiedReport.wallet_values[wc].ap_payments).map((b_pay,b_pay_i)=>(
																		          					<div class="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
																					          		  	<span className="tp3 f16" >{verifiedReport.wallet_values[wc].ap_payments[b_pay].name}</span>
																					          			<span className="tp3 f16 grid-justify-end" >{verifiedReport.wallet_values[wc].ap_payments[b_pay].amount}</span>
																					          		</div>
																		          				))
																		          			):''
																		          		):''
																		          	}
																		          	</div>
																		          </td>
																		          <td className="text-end" >
																		          	{
																	        			verifiedReport.wallet_values[wc].amount>0?(
																	        				<span className="sucess-text">{verifiedReport.wallet_values[wc].amount}</span>
																	        			):(
																	        				<span className="error-text">{verifiedReport.wallet_values[wc].amount}</span>
																	        			)
																	        		}
																		          </td>
															      			</tr>
															      		))
															      	):'Wallet Not found!'
															      }
															      {
															      	verifiedReport!==undefined?(
															      		verifiedReport.main.advance_total!==undefined?(
															      			Object.keys(verifiedReport.main.advance_total).length>0?(
															      				Object.keys(verifiedReport.main.advance_total).map((r_pay,r_pay_i)=>(
															      					verifiedReport.main.advance_total[r_pay].amount!==0?(
																	      				<tr key={`r_pay_x${r_pay_i}`}>
																				        	<td colSpan={3} ></td>
																				        	<td className="text-end" ><span className="tp1 f16" >{verifiedReport.main.advance_total[r_pay].name}</span></td>
																				        	<td className="text-end" >
																				        		{
																				        			verifiedReport.main.advance_total[r_pay].amount>0?(
																				        				<span className="sucess-text">{verifiedReport.main.advance_total[r_pay].amount}</span>
																				        			):(
																				        				<span className="error-text">{verifiedReport.main.advance_total[r_pay].amount}</span>
																				        			)
																				        		}
																				        	</td>
																				        </tr>
																			        ):''
																		        ))
															      			):''
															      		):''
															      	):''
															      }
															      {
															      	verifiedReport!==undefined?(
															      		verifiedReport.main!==undefined?(
															      			<tr>
															      				<td colSpan={3} ></td>
																	        	<td className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
																	        	<td className="text-end bgy" >{verifiedReport.main.advance_day_total}</td>
																	        </tr>
															      		):''
															      	):''
															      }	
															      </tbody>
																</Table>
															</>
															):'Wallet Not found!'
									          		):''
									          	}
									          	{
									          		verifiedReport!==undefined?(
									          			verifiedReport.debit_values!==undefined&&Object.keys(verifiedReport.debit_values).length>0?(
									          				<>
										          				<span className="d-grid f18 tp2 py-2" >Credit details</span>
													          	<Table bordered className="tbl-bg-blue" >
															      <thead>
															        <tr>
															          <th><span className="tp2" >Customer</span></th>
															          <th><span className="tp2" >Bill details</span></th>
															          <th><span className="tp2" >Times</span></th>
															          <th><span className="tp2" >Payment Methods</span></th>
															          <th className="text-end" ><span className="tp2" >Total</span></th>
															        </tr>
															      </thead>
															      <tbody>
															      {
															      	Object.keys(verifiedReport.debit_values).map((debit,deb_i)=>(
															      	<tr key={`debi_${deb_i}`} >
															      		<td>
															      			<div className="d-grid">
																          		<span className="tp3 f16" >{verifiedReport.debit_values[debit].customer_name}</span>
																          		<span className="tp3 f16" >{verifiedReport.debit_values[debit].customer_phone}</span>
																          	</div>
															      		</td>
															      		<td>
																          	<div className="d-grid">
																          		<span className="tp1 f16" >Token No : {verifiedReport.debit_values[debit].token_no}</span>
																          		<span className="tp3 f16" >Bill No : {verifiedReport.debit_values[debit].bill_no}</span>
																          		<span className="tp3 f16" >Date : {verifiedReport.debit_values[debit].collected_date}</span>
																          	</div>
																        </td>
																        <td>
																          	<div className="grid-auto-100 bdrb2 mb-1 px-1" >
															          		  	<span className="tp3 f16" >Collected</span>
															          			<span className="tp3 f16 grid-justify-end" >{verifiedReport.debit_values[debit].collected_time}</span>
															          		</div>
																        </td>
																        <td>
																          	<div className="d-grid">
																          	{
																          		verifiedReport.debit_values[debit].debit_payments!==undefined?(
																          			Object.keys(verifiedReport.debit_values[debit].debit_payments).length>0?(
																          				Object.keys(verifiedReport.debit_values[debit].debit_payments).map((b_pay,b_pay_i)=>(
																          					<div className="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
																			          		  	<span className="tp3 f16" >{verifiedReport.debit_values[debit].debit_payments[b_pay].name}</span>
																			          			<span className="tp3 f16 grid-justify-end" >{verifiedReport.debit_values[debit].debit_payments[b_pay].amount}</span>
																			          		</div>
																          				))
																          			):''
																          		):''
																          	}
																          	</div>
																          </td>
																          <td className="text-end pos-rel" >
																          	<span className="tp1 f18" >{verifiedReport.debit_values[debit].debit_total}</span>
																          </td>
															      	</tr>
															      	))
															      }
															      {
															      	verifiedReport!==undefined?(
															      		verifiedReport.main.debit_total!==undefined?(
															      			Object.keys(verifiedReport.main.debit_total).length>0?(
															      				Object.keys(verifiedReport.main.debit_total).map((r_pay,r_pay_i)=>(
																      				verifiedReport.main.debit_total[r_pay].amount>0?(
																	      				<tr key={`r_pay_${r_pay_i}`}>
																				        	<td colSpan={3} ></td>
																				        	<td className="text-end" ><span className="tp1 f16" >{verifiedReport.main.debit_total[r_pay].name}</span></td>
																				        	<td className="text-end" >{verifiedReport.main.debit_total[r_pay].amount}</td>
																				        </tr>
																				    ):''
																		        ))
															      			):''
															      		):''
															      	):''
															      }
															      {
															      	verifiedReport!==undefined?(
															      		verifiedReport.main!==undefined?(
															      			<tr>
															      				<td colSpan={3} ></td>
																	        	<td className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
																	        	<td className="text-end bgy" >{verifiedReport.main.debit_amount_total}</td>
																	        </tr>
															      		):''
															      	):''
															      }		
															      </tbody>
															    </Table>
														    </>
													    ):''
									          		):''
										        }
									          	<span className="d-grid f18 tp2 py-2" >Collection details</span>
									          	<Table bordered className="tbl-bg-blue" >
											      <thead>
											        <tr>
											          <th><span className="tp2" >Token</span></th>
											          <th><span className="tp2" >Services</span></th>
											          <th><span className="tp2" >Customer</span></th>
											          <th><span className="tp2" >Times</span></th>
											          <th><span className="tp2" >Payment Methods</span></th>
											          <th className="text-end" ><span className="tp2" >Total</span></th>
											        </tr>
											      </thead>
											      <tbody>
											      {
											      	verifiedReport!==undefined?(
											      		verifiedReport.bill_values!==undefined?(
											      			Object.keys(verifiedReport.bill_values).map((tkn,tkn_i)=>(
											      				<tr key={`trep_${tkn_i}`} >
														          <td>
														          	<div className="d-grid">
														          		<span className="tp1 f18" >No : {verifiedReport.bill_values[tkn].token_no}</span>
														          		<span className="tp3 f16" >Bill No : {verifiedReport.bill_values[tkn].bill_no}</span>
														          	</div>
														          </td>
														          <td>
														          	<div className="d-grid">
														          	{
														          		verifiedReport.bill_values[tkn].bill_services!==undefined?(
														          			Object.keys(verifiedReport.bill_values[tkn].bill_services).length>0?(
														          				Object.keys(verifiedReport.bill_values[tkn].bill_services).map((t_ser,t_ser_i)=>(
														          					<span className="tp3 f16" key={`tkrpt_${t_ser_i}`} >{verifiedReport.bill_values[tkn].bill_services[t_ser].service_name}{verifiedReport.bill_values[tkn].bill_services[t_ser].quantity!==undefined&&verifiedReport.bill_values[tkn].bill_services[t_ser].quantity>1?(<> ( {verifiedReport.bill_values[tkn].bill_services[t_ser].quantity} )</>):''}</span>
														          				))
														          			):''
														          		):''
														          	}
														          	</div>
														          </td>
														          <td>
														          	<div className="d-grid">
														          		<span className="tp3 f16" >{verifiedReport.bill_values[tkn].customer_name}</span>
														          		<span className="tp3 f16" >{verifiedReport.bill_values[tkn].customer_phone}</span>
														          	</div>
														          </td>
														          <td>
														          	<div className="d-grid">
														          		  <div className="grid-auto-100  bdrb2 mb-1 px-1">
														          		  	<span className="tp3 f16" >Open</span>
														          		  	<span className="tp3 f16 grid-justify-end" >{verifiedReport.bill_values[tkn].open_time}</span>
														          		  </div>
														          		  <div className="grid-auto-100 bdrb2 mb-1 px-1">
														          		  	<span className="tp3 f16" >Close</span>
														          		  	<span className="tp3 f16 grid-justify-end" >{verifiedReport.bill_values[tkn].close_time}</span>
														          		  </div>
														          	</div>
														          </td>
														          <td>
														          	<div className="d-grid">
														          	{
														          		verifiedReport.bill_values[tkn].bill_payments!==undefined?(
														          			Object.keys(verifiedReport.bill_values[tkn].bill_payments).length>0?(
														          				Object.keys(verifiedReport.bill_values[tkn].bill_payments).map((b_pay,b_pay_i)=>(
														          					<div className="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
																	          		  	<span className="tp3 f16" >{verifiedReport.bill_values[tkn].bill_payments[b_pay].name}</span>
																	          			<span className="tp3 f16 grid-justify-end" >{verifiedReport.bill_values[tkn].bill_payments[b_pay].amount}</span>
																	          		</div>
														          				))
														          			):''
														          		):''
														          	}
														          	</div>
														          </td>
														          <td className="text-end pos-rel" >
														          	<span className="tp1 f18" >{verifiedReport.bill_values[tkn].bill_total}</span>
														          </td>
														        </tr>
											      			))
											      		):''
											      	):''
											      }
											      {
											      	verifiedReport!==undefined?(
											      		verifiedReport.main.payments_total!==undefined?(
											      			Object.keys(verifiedReport.main.payments_total).length>0?(
											      				Object.keys(verifiedReport.main.payments_total).map((r_pay,r_pay_i)=>(
												      				<tr key={`r_pay_${r_pay_i}`}>
															        	<td colSpan={4} ></td>
															        	<td className="text-end" ><span className="tp1 f16" >{verifiedReport.main.payments_total[r_pay].name}</span></td>
															        	<td className="text-end" >{verifiedReport.main.payments_total[r_pay].amount}</td>
															        </tr>
														        ))
											      			):''
											      		):''
											      	):''
											      }
											      {
											      	verifiedReport!==undefined?(
											      		verifiedReport.main!==undefined?(
											      			<tr>
											      				<td colSpan={4} ></td>
													        	<td className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
													        	<td className="text-end bgy" >{verifiedReport.main.bill_amount_total}</td>
													        </tr>
											      		):''
											      	):''
											      }											        
											      </tbody>
											    </Table>
											    {
											    	verifiedReport!==undefined&&verifiedReport.expense_values!==undefined&&Object.keys(verifiedReport.expense_values).length>0?(
											    		<>
											    			<span className="d-grid f18 tp2 py-2" >Expense details</span>
											    			<Table bordered className="tbl-bg-blue" >
															    <thead>
														        <tr>
														          <th className="tp2" >Particulars</th>
														          <th className="tp2" >Remarks</th>
														          <th className="tp2" >Staff</th>
														          <th className="tp2" >Payment method</th>
														          <th className="text-end tp2" >Amount</th>
														        </tr>
															    </thead>
															    <tbody>
															    {
															    	Object.keys(verifiedReport.expense_values).map((exp,expi)=>(
															    		<tr key={`expi_${expi}`} className="tc2" > 
										        						<td>
										        							<span className="d-grid" >{verifiedReport.expense_values[exp].particulars}</span>
										        						</td>
										        						<td>{verifiedReport.expense_values[exp].remarks}</td>
										        						<td>{verifiedReport.expense_values[exp].user}</td>
										        						<td>{verifiedReport.expense_values[exp].payment_type}</td>
										        						<td className="text-end">{verifiedReport.expense_values[exp].expense_total}</td>
										        					</tr>
															    	))
															    }
															    <tr>
																		<td colSpan={3} className="tp2 text-end f20 f-400">Total</td>
																		<td>
																			{
																				verifiedReport.main.expense_total!==undefined&&Object.keys(verifiedReport.main.expense_total).length>0?(
																					Object.keys(verifiedReport.main.expense_total).map((ex,exi)=>(
																						verifiedReport.main.expense_total[ex].amount>0?(
																							<div class="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${exi}`} >
																			          		  	<span className="tp2 f16" >{verifiedReport.main.expense_total[ex].name}</span>
																			          			<span className="tp2 f16 grid-justify-end" >{verifiedReport.main.expense_total[ex].amount}</span>
																			          		</div>
																		          		):''
																					))
																				):''
																			}
																		</td>
																		<td className="text-end f20 f-400">{verifiedReport.main!==undefined?verifiedReport.main.expense_day_total:''}</td>
																	</tr>
															    </tbody>
															   </Table>
											    		</>
											    	):''
											    }
									          </Col>
									        </Accordion.Body>
									      </Accordion.Item>
									    </Accordion>
				        			</Col>
				        		</Row>
							</Modal.Body>
						):(
							<Modal.Body>
								<span className="tp2 f18" >Not found!</span>
							</Modal.Body>
						)	
					)
				}
		    </Modal>

		</Container>
		);
}