import React, { useContext } from 'react';
import { MainNavbar } from './navbar';
import { HomePage } from './home';
import { Token } from './manageTokens';
import { SpecialTokens } from './specialTokens';
import { Collections } from './collections';
import { QuickReport } from './reportQuick';
import { CollectionReports } from './reportCollection';
import { CreditReport } from './reportCredit';
import { CancelTokenReport } from './reportCancelToken';
import { CustomerReport } from './reportCustomer';
import { ServiceReport } from './reportService';
import { StaffReport } from './reportStaff';
import { DetailReport } from './reportDetail';
import { StaffType } from './staffType';
import { Staff } from './staff';
import { Services } from './services';
import { Customers } from './customers';
import { Enquiry } from './enquiry';
import { Work } from './works';
import { VerifyDayClose } from './verifyDayClose';
import { PaymentMethods } from './paymentMethods';
import { Notification } from './notifications';
import { Credits } from './credits';
import { SpecialBills } from './specialBills';
import { Cashier } from './cashier';
import { StaffTokenReport } from './reportStaffToken';
import { DetailTokenReport } from './reportDetailedToken';
import { IncompleteTokenReport } from './reportIncompleteToken';
import { PayWallet } from './payWallet';
import { PaymentWalletReport } from './reportPayWallet';
import { CustomerWallet } from './customerWallet';
import { ExpenseParticular } from './expenseType';
import { AppContext } from '../App';
import { Expense } from './expense';
import { ExpensePetty } from './expensePetty';
import { ExpenseReport } from './reportExpense';
import { DayCloseReopenReport } from './reportDayCloseReOpen';
import { SalaryStaffs } from './salary_staff';
import { StaffsAttandence } from './staffAttandence';
import { SalaryStaffsDisabled } from './salary_disabled_staffs';
import { SalaryCalcReport } from './reportSalaryCalc';
import { SalaryPayReport } from './reportSalaryPay';

export const Dashboard=()=>{
	const { page } = useContext(AppContext);
	

	return(
		<div className="dashboard-wraper">
			<MainNavbar />
			{
				page!==undefined?(
					page === 'dashboard'?(
						<HomePage />
					):page==='token'?(
						<Token />
					):page==='cashier'?(
						<Cashier />
					):page==='credits'?(
						<Credits />
					):page==='special_token'?(
						<SpecialTokens />
					):page==='collections'?(
						<Collections />
					):page==='quick_report'?(
						<QuickReport />
					):page==='collection_report'?(
						<CollectionReports />
					):page==='credit_report'?(
						<CreditReport />
					):page==='token_cancel_report'?(
						<CancelTokenReport />
					):page==='customer_report'?(
						<CustomerReport />
					):page==='service_report'?(
						<ServiceReport />
					):page==='staff_report'?(
						<StaffReport />
					):page==='detailed_report'?(
						<DetailReport />
					):page==='staff_type'?(
						<StaffType />
					):page==='staffs'?(
						<Staff />
					):page==='services'?(
						<Services />
					):page==='customers'?(
						<Customers />
					):page==='enquiry'?(
						<Enquiry />
					):page==='works'?(
						<Work />
					):page==='notification'?(
						<Notification />
					):page==='verify_day_close'?(
						<VerifyDayClose />
					):page==='payment_methods'?(
						<PaymentMethods />
					):page==='special_bills'?(
						<SpecialBills />
					):page==='staff_token_report'?(
						<StaffTokenReport />
					):page==='detailed_token_report'?(
						<DetailTokenReport />
					):page==='incomplete_token_report'?(
						<IncompleteTokenReport />
					):page==='pay_wallet'?(
						<PayWallet />
					):page==='pay_wallet_report'?(
						<PaymentWalletReport />
					):page==='c-wallet'?(
						<CustomerWallet />
					):page==='exp_parti'?(
						<ExpenseParticular />
					):page==='exp-add'?(
						<Expense />
					):page==='petty-exp'?(
						<ExpensePetty />
					):page==='expense_report'?(
						<ExpenseReport />
					):page==='reopen_report'?(
						<DayCloseReopenReport />
					):page==='salary-saff'?(
						<SalaryStaffs />
					):page==='staff-attandence'?(
						<StaffsAttandence />
					):page==='staff-disabled'?(
						<SalaryStaffsDisabled />
					):page==='report_salary_calc'?(
						<SalaryCalcReport />
					):page==='report_salary_pay'?(
						<SalaryPayReport />
					):''
				):''
			}
	    </div>
		)
}