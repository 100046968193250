import React, {useState, useEffect, useContext} from 'react';
import {Container, Row, Col, Button, Form, Modal, Spinner, Table} from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../App';


export const SalaryStaffs=()=>{
	const { user, load, setLoad, postCall, getCall, setGetLoad, getLoad, salaryStaff, setSalaryStaff, getDate } = useContext(AppContext);
	const [ staffAddModel, setStaffAddModel ] = useState(false);
	const [ staffAddLoad, setStaffAddLoad ] = useState(false);
	const [ editable, setEditable ] = useState();
	const [ salType, setSalType ] = useState();
	const addStaff=(e)=>{
		e.preventDefault();
		let desi = e.target.desig.value;
		let pay = e.target.b_pay.value;
		let ot = e.target.ot.value;
		let cut = e.target.cut!==undefined?e.target.cut.value:0;
		let type = e.target.type.value;
		if(editable!==undefined){
			if (pay!=="") {
				setStaffAddLoad(true);
				postCall('api/sm/edit/staff/',{ s_id:editable.id.substring(1), designation:desi, basic_pay:pay, ot:ot, cut:cut, type:type }).then(res=>{
					setSalaryStaff(res.data);
					setStaffAddLoad(false);
					setStaffAddModel(false);
				}).catch(err=>{
					setStaffAddLoad(false);
				})
			}
		}else{
			let fname = e.target.first_name.value;
			let lname = e.target.last_name.value;
			if (fname!==""&&pay!=="") {
				setStaffAddLoad(true);
				postCall('api/sm/add/staff/',{ first_name:fname, last_name:lname, designation:desi, basic_pay:pay, ot:ot, cut:cut, type:type }).then(res=>{
					setSalaryStaff(res.data);
					setStaffAddLoad(false);
					setStaffAddModel(false);
				}).catch(err=>{
					setStaffAddLoad(false);
				})
			}
		}
	}
	useEffect(()=>{
		if (!staffAddModel) {
			setEditable();
			setSalType();
		}
	},[staffAddModel])
	const [ changeStatLoad, setChangeStatLoad ] = useState(false);
	const [ activeId, setActiveId ] = useState();
	const changeStatus=(sid,status)=>{
		if (sid!==""&&status!=="") {
			setActiveId(sid);
			setChangeStatLoad(true);
			postCall('api/sm/change/status/staff/',{s_id:sid.substring(1),status:status,from_enabled:true}).then(res=>{
				setChangeStatLoad(false);
				setSalaryStaff(res.data);
				setActiveId();
			}).catch(err=>{
				setChangeStatLoad(false);
			});
		}
	}
	const [ additionModal, setAdditionModal ] = useState(false);
	const [ dedModal, setDedModal ] = useState(false);
	const [ payModal, setPayModal ] = useState(false);
	const [ addLoad, setAddLoad ] = useState(false);
	const fnPost=(e,link,modal)=>{
		e.preventDefault();
		let parti = e.target.partic.value;
		let amt = e.target.amt.value;
		let date = e.target.date!==undefined?e.target.date.value:getDate();
		if (parti!==""&&amt>0&&activeId!==undefined&&link!=="") {
			setAddLoad(true);
			postCall(link,{s_id:activeId.substring(1),particular:parti,amount:amt,from_enabled:true,date:date}).then(res=>{				
				if (res.data.status) {
					setAddLoad(false);
					modal(false);
					setSalaryStaff(res.data.staffs);
				}else{
					setAddLoad(false);
					$.alert({
				        title: 'Error!',
				        content: res.data.message!==undefined?res.data.message:'Somthing went wrong!',
				        type:'red',
				    });
				}
			}).catch(err=>{
				setAddLoad(false);
			});
		}
	}
	const [ staffSalaryCalc, setStaffSalaryCalc ] = useState();
	const [ salaryCalcModal, setSalaryCalcModal ] = useState(false);
	useEffect(()=>{
		if (!salaryCalcModal) {
			setActiveId();
			setStaffSalaryCalc();
		}
	},[salaryCalcModal]);
	const [ detailsModal, setDetailsModal ] = useState(false);
	const [ getDetLoad, setGetDetLoad ] = useState(false);
	const [ updateModal, setUpdateModal ] = useState(false);
	const [ updateLoad, setUpdateLoad ] = useState(false);
	const [ activeStaff, setActiveStaff ] = useState();
	const updateAttandance=(e)=>{
		e.preventDefault();
		let att = e.target.attendence.value;
		let ot = e.target.overtime.value;
		if (att!==""&&parseInt(ot)>-1&&activeStaff!==undefined) {
			setUpdateLoad(true);
			postCall('api/sm/update/attendance/from/calculate/',{s_id:staffSalaryCalc.main.s_id,att:att,ot:ot,date:activeStaff.details.date}).then(res=>{				
				setUpdateLoad(false);
				setSalaryStaff(res.data.staffs);
				setStaffSalaryCalc(res.data.calc);
				setUpdateModal(false);
			}).catch(err=>{
				setUpdateLoad(false);
			});
		}
	}
	useEffect(()=>{
		if (!updateModal) {
			setActiveStaff();
		}
	},[updateModal]);
	const updateToWallet=(e)=>{
		e.preventDefault();
		let amt = e.target.amt.value;
		if (amt!=="") {
			$.confirm({
		    title: 'Confirm!',
		    content: 'Are you sure to add to wallet.?',
		    type:'blue',
		    buttons: {
		        confirm: function () {
			        	setUpdateLoad(true);
						postCall('api/sm/update/salary/to/wallet/',{s_id:staffSalaryCalc.main.s_id,amt:amt}).then(res=>{				
							setUpdateLoad(false);
							setSalaryCalcModal(false);
							setSalaryStaff(res.data);
						}).catch(err=>{
							setUpdateLoad(false);
						});
		        	},
		        cancel: function () {},
			    }
			});			
		}
	}
	const [ deleteAddiLoad, setDeleteAddiLoad ] = useState(false);
	const deleteAddition=(did)=>{
		if (did!=="") {
			$.confirm({
			    title: 'Confirm Delete!',
			    content: 'Are you sure delete?',
			    type : 'red',
			    buttons: {
			        confirm: function () {
			            setActiveId(did);
						setDeleteAddiLoad(true);
						postCall('api/sm/delete/addition/',{addi_id:did.substring(1)}).then(res=>{
							setActiveId();
							setDeleteAddiLoad(false);
							setSalaryStaff(res.data.staffs);
							setStaffSalaryCalc(res.data.calc);
						}).catch(err=>{
							setActiveId();
							setDeleteAddiLoad(false);
						});
			        },
			        cancel: function () {
			        },
			    }
			});			
		}
	}
	return(
		<Container className="my-3 bdr2 br-3" >
			<Row className='' >
				<Col xs="12" sm="12" md="12" lg="12" className="staff-type-header d-grid p-2 bdrb2 bgs1" >
					<span className="f16 tp2" >{load?(<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>):'Staffs'}</span>
					<Button onClick={()=>{setStaffAddModel(true)}} className="btn-t1" ><i className="bi bi-plus-circle"></i> Add new</Button>
				</Col>
				{
					salaryStaff!==undefined&&Object.keys(salaryStaff).length>0?(
						Object.keys(salaryStaff).map((item,i)=>(
							<div className="grid-split-2 al-center p-2 bdrb2 bgs1-lite" key={`sal_staff_${i}`} >
								<div className="d-grid">
									<div className="d-flex al-center gap-2">
										<span className="f18 tp2" >{`${salaryStaff[item].first_name} ${salaryStaff[item].last_name} `}</span>
										<span className="f16 tp2" >{`[ ${salaryStaff[item].username} / ${salaryStaff[item].designation} ]`}</span></div>
									<div className='d-flex'>
										<span className="f14" >Staff id : {salaryStaff[item].staff_u_id}, Basic pay : {salaryStaff[item].base_pay}, {
											salaryStaff[item].type!==undefined&&salaryStaff[item].type==='M'?'Monthly Salary':
											salaryStaff[item].type!==undefined&&salaryStaff[item].type==='D'?'Daily Wages':'Salary Type Not Defined'
										}, Overtime Charge : {salaryStaff[item].over_time_pay}, Leave cut : {salaryStaff[item].leave_cut}</span>
									</div>
								</div>
								<div className="d-grid just-end al-center">
									<div className="d-flex gap-1 just-end al-center">
										<span className="bg-total px-2 py-1 br-3 mb-1">Unsettled : {salaryStaff[item].unsettled}</span>
										<span className="bg-total px-2 py-1 br-3 mb-1">Wallet : {salaryStaff[item].balance}</span>
									</div>
									<div className="d-flex just-end gap-1">
										<Button onClick={()=>{
											setActiveId(item);
											setAdditionModal(true);
										}} className="btn-t1" size="sm" ><i className="bi bi-plus-circle"></i> Addition</Button>
										<Button onClick={()=>{
											setActiveId(item);
											setDedModal(true);
										}} className="btn-t1" size="sm" ><i className="bi bi-dash-circle"></i> Deduction</Button>										
										<Button onClick={()=>{
											setGetLoad(true);
											setActiveId(item);
											setSalaryCalcModal(true);
											postCall('api/sm/get/staff/salary/calculate/details/',{s_id:item.substring(1),date:getDate()}).then(res=>{
												setGetLoad(false);
												setStaffSalaryCalc(res.data);												
											}).catch(err=>{
												setGetLoad(false);
												setActiveId(item);
											});
										}} className="btn-t1" size="sm" ><i className="bi bi-calendar3"></i> Salary Calculator</Button>
										<Button onClick={()=>{
											setActiveId(item);
											setPayModal(true);
										}} className="btn-t1" size="sm" ><i className="bi bi-credit-card-2-back"></i> Payment</Button>
										<Button onClick={()=>{
											setEditable({id:item,details:salaryStaff[item]});
											setStaffAddModel(true);
										}} className="btn-t1" size="sm" ><i className="bi bi-pencil-square"></i> Edit</Button>
										{
											activeId!==undefined&&activeId===item&&changeStatLoad?(
												<Button className="btn-enable" size="sm" ><Spinner animation="border" size="sm" /> Wait...</Button>
											):(
												salaryStaff[item].status?(
													<Button onClick={()=>{changeStatus(item,false)}}  className="btn-disable" size="sm" ><i className="bi bi-eye-slash"></i> Disable</Button>
												):(
													<Button onClick={()=>{changeStatus(item,true)}} className="btn-enable" size="sm" ><i className="bi bi-eye-slash"></i> Enable</Button>
												)
											)
										}
									</div>
								</div>
							</div>
						))
					):''
				}							
			</Row>
			<Modal size="xl" show={staffAddModel} backdrop="static" onHide={()=>{setStaffAddModel(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Add staff</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={addStaff} >
			        	<Form.Group className="mb-3">
			        		<Form.Label>Salary Type</Form.Label>
			        		<Form.Select name="type" onChange={(e)=>{setSalType(e.target.value)}} defaultValue={editable!==undefined?editable.details.type:''} className="shadow-none br-2">
						      <option value="M" >Monthly Salary</option>
						      <option value="D" >Daily Wages</option>
						    </Form.Select>
						</Form.Group>
		        		{
		        			editable===undefined?(
						    	<Row>
							    	<Col xs={12} sm={12} md={6} >
							    		<Form.Group className="mb-3">
							    			<Form.Label>First name</Form.Label>
							    			<Form.Control type="text" name="first_name" className="shadow-none br-2" required/>
							    		</Form.Group>
							    	</Col>
							    	<Col xs={12} sm={12} md={6} >
							    		<Form.Group className="mb-3">
							    			<Form.Label>Last name</Form.Label>
							    			<Form.Control type="text" name="last_name" className="shadow-none br-2" />
							    		</Form.Group>
							    	</Col>
						    	</Row>
							):''
						}
				    	<Row>
					    	<Col xs={12} sm={12} md={6} >
					    		<Form.Group className="mb-3">
						    		<Form.Label>Designation</Form.Label>
						    		<Form.Control type="text" name="desig" defaultValue={editable!==undefined?editable.details.designation:''} className="shadow-none br-2" required/>
					    		</Form.Group>
					    	</Col>
					    	<Col xs={12} sm={12} md={6} >
					    		<Form.Group className="mb-3">
						    		<Form.Label>Basic pay</Form.Label>
						    		<Form.Control type="number" min="0" setp="0.01" name="b_pay" defaultValue={editable!==undefined?editable.details.base_pay:''} className="shadow-none br-2" required/>
					    		</Form.Group>
					    	</Col>
				    	</Row>
				    	<Row>
					    	<Col xs={12} sm={12} md={6} >
					    		<Form.Group className="mb-3">
					    			<Form.Label>Salary cutting change of leave.</Form.Label>
					    			{
					    				salType===undefined||(salType!==undefined&&salType==='M')?(
					    					<Form.Control type="number" min="0" setp="0.01" name="cut" defaultValue={editable!==undefined?editable.details.leave_cut:''} className="shadow-none br-2" required/>
					    				):(
					    					<Form.Control type="number" className="shadow-none br-2" disabled/>
					    				)
					    			}
					    		</Form.Group>
					    	</Col>
					    	<Col xs={12} sm={12} md={6} >
					    		<Form.Group className="mb-3">
					    			<Form.Label>Overtime charge per hour.</Form.Label>
					    			<Form.Control type="number" min="0" setp="0.01" name="ot" defaultValue={editable!==undefined?editable.details.over_time_pay:''} className="shadow-none br-2" required/>
					    		</Form.Group>
					    	</Col>
				    	</Row>
					    <div  className="d-grid">
					    {
					    	staffAddLoad?(
					    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
					    	):(
					    	<Button type="submit" className="btn-t1">SAVE</Button>
					    	)
					    }
					    </div>
		        	</Form>
		        </Modal.Body>
		        <Modal.Footer>
		        	
		        </Modal.Footer>
		    </Modal>
		    <Modal size="xl" show={additionModal} backdrop="static" onHide={()=>{setAdditionModal(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Addition</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={e=>fnPost(e,'api/sm/addition/',setAdditionModal)} >
		        		<Form.Group className="mb-3">
		        			<Form.Label>Date</Form.Label>
		        			<Form.Control name="date" type="date" defaultValue={getDate()}  className="shadow-none br-2" />
		        		</Form.Group>
		        		<Form.Group className="mb-3">
				    		<Form.Label>Particular</Form.Label>
				    		<Form.Control type="text" name="partic" className="shadow-none br-2" placeholder="Particular" required/>
				    	</Form.Group>				    
				    	<Form.Group className="mb-3">
			    			<Form.Label>Amount</Form.Label>
				    		<Form.Control type="number" min="1" step=".01" name="amt" className="shadow-none br-2" placeholder="Amount" required/>
				    	</Form.Group>					    
					    <div  className="d-grid">
					    {
					    	addLoad?(
					    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
					    	):(
					    	<Button type="submit" className="btn-t1">SAVE</Button>
					    	)
					    }
					    </div>
		        	</Form>
		        </Modal.Body>
		        <Modal.Footer></Modal.Footer>
		    </Modal>
		    <Modal size="xl" show={dedModal} backdrop="static" onHide={()=>{setDedModal(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Deduction</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={e=>fnPost(e,'api/sm/deduction/',setDedModal)} >	
		        		<Form.Group className="mb-3">
		        			<Form.Label>Date</Form.Label>
		        			<Form.Control name="date" type="date" defaultValue={getDate()}  className="shadow-none br-2" />
		        		</Form.Group>
		        		<Form.Group className="mb-3">
				    		<Form.Label>Particular</Form.Label>
				    		<Form.Control type="text" name="partic" className="shadow-none br-2" placeholder="Particular" required/>
				    	</Form.Group>				    
				    	<Form.Group className="mb-3">
			    			<Form.Label>Amount</Form.Label>
				    		<Form.Control type="number" min="1" step=".01" name="amt" className="shadow-none br-2" placeholder="Amount" required/>
				    	</Form.Group>					    
					    <div  className="d-grid">
					    {
					    	addLoad?(
					    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
					    	):(
					    	<Button type="submit" className="btn-t1">SAVE</Button>
					    	)
					    }
					    </div>
		        	</Form>
		        </Modal.Body>
		        <Modal.Footer></Modal.Footer>
		    </Modal>
		    <Modal size="xl" show={payModal} backdrop="static" onHide={()=>{setPayModal(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Payment</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={e=>fnPost(e,'api/sm/payment/',setPayModal)} >	
		        		<Form.Group className="mb-3">
				    		<Form.Label>Particular</Form.Label>
				    		<Form.Select type="text" name="partic" className="shadow-none br-2" placeholder="Particular" required>
				    			<option value="salary" >Salary</option>
				    			<option value="advance" >Salary Advance</option>
				    		</Form.Select>
				    	</Form.Group>				    
				    	<Form.Group className="mb-3">
			    			<Form.Label>Amount</Form.Label>
				    		<Form.Control type="number" min="1" step=".01" name="amt" className="shadow-none br-2" placeholder="Amount" required/>
				    	</Form.Group>					    
					    <div  className="d-grid">
					    {
					    	addLoad?(
					    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
					    	):(
					    	<Button type="submit" className="btn-t1">SAVE</Button>
					    	)
					    }
					    </div>
		        	</Form>
		        </Modal.Body>
		        <Modal.Footer></Modal.Footer>
		    </Modal>
		    <Modal size="xl" show={salaryCalcModal} backdrop="static" onHide={()=>{setSalaryCalcModal(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>{getLoad?(<span className="please-wait-spinner f18" ><Spinner animation="border" size="sm" /> Please wait..</span>):'Salary Calculation'}</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	{
		        		staffSalaryCalc!==undefined&&staffSalaryCalc.attendance!==undefined&&Object.keys(staffSalaryCalc.attendance.attendance).length>0?(
	        				<>
		        				<span className="d-flex gap-2 f18 tp2 mb-1">System generated salary report from <b>{staffSalaryCalc.main.from}</b> to <b>{staffSalaryCalc.main.to}</b></span>
		        				<span className="d-flex gap-2 f18 tp2 mb-2">System Calculated salary : <span className="bg-total px-2 br-3">{parseInt(staffSalaryCalc.attendance.total.total_payable)+parseInt(staffSalaryCalc.additions.total.amount)}</span></span>
		        				{
		        					staffSalaryCalc.main.submittable?(
			        				<Form onSubmit={updateToWallet} className="grid-split-2 gap-1 mb-3 bgs1 bdr1 px-2 tp2 py-2" >
				        				<Form.Control type="number" defaultValue={parseInt(staffSalaryCalc.attendance.total.total_payable)+parseInt(staffSalaryCalc.additions.total.amount)} step="0.01" name="amt" className="shadow-none br-2" required/>
									    {
									    	updateLoad?(
									    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
									    	):(
									    	<Button type="submit" className="btn-t1">UPDATE TO WALLET</Button>
									    	)
									    }
			        				</Form>
			        				):''
			        			}
		        				<span className="d-flex gap-2 f20 bgs1 bdr1 bdrb0 px-2 tp2 py-2" >Attendance of <b>{staffSalaryCalc.attendance.total.total_days}</b> days</span>
		        				<Table bordered className="tbl-bg-blue" >
								    <thead>
								        <tr className="tp2">
								          <th>Date</th>
								          <th>Updated by</th>
								          <th>Login time</th>
								          <th>Log out time</th>
								          <th>Overtime ( hours )</th>
								          <th>Attendance</th>
								        </tr>
								    </thead>			    
								    <tbody>	
								    {
								    	Object.keys(staffSalaryCalc.attendance.attendance).map((item,i)=>(
							    			<tr key={`att_${i}`} className="tp2" >
							    				<td>
							    					<span className="d-grid f16" >{staffSalaryCalc.attendance.attendance[item].date}</span>
							    					<span className="d-grid f14" >{staffSalaryCalc.attendance.attendance[item].day}</span>
							    				</td>
							    				<td>{staffSalaryCalc.attendance.attendance[item].updated_by}</td>
							    				<td>{staffSalaryCalc.attendance.attendance[item].login}</td>
							    				<td>{staffSalaryCalc.attendance.attendance[item].log_out}</td>
							    				<td>{staffSalaryCalc.attendance.attendance[item].over_time}</td>
							    				<td>
							    					<div className="grid-split-2 al-center">
							    						<span>{staffSalaryCalc.attendance.attendance[item].attendance}</span>
							    						<div className="d-flex just-end">
							    							<Button onClick={()=>{
							    								setActiveStaff({id:item,details:staffSalaryCalc.attendance.attendance[item]});
							    								setUpdateModal(true);
							    							}} className="btn-t1" size="sm" ><i className="bi bi-pencil-square"></i> Edit</Button>
							    						</div>
							    					</div>
							    				</td>
							    			</tr>
							    		))
								    }
								    {
								    	staffSalaryCalc.attendance.total!==undefined?(
								    		<tr>
								    			
								    			<td colSpan="3" >
								    				<div className="grid-split-2 al-center bdrb2">
							    						<span>Total Full day</span>
							    						<div className="d-flex just-end">
							    							<span>{staffSalaryCalc.attendance.total.total_full}</span>
							    						</div>
							    					</div>
							    					<div className="grid-split-2 al-center bdrb2">
							    						<span>Total Half day</span>
							    						<div className="d-flex just-end">
							    							<span>{staffSalaryCalc.attendance.total.total_half}</span>
							    						</div>
							    					</div>
							    					<div className="grid-split-2 al-center bdrb2">
							    						<span>Total leave</span>
							    						<div className="d-flex just-end">
							    							<span>{staffSalaryCalc.attendance.total.total_leave}</span>
							    						</div>
							    					</div>
							    					<div className="grid-split-2 al-center">
							    						<span>Total Overtime</span>
							    						<div className="d-flex just-end">
							    							<span>{staffSalaryCalc.attendance.total.total_over}</span>
							    						</div>
							    					</div>
								    			</td>
								    			<td colSpan="3" className="f18">
								    				<div className="grid-split-2 al-center bdrb2">
							    						<span>Salary</span>
							    						<div className="d-flex just-end">
							    							<span>{staffSalaryCalc.attendance.total.salary}</span>
							    						</div>
							    					</div>
							    					<div className="grid-split-2 al-center bdrb2">
							    						<span>Overtime</span>
							    						<div className="d-flex just-end">
							    							<span>{staffSalaryCalc.attendance.total.over_time_val}</span>
							    						</div>
							    					</div>
							    					<div className="grid-split-2 al-center bdrb2">
							    						<span>Leave deduction</span>
							    						<div className="d-flex just-end">
							    							<span>{staffSalaryCalc.attendance.total.leave_deduction}</span>
							    						</div>
							    					</div>
							    					<div className="grid-split-2 al-center">
							    						<span>Total Payable</span>
							    						<div className="d-flex just-end">
							    							<span>{staffSalaryCalc.attendance.total.total_payable}</span>
							    						</div>
							    					</div>
								    			</td>
								    		</tr>
								    	):''
								    }		    
								    </tbody>
								</Table>
							</>	
		        		):''
		        	}
		        	{
		        		staffSalaryCalc!==undefined&&staffSalaryCalc.additions!==undefined&&Object.keys(staffSalaryCalc.additions.addition).length>0?(
		        			<>
		        				<span className="d-grid f20 bgs1 bdr1 bdrb0 px-2 tp2 py-2" >Additions & Deductions</span>
		        				<Table bordered className="tbl-bg-blue" >
								    <thead>
								        <tr className="tp2">
								          <th>Date</th>
								          <th>Updated by</th>								          
								          <th>Reason</th>
								          <th>Amount</th>
								        </tr>
								    </thead>			    
								    <tbody>	
								    {
								    	Object.keys(staffSalaryCalc.additions.addition).map((item,i)=>(
							    			<tr key={`additions_${i}`} className="tp2" >
							    				<td>{staffSalaryCalc.additions.addition[item].date}</td>
							    				<td>{staffSalaryCalc.additions.addition[item].created_by}</td>
							    				<td>{staffSalaryCalc.additions.addition[item].particulars}</td>
							    				<td>
							    					<div className="grid-split-2 al-center">
							    						{
									    					staffSalaryCalc.additions.addition[item].payment_type==='debit'?(
									    						<span className="sucess-text">+{staffSalaryCalc.additions.addition[item].amount}</span>
									    					):(
									    						<span className="error-text">-{staffSalaryCalc.additions.addition[item].amount}</span>
									    					)
									    				}
							    						<div className="d-flex just-end">
							    						{
							    							activeId!==undefined&&activeId===item&&deleteAddiLoad?(
							    								<Button className="btn-t1" size="sm" ><Spinner animation="border" size="sm" /> Please wait...</Button>
							    							):(
							    								<Button onClick={()=>{deleteAddition(item)}} className="btn-t1" size="sm" ><i className="bi bi-trash"></i> Delete</Button>
							    							)
							    						}
							    							
							    						</div>
							    					</div>							    				
							    				</td>
							    			</tr>
							    		))
								    }
								    {
								    	staffSalaryCalc.additions.total!==undefined?(
								    		<tr>
								    			<td colSpan="3"  className="text-end f18" >Total</td>
								    			<td colSpan="2"  className="f18">
								    			{
								    				parseInt(staffSalaryCalc.additions.total.amount)>0?(
								    					<span className="sucess-text" >{staffSalaryCalc.additions.total.amount}</span>
								    				):parseInt(staffSalaryCalc.additions.total.amount)<0?(
								    					<span className="error-text" >{staffSalaryCalc.additions.total.amount}</span>
								    				):(
								    					<span>{staffSalaryCalc.additions.total.amount}</span>
								    				)
								    			}</td>
								    		</tr>
								    	):''
								    }		    
								    </tbody>
								</Table>
		        			</>
		        		):''
		        	}
		        </Modal.Body>
		        <Modal.Footer></Modal.Footer>
		    </Modal>
		    <Modal size="xl" show={updateModal} backdrop="static" onHide={()=>{setUpdateModal(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Update Attendance</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={updateAttandance} >		        		
				    	<Row>
					    	<Col xs={12} sm={12} md={6} >
					    		<Form.Group className="mb-3">
					    			<Form.Label>Attendance</Form.Label>
					    			<Form.Select name="attendence" defaultValue={activeStaff!==undefined&&activeStaff.details.attendance!=='--'?activeStaff.details.attendance:'F'}  className="shadow-none br-2" required>
								      <option value="F" >Full day</option>
								      <option value="H" >Half day</option>
								      <option value="L" >Absend</option>
								    </Form.Select>
					    		</Form.Group>
					    	</Col>
					    	<Col xs={12} sm={12} md={6} >
					    		<Form.Group className="mb-3">
					    			<Form.Label>Overtime</Form.Label>
					    			<Form.Control defaultValue={activeStaff!==undefined&&activeStaff.details.over_time!=="--"?activeStaff.details.over_time:0} type="number" step=".01" min="0" name="overtime" className="shadow-none br-2" placeholder="First name" required/>
					    		</Form.Group>
					    	</Col>
				    	</Row>					    
					    <div  className="d-grid">
					    {
					    	updateLoad?(
					    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
					    	):(
					    	<Button type="submit" className="btn-t1" ><i className="bi bi-check2-square"></i> Update</Button>
					    	)
					    }
					    </div>
		        	</Form>
		        	
		        </Modal.Body>
		        <Modal.Footer>
		        	
		        </Modal.Footer>
		    </Modal>
		</Container>
		)
}