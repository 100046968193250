import React, {useState, createContext, useEffect} from 'react';
import { Row, Col, Modal, Spinner, Table } from 'react-bootstrap';
import axios from "axios";
import {LocalServer} from './templates/WebIp';
import { Authentication } from './templates/authentication';
import { Dashboard } from './templates/dashboard';
import $ from 'jquery';
import 'jquery-confirm';
export const AppContext = createContext();

function App() {
  var link = LocalServer();
  const [ authForm, setAuthForm ] = useState(true);
  const [ accessToken, setAccessToken ] = useState();
  const [ page, setPage ] = useState('dashboard');
  const [ dayClose, setDayClose ] = useState(false);
  const [ closeDayReport, setCloseDayReport ] = useState();
  const [ user, setUser ] = useState();
  const [ account, setAccount ] = useState()
  const [ expenseParti, setExpenseParti ] = useState();
  const [ expense, setExpense ] = useState();
  const [ load, setLoad ] = useState(false);
  const [ pettyExpense, setPettyExpense ] = useState();
  const [ salaryStaff, setSalaryStaff ] = useState();
  const [ salaryCalcRepo, setSalaryCalcRepo ] = useState();
  const [ salaryPayRepo, setSalaryPayRepo ] = useState();
  useEffect(()=>{
    if(user!==undefined){
      setDayClose(user.day_status);
      setAccount(user.account);
      getHomePage();
      if(user.type==='admin'){
        getTokens();        
        setInterval(()=>{getTokens()},15000);
        getSubmitted();
        setInterval(()=>{getSubmitted()},15000);
      }else{
        if (user.permission!==undefined) {
          if (user.permission.token!==undefined) {
            if(Object.values(user.permission.token).indexOf(true)!==-1){
              getTokens();
              setInterval(()=>{getTokens()},15000);
            }
          }
          if (user.permission.token_settle!==undefined) {
            if(user.permission.token_settle.X){
              getTokens();
              setInterval(()=>{getSubmitted()},15000);
            }
          }
        }
      }
    }
  },[user]);
  const getCall=(loadfn,path,setfn)=>{
    loadfn(true);
    axios.get(`${link}${path}`,{
          headers:{
            Authorization: 'Bearer '+accessToken
          }
        }).then((response)=>{
          loadfn(false);
          setfn(response.data);
        }).catch((err)=>{
          loadfn(false);
          if (err!==undefined&&err.response!==undefined) {
            if(err.response.status === 401){
                setAccessToken();
            }else if(err.response.status === 404){
                console.log(path, err.response.status,':',err.response.statusText);
            }else if(err.response.status === 500){
                console.log(path, err.response.status,':',err.response.statusText);
            }else{
                console.log(path, err.response.status,':',err.response.data);
            }
          }else{
            $.dialog({
              title: "Can't reach server!",
              content: 'Please check internet connection!',
            });
        }
    });
  }
  const postCall=(path,data)=>{
    return new Promise((resolve,reject)=>{
      axios.post(`${link}${path}`,data,{
            headers:{
              Authorization:accessToken!==undefined?'Bearer '+accessToken:''
            }
          }).then((response)=>{
            resolve({data:response.data});
          }).catch((err)=>{
            if (err!==undefined&&err.response!==undefined) {
              if(err.response.status === 401){
                  setAccessToken();
              }else if(err.response.status === 404){
                  console.log(path, err.response.status,':',err.response.statusText);
              }else if(err.response.status === 500){
                  console.log(path, err.response.status,':',err.response.statusText);
              }else{
                  console.log(path, err.response.status,':',err.response.data);
              }
            }else{
              $.dialog({
                title: "Can't reach server!",
                content: 'Please check internet connection!',
              });
            }
            reject();
      });
    });
  }
  const [ permissions, setPermissions ] = useState();
  const [ getPermiLoad, setGetPermiLoad ] = useState(false);
  const getPermissions=()=>{
    setGetPermiLoad(true);
    axios.get(`${link}api/pnp/get/permissions/`,{
      headers:{
              Authorization: 'Bearer '+accessToken
            }
    }).then(response=>{
      setGetPermiLoad(false);
      setPermissions(response.data);
    }).catch(err=>{
      setGetPermiLoad(false);
      if (err!==undefined&&err.response!==undefined) {
        if(err.response.status === 401){
            setAuthForm(true);
        }else if(err.response.status === 404){
            console.log('Get_Permissions - ',err.response.status,':',err.response.statusText);
        }else if(err.response.status === 500){
            console.log('Get_Permissions - ',err.response.status,':',err.response.statusText);
        }else{
            console.log('Get_Permissions - ',err.response.status,':',err.response.data);
        }
      }else{
        $.dialog({
          title: "Can't reach server!",
          content: 'Please check internet connection!',
        });
      }
    });
  } 
  const [ staffTypes, setStaffTypes ] = useState();
  const [ getStaffTypeLoad, setGetStaffTypeLoad ] = useState(false);
  const getStaffTypes=()=>{
    setGetStaffTypeLoad(true);
    axios.get(`${link}api/pnp/get/staff/type/`,{
        headers:{
                Authorization: 'Bearer '+accessToken
              }
      }).then(response=>{
        setGetStaffTypeLoad(false);
        setStaffTypes(response.data);
      }).catch(err=>{
        setGetStaffTypeLoad(false);
        if (err!==undefined&&err.response!==undefined) {
          if(err.response.status === 401){
              setAuthForm(true);
          }else if(err.response.status === 404){
              console.log('Get_staff_type - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 500){
              console.log('Get_staff_type - ',err.response.status,':',err.response.statusText);
          }else{
              console.log('Get_staff_type - ',err.response.status,':',err.response.data);
          }
        }else{
          $.dialog({
            title: "Can't reach server!",
            content: 'Please check internet connection!',
          });
        }
      });
  }
  const [ staffs, setStaffs ] = useState();
  const [ getStaffsLoad, setGetStaffsLoad ] = useState(false);
  const getStaffs=()=>{
    setGetStaffsLoad(true);
    axios.get(`${link}api/pnp/get/staff/`,{
        headers:{
                Authorization: 'Bearer '+accessToken
              }
      }).then(response=>{
        setGetStaffsLoad(false);
        setStaffs(response.data);
      }).catch(err=>{
        setGetStaffsLoad(false);
        if (err!==undefined&&err.response!==undefined) {
          if(err.response.status === 401){
              setAuthForm(true);
          }else if(err.response.status === 404){
              console.log('Get_staff - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 500){
              console.log('Get_staff - ',err.response.status,':',err.response.statusText);
          }else{
              console.log('Get_staff - ',err.response.status,':',err.response.data);
          }
        }else{
          $.dialog({
            title: "Can't reach server!",
            content: 'Please check internet connection!',
          });
        }
      });
  }
  const [ services, setServices ] = useState();
  const [ getServiceLoad, setGetServiceLoad ] = useState(false);
  const getServices=()=>{
      setGetServiceLoad(true);
      axios.get(`${link}api/snp/get/service/`,{
          headers:{
                  Authorization: 'Bearer '+accessToken
                }
        }).then(response=>{
          setGetServiceLoad(false);
          setServices(response.data);
        }).catch(err=>{
          setGetServiceLoad(false);
          if (err!==undefined&&err.response!==undefined) {
            if(err.response.status === 401){
                setAuthForm(true);
            }else if(err.response.status === 404){
                console.log('Get_services - ',err.response.status,':',err.response.statusText);
            }else if(err.response.status === 500){
                console.log('Get_services - ',err.response.status,':',err.response.statusText);
            }else{
                console.log('Get_services - ',err.response.status,':',err.response.data);
            }
          }else{
            $.dialog({
              title: "Can't reach server!",
              content: 'Please check internet connection!',
            });
          }
        });
  }
  const [ customers, setCustomers ] = useState();
  const [ getCustomersLoad, setGetCustomersLoad ] = useState(false);
  const getCustomers=()=>{
      setGetCustomersLoad(true);
      axios.get(`${link}api/cp/get/customer/`,{
          headers:{
                  Authorization: 'Bearer '+accessToken
                }
        }).then(response=>{
          setGetCustomersLoad(false);
          setCustomers(response.data);
        }).catch(err=>{
          setGetCustomersLoad(false);
          if (err!==undefined&&err.response!==undefined) {
            if(err.response.status === 401){
                setAuthForm(true);
            }else if(err.response.status === 404){
                console.log('Get_customer - ',err.response.status,':',err.response.statusText);
            }else if(err.response.status === 500){
                console.log('Get_customer - ',err.response.status,':',err.response.statusText);
            }else{
                console.log('Get_customer - ',err.response.status,':',err.response.data);
            }
          }else{
            $.dialog({
              title: "Can't reach server!",
              content: 'Please check internet connection!',
            });
          }
        });
  }
  const [ getEnquiryLoad, setGetEnquiryLoad ] = useState(false);
  const [ enquiry, setEnquiry ] = useState();
  const getEnquiry=()=>{
    setGetEnquiryLoad(true);
    axios.get(`${link}api/e/get/enquiry/`,{
        headers:{
                Authorization: 'Bearer '+accessToken
              }
      }).then(response=>{
        setGetEnquiryLoad(false);
        setEnquiry(response.data);
      }).catch(err=>{
        setGetEnquiryLoad(false);
        if (err!==undefined&&err.response!==undefined) {
          if(err.response.status === 401){
              setAuthForm(true);
          }else if(err.response.status === 404){
              console.log('Get_enquiry - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 500){
              console.log('Get_enquiry - ',err.response.status,':',err.response.statusText);
          }else{
              console.log('Get_enquiry - ',err.response.status,':',err.response.data);
          }
        }else{
          $.dialog({
            title: "Can't reach server!",
            content: 'Please check internet connection!',
          });
        }
      });
  }
  const [ getWorkLoad, setGetWorkLoad ] = useState(false);
  const [ works, setWorks ] = useState();
  const getWorks=()=>{
    setGetWorkLoad(true);
    axios.get(`${link}api/e/get/pending/work/`,{
        headers:{
                Authorization: 'Bearer '+accessToken
              }
      }).then(response=>{
        setGetWorkLoad(false);
        setWorks(response.data);
      }).catch(err=>{
        setGetWorkLoad(false);
        if (err!==undefined&&err.response!==undefined) {
          if(err.response.status === 401){
              setAuthForm(true);
          }else if(err.response.status === 404){
              console.log('Get_pending_works - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 500){
              console.log('Get_pending_works - ',err.response.status,':',err.response.statusText);
          }else{
              console.log('Get_pending_works - ',err.response.status,':',err.response.data);
          }
        }else{
          $.dialog({
            title: "Can't reach server!",
            content: 'Please check internet connection!',
          });
        }
      });
  }
  const [ tokens, setTokens ] = useState();
  const [ closeDayState, setCloseDayState ] = useState(false);
  useEffect(()=>{
    if (tokens!==undefined) {
      if (Object.keys(tokens).length>0) {
        let p = new Promise((resolve,reject)=>{
          Object.keys(tokens).map(tok=>{
            if(tokens[tok].status==='open'){
              if (user.profile.user_id===tokens[tok].user_id) {
                resolve();
              }
            }
          });
          reject();
        });
        p.then(res=>{
          setCloseDayState(false);
        }).catch(err=>{
          setCloseDayState(true);
        });
      }else{
          setCloseDayState(true);
      }
    }
  },[tokens]);
  const [ getTokenLoad, setGetTokenLoad ] = useState(false);
  const [ stateSettle , setStateSettle ] = useState(false);
  const [ stateSettleAll, setStateSettleAll ] = useState(false);
  const getTokens=()=>{
    setGetTokenLoad(true);
    axios.get(`${link}api/mt/get/token/`,{
        headers:{
                Authorization: 'Bearer '+accessToken
              }
      }).then(response=>{
        setGetTokenLoad(false);
        setTokens(response.data.tokens);
        setStateSettle(response.data.settle);
        setStateSettleAll(response.data.settle_all);
      }).catch(err=>{
        setGetTokenLoad(false);
        if(err!==undefined&&err.response!==undefined){ 
          if(err.response.status === 401){
              setAuthForm(true);
          }else if(err.response.status === 404){
              console.log('Get_tokens - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 500){
              console.log('Get_tokens - ',err.response.status,':',err.response.statusText);
          }else{        
            console.log('Get_tokens - ',err.response.status,':',err.response.data);
          }
        }else{
          $.dialog({
              title: "Can't reach server!",
              content: 'Please check internet connection!',
          });
        }
      });
  }
  const [ submittedTokens, setSubmittedTokens  ] = useState();
  const [ getSubitLoad, setGetSubmitLoad ] = useState(false);
  const getSubmitted=()=>{
    setGetSubmitLoad(true);
    axios.get(`${link}api/mt/get/token/submitted/`,{
        headers:{
                Authorization: 'Bearer '+accessToken
              }
      }).then(response=>{
        setGetSubmitLoad(false);
        setSubmittedTokens(response.data.tokens);
        setStateSettle(response.data.settle);
        setStateSettleAll(response.data.settle_all);
      }).catch(err=>{
        setGetSubmitLoad(false);
        if(err!==undefined&&err.response!==undefined){ 
          if(err.response.status === 401){
              setAuthForm(true);
          }else if(err.response.status === 404){
              console.log('Get_tokens_submitted - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 500){
              console.log('Get_tokens_submitted - ',err.response.status,':',err.response.statusText);
          }else{        
            console.log('Get_tokens_submitted - ',err.response.status,':',err.response.data);
          }
        }else{
          $.dialog({
              title: "Can't reach server!",
              content: 'Please check internet connection!',
          });
        }
      });
  }
  const [ tokenServices, setTokenServices ] = useState();
  const [ tokenServiceLoad, setTokenServiceLoad ] = useState(false);
  const getTokenServices=()=>{
    setTokenServiceLoad(true);
    axios.get(`${link}api/mt/get/service/`,{
        headers:{
                Authorization: 'Bearer '+accessToken
              }
      }).then(response=>{
        setTokenServiceLoad(false);
        setTokenServices(response.data);
      }).catch(err=>{
        setTokenServiceLoad(false);
        if (err!==undefined&&err.response!==undefined) {
          if(err.response.status === 401){
              setAuthForm(true);
          }else if(err.response.status === 404){
              console.log('Get_token_serivecs - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 500){
              console.log('Get_token_serivecs - ',err.response.status,':',err.response.statusText);
          }else{
              console.log('Get_token_serivecs - ',err.response.status,':',err.response.data);
          }
        }else{
          $.dialog({
            title: "Can't reach server!",
            content: 'Please check internet connection!',
          });
        }
      });
  }
  const [ tokenCustomers, setTokenCustomers ] = useState();
  const [ getTokenCustomerLoad, setGetTokenCustomerLoad ] = useState(false);
  const getTokenCustomers=()=>{
    setGetTokenCustomerLoad(true);
    axios.get(`${link}api/mt/get/customer/`,{
        headers:{
                Authorization: 'Bearer '+accessToken
              }
      }).then(response=>{
        setGetTokenCustomerLoad(false);
        setTokenCustomers(response.data);
      }).catch(err=>{
        setGetTokenCustomerLoad(false);
        if (err!==undefined&&err.response!==undefined) {
          if(err.response.status === 401){
              setAuthForm(true);
          }else if(err.response.status === 404){
              console.log('Get_token_customers - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 500){
              console.log('Get_token_customers - ',err.response.status,':',err.response.statusText);
          }else{
              console.log('Get_token_customers - ',err.response.status,':',err.response.data);
          }
        }else{
          $.dialog({
            title: "Can't reach server!",
            content: 'Please check internet connection!',
          });
        }
      });
  }
  const [ payMethods, setPayMethods ] = useState();
  const [ getPayMethodLoad, setGetPayMethodLoad ] = useState(false);
  const getPaymentMethods=()=>{
    setGetPayMethodLoad(true);
    axios.get(`${link}api/pm/get/payment/method/`,{
        headers:{
                Authorization: 'Bearer '+accessToken
              }
      }).then(response=>{
        setGetPayMethodLoad(false);
        setPayMethods(response.data);
      }).catch(err=>{
        setGetPayMethodLoad(false);
        if (err!==undefined&&err.response!==undefined) {
          if(err.response.status === 401){
              setAuthForm(true);
          }else if(err.response.status === 404){
              console.log('Get_payment_methods - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 500){
              console.log('Get_payment_methods - ',err.response.status,':',err.response.statusText);
          }else{
              console.log('Get_payment_methods - ',err.response.status,':',err.response.data);
          }
        }else{
          $.dialog({
            title: "Can't reach server!",
            content: 'Please check internet connection!',
          });
        }
      });
  }
  const [ payModes, setPaymodes ] = useState();
  const getPayModes=()=>{
    axios.get(`${link}api/pm/get/payment/method/billing/`,{
        headers:{
                Authorization: 'Bearer '+accessToken
              }
      }).then(response=>{
        setPaymodes(response.data);
      }).catch(err=>{
        if (err!==undefined&&err.response!==undefined) {
          if(err.response.status === 401){
              setAuthForm(true);
          }else if(err.response.status === 404){
              console.log('Get_payment_methods_billing - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 500){
              console.log('Get_payment_methods_billing - ',err.response.status,':',err.response.statusText);
          }else{
              console.log('Get_payment_methods_billing - ',err.response.status,':',err.response.data);
          }
        }else{
          $.dialog({
            title: "Can't reach server!",
            content: 'Please check internet connection!',
          });
        }
      });
  }
  const [ collections, setCollections ] = useState();
  const [ getCollectionLoad, setGetCollectionLoad ] = useState(false);
  const getCollections=(c_date)=>{
    setGetCollectionLoad(true);
    axios.post(`${link}api/bnr/get/collection/`,{date:c_date},{
        headers:{
                Authorization: 'Bearer '+accessToken
              }
      }).then(response=>{
        setGetCollectionLoad(false);
        setCollections(response.data);
      }).catch(err=>{
        setGetCollectionLoad(false);
        if (err!==undefined&&err.response!==undefined) {
          if(err.response.status === 401){
              setAuthForm(true);
          }else if(err.response.status === 404){
              console.log('Get_collections - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 500){
              console.log('Get_collections - ',err.response.status,':',err.response.statusText);
          }else{
              console.log('Get_collections - ',err.response.status,':',err.response.data);
          }
        }else{
          $.dialog({
            title: "Can't reach server!",
            content: 'Please check internet connection!',
          });
        }
      });
  }
  const [ notifications, setNotification ] = useState();
  const [ getNotifiLoad, setGetNotifiLoad ] = useState(false);
  const getNotifications=()=>{
    setGetNotifiLoad(true);
    axios.get(`${link}api/rn/get/notification/`,{
        headers:{
                Authorization: 'Bearer '+accessToken
              }
      }).then(response=>{
        setGetNotifiLoad(false);
        setNotification(response.data);
      }).catch(err=>{
        setGetNotifiLoad(false);
        if (err!==undefined&&err.response!==undefined) {  
          if(err.response.status === 401){
              setAuthForm(true);
          }else if(err.response.status === 404){
              console.log('Get_notifications - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 500){
              console.log('Get_notifications - ',err.response.status,':',err.response.statusText);
          }else{
              console.log('Get_notifications - ',err.response.status,':',err.response.data);
          }
        }else{
          $.dialog({
            title: "Can't reach server!",
            content: 'Please check internet connection!',
          });
        }
      });
  }
  function getDate(){
    let today = new Date();
    let x = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
    return x;
  }
  const [ date, setDate ]=useState(getDate());
  useEffect(()=>{
    if (date!==undefined&&user!==undefined) {
      getSpecialTokens();
    }
  },[date]);
  const [ specialTokens, setSpecialTokens ] = useState();
  const [ specialTokenLoad, setSpecialTokenLoad ] = useState(false);
  const getSpecialTokens=()=>{
    setSpecialTokenLoad(true);
    axios.post(`${link}api/at/get/token/`,{
        date:date,
          },{
            headers:{
              Authorization: 'Bearer '+accessToken
            }
          }).then((response)=>{
            setSpecialTokenLoad(false);
            setSpecialTokens(response.data);
          }).catch((err)=>{
            setSpecialTokenLoad(false);
            if (err!==undefined&&err.response!==undefined) {
              if(err.response.status === 401){
                  setAuthForm(true);
              }else if(err.response.status === 404){
                  console.log('Get_additional_Token - ',err.response.status,':',err.response.statusText);
              }else if(err.response.status === 500){
                  console.log('Get_additional_Token - ',err.response.status,':',err.response.statusText);
              }else{
                  console.log('Get_additional_Token - ',err.response.status,':',err.response.data);
              }
            }else{
              $.dialog({
                title: "Can't reach server!",
                content: 'Please check internet connection!',
              });
            }
      });
  }
  const [ dayCloseToVerify, setDayCloseToVerify ] = useState();
  const [ dayCloseToVerifyLoad, setDayCloseToVerifyLoad ] = useState(false);
  const getDayCloseToVerify=()=>{
    setDayCloseToVerifyLoad(true);
    axios.get(`${link}api/pnp/day/close/unverified/reports/`,{
        headers:{
                Authorization: 'Bearer '+accessToken
              }
      }).then(response=>{
        setDayCloseToVerifyLoad(false);
        setDayCloseToVerify(response.data);
      }).catch(err=>{
        setDayCloseToVerifyLoad(false);
        if (err!==undefined&&err.response!==undefined) {
          if(err.response.status === 401){
              setAuthForm(true);
          }else if(err.response.status === 404){
              console.log('Day_close_unverified_reports - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 500){
              console.log('Day_close_unverified_reports - ',err.response.status,':',err.response.statusText);
          }else{
              console.log('Day_close_unverified_reports - ',err.response.status,':',err.response.data);
          }
        }else{
          $.dialog({
            title: "Can't reach server!",
            content: 'Please check internet connection!',
          });
        }
      });
  }
  const [ credits, setCredits ] = useState();
  const [ getCreditLoad, setGetCreditLoad ] = useState(false);
  const getCredits=()=>{
    setGetCreditLoad(true);
    axios.get(`${link}api/bnr/get/credits/`,{
        headers:{
                Authorization: 'Bearer '+accessToken
              }
      }).then(response=>{
        setGetCreditLoad(false);
        setCredits(response.data);
      }).catch(err=>{
        setGetCreditLoad(false);
        if (err!==undefined&&err.response!==undefined) {
          if(err.response.status === 401){
              setAuthForm(true);
          }else if(err.response.status === 404){
              console.log('Get_credits - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 500){
              console.log('Get_credits - ',err.response.status,':',err.response.statusText);
          }else{
              console.log('Get_credits - ',err.response.status,':',err.response.data);
          }
        }else{
          $.dialog({
            title: "Can't reach server!",
            content: 'Please check internet connection!',
          });
        }
      });
  }
  const [ quickReport, setQuickReport ] = useState();
  const [ getQuickReportLoad, setGetQuickReporLoad ] = useState(false);
  const getQuickReport=()=>{
    if (quickReport===undefined) {
      setGetQuickReporLoad(true);
      axios.get(`${link}api/bnr/get/quick/report/history/`,{
        headers:{
                Authorization: 'Bearer '+accessToken
              }
      }).then(response=>{
        setGetQuickReporLoad(false);
        setQuickReport(response.data);
      }).catch(err=>{
        setGetQuickReporLoad(false);
        if (err!==undefined&&err.response!==undefined) {
          if(err.response.status === 401){
              setAuthForm(true);
          }else if(err.response.status === 404){
              console.log('Get_quick_report - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 500){
              console.log('Get_quick_report - ',err.response.status,':',err.response.statusText);
          }else{
              console.log('Get_quick_report - ',err.response.status,':',err.response.data);
          }
        }else{
          $.dialog({
            title: "Can't reach server!",
            content: 'Please check internet connection!',
          });
        }
      });
    }
  }
  const [ quickReportYear, setQuickReportYear ] = useState();
  const [ getQuickReportYearLoad, setGetQuickReporYearLoad ] = useState(false);
  const getQuickReportYear=()=>{
    if (quickReport===undefined) {
      setGetQuickReporYearLoad(true);
      axios.get(`${link}api/bnr/get/quick/report/year/`,{
        headers:{
                Authorization: 'Bearer '+accessToken
              }
      }).then(response=>{
        setGetQuickReporYearLoad(false);
        setQuickReportYear(response.data);
      }).catch(err=>{
        setGetQuickReporYearLoad(false);
        if (err!==undefined&&err.response!==undefined) {
          if(err.response.status === 401){
              setAuthForm(true);
          }else if(err.response.status === 404){
              console.log('Get_quick_report - ',err.response.status,':',err.response.statusText);
          }else if(err.response.status === 500){
              console.log('Get_quick_report - ',err.response.status,':',err.response.statusText);
          }else{
              console.log('Get_quick_report - ',err.response.status,':',err.response.data);
          }
        }else{
          $.dialog({
            title: "Can't reach server!",
            content: 'Please check internet connection!',
          });
        }
      });
    }
  }
  const [ quickReportToday, setQuickReportToday ] = useState();
  const [ getQuickReportTodayLoad, setGetQuickReporTodayLoad ] = useState(false);
  const getQuickReportToday=()=>{
    setGetQuickReporTodayLoad(true);
    axios.get(`${link}api/bnr/get/quick/report/`,{
      headers:{
              Authorization: 'Bearer '+accessToken
            }
    }).then(response=>{
      setGetQuickReporTodayLoad(false);
      setQuickReportToday(response.data);
    }).catch(err=>{
      setGetQuickReporTodayLoad(false);
      if (err!==undefined&&err.response!==undefined) {
        if(err.response.status === 401){
            setAuthForm(true);
        }else if(err.response.status === 404){
            console.log('Get_quick_report_today - ',err.response.status,':',err.response.statusText);
        }else if(err.response.status === 500){
            console.log('Get_quick_report_today - ',err.response.status,':',err.response.statusText);
        }else{
            console.log('Get_quick_report_today - ',err.response.status,':',err.response.data);
        }
      }else{
        $.dialog({
          title: "Can't reach server!",
          content: 'Please check internet connection!',
        });
      }
    });
  }
  const [ customerReport, setCustomerReport ] = useState();
  const [ getCustomerReportLoad, setGetCustomerReportLoad ] = useState(false);
  const getCustomerReport=(start_date=getDate(),end_date=getDate())=>{
    setGetCustomerReportLoad(true);
    axios.post(`${link}api/bnr/get/customer/report/`,{
      start_date:start_date,
      end_date:end_date,
    },{
      headers:{
              Authorization: 'Bearer '+accessToken
            }
    }).then(response=>{
      setGetCustomerReportLoad(false);
      setCustomerReport(response.data);
    }).catch(err=>{
      setGetCustomerReportLoad(false);
      if (err!==undefined&&err.response!==undefined) {
        if(err.response.status === 401){
            setAuthForm(true);
        }else if(err.response.status === 404){
            console.log('Get_customer_report - ',err.response.status,':',err.response.statusText);
        }else if(err.response.status === 500){
            console.log('Get_customer_report - ',err.response.status,':',err.response.statusText);
        }else{
            console.log('Get_customer_report - ',err.response.status,':',err.response.data);
        }
      }else{
        $.dialog({
          title: "Can't reach server!",
          content: 'Please check internet connection!',
        });
      }
    });
  }
  const [ serviceReport, setServiceReport ] = useState();
  const [ getServiceReportLoad, setGetServiceReportLoad ] = useState(false);
  const getServiceReport=(start_date=getDate(),end_date=getDate())=>{
    setGetServiceReportLoad(true);
    axios.post(`${link}api/bnr/get/service/report/`,{
      start_date:start_date,
      end_date:end_date,
    },{
      headers:{
              Authorization: 'Bearer '+accessToken
            }
    }).then(response=>{
      setGetServiceReportLoad(false);
      setServiceReport(response.data);
    }).catch(err=>{
      setGetServiceReportLoad(false);
      if (err!==undefined&&err.response!==undefined) {
        if(err.response.status === 401){
            setAuthForm(true);
        }else if(err.response.status === 404){
            console.log('Get_service_report - ',err.response.status,':',err.response.statusText);
        }else if(err.response.status === 500){
            console.log('Get_service_report - ',err.response.status,':',err.response.statusText);
        }else{
            console.log('Get_service_report - ',err.response.status,':',err.response.data);
        }
      }else{
        $.dialog({
          title: "Can't reach server!",
          content: 'Please check internet connection!',
        });
      }
    });
  }
  const [ bill, setBill ] = useState();
  const [ getBillLoad, setGetBillLoad ] = useState(false);
  const [ viewBillModal, setViewBillModal ] = useState(false);
  const getBill=(bpk)=>{
    setGetBillLoad(true);
      axios.post(`${link}api/bnr/get/bill/by/id/`,{
        bill_id : bpk,
          },{
          headers:{
                  Authorization: 'Bearer '+accessToken
                }
        }).then(response=>{
          setGetBillLoad(false);
          setBill(response.data);
        }).catch(err=>{
          setGetBillLoad(false);
          if (err!==undefined&&err.response!==undefined) {
            if(err.response.status === 401){
                setAuthForm(true);
            }else if(err.response.status === 404){
                console.log('Get_bill_in_credits - ',err.response.status,':',err.response.statusText);
            }else if(err.response.status === 500){
                console.log('Get_bill_in_credits - ',err.response.status,':',err.response.statusText);
            }else{
                console.log('Get_bill_in_credits - ',err.response.status,':',err.response.data);
            }
          }else{
            $.dialog({
              title: "Can't reach server!",
              content: 'Please check internet connection!',
            });
          }
        });
  }
  const [ staffReport, setStaffReport ] = useState();
  const [ staffReportLoad, setStaffReportLoad ] = useState();
  const getStaffReport=(start_date=getDate(),end_date=getDate())=>{
    setStaffReportLoad(true);
    axios.post(`${link}api/bnr/get/staff/report/`,{
      start_date:start_date,
      end_date:end_date,
    },{
      headers:{
              Authorization: 'Bearer '+accessToken
            }
    }).then(response=>{
      setStaffReportLoad(false);
      setStaffReport(response.data);
    }).catch(err=>{
      setStaffReportLoad(false);
      if (err!==undefined&&err.response!==undefined) {
        if(err.response.status === 401){
            setAuthForm(true);
        }else if(err.response.status === 404){
            console.log('Get_staff_report - ',err.response.status,':',err.response.statusText);
        }else if(err.response.status === 500){
            console.log('Get_staff_report - ',err.response.status,':',err.response.statusText);
        }else{
            console.log('Get_staff_report - ',err.response.status,':',err.response.data);
        }
      }else{
        $.dialog({
          title: "Can't reach server!",
          content: 'Please check internet connection!',
        });
      }
    });
  }
  const [ collectionReport, setCollectionReport ] = useState();
  const [ collectionReportLoad, setCollectionReportLoad ] = useState(false);
  const getCollectionReport=(start_date=getDate(),end_date=getDate())=>{
    setCollectionReportLoad(true);
    axios.post(`${link}api/bnr/get/collection/report/`,{
      start_date:start_date,
      end_date:end_date,
    },{
      headers:{
              Authorization: 'Bearer '+accessToken
            }
    }).then(response=>{
      setCollectionReportLoad(false);
      setCollectionReport(response.data);
    }).catch(err=>{
      setCollectionReportLoad(false);
      if (err!==undefined&&err.response!==undefined) {
        if(err.response.status === 401){
            setAuthForm(true);
        }else if(err.response.status === 404){
            console.log('Get_collection_report - ',err.response.status,':',err.response.statusText);
        }else if(err.response.status === 500){
            console.log('Get_collection_report - ',err.response.status,':',err.response.statusText);
        }else{
            console.log('Get_collection_report - ',err.response.status,':',err.response.data);
        }
      }else{
        $.dialog({
          title: "Can't reach server!",
          content: 'Please check internet connection!',
        });
      }
    });
  }
  const [ creditReport, setCreditReport ] = useState();
  const [ creditReportLoad, setCreditReportLoad ] = useState(false);
  const getCreditReport=(start_date=getDate(),end_date=getDate(),staff_id="")=>{
    setCreditReportLoad(true);
    axios.post(`${link}api/bnr/get/credit/report/`,{
      start_date:start_date,
      end_date:end_date,
      staff_id:staff_id,
    },{
      headers:{
              Authorization: 'Bearer '+accessToken
            }
    }).then(response=>{
      setCreditReportLoad(false);
      setCreditReport(response.data);
    }).catch(err=>{
      setCreditReportLoad(false);
      if (err!==undefined&&err.response!==undefined) {
        if(err.response.status === 401){
            setAuthForm(true);
        }else if(err.response.status === 404){
            console.log('Get_Credit_report - ',err.response.status,':',err.response.statusText);
        }else if(err.response.status === 500){
            console.log('Get_Credit_report - ',err.response.status,':',err.response.statusText);
        }else{
            console.log('Get_Credit_report - ',err.response.status,':',err.response.data);
        }
      }else{
        $.dialog({
          title: "Can't reach server!",
          content: 'Please check internet connection!',
        });
      }
    });
  }
  const [ detailReport, setDetailReport ] = useState();
  const [ detailReportLoad, setDetailReportLoad ] = useState(false);
  const getDetailReport=(start_date=getDate(),end_date=getDate(),staff_id="",service_id="")=>{
    setDetailReportLoad(true);
    axios.post(`${link}api/bnr/get/detailed/report/`,{
      start_date:start_date,
      end_date:end_date,
      staff_id:staff_id,
      service_id:service_id,
    },{
      headers:{
              Authorization: 'Bearer '+accessToken
            }
    }).then(response=>{
      setDetailReportLoad(false);
      setDetailReport(response.data);
    }).catch(err=>{
      setDetailReportLoad(false);
      if (err!==undefined&&err.response!==undefined) {
        if(err.response.status === 401){
            setAuthForm(true);
        }else if(err.response.status === 404){
            console.log('Get_Detailed_report - ',err.response.status,':',err.response.statusText);
        }else if(err.response.status === 500){
            console.log('Get_Detailed_report - ',err.response.status,':',err.response.statusText);
        }else{
            console.log('Get_Detailed_report - ',err.response.status,':',err.response.data);
        }
      }else{
        $.dialog({
          title: "Can't reach server!",
          content: 'Please check internet connection!',
        });
      }
    });
  }
  const [ cancelReport, setCancelReport ] = useState();
  const [ cancelReportLoad, setCancelReportLoad ] = useState(false);
  const getCancelReport=(start_date=getDate(),end_date=getDate(),staff_id="")=>{
    setCancelReportLoad(true);
    axios.post(`${link}api/bnr/get/token/cancel/report/`,{
      start_date:start_date,
      end_date:end_date,
      staff_id:staff_id,
    },{
      headers:{
              Authorization: 'Bearer '+accessToken
            }
    }).then(response=>{
      setCancelReportLoad(false);
      setCancelReport(response.data);
    }).catch(err=>{
      setCancelReportLoad(false);
      if (err!==undefined&&err.response!==undefined) {
        if(err.response.status === 401){
            setAuthForm(true);
        }else if(err.response.status === 404){
            console.log('Get_token_cancel_report - ',err.response.status,':',err.response.statusText);
        }else if(err.response.status === 500){
            console.log('Get_token_cancel_report - ',err.response.status,':',err.response.statusText);
        }else{
            console.log('Get_token_cancel_report - ',err.response.status,':',err.response.data);
        }
      }else{
        $.dialog({
          title: "Can't reach server!",
          content: 'Please check internet connection!',
        });
      }
    });
  }
  const [ home, setHome ] = useState();
  const [ homeLoad, setHomeLoad ] = useState(false);
  const getHomePage=()=>{
    setHomeLoad(true);
    axios.get(`${link}api/hp/get/home/page/`,{
      headers:{
              Authorization: 'Bearer '+accessToken
            }
    }).then(response=>{
      setHomeLoad(false);
      setHome(response.data);
    }).catch(err=>{
      setHomeLoad(false);
      if (err!==undefined&&err.response!==undefined) {
        if(err.response.status === 401){
            setAuthForm(true);
        }else if(err.response.status === 404){
            console.log('Get_home_page - ',err.response.status,':',err.response.statusText);
        }else if(err.response.status === 500){
            console.log('Get_home_page - ',err.response.status,':',err.response.statusText);
        }else{
            console.log('Get_home_page - ',err.response.status,':',err.response.data);
        }
      }else{
        $.dialog({
          title: "Can't reach server!",
          content: 'Please check internet connection!',
        });
      }
    });
  }
  const [ specialBills, setSpecialBills ] = useState();
  const [ specialBillsLoad, setSpecialBillsLoad ] = useState(false);
  const [ billDate, setBillDate ] = useState(getDate());
  const getSpecialBills=()=>{
    setSpecialBillsLoad(true);
    axios.post(`${link}api/ab/get/bills/`,{
      date:billDate,
    },{
      headers:{
              Authorization: 'Bearer '+accessToken
            }
    }).then(response=>{
      setSpecialBillsLoad(false);
      setSpecialBills(response.data);
    }).catch(err=>{
      setSpecialBillsLoad(false);
      if (err!==undefined&&err.response!==undefined) {
        if(err.response.status === 401){
            setAuthForm(true);
        }else if(err.response.status === 404){
            console.log('Get_additional_bills - ',err.response.status,':',err.response.statusText);
        }else if(err.response.status === 500){
            console.log('Get_additional_bills - ',err.response.status,':',err.response.statusText);
        }else{
            console.log('Get_additional_bills - ',err.response.status,':',err.response.data);
        }
      }else{
        $.dialog({
          title: "Can't reach server!",
          content: 'Please check internet connection!',
        });
      }
    });
  }
  useEffect(()=>{
    if (billDate!==undefined&&user!==undefined) {
      getSpecialBills();
    }
  },[billDate]);
  const [ getLoad, setGetLoad ] = useState(false);
  const [ staffTokenReport, setStaffTokenReport ] = useState();
  const [ detailTreport, setDetailTreport ] = useState();
  const [ incompleteTokenReport, setIncompleteTokenReport ] = useState();
  const [ payWallet, setPayWallet ] = useState();
  const [ payWalletReport, setPayWalletReport ] = useState();
  const [ walletCus, setWalletCus ] = useState();
  const [ expenseReport, setExpenseReport ] = useState();
  const [ reOpenRepo, setReOpenRepo ] = useState();
  const [ attendence, setAttendence ] = useState();
  const [ smDiStaff, setSmDiStaffs ] = useState();
  return (
    <AppContext.Provider value={{ authForm, setAuthForm, link, getCall, postCall, getLoad, setGetLoad, accessToken, setAccessToken, page, setPage, user, setUser,
    getPermissions, getPermiLoad, permissions, staffs, setStaffs, getStaffs, getStaffsLoad, staffTypes, setStaffTypes, closeDayState,
    getStaffTypes, getStaffTypeLoad, services, setServices, getServices, getServiceLoad, customers, setCustomers, getCustomersLoad,
    getCustomers, getEnquiryLoad, getEnquiry, enquiry, setEnquiry, setGetEnquiryLoad, getTokens, tokens, setTokens, getTokenLoad,
    getTokenServices, tokenServices, setTokenServices, tokenServiceLoad, getTokenCustomers, tokenCustomers, setTokenCustomers,
    getTokenCustomerLoad, payMethods, setPayMethods, getPaymentMethods, getPayMethodLoad, getPayModes, payModes, dayClose,
    setDayClose, collections, getCollectionLoad, getCollections, getNotifications, notifications, getNotifiLoad, setNotification,
    setGetNotifiLoad, closeDayReport, setCloseDayReport, getSpecialTokens, specialTokens, setSpecialTokens, specialTokenLoad, smDiStaff, setSmDiStaffs,
    date, setDate, account, setAccount, getDayCloseToVerify, dayCloseToVerify, setDayCloseToVerify, dayCloseToVerifyLoad, attendence, setAttendence,
    getCredits, credits, setCredits, getCreditLoad, getQuickReport, getQuickReportLoad, quickReport, getCustomerReport, salaryStaff, setSalaryStaff,
    customerReport, getCustomerReportLoad, getDate, serviceReport, getServiceReportLoad, getServiceReport, getBill, reOpenRepo, setReOpenRepo, 
    setViewBillModal, getStaffReport, staffReport, staffReportLoad, getCollectionReport, collectionReport, expense, setExpense, expenseReport, setExpenseReport,
    collectionReportLoad, getCreditReport, creditReport, creditReportLoad, getDetailReport, detailReport, detailReportLoad, pettyExpense, setPettyExpense,
    getCancelReport, cancelReport, cancelReportLoad, getHomePage, home, homeLoad, getSpecialBills, specialBills, expenseParti, setExpenseParti,
    specialBillsLoad, setSpecialBills, getQuickReportToday, quickReportToday, getQuickReportTodayLoad, billDate, setBillDate, getWorks, load, setLoad,
    works, getWorkLoad, setGetWorkLoad, setWorks, getSubmitted, getSubitLoad, submittedTokens, setSubmittedTokens, stateSettle, walletCus, setWalletCus,
    stateSettleAll, setStateSettle, setStateSettleAll, quickReportYear, getQuickReportYear, getQuickReportYearLoad, staffTokenReport, setStaffTokenReport,
    detailTreport, setDetailTreport, incompleteTokenReport, setIncompleteTokenReport, payWallet, setPayWallet, payWalletReport, setPayWalletReport, salaryCalcRepo,
    setSalaryCalcRepo, salaryPayRepo, setSalaryPayRepo, }} >
    <Modal size="xl" show={viewBillModal} backdrop="static" onHide={()=>{setViewBillModal(false)}}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {
              getBillLoad?(
              <span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
              ):(
                bill!==undefined?(
                <>
                  <Row>
                    <Col xs={12} sm={12} md={6} className="mb-3" >
                      <span className="f16 tp2 d-grid ">Bill details</span>
                      <span className="f14 ts2 d-grid">Token number : {bill.token_no}</span>
                      <span className="f14 ts2 d-grid">Invoice number : {bill.bill_no}</span>
                      <span className="f14 ts2 d-grid">Date : {bill.created_date}, {bill.created_time}</span>
                      <span className="f14 ts2 d-grid">Created by {bill.created_by}</span>
                    </Col>
                    <Col xs={12} sm={12} md={6} className="mb-3 text-end">
                      <span className="f16 tp2 d-grid ">Customer details</span>
                      <span className="f14 ts2 d-grid">{bill.customer_name} / {bill.customer_phone}</span>
                      <span className="f14 ts2 d-grid">{bill.customer_address}</span>
                    </Col>
                  </Row>
                  <Table bordered className="tbl-bg-blue" >
                    <thead>
                        <tr  >
                          <th  ><span className="tp0" >No</span></th>
                          <th ><span className="tp0" >Services</span></th>
                          <th className="text-end" ><span className="tp0" >TC</span></th>
                          <th className="text-end" ><span className="tp0" >SC</span></th>
                          <th className="text-end" ><span className="tp0" >Qty</span></th>
                          <th className="text-end" ><span className="tp0" >Total</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                          bill.bill_services!==undefined?(
                            Object.keys(bill.bill_services).length>0?(
                              Object.keys(bill.bill_services).map((t_ser,ts_i)=>(
                                <tr key={`bill_s_${ts_i}`}>
                                  <td><span className="f16 mb-1" >{ts_i+1}</span></td>
                                  <td><span className="f18" >{bill.bill_services[t_ser].service_name}</span></td>
                                  <td className="text-end" ><span className="f18" >{bill.bill_services[t_ser].govt_charge}</span></td>
                                  <td className="text-end" ><span className="f18" >{bill.bill_services[t_ser].akshaya_charge}</span></td>
                                  <td className="text-end" ><span className="f18" >{bill.bill_services[t_ser].quantity}</span></td>
                                  <td className="text-end" ><span className="f18" >{bill.bill_services[t_ser].amount}</span></td>
                                </tr>
                              ))
                            ):''
                          ):''
                        }
                        {
                          bill.bill_payments!==undefined?(
                            Object.keys(bill.bill_payments).length>0?(
                              Object.keys(bill.bill_payments).map((r_pay,r_pay_i)=>(
                                <tr key={`r_pay_${r_pay_i}`}>
                                  <td colSpan={3} ></td>
                                  <td colSpan={2} className="text-end" ><span className="tp1 f16" >{bill.bill_payments[r_pay].name}</span></td>
                                  <td className="text-end" >{bill.bill_payments[r_pay].amount}</td>
                                </tr>
                              ))
                            ):''
                          ):''
                        }
                        <tr>
                          <td colSpan={3} ></td>
                          <td colSpan={2} className="text-end bgy" ><span className="f18" ><b>Total</b></span></td>
                          <td className="text-end bgy f16" ><b>{bill!==undefined?bill.bill_total:'--'}</b></td>
                        </tr>
                    </tbody>
                </Table>
              </>
                ):(
                <span className="p-2" >Not found!</span>
                )

              )
            }
          </Modal.Body>
        </Modal>
    {
      user===undefined?(
        <Authentication />
        ):(
        <Dashboard />
        )
    }
    </AppContext.Provider>
  );
}
export default App;
