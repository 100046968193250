import React, {useContext, useEffect, useState} from 'react';
import {Container, Row, Col, Button, Form, Modal, Spinner, Table } from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import { AppContext } from '../App';


export const SpecialBills=()=>{
	const { accessToken, user, account, setAuthForm, link, tokenServices, tokenCustomers, setTokenCustomers, getSpecialBills,
	specialBills, specialBillsLoad, setSpecialBills, billDate, setBillDate } = useContext(AppContext);

	const [ selectCount, setSelectCount ] = useState(-1);
	const [ showAutoSuggestion, setShowAutoSuggestion ] = useState(false);
	const [ selectedItem, setSelectedItem ] = useState();
	const [ nameReqErr, setNameReqErr ] = useState(false);
	const [ cName, setCname ] = useState();
	const [ cPhone, setCphone ] = useState();
	const [ cAddress, setCaddress ] = useState();
	useEffect(()=>{
		if(selectedItem!==undefined){
			$('#cus_phone').val(selectedItem.profile.phone);
			setCphone(prev=>(selectedItem.profile.phone));
			$('#cus_name').val(selectedItem.profile.name);
			setCname(prev=>(selectedItem.profile.name));
			$('#cus_address').val(selectedItem.profile.address);
			setCaddress(prev=>(selectedItem.profile.address));
		}else{
			$('#cus_name').val('');
			$('#cus_address').val('');
		}
	},[selectedItem]);
	const [ searchResult, setSearchResult ] = useState({});
	const searchCustomer=(c_num)=>{
		setSearchResult(prev=>{});
		if (c_num!=="") {
			if (tokenCustomers!==undefined){
				setSelectedItem();
				if (Object.keys(tokenCustomers).length>0){
					setSelectCount(-1);
					setShowAutoSuggestion(true);
					let count=0;
					Object.keys(tokenCustomers).map(customer=>{
						if (count<20) {
							if(tokenCustomers[customer].phone.match(c_num)){
								setSearchResult(prev=>({...prev,[customer]:tokenCustomers[customer]}));
								count+=1;
							}
						}
					});
				}
			}
		}else{
			setSearchResult(prev=>{});
		}
	}
	$('#cus_phone').off().on("keydown", function (e) {
	    var code = e.which || e.keyCode;
	    if (code === 40) {
	    	e.preventDefault();
	    	if (selectCount<Object.keys(searchResult).length-1) {
	    		setSelectCount(prev=>prev+1);
	    	}else{
	    		setSelectCount(0);
	    	}
	    }else if(code === 38){
	    	e.preventDefault();
	    	if (selectCount>0) {
	    		setSelectCount(prev=>prev-1);
	    	}else{
	    		setSelectCount(Object.keys(searchResult).length-1);
	    	}
	    }else if(code === 13){
	    	e.preventDefault();
	    	setShowAutoSuggestion(false);
	    	if(selectCount>=0){
	    		setSelectedItem({id: Object.keys(searchResult)[selectCount], profile:searchResult[Object.keys(searchResult)[selectCount]]});
	    	}
	    }
	});
	const [ specialBillModal, setSpecialBillModal ] = useState(false);
	useEffect(()=>{
		if (!specialBillModal) {
			setSelectedItem();
			setCname();
			setCphone();
			setCaddress();
			setLiveToken({});
			setSpecialBillState('add_customer');
			setPrintBill();
		}
	},[specialBillModal]);
	const [ specialBillState, setSpecialBillState ] = useState('add_customer');
	const [ serviceSearchResult, setServiceSearchResult ] = useState({});
	const [ serviceSelectCount, setServiceSelectCount ] = useState(-1);
	const [ selectedServiceItem, setSelectedServiceItem ] = useState();
	useEffect(()=>{
		if(selectedServiceItem!==undefined){
			$('#serv_search').val(selectedServiceItem.service.name);
			$('#special_price').focus();
		}
	},[selectedServiceItem]);
	$('#special_price').off().on("keydown", function (e) {
		let code = e.which || e.keyCode;
		if(code === 13){
			e.preventDefault();
			$('#service_qty').focus();
		}
	});
	$('#service_qty').off().on("keydown", function (e) {
		let code = e.which || e.keyCode;
		if(code === 13){
			e.preventDefault();
			$('#add_item').focus();
		}
	});
	const [ liveToken, setLiveToken ] = useState({});
	const [ showServiceAutoSuggestion, setShowServiceAutoSuggestion ] = useState(false);	
	const serviceSearch=(search_key)=>{
		setServiceSearchResult(prev=>({}));
		if (search_key!=="") {
			if (tokenServices!==undefined){
				setSelectedServiceItem();
				if (Object.keys(tokenServices).length>0){
					setServiceSelectCount(-1);
					setShowServiceAutoSuggestion(true);
					let count=0;
					Object.keys(tokenServices).map(service=>{
						if (count<20) {
							if(tokenServices[service].name.toLowerCase().includes(search_key.toLowerCase())){
								setServiceSearchResult(prev=>({...prev,[service]:tokenServices[service]}));
								count+=1;
							}
						}
					});
				}
			}
		}else{
			setServiceSearchResult(prev=>({}));
		}
	}
	$('#serv_search').off().on("keydown", function (e) {
	    var code = e.which || e.keyCode;
	    if (code === 40) {
	    	e.preventDefault();
	    	if (serviceSelectCount<Object.keys(serviceSearchResult).length-1) {
	    		setServiceSelectCount(prev=>prev+1);
	    	}else{
	    		setServiceSelectCount(0);
	    	}
	    }else if(code === 38){
	    	e.preventDefault();
	    	if (serviceSelectCount>0) {
	    		setServiceSelectCount(prev=>prev-1);
	    	}else{
	    		setServiceSelectCount(Object.keys(serviceSearchResult).length-1);
	    	}
	    }else if(code === 13){
	    	e.preventDefault();
	    	setShowServiceAutoSuggestion(false);
	    	if(serviceSelectCount>=0){
				$('#special_price').val(parseInt(serviceSearchResult[Object.keys(serviceSearchResult)[serviceSelectCount]].gov_service_charge)+parseInt(serviceSearchResult[Object.keys(serviceSearchResult)[serviceSelectCount]].akshaya_charge));
	    		setSelectedServiceItem({id: Object.keys(serviceSearchResult)[serviceSelectCount], service:serviceSearchResult[Object.keys(serviceSearchResult)[serviceSelectCount]]});
	    	}else{
	    		$('#special_price').focus();
	    	}
	    }
	});
	const serviceAdd=(e)=>{
		e.preventDefault();
		let service = e.target.service.value;
		let price = e.target.price.value;
		let qty = e.target.qty.value;
		if (service!==""&&price!==""&&qty>0) {
			if (liveToken!==undefined&&Object.keys(liveToken).indexOf(service)===-1) {
				setLiveToken(prev=>({...prev,[service]:{
					price:price,
					qty:qty,
				}
				}));
				$('#serv_search').focus();
			}else{
				$(`#item_i_${Object.keys(liveToken).indexOf(service)}`).addClass('error-message',500);
				setTimeout(()=>{
					$(`#item_i_${Object.keys(liveToken).indexOf(service)}`).removeClass('error-message',500);
				},500);
			}
		}
		
	}
	const [ total, setTotal] = useState(parseInt(0));
	useEffect(()=>{
		if (liveToken!==undefined) {
			if (Object.keys(liveToken).length>0) {
				setTotal(prev=>(0));
				Object.keys(liveToken).map(tser=>{
					setTotal(prev =>(prev + ( parseInt(liveToken[tser].price)*parseInt(liveToken[tser].qty) ) ));
				})
			}else{
				setTotal(0);
			}
		}
	},[liveToken]);
	const [ addBillLoad, setAddBillLoad ] = useState(false);
	const deleteService=(ser)=>{
		setLiveToken(prev=>{
			const x={...prev};
			delete x[ser];
			return x;
		});
	}
	const [ postBillLoad, setPostBillLoad ] = useState(false);
	const [ printBill, setPrintBill ] = useState();
	const postBill=(state)=>{
		
		if(Object.keys(liveToken).length>0){
			setPostBillLoad(true);
			axios.post(`${link}api/ab/add/bill/`,{
			c_id:selectedItem!==undefined?selectedItem.id.substring(1):'',
			date:billDate,
			c_name:cName!==undefined?cName:'',
			c_phone:cPhone!==undefined?cPhone:'',
			c_address:cAddress!==undefined?cAddress:'',
			services:liveToken,
			},{
			headers:{
					Authorization: 'Bearer '+accessToken
					}
			}).then(response=>{
			setPostBillLoad(false);
			setSpecialBills(response.data.bills);	      
			if (state){
				setSpecialBillState('print');
				setPrintBill(response.data.print);
			}else{
				setSpecialBillModal(false);
			}
			}).catch(err=>{
			setPostBillLoad(false);
			if (err!==undefined&&err.response!==undefined) {
				if(err.response.status === 401){
					setAuthForm(true);
				}else if(err.response.status === 404){
					console.log('Add_special_bill - ',err.response.status,':',err.response.statusText);
				}else if(err.response.status === 500){
					console.log('Add_special_bill - ',err.response.status,':',err.response.statusText);
				}else{
					console.log('Add_special_bill - ',err.response.status,':',err.response.data);
				}
			}
			});
		}
	}
	const printSpecialBill=()=>{
		$('#root').hide();
		$('#printable').html($('#special_bill_print').html());
		setSpecialBillModal(false);
		setTimeout(()=>{window.print()},200);
		setTimeout(()=>{
			$('#root').show();
			$('#printable').html('');
		},400);
	}
	const attchCustomer=(e)=>{
		e.preventDefault();
		let name=e.target.name.value;
		if (name!=="") {
			setSpecialBillState('add_services');
		}else{
			setNameReqErr(true);
			setTimeout(()=>{
				setNameReqErr(false);	
			},15000);
		}
	}
	return(
		<Container className="my-3" >
    		<Col  xs={12} sm={12} md={12} lg={12} className='bdr2 br-3' >
				<Col xs="12" sm="12" md="12" lg="12" className="staff-type-header bgs1 d-grid p-2 bdrb2" >
					<div className="special-token-taskbar">
						<span>{specialBillsLoad?(<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>):'Special Bills.'}</span>
						<div className="px-1">
							<Form.Control type="date" onChange={(e)=>{setBillDate(e.target.value)}} defaultValue={billDate} className="br-2 shadow-none"  />
						</div>
					</div>
					{
						user!==undefined?(
							user.type==='admin'?(
								<Button className="btn-t1" onClick={()=>{setSpecialBillModal(true)}}  ><i className="bi bi-plus-circle"></i> Add new</Button>
							):(
							user.permission!==undefined?(
								user.permission.enquiry!==undefined&&user.permission.enquiry.A?(
									<Button className="btn-t1" onClick={()=>{setSpecialBillModal(true)}}  ><i className="bi bi-plus-circle"></i> Add new</Button>
								):''
							):''
							)
						):''
					}
				</Col>
				<Col>
					<div className="p-2" >
		    		{
		    			specialBills!==undefined?(
		    				Object.keys(specialBills).length>0?(
		    					Object.keys(specialBills).map((bill,bill_i)=>(
		    							<Row className="mx-0" key={`bill_${bill_i}`} >
							    			<Col xs={12} sm={12} md={12} className="new-token-item bdr1 br-3 mb-2 px-0">
							    				<div className="item-tid bdr2-r">
									          		<span className="tp3 f16" >Invoice : {bill.substring(1)}</span>
									          	</div>
									          	<div className="item-tservice bdr2-r">
									          		<span className="tp1 f18" >{
														Object.keys(specialBills[bill].bill_services).length>0?
														specialBills[bill].bill_services[Object.keys(specialBills[bill].bill_services)[0]].service_name
														:'No services added!'
														}</span>
									          		<span className="ts2 f16" ></span>
									          		<span className="ts2 f14" >{specialBills[bill].customer_name} / {specialBills[bill].customer_phone} / {specialBills[bill].created_date} / {specialBills[bill].created_time} / [ Created by {specialBills[bill].created_by} ]</span>
									          	</div>
											    <div className="item-toption">
											    <Button onClick={()=>{
											    	setPrintBill(specialBills[bill]);
											    	setSpecialBillModal(true);
											    	setSpecialBillState('print');
											    }} className='btn-t1'><i className="bi bi-printer"></i> Print</Button>
											    </div>
							    			</Col>
						    			</Row>
		    						))
		    					):'Nothing new!'
		    				):'Permission denied'
		    		}
		    		</div>
				</Col>
				
			</Col>
			<Modal show={specialBillModal} backdrop="static" size="xl" onHide={()=>{setSpecialBillModal(false)}}>
		      	<Modal.Header closeButton></Modal.Header>
		      	{
		      		specialBillState!==undefined?(
		      			specialBillState==='add_customer'?(
		      				<Modal.Body>
		      					<Form onSubmit={attchCustomer} >
					        		<Form.Group className="mb-3">
					        			<Form.Label>Contact number </Form.Label>
					        			<div className="serach-customer d-grid pos-rel">
					        				<Form.Control onBlur={(e)=>{
					        					setTimeout(()=>{
					        						setShowAutoSuggestion(false);
					        					},200);
					        				}} autoComplete="off" defaultValue={cPhone} onWheel={(e) => {e.target.blur()} } id="cus_phone" onChange={(e)=>{
					        					searchCustomer(e.target.value);
					        					setCphone(prev=>(e.target.value));
					        				}} type="number" name="phone" className="shadow-none br-2" placeholder="Contact number" />
					        				
				        					{
			        						searchResult!==undefined&&showAutoSuggestion?(
			        							Object.keys(searchResult).length > 0 ?(
			        								<div className="customer-search-results bdr1">
			        								{
			        								Object.keys(searchResult).map((cus,cus_index)=>(
			        									<span onClick={()=>{setSelectedItem({ id : cus, profile : searchResult[cus] }) }} className={cus===Object.keys(searchResult)[selectCount]?'d-grid bdrb1 active':'d-grid bdrb1'}  key={`cust_${cus_index}`}>{searchResult[cus].name} - {searchResult[cus].phone} </span>
			        									))
			        								}
			        								</div>
			        								):''
			        								
			        							):''
				        					}

					        			</div>
					        		</Form.Group>
					        		<Form.Group className="mb-3">
					        			<Form.Label>Name {nameReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
					        			<Form.Control onChange={(e)=>{setCname(prev=>(e.target.value))}} defaultValue={cName} type="text" id="cus_name" name="name" className="shadow-none br-2" placeholder="Customer Name" />
					        		</Form.Group>
					        		<Form.Group className="mb-3">
					        			<Form.Label>Address</Form.Label>
					        			<Form.Control onChange={(e)=>{setCaddress(prev=>(e.target.value))}} defaultValue={cAddress} as="textarea" id="cus_address" className="shadow-none br-2" name="address" />
					        		</Form.Group>
					        		<div className="d-grid">
			        					<Button type="submit" className="btn-t1">Next <i className="bi bi-arrow-right-circle"></i></Button>
					        		</div>
					        	</Form>
		      				</Modal.Body>
		      			):specialBillState==='add_services'?(
		      				<Modal.Body>
		      					<Row className="mb-2" >
					        		<Col className="service-search-wrapper mx-2 p-2" >
					        			<Form className="service-add-form-special" onSubmit={serviceAdd} >
					        				<div className="pos-rel">
					        					<Form.Control type="text" name="service" id="serv_search" onChange={(e)=>{serviceSearch(e.target.value)}} autoComplete="off" placeholder="Add new service" />
					        					{
				        						serviceSearchResult!==undefined&&showServiceAutoSuggestion?(
				        							Object.keys(serviceSearchResult).length > 0 ?(
				        								<div className="customer-search-results bdr1">
				        								{
				        								Object.keys(serviceSearchResult).map((serv,serv_i)=>(
				        									<span onClick={()=>{
				        										setSelectedServiceItem({ id:serv, service:serviceSearchResult[serv] });
				        										$('#special_price').val(parseInt(serviceSearchResult[serv].gov_service_charge)+parseInt(serviceSearchResult[serv].akshaya_charge));
				        										setShowServiceAutoSuggestion(false);
				        									}} className={serv===Object.keys(serviceSearchResult)[serviceSelectCount]?'d-grid bdrb1 active':'d-grid bdrb1'}  key={`servi_${serv_i}`}>{serviceSearchResult[serv].name}</span>
				        									))
				        								}
				        								</div>
				        								):''
				        								
				        							):''
					        					}
					        				</div>
					        				<Form.Control type="number" name="price"  id="special_price"  min="1" placeholder="Price" />
					        				<Form.Control type="number" name="qty"  id="service_qty" defaultValue="1" min="1" placeholder="Qty" />
					        				{
					        					addBillLoad?(
								    				<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /></span></Button>
					        						):(
					        						<Button className="btn-t1" id="add_item" type="submit">Add Item</Button>
					        						)
					        				}
					        			</Form>
					        		</Col>
						        </Row>
						        <Row>
						        	<Col className="p-2" >
						        		<Table bordered className="tbl-bill tbl-bg-blue" >
									      <thead>
									        <tr>
									          <th><span className="tp2" >Index</span></th>
									          <th><span className="tp2" >Services</span></th>
									          <th className="text-end" ><span className="tp2" >Price</span></th>
									          <th className="text-end" ><span className="tp2" >Qty</span></th>
									          <th className="text-end" ><span className="tp2" >Total</span></th>
									        </tr>
									      </thead>
									      <tbody>
									      	{
									      	liveToken!==undefined?(
									      		Object.keys(liveToken).length>0?(
									      			Object.keys(liveToken).map((t_ser,ts_i)=>(
									      				<tr key={`bill_s_${ts_i}`} id={`item_i_${ts_i}`} >
												          <td><span className="tp1 f16 mb-1" >{ts_i+1}</span></td>
												          <td><span className="f16 tp2" >{t_ser}</span></td>
												          <td className="text-end" ><span className="tp1 f18" >{liveToken[t_ser].price}</span></td>
												          <td className="text-end pos-rel td-qty" ><span className="tp1 f18" >{liveToken[t_ser].qty}</span></td>
												          <td className="text-end pos-rel" >
												          	<span onClick={()=>{deleteService(t_ser)}} className="pos-abs remove-service" ><i className="bi bi-x-lg"></i></span>
												          	<span className="tp1 f18" >{parseInt(liveToken[t_ser].price)*parseInt(liveToken[t_ser].qty)}</span>
												          </td>
												        </tr>
									      				))
									      			):''
									      		):''
									      	}
									        
									        <tr>
									        	<td colSpan={2} ></td>
									        	<td colSpan={2} className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
									        	<td className="text-end bgy" >{total!==undefined?total:''}</td>
									        </tr>
									        
									      </tbody>
									    </Table>
						        	</Col>
						        </Row>
		      				</Modal.Body>
		      			):specialBillState==='print'?(
		      				<Modal.Body>
		      					<Row id="special_bill_print">
						        	<Col  className="mx-0 px-0 bdr0" >
						        		{
							    			account!==undefined?(
								        		<div className="print-token-header bdr0-b" >
								        			{
									    				account.name!==""||account.address!==""||account.phone_numbers?(
									    					<div className="print-token-address">
											    				<div className="px-3 py-1">
											    					<span className="f20 tp0 d-grid"><b>{account.name}</b></span>
											    					<span className="f16 tp0 d-grid" >{account.address}</span>
											    					<span className="f16 tp0 d-grid" >{account.phone_numbers}</span>
											    				</div>
											    			</div>
									    				):''
									    			}
								        			{
								        				account.logo!==""?(
											    			<div className="print-token-header-logo d-grid" >
											    				<img className="px-3 py-1" src={account.logo} />
											    			</div>
									    				):''
									    			}
									    												    			
									    		</div>
								    		):''
							    		}
							    		{
							    			printBill!==undefined?(
							    				<div className="px-3 py-1 invoice-cus-details" >
							    					<div>
							    						<span className="f16 tp0 d-grid ">Customer details</span>
							    						<span className="f14 tp0 d-grid">{printBill.customer_name} / {printBill.customer_phone}</span>
							    						<span className="f14 tp0 d-grid">{printBill.customer_address}</span>
							    					</div>
							    					<div className="text-end">							    						
							    						<span className="f16 tp0 d-grid ">Invoice details</span>
							    						<span className="f14 tp0 d-grid">{printBill.bill_no} : Invoice</span>
							    						<span className="f14 tp0 d-grid">{printBill.date} / {printBill.created_time} : Date</span>
							    					</div>
							    				</div>
							    			):''
							    		}
						        		<Table className="tbl-bill-print" >
										    <thead>
										        <tr className="bdr0-b bdr0-t" >
										          <th className="bdr0-r" ><span className="tp0" >No</span></th>
										          <th className="bdr0-r" ><span className="tp0" >Services</span></th>
										          <th className="text-end bdr0-r" ><span className="tp0" >Price</span></th>
										          <th className="text-end bdr0-r" ><span className="tp0" >Qty</span></th>
										          <th className="text-end" ><span className="tp0" >Total</span></th>
										        </tr>
										    </thead>
										    <tbody>
										      	{
										      		printBill!==undefined?(
											      		Object.keys(printBill.bill_services).length>0?(
											      			Object.keys(printBill.bill_services).map((t_ser,ts_i)=>(
											      				<tr key={`bill_s_${ts_i}`} className="bdr0-b" >
														          <td className="bdr0-r"><span className="tp0 f16 mb-1" >{ts_i+1}</span></td>
														          <td className="bdr0-r"><span className="tp0 f18" >{printBill.bill_services[t_ser].service_name}</span></td>
														          <td className="text-end bdr0-r" ><span className="tp0 f18" >{printBill.bill_services[t_ser].price}</span></td>
														          <td className="text-end pos-rel td-qty bdr0-r" >
															          <span className="tp0 f18" >{printBill.bill_services[t_ser].quantity}</span>									          	
														          </td>
														          <td className="text-end pos-rel" >
														          	<span className="tp0 f18" >{printBill.bill_services[t_ser].amount}</span>
														          </td>
														        </tr>
										      				))
										      			):''
										      		):''
										      	}
										        
									        	<tr className="bdr0-b" >
									        		<td colSpan={2} ></td>
									        		<td colSpan={2} className="text-end bdr0-r" ><span className="tp0 f18" ><b>Total</b></span></td>
									        		<td className="text-end tp0" ><b>{printBill!==undefined?printBill.bill_total:'--'}</b></td>
									        	</tr>
										    </tbody>
									   	</Table>
									   	<div className="d-grid bill-signature px-3 pt-2 pb-5">
									   		<span className="f16 tp0" >System generated invoice. by {user!==undefined?user.profile.first_name:''}</span>
									   	</div>
						        	</Col>
						        </Row>
		      				</Modal.Body>
		      			):''
		      		):''
		      	}

  				{
  					specialBillState!==undefined?(
  						specialBillState==='add_customer'?(
	  						<Modal.Footer>
	  						</Modal.Footer>
  						):specialBillState==='add_services'?(
  							postBillLoad?(
  								<Modal.Footer>
              						<Button className="shadow-none br-2" disabled><Spinner animation="border" size="sm" /> Please wait...</Button>
              					</Modal.Footer>
  							):(
	  							<Modal.Footer>
				        			<Button onClick={()=>{setSpecialBillState('add_customer')}} className="btn-t1"><i className="bi bi-arrow-left-circle"></i> Back</Button>
				        			<Button onClick={()=>{postBill(true)}} className="btn-t1"><i className="bi bi-printer"></i> Print & Settle</Button>
				        			<Button onClick={()=>{postBill(false)}} className="btn-t1"><i className="bi bi-check-circle"></i> Settle</Button>			        			
	  							</Modal.Footer>
  							)
  						):specialBillState==='print'?(
  							<Modal.Footer>
  								<Button onClick={()=>{printSpecialBill()}} className="btn-t1" ><i className="bi bi-printer"></i> Print</Button>
  							</Modal.Footer>
  						):''
  					):''
  				}  				
		      </Modal>
		</Container>
		);
}