import React, {useState, useContext, useEffect} from 'react';
import { Alert, Spinner, Button, Modal, Form, Row, Col, Table, Pagination, InputGroup, Container } from 'react-bootstrap';
import $ from 'jquery';
import 'jquery-confirm';


import { AppContext } from '../App';


export const Expense=()=>{
	const { setPage, postCall, getLoad, setGetLoad, load, getDate, expense, setExpense, expenseParti, payModes } = useContext(AppContext);
	const [ expDate, setExpDate ] = useState(getDate());
	const [ updateModal, setUpdateModal ] = useState(false);
	const [ updateLoad, setUpdateLoad ] = useState(false);
	const addExpense=(e)=>{
		e.preventDefault();
		let parti = e.target.partic.value;
		let amt = e.target.amt.value;
		let remark = e.target.remark.value;
		let pay = e.target.paym.value;
		if (pay==="") {
			document.querySelector('#paym_err').classList.add('bdr-red');
			setTimeout(()=>{document.querySelector('#paym_err').classList.remove('bdr-red');},5000);
		}else{document.querySelector('#paym_err').classList.remove('bdr-red');}
		if (parti==="") {
			document.querySelector('#parti_err').classList.add('bdr-red');
			setTimeout(()=>{document.querySelector('#parti_err').classList.remove('bdr-red');},5000);
		}else{document.querySelector('#parti_err').classList.remove('bdr-red')}
		if (amt===""||parseInt(amt)<1) {
			document.querySelector('#amt_err').classList.add('bdr-red');
			setTimeout(()=>{document.querySelector('#amt_err').classList.remove('bdr-red');},5000);
		}else{document.querySelector('#amt_err').classList.remove('bdr-red')}
		if (parti!==""&&amt!==""&&parseInt(amt)>0&&pay!=="") {
			setUpdateLoad(true)
			postCall('api/em/add/expense/',{p_id:parti.substring(1),amount:amt,remarks:remark,type:pay.substring(1)}).then(res=>{
				setUpdateLoad(false);
				setExpense(res.data);
				setUpdateModal(false);
			}).catch(err=>{
				setUpdateLoad(false);
			});
		}
	}
	const [ deleteLoad, setDeleteLoad ] = useState(false);
	const [ deleteId, setDeleteId ] = useState();
	return(
			<Container className="my-3" >
				<Col  xs={12} sm={12} md={12} lg={12} className='bdr2 br-3' >
					<Col xs="12" sm="12" md="12" lg="12" className="staff-type-header bgs1 d-grid p-2 bdrb2" >
						<span>{load?(<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>):'Expense'}</span>
						<Button onClick={()=>{setUpdateModal(true)}} className="btn-t1"  ><i className="bi bi-plus-circle"></i> Add new</Button>
					</Col>
				</Col>
				<div className="dash-body-content">
					{
						expense!==undefined&&expense.expenses!==undefined&&Object.keys(expense.expenses).length>0?(
							<Table bordered className="tbl-bg-blue" >
							    <thead>
							        <tr>
							          <th className="tp2" >Particulars</th>
							          <th className="tp2" >Remarks</th>
							          <th className="tp2" >Payment method</th>
							          <th className="text-end tp2" >Amount</th>
							          <th className="text-end tp2"></th>
							        </tr>
							    </thead>
							    <tbody>
							    {
							    	Object.keys(expense.expenses).map((exp,exi)=>(
										<tr key={`exp_${exi}`} className="tc2" > 
			        						<td>
			        							<span className="d-grid" >{expense.expenses[exp].particulars}</span>
			        						</td>
			        						<td>{expense.expenses[exp].remarks}</td>
			        						<td>{expense.expenses[exp].payment_type}</td>
			        						<td className="text-end">{expense.expenses[exp].amount}</td>
			        						<td className="text-end">
			        						{
			        							deleteId!==undefined&&deleteId===exp&&deleteLoad?(
			        								<span className="tc2 py-1 px-2 br3 cpoint just-center drop-btn" ><Spinner animation="border" size="sm" /> Wait...</span>
			        							):(
				        							<span onClick={()=>{
				        							setDeleteLoad(true);
				        							setDeleteId(exp);
				        							postCall('api/em/delete/expense/',{e_id:exp.substring(1)}).then(res=>{
				        								setExpense(res.data);
				        								setDeleteLoad(false);
				        								setDeleteId();
				        							}).catch(err=>{
				        								setDeleteLoad(false);
				        							});
											   		}} className="tc2 py-1 px-2 br3 cpoint just-center drop-btn" ><i className="bi bi-trash"></i> Delete</span>
			        							)
			        						}
			        						</td>
			        					</tr>
									))
								}
								<tr>
									<td colSpan={3} className="tp2 text-end">Total</td>
									<td className="text-end">{expense.total}</td>
									<td></td>
								</tr>
								</tbody>
				        	</Table>									
						):''
					}
				</div>
				<Modal size="xl" show={updateModal} backdrop="static" onHide={()=>{setUpdateModal(false)}}>
		        	<Modal.Header closeButton><span className="tc-blue f18 f200" >Add Expense</span></Modal.Header>
		        	<Modal.Body>
		        		<Form onSubmit={addExpense} >
		        			<Form.Group className="mb-3">
		        				<Form.Label className="tc2" >Particular</Form.Label>
			        			<Form.Select name="partic" id="parti_err" className="shadow-none br3 tc2">
			        				<option value="" >Choose</option>
			        				{
			        					expenseParti!==undefined&&Object.keys(expenseParti).length>0?(
			        						Object.keys(expenseParti).map((parti,pi)=>(
			        							<option value={parti} key={`part_${pi}`} >{expenseParti[parti].name}</option>
			        						))
			        					):''
			        				}	        				
								</Form.Select>
							</Form.Group>
							<Form.Group>
								<Form.Label className="tc2 d-grid" id="pay-label" >Expense Payment Method</Form.Label>
								<Form.Select name="paym" id="paym_err" className="shadow-none br3 tc2">
			        				<option value="" >Choose</option>
			        				{
										payModes!==undefined&&Object.keys(payModes).length>0?(
											Object.keys(payModes).map((payM, pay_i)=>(
												!payModes[payM].credit&&!payModes[payM].cw?(
			        								<option value={payM} key={`part_${pay_i}`} >{payModes[payM].name}</option>
			        							):''
			        						))
			        					):''
			        				}	        				
								</Form.Select>
							</Form.Group>				        		
			        		<Form.Group className="mb-3">
			        			<Form.Label className="tc2" >Amount</Form.Label>
			        			<Form.Control type="number" id="amt_err" min="0" name="amt" className="shadow-none br3" />
			        		</Form.Group>
			        		<Form.Group className="mb-3">
				        			<Form.Label className="tc2" >Remarks</Form.Label>
				        			<Form.Control as="textarea" rows="4" name="remark" className="shadow-none br3" />
				        		</Form.Group>
			        		<div  className="d-grid">
						    {
						    	updateLoad?(
						    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
						    	):(
						    	<Button type="submit" className="btn-t1 br3">SAVE</Button>
						    	)
						    }
						    </div>
		        		</Form>
		        	</Modal.Body>
		        	<Modal.Footer>
		        	</Modal.Footer>
        		</Modal>
			</Container>
		)
}