import React, {useContext, useState} from 'react';
import {Container, Row, Col, Button, Spinner, Accordion, Tabs, Tab } from 'react-bootstrap';
import axios from "axios";
import { AppContext } from '../App';


export const Notification=()=>{
	const { accessToken, user, setAuthForm, link,  notifications, getNotifiLoad, setNotification, setGetNotifiLoad } = useContext(AppContext);

	
	const [ resolveLoad, setResolveLoad ] = useState(false);
	const [ eqId, setEqId ] = useState();
	const resolved=(enq)=>{
		setResolveLoad(true);
		axios.post(`${link}api/rn/resolve/notification/`,{
				notification_id:enq.substring(1),
	        },{
	          headers:{
	            Authorization: 'Bearer '+accessToken
	          }
	        }).then((response)=>{
	        	setResolveLoad(false);
	        	setNotification(response.data);
	        }).catch((err)=>{
	        	setResolveLoad(false);
		        if(err.response.status === 401){
		            setAuthForm(true);
		        }else if(err.response.status === 404){
		            console.log('Resolve_Enquiry - ',err.response.status,':',err.response.statusText);
		        }else if(err.response.status === 500){
		            console.log('Resolve_Enquiry - ',err.response.status,':',err.response.statusText);
		        }else{
		            console.log('Resolve_Enquiry - ',err.response.status,':',err.response.data);
		        }
	    });
	}
	const pending=(enq)=>{
		setResolveLoad(true);
		axios.post(`${link}api/rn/pending/notification/`,{
				notification_id:enq.substring(1),
	        },{
	          headers:{
	            Authorization: 'Bearer '+accessToken
	          }
	        }).then((response)=>{
	        	setResolveLoad(false);
	        	setNotification(response.data);
	        }).catch((err)=>{
	        	setResolveLoad(false);
		        if(err.response.status === 401){
		            setAuthForm(true);
		        }else if(err.response.status === 404){
		            console.log('Resolve_Enquiry - ',err.response.status,':',err.response.statusText);
		        }else if(err.response.status === 500){
		            console.log('Resolve_Enquiry - ',err.response.status,':',err.response.statusText);
		        }else{
		            console.log('Resolve_Enquiry - ',err.response.status,':',err.response.data);
		        }
	    });
	}
	const [ resolvedNotifi, setResolvedNotif ] = useState();
	const [ pendingNotifi, setPendingNotifi ] = useState();
	const getResolved=(key)=>{
		if (key==='history') {
			setGetNotifiLoad(true);
		    axios.get(`${link}api/rn/get/resolved/notification/`,{
		        headers:{
		                Authorization: 'Bearer '+accessToken
		              }
		      }).then(response=>{
		        setGetNotifiLoad(false);
		        setResolvedNotif(response.data);
		      }).catch(err=>{
		        setGetNotifiLoad(false);
		        if(err.response.status === 401){
		              setAuthForm(true);
		          }else if(err.response.status === 404){
		              console.log('Get_resolved_notifications - ',err.response.status,':',err.response.statusText);
		          }else if(err.response.status === 500){
		              console.log('Get_resolved_notifications - ',err.response.status,':',err.response.statusText);
		          }else{
		              console.log('Get_resolved_notifications - ',err.response.status,':',err.response.data);
		          }
		    });
		  }else if (key==='pending'){
		  	setGetNotifiLoad(true);
		    axios.get(`${link}api/rn/get/pending/notification/`,{
		        headers:{
		                Authorization: 'Bearer '+accessToken
		              }
		      }).then(response=>{
		        setGetNotifiLoad(false);
		        setPendingNotifi(response.data);
		      }).catch(err=>{
		        setGetNotifiLoad(false);
		        if(err.response.status === 401){
		              setAuthForm(true);
		          }else if(err.response.status === 404){
		              console.log('Get_pending_notifications - ',err.response.status,':',err.response.statusText);
		          }else if(err.response.status === 500){
		              console.log('Get_pending_notifications - ',err.response.status,':',err.response.statusText);
		          }else{
		              console.log('Get_pending_notifications - ',err.response.status,':',err.response.data);
		          }
		      });
		  }
	}
	const resolvedPeding=(enq)=>{
		setResolveLoad(true);
		axios.post(`${link}api/rn/resolve/pending/notification/`,{
				notification_id:enq.substring(1),
	        },{
	          headers:{
	            Authorization: 'Bearer '+accessToken
	          }
	        }).then((response)=>{
	        	setResolveLoad(false);
	        	setPendingNotifi(response.data);
	        }).catch((err)=>{
	        	setResolveLoad(false);
		        if(err.response.status === 401){
		            setAuthForm(true);
		        }else if(err.response.status === 404){
		            console.log('Resolve_pending_notification - ',err.response.status,':',err.response.statusText);
		        }else if(err.response.status === 500){
		            console.log('Resolve_pending_notification - ',err.response.status,':',err.response.statusText);
		        }else{
		            console.log('Resolve_pending_notification - ',err.response.status,':',err.response.data);
		        }
	    });
	}
	return(
		<Container className="my-3" >

    		<Col  xs={12} sm={12} md={12} lg={12} className='bdr2 br-3' >
				<Col xs="12" sm="12" md="12" lg="12" className="staff-type-header bgs1 d-grid p-2 bdrb2" >
					<span>{getNotifiLoad?(<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>):'Notifications.'}</span>
				</Col>
				<Tabs defaultActiveKey="home" className="mt-2 " onSelect={(e)=>{getResolved(e)}} >
					<Tab eventKey="home" title="Notifications" className="" >
					{
						notifications!==undefined?(
							Object.keys(notifications).length>0?(
									Object.keys(notifications).map((enq,enq_i)=>(
										notifications[enq].status===false?(
										<Col xs="12" sm="12" md="12" lg="12" className="enquiry-item d-grid p-2 bdrb2" key={`enq_${enq_i}`} >
											
											<Accordion>
										      <Accordion.Item eventKey="0">
										        <Accordion.Header><span className="tp1 f18" >{notifications[enq].service_name}, Expiry - {notifications[enq].expiry_date}</span></Accordion.Header>
										        <Accordion.Body>
										        
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Bill created date</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{notifications[enq].bill_created_date}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Expiry date</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{notifications[enq].expiry_date}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Customer Name</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{notifications[enq].customer_name}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Contact number</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{notifications[enq].customer_phone}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Address</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="p-multi-line ts1" >{notifications[enq].customer_address}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">More details</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="p-multi-line ts1" >{notifications[enq].details}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Service charge</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1" >{parseInt(notifications[enq].gov_service_charge)+parseInt(notifications[enq].akshaya_charge)}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Required documents</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="p-multi-line ts1" >{notifications[enq].required_documents}</span>
												          </Col>
											          </Row>
											        
										        </Accordion.Body>
										      </Accordion.Item>
										    </Accordion>
										    <div className="d-grid">
										    {
										    	user!==undefined?(
													user.type==='admin'?(
												    	eqId!==undefined&&eqId===enq&&resolveLoad?(
												    		<Button className="btn-t1" disabled><Spinner animation="border" size="sm" /></Button>
												    	):(
												    		<>
													    	<Button onClick={()=>{
													    			resolved(enq);
													    			setEqId(enq);
													    		}} className="btn-t1-r mb-1">Mark as resolved</Button>
													    	<Button onClick={()=>{
													    			pending(enq);
													    			setEqId(enq);
													    		}} className="btn-t1-r">Mark as Pending</Button>
													    	</>
												    	)
													):(
														user.permission!==undefined?(
															user.permission.notification!==undefined&&user.permission.notification.E?(
																eqId!==undefined&&eqId===enq&&resolveLoad?(
														    		<Button className="btn-t1" disabled><Spinner animation="border" size="sm" /></Button>
														    	):(
														    		<>
															    		<Button onClick={()=>{
															    			resolved(enq);
															    			setEqId(enq);
															    		}} className="btn-t1-r mb-1">Mark as resolved</Button>
															    		<Button onClick={()=>{
															    			pending(enq);
															    			setEqId(enq);
															    		}} className="btn-t1-r">Mark as Pending</Button>
															    	</>
														    	)
															):''
														):''
													)
												):''
										    }
										    </div>  
										</Col>
										):''
									))
							):''
						):'Not found'
					}
					</Tab>					
					<Tab eventKey="pending" title="Pending items">
					{
						pendingNotifi!==undefined?(
							Object.keys(pendingNotifi).length>0?(
									Object.keys(pendingNotifi).map((enq,enq_i)=>(
										<Col xs="12" sm="12" md="12" lg="12" className="enquiry-item d-grid p-2 bdrb2" key={`enq_${enq_i}`} >
											
											<Accordion>
										      <Accordion.Item eventKey="0">
										        <Accordion.Header><span className="tp1 f18" >{pendingNotifi[enq].service_name}, Expiry - {pendingNotifi[enq].expiry_date}</span></Accordion.Header>
										        <Accordion.Body>
										        
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Bill created date</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{pendingNotifi[enq].bill_created_date}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Expiry date</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{pendingNotifi[enq].expiry_date}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Customer Name</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{pendingNotifi[enq].customer_name}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Contact number</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{pendingNotifi[enq].customer_phone}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Address</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="p-multi-line ts1" >{pendingNotifi[enq].customer_address}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">More details</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="p-multi-line ts1" >{pendingNotifi[enq].details}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Service charge</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1" >{parseInt(pendingNotifi[enq].gov_service_charge)+parseInt(pendingNotifi[enq].akshaya_charge)}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Required documents</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="p-multi-line ts1" >{pendingNotifi[enq].required_documents}</span>
												          </Col>
											          </Row>
											        
										        </Accordion.Body>
										      </Accordion.Item>
										    </Accordion>
										    {
										    	user!==undefined?(
													user.type==='admin'?(
												    	eqId!==undefined&&eqId===enq&&resolveLoad?(
												    		<Button className="btn-t1" disabled><Spinner animation="border" size="sm" /></Button>
												    	):(
													    	<Button onClick={()=>{
													    			resolvedPeding(enq);
													    			setEqId(enq);
													    		}} className="btn-t1-r">Mark as resolved</Button>
												    	)
													):(
														user.permission!==undefined?(
															user.permission.notification!==undefined&&user.permission.notification.E?(
																eqId!==undefined&&eqId===enq&&resolveLoad?(
														    		<Button className="btn-t1" disabled><Spinner animation="border" size="sm" /></Button>
														    	):(
															    	<Button onClick={()=>{
															    			resolvedPeding(enq);
															    			setEqId(enq);
															    		}} className="btn-t1-r">Mark as resolved</Button>
														    	)
															):''
														):''
													)
												):''
										    }   
										</Col>
									))
							):''
						):'Not found'
					}
					</Tab>
					<Tab eventKey="history" title="Resolved items">
					{
						resolvedNotifi!==undefined?(
							Object.keys(resolvedNotifi).length>0?(
									Object.keys(resolvedNotifi).map((enq,enq_i)=>(
										resolvedNotifi[enq].status?(
										<Col xs="12" sm="12" md="12" lg="12" className="enquiry-item d-grid p-2 bdrb2" key={`enq_${enq_i}`} >
											
											<Accordion>
										      <Accordion.Item eventKey="0">
										        <Accordion.Header><span className="tp1 f18" >{resolvedNotifi[enq].service_name}, {resolvedNotifi[enq].customer_name}</span></Accordion.Header>
										        <Accordion.Body>
										        		
										        	  <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Bill created date</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{resolvedNotifi[enq].bill_created_date}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Expiry date</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{resolvedNotifi[enq].expiry_date}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Customer Name</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{resolvedNotifi[enq].customer_name}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Contact number</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{resolvedNotifi[enq].customer_phone}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Address</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="p-multi-line ts1" >{resolvedNotifi[enq].customer_address}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">More details</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="p-multi-line ts1" >{resolvedNotifi[enq].details}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Service charge</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1" >{parseInt(resolvedNotifi[enq].gov_service_charge)+parseInt(resolvedNotifi[enq].akshaya_charge)}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Required documents</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="p-multi-line ts1" >{resolvedNotifi[enq].required_documents}</span>
												          </Col>
											          </Row>
											        
										        </Accordion.Body>
										      </Accordion.Item>
										    </Accordion>
								    		<span className="sucess-text justify-self-center"><i className="bi bi-check-circle-fill"></i> Resolved</span>
										</Col>
										):''
									))
							):''
						):'Not found'
					}
					</Tab>
				</Tabs>
			</Col>
		</Container>
		);
}