import React, {useContext, useEffect, useState, useCallback, useMemo} from 'react';
import {Container, Col, Button, Form, Modal, Spinner, Accordion, InputGroup, Table, getBill } from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import { AppContext } from '../App';

export const Customers=()=>{
	const { accessToken, user, setAuthForm, link, customers, setCustomers, getCustomersLoad, postCall, setGetLoad, getLoad, getBill, setViewBillModal } = useContext(AppContext);

	const [ newCustomerModel, setNewCustomerModel ] = useState(false);
	useEffect(()=>{
		if(!newCustomerModel){
			setSearchResult(prev=>{});
			setSelectedItem();
			setEditCus(false);
		}		
	},[newCustomerModel]);
	const [ addCustomerLoad, setAddCustomerLoad ] = useState(false);
	const [ nameReqErr, setNameReqErr ] = useState(false);
	const [ selectCount, setSelectCount ] = useState(-1);
	const [ showAutoSuggestion, setShowAutoSuggestion ] = useState(false);
	const [ selectedItem, setSelectedItem ] = useState();
	useEffect(()=>{
		if(selectedItem!==undefined){
			$('#cus_phone').val(selectedItem.profile.phone);
			$('#cus_name').val(selectedItem.profile.name);
			$('#cus_address').val(selectedItem.profile.address);
		}else{
			$('#cus_name').val('');
			$('#cus_address').val('');
		}
	},[selectedItem]);
	const [ editCus, setEditCus ] = useState(false);
	const [ searchResult, setSearchResult ] = useState({});
	const addCustomer=(e)=>{
		e.preventDefault();
		let name = e.target.name.value;
		let phone = e.target.phone.value;
		let address = e.target.address.value;
		if (name==="") {
			setNameReqErr(true);
			setTimeout(()=>{setNameReqErr(false)},5000);
		}
		if(name!==""&&!editCus){
			setAddCustomerLoad(true);
			axios.post(`${link}api/cp/add/customer/`,{
					name:name,
					phone:phone,
					address:address,
		        },{
		          headers:{
		            Authorization: 'Bearer '+accessToken
		          }
		        }).then((response)=>{
		        	setAddCustomerLoad(false);
		        	setCustomers(response.data);
		        	setNewCustomerModel(false);
		        }).catch((err)=>{
		        	setAddCustomerLoad(false);
			        if(err.response.status === 401){
			            setAuthForm(true);
			        }else if(err.response.status === 404){
			            console.log('Add_Customer - ',err.response.status,':',err.response.statusText);
			        }else if(err.response.status === 500){
			            console.log('Add_Customer - ',err.response.status,':',err.response.statusText);
			        }else{
			            console.log('Add_Customer - ',err.response.status,':',err.response.data);
			        }
		    });
		}else if(name!==""&&editCus){
			setAddCustomerLoad(true);
			axios.post(`${link}api/cp/edit/customer/`,{
					customer_id:selectedItem.id.substring(1),
					name:name,
					phone:phone,
					address:address,
		        },{
		          headers:{
		            Authorization: 'Bearer '+accessToken
		          }
		        }).then((response)=>{
		        	setAddCustomerLoad(false);
		        	setCustomers(response.data);
		        	setNewCustomerModel(false);
		        }).catch((err)=>{
		        	setAddCustomerLoad(false);
			        if(err.response.status === 401){
			            setAuthForm(true);
			        }else if(err.response.status === 404){
			            console.log('Edit_Customer - ',err.response.status,':',err.response.statusText);
			        }else if(err.response.status === 500){
			            console.log('Edit_Customer - ',err.response.status,':',err.response.statusText);
			        }else{
			            console.log('Edit_Customer - ',err.response.status,':',err.response.data);
			        }
		    });
		}
	}
	const searchCustomer=(c_num)=>{
		if (editCus!==undefined&&!editCus) {
			setSearchResult(prev=>{});
			if (c_num!=="") {
				if (customers!==undefined){
					setSelectedItem();
					if (Object.keys(customers).length>0){
						setSelectCount(-1);
						setShowAutoSuggestion(true);
						let count = 0;
						Object.keys(customers).map((customer,custi)=>{
							if(customers[customer].phone.match(c_num)){
								if (count<20) {
									setSearchResult(prev=>({...prev,[customer]:customers[customer]}));
									count+=1;
								}
							}
						});
					}
				}
			}else{
				setSearchResult(prev=>{});
			}
		}
	}	
	$('#cus_phone').off().on("keydown", function (e) {
	    var code = e.which || e.keyCode;
	    if (code === 40) {
	    	e.preventDefault();
	    	if (selectCount<Object.keys(searchResult).length-1) {
	    		setSelectCount(prev=>prev+1);
	    	}else{
	    		setSelectCount(0);
	    	}
	    }else if(code === 38){
	    	e.preventDefault();
	    	if (selectCount>0) {
	    		setSelectCount(prev=>prev-1);
	    	}else{
	    		setSelectCount(Object.keys(searchResult).length-1);
	    	}
	    }else if(code === 13){
	    	e.preventDefault();
	    	setShowAutoSuggestion(false);
	    	if(selectCount>=0){
	    		setSelectedItem({id: Object.keys(searchResult)[selectCount], profile:searchResult[Object.keys(searchResult)[selectCount]]});
	    	}
	    }
	});
	const [ mainSearchResult, setMainSearchResult ] = useState({});
	const SearchCustomers=(s_name)=>{
		if(s_name!==""){
			setMainSearchResult(prev=>{});
			if(customers!==undefined){
			    if(Object.keys(customers).length>0){
			    	let count = 0;
			    	Object.keys(customers).map((cust, csti)=>{
			    		if (count<800) {
				    		if (customers[cust].name.toLowerCase().includes(s_name.toLowerCase())||customers[cust].phone.includes(s_name)) {
				    			setMainSearchResult(prev=>({...prev,[cust]:customers[cust]}));
				    			count+=1;
				    		}
				    	}
			    	});
			    }
			}
		}else{setMainSearchResult(prev=>{})}		
	}
	const [ searchPageCount, setSearchPageCount ] = useState(0);
	const [ currentSearchPage, setCurrentSearchPage ] = useState(0);
	useEffect(()=>{
		if (mainSearchResult!==undefined) {
			setCurrentSearchPage(prev=>(0));
			let len = Object.keys(mainSearchResult).length;
			setSearchPageCount(Math.ceil(len/30));
		}
	},[mainSearchResult]);
	useEffect(()=>{
		if (customers!==undefined&&mainSearchResult!==undefined) {
			if (Object.keys(mainSearchResult).length>0&&$('#cus_search_box').val()!=="") {
				SearchCustomers($('#cus_search_box').val());			
			}
		}
	},[customers]);
	const [ pageCount, setPageCount ] = useState(0);
	const [ currentPage, setCurrentPage ] = useState(0);
	useEffect(()=>{
		if (customers!==undefined) {
			let len = Object.keys(customers).length;
			setPageCount(Math.ceil(len/50));
		}
	},[customers]);
	const [ cbill, setCbill ] = useState();
	const [ billModal, setBillModal ] = useState(false);
	useEffect(()=>{
		if (!billModal) {
			setCbill();
		}
	},[billModal])
	return (
		<Container className="my-3" >
			<Col xs={12} sm={12} md={12} lg={12} className="service-search mb-3" >
    			<Form onSubmit={(e)=>{e.preventDefault()}} >
    				<InputGroup>
    					<Form.Control id="cus_search_box" onChange={(e)=>{SearchCustomers(e.target.value)}} className="br-2" size="lg" type="text" placeholder="Search here" />
    					<InputGroup.Text className="br-3 btn-t1 px-4" id="basic-addon2"><i className="bi bi-search"></i></InputGroup.Text>
    				</InputGroup>
    			</Form>
    		</Col>

    		<Col  xs={12} sm={12} md={12} lg={12} className='bdr2 br-3' >
				<Col xs={12} sm={12} md={12} lg={12} className="staff-type-header bgs1 d-grid p-2 bdrb2" >
					<span>{getCustomersLoad?(<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>):'Customers'}</span>
					{
						user!==undefined?(
							user.type==='admin'?(
								<Button className="btn-t1" onClick={()=>{setNewCustomerModel(true)}}  ><i className="bi bi-plus-circle"></i> Add new</Button>
							):(
							user.permission!==undefined?(
								user.permission.customer!==undefined&&user.permission.customer.A?(
									<Button className="btn-t1" onClick={()=>{setNewCustomerModel(true)}}  ><i className="bi bi-plus-circle"></i> Add new</Button>
								):''
							):''
							)
						):''
					}
				</Col>
				{
	    			customers!==undefined?(
	    				Object.keys(customers).length > 0 ?(
	    					mainSearchResult!==undefined&&Object.keys(mainSearchResult).length>0?(
	    						<Col  xs={12} sm={12} md={12} lg={12}>
		    						{
		    						Object.keys(mainSearchResult).map((customer,cus_index)=>(
		    							searchPageCount!==undefined&&cus_index>=currentSearchPage&&cus_index<currentSearchPage+30?(
					    					<div className="customer-item p-2 bdrb2" key={`cus_${cus_index}`} >
												<Accordion size='sm' >
											      <Accordion.Item eventKey="0">
											        <Accordion.Header><span className="tp1 f18" >{mainSearchResult[customer].name}, {mainSearchResult[customer].phone}</span></Accordion.Header>
											        <Accordion.Body>
											          <Col>
											    		<p className="p-multi-line ts1 mb-2" >{mainSearchResult[customer].address}</p>
											          </Col>
											        </Accordion.Body>
											      </Accordion.Item>
											    </Accordion>
											    <Button onClick={()=>{
											    	setGetLoad(true);
											    	setBillModal(true);
											    	postCall('api/cp/get/customer/bills/',{c_id:customer.substring(1)}).then(res=>{
											    		setGetLoad(false);
											    		setCbill(res.data);
											    	}).catch(err=>{
											    		setGetLoad(false);
											    		setBillModal(false);
											    	})
											    }}  className="btn-t1-r" ><i className="bi bi-receipt"></i> Get Bills</Button>
											    {
													user!==undefined?(
														user.type==='admin'?(
													    <Button onClick={()=>{
													    	setEditCus(true);
													    	setSelectedItem({id:customer,profile:mainSearchResult[customer]});
													    	setNewCustomerModel(true);
													    }} className="btn-t1-r"><i className="bi bi-pencil-square"></i> Edit</Button>
													    ):(
													    user.permission!==undefined?(
																user.permission.customer!==undefined&&user.permission.customer.E?(
																<Button onClick={()=>{
															    	setEditCus(true);
															    	setSelectedItem({id:customer,profile:mainSearchResult[customer]});
															    	setNewCustomerModel(true);
															    }} className="btn-t1-r"><i className="bi bi-pencil-square"></i> Edit</Button>
																):''
															):''
														)
													):''
												}
											</div>
										):''
			    					))
			    					}
			    					<div className="py-2 pagination-wrap" >
			    					{
			    						searchPageCount!==undefined&&customers!==undefined?(
			    							 Object.keys(customers).map((c,ci)=>(
			    							 	ci<searchPageCount?(
			    							 		<div onClick={()=>{setCurrentSearchPage(ci*30)}} className={currentSearchPage!==undefined&&currentSearchPage===ci*30?'pageination-index m-2 active':'pageination-index m-2'} >
			    							 			<span>{ci+1}</span>
			    							 		</div>
			    							 	):''
			    							 ))
			    						):''
			    					}
			    					</div>		    					
		    					</Col>
	    					):(
	    						<Col  xs={12} sm={12} md={12} lg={12}>
	    							{
			    					Object.keys(customers).map((customer,cus_index)=>(
				    					pageCount!==undefined&&cus_index>=currentPage&&cus_index<currentPage+50?(
					    					<div className="customer-item d-grid p-2 bdrb2" key={`cus_${cus_index}`} >
												<Accordion size="sm" >
											      <Accordion.Item eventKey="0">
											        <Accordion.Header><span className="tp1 f18" >{customers[customer].name}, {customers[customer].phone}</span></Accordion.Header>
											        <Accordion.Body>
											          <Col>
											    		<p className="p-multi-line ts1 mb-2" >{customers[customer].address}</p>
											          </Col>
											        </Accordion.Body>
											      </Accordion.Item>
											    </Accordion>
											    <Button onClick={()=>{
											    	setGetLoad(true);
											    	setBillModal(true);
											    	postCall('api/cp/get/customer/bills/',{c_id:customer.substring(1)}).then(res=>{
											    		setGetLoad(false);
											    		setCbill(res.data);
											    	}).catch(err=>{
											    		setGetLoad(false);
											    		setBillModal(false);
											    	})
											    }} className="btn-t1-r" ><i className="bi bi-receipt"></i> Get Bills</Button>
											    {
													user!==undefined?(
														user.type==='admin'?(
													    <Button onClick={()=>{
													    	setEditCus(true);
													    	setSelectedItem({id:customer,profile:customers[customer]});
													    	setNewCustomerModel(true);
													    }} className="btn-t1-r"><i className="bi bi-pencil-square"></i> Edit</Button>
													    ):(
													    user.permission!==undefined?(
																user.permission.customer!==undefined&&user.permission.customer.E?(
																<Button onClick={()=>{
															    	setEditCus(true);
															    	setSelectedItem({id:customer,profile:customers[customer]});
															    	setNewCustomerModel(true);
															    }} className="btn-t1-r"><i className="bi bi-pencil-square"></i> Edit</Button>
																):''
															):''
														)
													):''
												}
											</div>
										):''
			    					))
			    					}
			    					<div className="py-2 pagination-wrap" >
			    					{
			    						pageCount!==undefined&&customers!==undefined?(
			    							 Object.keys(customers).map((c,ci)=>(
			    							 	ci<pageCount?(
			    							 		<div onClick={()=>{setCurrentPage(ci*50)}} className={currentPage!==undefined&&currentPage===ci*50?'pageination-index m-2 active':'pageination-index m-2'} >
			    							 			<span>{ci+1}</span>
			    							 		</div>
			    							 	):''
			    							 ))
			    						):''
			    					}
			    					</div>
		    					</Col>
		    				)
	    				):''
	    			):'Not found'
	    		}
			</Col>
			<Modal size="xl" show={newCustomerModel} backdrop="static" onHide={()=>{setNewCustomerModel(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>{editCus?'Update':'Add new'} Customer</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={addCustomer} >
		        		<Form.Group className="mb-3">
		        			<Form.Label>Contact number </Form.Label>
		        			<div className="serach-customer d-grid pos-rel">
		        				<Form.Control onBlur={(e)=>{
		        					setTimeout(()=>{
		        						setShowAutoSuggestion(false);
		        					},200);
		        				}} autoComplete="off" onWheel={(e) => {e.target.blur()} } id="cus_phone" onChange={(e)=>{searchCustomer(e.target.value)}} type="number" name="phone" className="shadow-none br-2" placeholder="Contact number" />
		        				
	        					{
        						searchResult!==undefined&&showAutoSuggestion?(
        							Object.keys(searchResult).length > 0 ?(
        								<div className="customer-search-results bdr1">
        								{
        								Object.keys(searchResult).map((cus,cus_index)=>(
        									<span onClick={()=>{setSelectedItem({ id : cus, profile : searchResult[cus] }) }} className={cus===Object.keys(searchResult)[selectCount]?'d-grid bdrb1 active':'d-grid bdrb1'}  key={`cust_${cus_index}`}>{searchResult[cus].name} - {searchResult[cus].phone} </span>
        									))
        								}
        								</div>
        								):''
        								
        							):''
	        					}

		        			</div>
		        		</Form.Group>
		        		<Form.Group className="mb-3">
		        			<Form.Label>Name {nameReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
		        			<Form.Control type="text" id="cus_name" name="name" className="shadow-none br-2" placeholder="Customer Name" />
		        		</Form.Group>
		        		<Form.Group className="mb-3">
		        			<Form.Label>Address</Form.Label>
		        			<Form.Control as="textarea" id="cus_address" className="shadow-none br-2" name="address" />
		        		</Form.Group>
		        		<div className="d-grid">
		        		{
					    	addCustomerLoad?(
					    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
					    	):(
					    	<Button type="submit" className="btn-t1">{editCus?'UPDATE':'SAVE'}</Button>
					    	)
					    }
		        		</div>
		        	</Form>
		        </Modal.Body>
		        <Modal.Footer></Modal.Footer>
		    </Modal>
		    <Modal size="xl" show={billModal} backdrop="static" onHide={()=>{setBillModal(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>
		          	{
		        		getLoad?(
		        			<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
		        		):(
		        			cbill!==undefined&&cbill.customer_name!==undefined&&cbill.customer_phone!==undefined?(
		        				<span className="f18" >{`${cbill.customer_name}, ${cbill.customer_phone}`}</span>
		        			):'Not found!'
		        		)
		        	}
		          </Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	{
		        		cbill!==undefined&&cbill.bills!==undefined&&Object.keys(cbill.bills).length>0?(
		        			<Table bordered className="tbl-bg-blue" >
							    <thead>
							        <tr>
							          <th><span className="tp2" >Date</span></th>
							          <th><span className="tp2" >Time</span></th>
							          <th><span className="tp2" >Invoice</span></th>
							          <th className="text-end tp2" ><span>TC</span></th>
							          <th className="text-end tp2" ><span>SC</span></th>
							          <th className="text-end tp2" ><span>Credit</span></th>
							          <th className="text-end tp2" >Total</th>
							        </tr>
							    </thead>
							    <tbody>
							    {
							    	Object.keys(cbill.bills).map((bill,bill_i)=>(
					    				<tr key={`bill_${bill_i}`}>
								    		<td><span className="tp2">{cbill.bills[bill].bill_date} <span onClick={()=>{
												getBill(cbill.bills[bill].bill_id);
												setViewBillModal(true);
											}} className="view-bill-link" >View bill</span></span></td>
								    		<td><span className="tp2">{cbill.bills[bill].bill_time}</span></td>
								    		<td><span className="tp2">{cbill.bills[bill].bill_id}</span></td>
								    		<td className="tp2 text-end"><span>{cbill.bills[bill].transaction_total}</span></td>
								    		<td className="tp2 text-end"><span>{cbill.bills[bill].service_amount_total}</span></td>
								    		<td className="tp2 text-end">												    			
								    			{
								    				cbill.bills[bill].credit_amount!==null&&cbill.bills[bill].credit_amount!==""&&cbill.bills[bill].credit_amount>0?(
								    					cbill.bills[bill].credit_status?(
								    						<span className="sucess-tag mx-2"><i className="bi bi-check-circle"></i> Paid</span>
								    					):(

								    						<span className="error-message mx-2"><i className="bi bi-x-circle"></i> Pending</span>
								    					)	
								    				):''
								    			}
								    			<span>{cbill.bills[bill].credit_amount}</span>
								    		</td>
								    		<td className="tp2 text-end"><span>{cbill.bills[bill].bill_total}</span></td>
								    	</tr>
					    			))
							    }
							    <tr>
							    	<td></td>
							    	<td colSpan={2} className="f16 text-end bgy">Total</td>
							    	<td className="text-end bgy" ><span>{cbill.trans_bills_total}</span></td>
							    	<td className="text-end bgy" ><span>{cbill.serv_bills_total}</span></td>
							    	<td className="text-end bgy" ><span>{cbill.credit_bills_total}</span></td>
							    	<td className="text-end bgy" ><span>{cbill.bills_total}</span></td>
							    </tr>										    			        
							    </tbody>
							</Table>
		        		):'Not found!'
		        	}
		        </Modal.Body>
		        <Modal.Footer></Modal.Footer>
		    </Modal>
		</Container>
		)
}