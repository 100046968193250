import React, {useContext, useEffect, useState} from 'react';
import {Container, Row, Col, Button, Form, Modal, Spinner, Accordion, Tabs, Tab } from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import { AppContext } from '../App';


export const Enquiry=()=>{
	const { accessToken, user, setAuthForm, link, customers, setCustomers, getEnquiryLoad, setGetEnquiryLoad, enquiry, setEnquiry } = useContext(AppContext);

	const [ newEnquiryModel, setNewEnquiryModel ] = useState(false);
	const [ phoneReqErr, setPhoneReqErr ] = useState(false);
	const [ nameReqErr, setNameReqErr ] = useState(false);
	const [ eqTitleErr, setEqTitleErr ] = useState(false);
	const [ eqDetailsErr, setEqDetailsErr ] = useState(false);
	const [ selectCount, setSelectCount ] = useState(-1);
	const [ showAutoSuggestion, setShowAutoSuggestion ] = useState(false);
	const [ selectedItem, setSelectedItem ] = useState();
	useEffect(()=>{
		if(selectedItem!==undefined){
			$('#cus_phone').val(selectedItem.profile.phone);
			$('#cus_name').val(selectedItem.profile.name);
			$('#cus_address').val(selectedItem.profile.address);
		}else{
			$('#cus_name').val('');
			$('#cus_address').val('');
		}
	},[selectedItem]);
	const [ searchResult, setSearchResult ] = useState({});
	const searchCustomer=(c_num)=>{
		setSearchResult(prev=>{});
		if (c_num!=="") {
			if (customers!==undefined){
				setSelectedItem();
				if (Object.keys(customers).length>0){
					setSelectCount(-1);
					setShowAutoSuggestion(true);
					let count = 0;
					Object.keys(customers).map(customer=>{
						if(customers[customer].phone.match(c_num)){
							if (count<20) {
							setSearchResult(prev=>({...prev,[customer]:customers[customer]}))
							count+=1;
							}
						}
					});
				}
			}
		}else{
			setSearchResult(prev=>{});
		}
	}
	$('#cus_phone').off().on("keydown", function (e) {
	    var code = e.which || e.keyCode;
	    if (code === 40) {
	    	e.preventDefault();
	    	if (selectCount<Object.keys(searchResult).length-1) {
	    		setSelectCount(prev=>prev+1);
	    	}else{
	    		setSelectCount(0);
	    	}
	    }else if(code === 38){
	    	e.preventDefault();
	    	if (selectCount>0) {
	    		setSelectCount(prev=>prev-1);
	    	}else{
	    		setSelectCount(Object.keys(searchResult).length-1);
	    	}
	    }else if(code === 13){
	    	e.preventDefault();
	    	setShowAutoSuggestion(false);
	    	if(selectCount>=0){
	    		setSelectedItem({id: Object.keys(searchResult)[selectCount], profile:searchResult[Object.keys(searchResult)[selectCount]]});
	    	}
	    }
	});
	const [ addEnquiryLoad, setAddEnquiryLoad ] = useState(false);
	const addEnquiry=(e)=>{
		e.preventDefault();
		let phone = e.target.phone.value;
		let name = e.target.name.value;
		let address = e.target.address.value;
		let title = e.target.title.value;
		let details = e.target.details.value;
		if (phone==="") {
			setPhoneReqErr(true);
			setTimeout(()=>{setPhoneReqErr(false)},5000);
		}
		if (name==="") {
			setNameReqErr(true);
			setTimeout(()=>{setNameReqErr(false)},5000);
		}
		if (title==="") {
			setEqTitleErr(true);
			setTimeout(()=>{setEqTitleErr(false)},5000);
		}
		if (details==="") {
			setEqDetailsErr(true);
			setTimeout(()=>{setEqDetailsErr(false)},5000);
		}
		if (phone!==""&&name!==""&&title!==""&&details!=="") {
			setAddEnquiryLoad(true);
			axios.post(`${link}api/e/add/enquiry/`,{
					customer_id:selectedItem!==undefined?selectedItem.id.substring(1):'',
					name:name,
					phone:phone,
					address:address,
					title:title,
					note:details,
		        },{
		          headers:{
		            Authorization: 'Bearer '+accessToken
		          }
		        }).then((response)=>{
		        	setAddEnquiryLoad(false);
		        	setEnquiry(response.data.enquiry);
		        	setCustomers(response.data.customers);
		        	setNewEnquiryModel(false);
		        }).catch((err)=>{
		        	setAddEnquiryLoad(false);
			        if(err.response.status === 401){
			            setAuthForm(true);
			        }else if(err.response.status === 404){
			            console.log('Add_Enquiry - ',err.response.status,':',err.response.statusText);
			        }else if(err.response.status === 500){
			            console.log('Add_Enquiry - ',err.response.status,':',err.response.statusText);
			        }else{
			            console.log('Add_Enquiry - ',err.response.status,':',err.response.data);
			        }
		    });
		}
	}
	const [ resolveLoad, setResolveLoad ] = useState(false);
	const [ eqId, setEqId ] = useState();
	const resolved=(enq)=>{
		setResolveLoad(true);
		axios.post(`${link}api/e/resolve/enquiry/`,{
				enquiry_id:enq.substring(1),
	        },{
	          headers:{
	            Authorization: 'Bearer '+accessToken
	          }
	        }).then((response)=>{
	        	setResolveLoad(false);
	        	setEnquiry(response.data);
	        }).catch((err)=>{
	        	setResolveLoad(false);
		        if(err.response.status === 401){
		            setAuthForm(true);
		        }else if(err.response.status === 404){
		            console.log('Resolve_Enquiry - ',err.response.status,':',err.response.statusText);
		        }else if(err.response.status === 500){
		            console.log('Resolve_Enquiry - ',err.response.status,':',err.response.statusText);
		        }else{
		            console.log('Resolve_Enquiry - ',err.response.status,':',err.response.data);
		        }
	    });
	}
	const pending=(enq)=>{
		setResolveLoad(true);
		axios.post(`${link}api/e/pending/enquiry/`,{
				enquiry_id:enq.substring(1),
	        },{
	          headers:{
	            Authorization: 'Bearer '+accessToken
	          }
	        }).then((response)=>{
	        	setResolveLoad(false);
	        	setEnquiry(response.data);
	        }).catch((err)=>{
	        	setResolveLoad(false);
		        if(err.response.status === 401){
		            setAuthForm(true);
		        }else if(err.response.status === 404){
		            console.log('Pending_Enquiry - ',err.response.status,':',err.response.statusText);
		        }else if(err.response.status === 500){
		            console.log('Pending_Enquiry - ',err.response.status,':',err.response.statusText);
		        }else{
		            console.log('Pending_Enquiry - ',err.response.status,':',err.response.data);
		        }
	    });
	}
	const [ resolvedEnq, setResolvedEnq ] = useState();
	const [ pendingEnq, setPendingEnq ] = useState();
	const getResolved=(key)=>{
		if (key==='history') {
			setGetEnquiryLoad(true);
		    axios.get(`${link}api/e/get/resolved/enquiry/`,{
		        headers:{
		                Authorization: 'Bearer '+accessToken
		              }
		      }).then(response=>{
		        setGetEnquiryLoad(false);
		        setResolvedEnq(response.data);
		      }).catch(err=>{
		        setGetEnquiryLoad(false);
		        if(err.response.status === 401){
		              setAuthForm(true);
		          }else if(err.response.status === 404){
		              console.log('Get_resolved_enquiry - ',err.response.status,':',err.response.statusText);
		          }else if(err.response.status === 500){
		              console.log('Get_resolved_enquiry - ',err.response.status,':',err.response.statusText);
		          }else{
		              console.log('Get_resolved_enquiry - ',err.response.status,':',err.response.data);
		          }
		    });
		}else if(key==='pending') {
			setGetEnquiryLoad(true);
		    axios.get(`${link}api/e/get/pending/enquiry/`,{
		        headers:{
		                Authorization: 'Bearer '+accessToken
		              }
		      }).then(response=>{
		        setGetEnquiryLoad(false);
		        setPendingEnq(response.data);
		      }).catch(err=>{
		        setGetEnquiryLoad(false);
		        if(err.response.status === 401){
		              setAuthForm(true);
		          }else if(err.response.status === 404){
		              console.log('Get_pending_enquiry - ',err.response.status,':',err.response.statusText);
		          }else if(err.response.status === 500){
		              console.log('Get_pending_enquiry - ',err.response.status,':',err.response.statusText);
		          }else{
		              console.log('Get_pending_enquiry - ',err.response.status,':',err.response.data);
		          }
		    });
		}
	}
	const resolvedPeding=(enq)=>{
		setResolveLoad(true);
		axios.post(`${link}api/e/resolve/enquiry/pending/`,{
				enquiry_id:enq.substring(1),
	        },{
	          headers:{
	            Authorization: 'Bearer '+accessToken
	          }
	        }).then((response)=>{
	        	setResolveLoad(false);
	        	setPendingEnq(response.data);
	        }).catch((err)=>{
	        	setResolveLoad(false);
		        if(err.response.status === 401){
		            setAuthForm(true);
		        }else if(err.response.status === 404){
		            console.log('Resolve_Enquiry_pending - ',err.response.status,':',err.response.statusText);
		        }else if(err.response.status === 500){
		            console.log('Resolve_Enquiry_pending - ',err.response.status,':',err.response.statusText);
		        }else{
		            console.log('Resolve_Enquiry_pending - ',err.response.status,':',err.response.data);
		        }
	    });
	}
	return(
		<Container className="my-3" >

    		<Col  xs={12} sm={12} md={12} lg={12} className='bdr2 br-3' >
				<Col xs="12" sm="12" md="12" lg="12" className="staff-type-header bgs1 d-grid p-2 bdrb2" >
					<span>{getEnquiryLoad?(<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>):'Enquiries.'}</span>
					{
						user!==undefined?(
							user.type==='admin'?(
								<Button className="btn-t1" onClick={()=>{setNewEnquiryModel(true)}}  ><i className="bi bi-plus-circle"></i> Add new</Button>
							):(
							user.permission!==undefined?(
								user.permission.enquiry!==undefined&&user.permission.enquiry.A?(
									<Button className="btn-t1" onClick={()=>{setNewEnquiryModel(true)}}  ><i className="bi bi-plus-circle"></i> Add new</Button>
								):''
							):''
							)
						):''
					}
				</Col>
				<Tabs defaultActiveKey="home" className="mt-2 " onSelect={(e)=>{getResolved(e)}} >
					<Tab eventKey="home" title="New enquiries" className="" >
					{
						enquiry!==undefined?(
							Object.keys(enquiry).length>0?(
									Object.keys(enquiry).map((enq,enq_i)=>(
										enquiry[enq].resolved===false?(
										<Col xs="12" sm="12" md="12" lg="12" className="enquiry-item d-grid p-2 bdrb2" key={`enq_${enq_i}`} >											
											<Accordion>
										      <Accordion.Item eventKey="0">
										        <Accordion.Header><span className="tp1 f18" >{enquiry[enq].title} [ {enquiry[enq].username} ]</span></Accordion.Header>
										        <Accordion.Body>
										        
													  <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Created date</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{enquiry[enq].created_date}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Customer Name</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{enquiry[enq].name}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Contact number</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{enquiry[enq].phone}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Address</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="p-multi-line ts1" >{enquiry[enq].address}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Details</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="p-multi-line ts1" >{enquiry[enq].enquiry_details}</span>
												          </Col>
											          </Row>
											        
										        </Accordion.Body>
										      </Accordion.Item>
										    </Accordion>
										    <div className="d-grid">
										    {
										    	user!==undefined?(
													user.type==='admin'?(
												    	eqId!==undefined&&eqId===enq&&resolveLoad?(
												    		<Button className="btn-t1" disabled><Spinner animation="border" size="sm" /></Button>
												    	):(
													    	<>
													    	<Button onClick={()=>{
													    			resolved(enq);
													    			setEqId(enq);
													    		}} className="btn-t1-r mb-1">Mark as resolved</Button>
													    	<Button onClick={()=>{
													    			pending(enq);
													    			setEqId(enq);
													    		}} className="btn-t1-r">Mark as Pending</Button>
													    	</>
												    	)
													):(
														user.permission!==undefined?(
															user.permission.enquiry!==undefined&&user.permission.enquiry.E?(
																eqId!==undefined&&eqId===enq&&resolveLoad?(
														    		<Button className="btn-t1" disabled><Spinner animation="border" size="sm" /></Button>
														    	):(
														    		<>
															    	<Button onClick={()=>{
															    			resolved(enq);
															    			setEqId(enq);
															    		}} className="btn-t1-r mb-1">Mark as resolved</Button>
															    	<Button onClick={()=>{
														    			pending(enq);
														    			setEqId(enq);
														    		}} className="btn-t1-r">Mark as Pending</Button>
														    		</>

														    	)
															):''
														):''
													)
												):''
										    }   
										    </div>
										</Col>
										):''
									))
							):'Not found!'
						):'Not found'
					}
					</Tab>
					<Tab eventKey="pending" title="Pending items">
						{
							pendingEnq!==undefined?(
								Object.keys(pendingEnq).length>0?(
										Object.keys(pendingEnq).map((enq,enq_i)=>(
											pendingEnq[enq].resolved===false&&pendingEnq[enq].pending?(
											<Col xs="12" sm="12" md="12" lg="12" className="enquiry-item d-grid p-2 bdrb2" key={`enq_${enq_i}`} >												
												<Accordion>
											      <Accordion.Item eventKey="0">
											        <Accordion.Header><span className="tp1 f18" >{pendingEnq[enq].title}  [ {pendingEnq[enq].username} ]</span></Accordion.Header>
											        <Accordion.Body>
											        
														<Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Created date</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{pendingEnq[enq].created_date}</span>
												          </Col>
											          	</Row>
														<Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Pending added</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{pendingEnq[enq].updated_date}</span>
												          </Col>
											          	</Row>
												          <Row className="mb-3" >
													          <Col xs={12} sm={6} md={3} lg={2} >
													    		<span className="ts1">Customer Name</span>
													          </Col>
													          <Col xs={12} sm={6} md={9} lg={10} >
													    		<span className="ts1">{pendingEnq[enq].name}</span>
													          </Col>
												          </Row>
												          <Row className="mb-3" >
													          <Col xs={12} sm={6} md={3} lg={2} >
													    		<span className="ts1">Contact number</span>
													          </Col>
													          <Col xs={12} sm={6} md={9} lg={10} >
													    		<span className="ts1">{pendingEnq[enq].phone}</span>
													          </Col>
												          </Row>
												          <Row className="mb-3" >
													          <Col xs={12} sm={6} md={3} lg={2} >
													    		<span className="ts1">Address</span>
													          </Col>
													          <Col xs={12} sm={6} md={9} lg={10} >
													    		<span className="p-multi-line ts1" >{pendingEnq[enq].address}</span>
													          </Col>
												          </Row>
												          <Row className="mb-3" >
													          <Col xs={12} sm={6} md={3} lg={2} >
													    		<span className="ts1">Details</span>
													          </Col>
													          <Col xs={12} sm={6} md={9} lg={10} >
													    		<span className="p-multi-line ts1" >{pendingEnq[enq].enquiry_details}</span>
													          </Col>
												          </Row>
												        
											        </Accordion.Body>
											      </Accordion.Item>
											    </Accordion>
											    {
											    	user!==undefined?(
														user.type==='admin'?(
													    	eqId!==undefined&&eqId===enq&&resolveLoad?(
													    		<Button className="btn-t1" disabled><Spinner animation="border" size="sm" /></Button>
													    	):(
														    	<Button onClick={()=>{
														    			resolvedPeding(enq);
														    			setEqId(enq);
														    		}} className="btn-t1-r">Mark as resolved</Button>
													    	)
														):(
															user.permission!==undefined?(
																user.permission.enquiry!==undefined&&user.permission.enquiry.E?(
																	eqId!==undefined&&eqId===enq&&resolveLoad?(
															    		<Button className="btn-t1" disabled><Spinner animation="border" size="sm" /></Button>
															    	):(
																    	<Button onClick={()=>{
																    			resolvedPeding(enq);
																    			setEqId(enq);
																    		}} className="btn-t1-r">Mark as resolved</Button>
															    	)
																):''
															):''
														)
													):''
											    }   
											</Col>
											):''
										))
								):'Not found!'
							):'Not found'
						}
					</Tab>
					<Tab eventKey="history" title="Resolved items">
					{
						resolvedEnq!==undefined?(
							Object.keys(resolvedEnq).length>0?(
									Object.keys(resolvedEnq).map((enq,enq_i)=>(
										resolvedEnq[enq].resolved?(
										<Col xs="12" sm="12" md="12" lg="12" className="enquiry-item d-grid p-2 bdrb2" key={`enq_${enq_i}`} >
											
											<Accordion>
										      <Accordion.Item eventKey="0">
										        <Accordion.Header><span className="tp1 f18" >{resolvedEnq[enq].title} [ {resolvedEnq[enq].username} ]</span></Accordion.Header>
										        <Accordion.Body>
										        
													  <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Created date</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{resolvedEnq[enq].created_date}</span>
												          </Col>
											          </Row>
													  <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Resolved date</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{resolvedEnq[enq].updated_date}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Customer Name</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{resolvedEnq[enq].name}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Contact number</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="ts1">{resolvedEnq[enq].phone}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Address</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="p-multi-line ts1" >{resolvedEnq[enq].address}</span>
												          </Col>
											          </Row>
											          <Row className="mb-3" >
												          <Col xs={12} sm={6} md={3} lg={2} >
												    		<span className="ts1">Details</span>
												          </Col>
												          <Col xs={12} sm={6} md={9} lg={10} >
												    		<span className="p-multi-line ts1" >{resolvedEnq[enq].enquiry_details}</span>
												          </Col>
											          </Row>
											        
										        </Accordion.Body>
										      </Accordion.Item>
										    </Accordion>
								    		<span className="sucess-text justify-self-center"><i className="bi bi-check-circle-fill"></i> Resolved</span>
										</Col>
										):''
									))
							):''
						):'Not found'
					}
					</Tab>
				</Tabs>
			</Col>
			<Modal size="xl" show={newEnquiryModel} backdrop="static" onHide={()=>{setNewEnquiryModel(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Add Enquiry</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={addEnquiry} >
		        		<Form.Group className="mb-3">
		        			<Form.Label>Contact number {phoneReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
		        			<div className="serach-customer d-grid pos-rel">
		        				<Form.Control onBlur={()=>{
		        					setTimeout(()=>{
		        						setShowAutoSuggestion(false);
		        					},100);
		        				}} autoComplete="off" onWheel={(e) => {e.target.blur()} } id="cus_phone" onChange={(e)=>{searchCustomer(e.target.value)}} type="number" name="phone" className="shadow-none br-2" placeholder="Contact number" />
		        				
	        					{
        						searchResult!==undefined&&showAutoSuggestion?(
        							Object.keys(searchResult).length > 0 ?(
        								<div className="customer-search-results bdr1">
        								{
        								Object.keys(searchResult).map((cus,cus_index)=>(
        									<span onClick={()=>{setSelectedItem({ id : cus, profile : searchResult[cus] }) }} className={cus===Object.keys(searchResult)[selectCount]?'d-grid bdrb1 active':'d-grid bdrb1'}  key={`cust_${cus_index}`}>{searchResult[cus].name} - {searchResult[cus].phone} </span>
        									))
        								}
        								</div>
        								):''
        								
        							):''
	        					}

		        			</div>
		        		</Form.Group>
		        		<Form.Group className="mb-3">
		        			<Form.Label>Name {nameReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
		        			<Form.Control type="text" id="cus_name" name="name" className="shadow-none br-2" placeholder="Customer Name" />
		        		</Form.Group>
		        		<Form.Group className="mb-3">
		        			<Form.Label>Address</Form.Label>
		        			<Form.Control as="textarea" id="cus_address" className="shadow-none br-2" name="address" />
		        		</Form.Group>
		        		<Form.Group className="mb-3">
		        			<Form.Label>Enquiry title {eqTitleErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
		        			<Form.Control type="text" name="title" className="shadow-none br-2" placeholder="Enquiry title" />
		        		</Form.Group>
		        		<Form.Group className="mb-3">
		        			<Form.Label>Enquiry details {eqDetailsErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
		        			<Form.Control as="textarea" className="shadow-none br-2" name="details" />
		        		</Form.Group>
		        		<div className="d-grid">
		        		{
					    	addEnquiryLoad?(
					    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
					    	):(
					    	<Button type="submit" className="btn-t1">SAVE</Button>
					    	)
					    }
		        		</div>
		        	</Form>
		        </Modal.Body>
		        <Modal.Footer></Modal.Footer>
		    </Modal>
		</Container>
		);
}