import React, {useContext} from 'react';
import {Container, Row, Col, Table, Spinner, Form, InputGroup, Button} from 'react-bootstrap';
import { AppContext } from '../App';


export const SalaryPayReport=()=>{
	const { getCreditReport, salaryPayRepo, setSalaryPayRepo, getDate, postCall, load, setLoad} = useContext(AppContext);
	const getReport=(e)=>{
		e.preventDefault();
		let start = e.target.start.value;
		let end = e.target.end.value;
		let staff = e.target.staff.value;
		if (start!==""&&end!=="") {
			setLoad(true);
			postCall('api/bnr/get/salary/payment/report/',{start_date:start, end_date:end,staff_id:staff}).then(res=>{
				setSalaryPayRepo(res.data);
				setLoad(false);
			}).catch(err=>{
				setLoad(false);
			});
		}
	}
	return(
			<Container className="py-3" >
				<Form onSubmit={getReport} >
					<Row className="mb-3" >
						<Col xs={12} sm={4} md={3} >
							<InputGroup  className="br-3">
						        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">From</InputGroup.Text>
						        <Form.Control name="start" type="date" defaultValue={getDate()}  className="br-3" />
						    </InputGroup>
						</Col>
						<Col xs={12} sm={4} md={3} >
							<InputGroup  className="br-3">
						        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">To</InputGroup.Text>
						        <Form.Control name="end" type="date" defaultValue={getDate()}  className="br-3" />
						    </InputGroup>
						</Col>						
						<Col xs={12} sm={4} md={3} >
							<Form.Select name="staff" className="shadow-none br-2">
						      <option value="" >All staffs</option>
						      {
						      	salaryPayRepo!==undefined?(
						      		salaryPayRepo.users!==undefined?(
						      			Object.keys(salaryPayRepo.users).length>0?(
						      				Object.keys(salaryPayRepo.users).map((staff,staff_i)=>(
						      					<option value={staff.substring(1)} key={`user_${staff_i}`} >{salaryPayRepo.users[staff].name}</option>
						      				))
						      			):''
						      		):''
						      	):''
						      }
						    </Form.Select>
						</Col>
						<Col xs={12} sm={4} md={3} className="d-grid" >
							<Button type="submit" className="btn-t1" >Get Report</Button>
						</Col>
					</Row>
				</Form> 
				{
					load?(
						<div className="d-grid py-2">
							<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
						</div>
					):(
						salaryPayRepo!==undefined&&salaryPayRepo.salary_values!==undefined&&Object.keys(salaryPayRepo.salary_values).length>0?(
							<Table bordered className="tbl-bg-blue" >
						    <thead>
						        <tr>
						          <th className="tp2" >Date</th>
						          <th className="tp2" >Staff Name</th>
						          <th className="tp2" >Created by</th>
						          <th className="tp2" >Particular</th>
						          <th  className="tp2 text-end" >Amount</th>
						        </tr>
						    </thead>
						    <tbody>
						    	{
						    		Object.keys(salaryPayRepo.salary_values).map((item,i)=>(
						    			<tr key={`salary_${i}`} >
						    				<td>{salaryPayRepo.salary_values[item].creation_date}, {salaryPayRepo.salary_values[item].creation_time}</td>
						    				<td>{salaryPayRepo.salary_values[item].staff}</td>
						    				<td>{salaryPayRepo.salary_values[item].created_by}</td>
						    				<td>{salaryPayRepo.salary_values[item].particulars}</td>
						    				<td  className="tp2 text-end" >{salaryPayRepo.salary_values[item].amount}</td>
						    			</tr>	
						    		))
						    	}
							</tbody>
						</Table>
						):''
					)
				}
				
			</Container>
		)
}