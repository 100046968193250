import React, {useState, useContext, useEffect} from 'react';
import {Container, Row, Col, Button, Form, Modal, Spinner} from 'react-bootstrap';
import axios from "axios";
import { AppContext } from '../App';


export const StaffType=()=>{
	const { accessToken, user, setAuthForm, link, getPermiLoad, permissions, staffTypes, setStaffTypes, getStaffTypeLoad } = useContext(AppContext);
	const [ staffTypeModel, setStaffTypeModel ] = useState(false);
	const [ newPermissions, setNewPermissions ] = useState({});
	const managePermissions=(slug,permi)=>{
		setNewPermissions(prev=>{
			if(Object.keys(prev).indexOf(slug) ===-1){
				return {
					...prev, [slug]:[permi]
				}
			}else if(Object.keys(prev).indexOf(slug) !==-1 && prev[slug].indexOf(permi) === -1 ){
				return {
					...prev,[slug]:[...prev[slug],permi]
				}
			}else if(Object.keys(prev).indexOf(slug) !==-1 && prev[slug].indexOf(permi) !== -1 ){
				return {
					...prev,[slug]:[...prev[slug].filter((e)=>e !== permi)]
				}
			}else{
				return{...prev}
			}
		});
	}
	const [ modelState, setModelState ] = useState('add');
	const [ editStaffTypeId, setEditStaffTypeId ] = useState();
	const [ newStaffType, setNewStaffType ] = useState('');
	useEffect(()=>{
		if (!staffTypeModel) {
			setNewStaffType('');
			setNewPermissions({});
		}
	},[staffTypeModel]);
	const [ stafftypeAddLoad, setStaffTypeAddLoad ] = useState(false);
	const addNewStaffType=()=>{
		if(newStaffType!==undefined&&newStaffType!==""){
			setStaffTypeAddLoad(true);
			axios.post(`${link}api/pnp/add/staff/type/`,{
					name:newStaffType,
					permissions:newPermissions,
		        },{
		          headers:{
		            Authorization: 'Bearer '+accessToken
		          }
		        }).then((response)=>{
		        	setStaffTypeAddLoad(false);
		        	setStaffTypeModel(false);
		        	setStaffTypes(response.data);
		        }).catch((err)=>{
		        	setStaffTypeAddLoad(false);
			        if(err.response.status === 401){
			            setAuthForm(true);
			        }else if(err.response.status === 404){
			            console.log('Add_staff_type - ',err.response.status,':',err.response.statusText);
			        }else if(err.response.status === 500){
			            console.log('Add_staff_type - ',err.response.status,':',err.response.statusText);
			        }else{
			            console.log('Add_staff_type - ',err.response.status,':',err.response.data);
			        }
		    });
		}
	}
	const updateStaffType=()=>{
		if(newStaffType!==undefined&&newStaffType!==""){
			setStaffTypeAddLoad(true);
			axios.post(`${link}api/pnp/edit/staff/type/`,{
					id:editStaffTypeId,
					name:newStaffType,
					permissions:newPermissions,
		        },{
		          headers:{
		            Authorization: 'Bearer '+accessToken
		          }
		        }).then((response)=>{
		        	setStaffTypeAddLoad(false);
		        	setStaffTypeModel(false);
		        	setStaffTypes(response.data);
		        }).catch((err)=>{
		        	setStaffTypeAddLoad(false);
			        if(err.response.status === 401){
			            setAuthForm(true);
			        }else if(err.response.status === 404){
			            console.log('Edit_staff_type - ',err.response.status,':',err.response.statusText);
			        }else if(err.response.status === 500){
			            console.log('Edit_staff_type - ',err.response.status,':',err.response.statusText);
			        }else{
			            console.log('Edit_staff_type - ',err.response.status,':',err.response.data);
			        }
		    });
		}
	}
	return(
		<Container className="my-3 bdr2 br-3" >
			<Row className='bgs1' >
				<Col xs="12" sm="12" md="12" lg="12" className="staff-type-header d-grid p-2 bdrb2" >
					<span className="f16 tp2" >{getStaffTypeLoad?(<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>):'Staff Types'}</span>
					{
						user!==undefined?(
							user.type==='admin'?(
								permissions===undefined&&getPermiLoad?(
									<Button className="btn-t1" disabled>
										<span className="spinner-white text-white" ><Spinner animation="border" size="sm" /> Please wait...</span>
									</Button>
								):(
									<Button className="btn-t1" onClick={()=>{
										setStaffTypeModel(true);
										setModelState('add');
									}} ><i className="bi bi-plus-circle"></i> Add new</Button>
								)
							):(
								user.permission!==undefined?(
									user.permission.staff_type!==undefined&&user.permission.staff_type.A?(
										permissions===undefined&&getPermiLoad?(
											<Button className="btn-t1" disabled>
												<span className="spinner-white text-white" ><Spinner animation="border" size="sm" /> Please wait...</span>
											</Button>
										):(
											<Button className="btn-t1" onClick={()=>{
												setStaffTypeModel(true);
												setModelState('add');
											}} ><i className="bi bi-plus-circle"></i> Add new</Button>
										)
										):''
									):''
							)
						):''
					}
				</Col>
			</Row>

			<Row>
				{
					staffTypes!==undefined&&Object.keys(staffTypes).length>0?(
						Object.keys(staffTypes).map((stf,stf_i)=>(
							<Col xs="12" sm="12" md="12" lg="12" className="staff-type-item d-grid p-2 bdrb2" key={`staff_type_${stf_i}`} >
								<span className="f16 tp2" >{stf}</span>
								{
								user!==undefined?(
									user.type==='admin'?(
										<Button onClick={()=>{
											setNewPermissions(staffTypes[stf].permissions);
											setModelState('edit');
											setEditStaffTypeId(staffTypes[stf].id);
											setNewStaffType(stf);
											setStaffTypeModel(true);
										}} className="btn-t1-r" ><i className="bi bi-pencil-square"></i> Edit</Button>
									):(
										user.permission!==undefined?(
											user.permission.staff_type!==undefined&&user.permission.staff_type.E?(
												<Button onClick={()=>{
													setNewPermissions(staffTypes[stf].permissions);
													setModelState('edit');
													setEditStaffTypeId(staffTypes[stf].id);
													setNewStaffType(stf);
													setStaffTypeModel(true);
												}} className="btn-t1-r" ><i className="bi bi-pencil-square"></i> Edit</Button>
											):''
										):''
									)
								):''
								}
							</Col>
							))
						):'Not found!'
				}
			</Row>


			<Modal size="xl" show={staffTypeModel} backdrop="static" onHide={()=>{setStaffTypeModel(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>{modelState!==undefined&&modelState==='add'?'Create':'Update'} Staff Types</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form>
		        		<Form.Group className="mb-3">
		        			<Form.Label>Staff Type/Designation</Form.Label>
					    	<Form.Control onChange={(e)=>{setNewStaffType(e.target.value)}} defaultValue={newStaffType} type="text" name="type" required className="shadow-none br-2" placeholder="Staff type/Designation" />
					    </Form.Group>
					    <Form.Group className="mb-3">
					    	<Form.Label className="f18" >Permissions</Form.Label>
					    	{
					    		permissions!==undefined&&Object.keys(permissions).length>0?(
					    			Object.keys(permissions).map((p_obj,p_index)=>(
					    					<Col xs={12} sm={12} md={12} lg={12} className="bdr2 mb-2" key={`permi_${p_index}`} >									    		
									    		<Row className="mx-0" >
									    			<Col xs={12} sm={12} md={3} lg={4} className="bgs1 permi-name">
										    			<span className="f16 py-2" >{permissions[p_obj].name}</span>
										    		</Col>
										    		<Col xs={12} sm={12} md={9} lg={8}>
										    		<Row>
									    			{
									    				permissions[p_obj].permission.indexOf('V')!==-1?(
									    					<Col xs={12} sm={4} md={3} lg={2} className="p-2 permi-op" >
											    				<div onClick={()=>{managePermissions(p_obj,'V')}} className={
											    					newPermissions[p_obj]!==undefined&&newPermissions[p_obj].indexOf('V')!==-1?(
											    					'permission-item active d-grid px-2 py-1  br-2'
											    					):(
											    					'permission-item d-grid px-2 py-1 br-2'
											    					)}>View<i className="bi bi-check-circle"></i></div>
											    			</Col>
									    					):''
									    			}
									    			{
									    				permissions[p_obj].permission.indexOf('A')!==-1?(
									    					<Col xs={12} sm={4} md={3} lg={2} className="p-2 permi-op" >
											    				<div onClick={()=>{managePermissions(p_obj,'A')}} className={
											    					newPermissions[p_obj]!==undefined&&newPermissions[p_obj].indexOf('A')!==-1?(
											    					'permission-item active d-grid px-2 py-1 br-2'
											    					):(
											    					'permission-item d-grid px-2 py-1 br-2'
											    					)}>Add<i className="bi bi-check-circle"></i></div>
											    			</Col>
									    					):''
									    			}
									    			{
									    				permissions[p_obj].permission.indexOf('E')!==-1?(
									    					<Col xs={12} sm={4} md={3} lg={2} className="p-2 permi-op" >
											    				<div onClick={()=>{managePermissions(p_obj,'E')}} className={
											    					newPermissions[p_obj]!==undefined&&newPermissions[p_obj].indexOf('E')!==-1?(
											    					'permission-item active d-grid px-2 py-1 br-2'
											    					):(
											    					'permission-item d-grid px-2 py-1 br-2'
											    					)}>Edit<i className="bi bi-check-circle"></i></div>
											    			</Col>
									    					):''
									    			}
									    			{
									    				permissions[p_obj].permission.indexOf('X')!==-1?(
									    					<Col xs={12} sm={4} md={3} lg={2} className="p-2 permi-op" >
											    				<div onClick={()=>{managePermissions(p_obj,'X')}} className={
											    					newPermissions[p_obj]!==undefined&&newPermissions[p_obj].indexOf('X')!==-1?(
											    					'permission-item active d-grid px-2 py-1 br-2'
											    					):(
											    					'permission-item d-grid px-2 py-1 br-2'
											    					)}>Edit All<i className="bi bi-check-circle"></i></div>
											    			</Col>
									    					):''
									    			}
									    			{
									    				permissions[p_obj].permission.indexOf('D')!==-1?(
									    					<Col xs={12} sm={4} md={3} lg={2} className="p-2 permi-op" >
											    				<div onClick={()=>{managePermissions(p_obj,'D')}} className={
											    					newPermissions[p_obj]!==undefined&&newPermissions[p_obj].indexOf('D')!==-1?(
											    					'permission-item active d-grid px-2 py-1 br-2'
											    					):(
											    					'permission-item d-grid px-2 py-1 br-2'
											    					)}>Delete<i className="bi bi-check-circle"></i></div>
											    			</Col>
									    					):''
									    			}
									    			</Row>
									    			</Col>
									    		</Row>
									    	</Col>
					    				))
					    			):''
					    	}
					    </Form.Group>


		        	</Form>
		        </Modal.Body>
		        <Modal.Footer>
		        {
		        	stafftypeAddLoad?(
		        			<Button className="btn-t1" disabled><span className="spinner-white text-white" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
		        		):(
		        		modelState!==undefined&&modelState==='add'?(
		        			<Button onClick={()=>{addNewStaffType()}} className="btn-t1" >Save</Button>
		        			):modelState==='edit'?(
		        			<Button onClick={()=>{updateStaffType()}} className="btn-t1" >Update</Button>
		        			):''
		        		)
		        	
		        }
		        </Modal.Footer>
		    </Modal>
		</Container>
		)
}