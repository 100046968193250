import React, {useContext} from 'react';
import {Container, Row, Col, Table, Spinner, Form} from 'react-bootstrap';
import { AppContext } from '../App';


export const Collections=()=>{
	const { collections, getCollectionLoad, getDate, getCollections, getBill, setViewBillModal } = useContext(AppContext);
	return(
			<Container className="pos-rel" >
				<div className="grid-split-2 bgs1 mt-2 p-2 bdr2 al-center">
				{
					getCollectionLoad?(
						<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
						):(<span>Collections</span>)
				}
				<div className="d-grid just-end al-center" >
					<Form.Control onChange={(e)=>{getCollections(e.target.value)}} type="date" defaultValue={getDate()}  className="br-3" />
				</div>
				</div>

				{
					collections!==undefined?(
						collections.main.token_count!==undefined?(
							<>
							<Row className="pt-3">
								<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3" >
									<div className="collection-status p-3 br-3">
										<span className="d-grid f18 tp2 mb-2" >New tokens</span>
										<span className="d-grid f16 ts2" >{collections.main.token_count.created}</span>
									</div>
								</Col>
								<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3" >
									<div className="collection-status p-2 p-3 br-3">
										<span className="d-grid f18 tp2 mb-2" >Processing</span>
										<span className="d-grid f16 ts2" >{collections.main.token_count.processing}</span>
									</div>
								</Col>
								<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3" >
									<div className="collection-status p-2 p-3 br-3">
										<span className="d-grid f18 tp2 mb-2" >Cancelled</span>
										<span className="d-grid f16 ts2" >{collections.main.token_count.cancelled}</span>
									</div>
								</Col>
								<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3" >
									<div className="collection-status p-2 p-3 br-3">
										<span className="d-grid f18 tp2 mb-2" >Completed</span>
										<span className="d-grid f16 ts2" >{collections.main.token_count.completed}</span>
									</div>
								</Col>
								<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3" >
									<div className="collection-status p-2 p-3 br-3">
										<span className="d-grid f18 tp2 mb-2" >Unsettled Amount</span>
										<span className="d-grid f16 ts2" >{collections.main.token_count.unsettled}</span>
									</div>
								</Col>
								<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3" >
									<div className="collection-status p-2 p-3 br-3">
										<span className="d-grid f18 tp2 mb-2" >Settled Amount</span>
										<span className="d-grid f16 ts2" >{collections.main.bill_amount_total}</span>
									</div>
								</Col>
							</Row>
							<Row className="d-flex" >
								<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3  d-grid" >
									<div className="collection-status p-2 p-3 br-3 d-grid">
										<span className="d-grid f18 tp2 mb-2" >Todays TC</span>
										<span className="d-grid f16 ts2" >{collections.main.transaction_day_total}</span>
									</div>
								</Col>
								<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3 d-grid" >
									<div className="collection-status p-2 p-3 br-3  d-grid">
										<span className="d-grid f18 tp2 mb-2" >Todays SC</span>
										<span className="d-grid f16 ts2" >{collections.main.service_day_total}</span>
									</div>
								</Col>
								<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3  d-grid" >
									<div className="collection-status p-2 p-3 br-3  d-grid">
										<span className="d-grid f18 tp2 mb-2" >Todays Credit</span>
										<span className="d-grid f16 ts2" >{collections.main.credit_day_total}</span>
									</div>
								</Col>
								<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3 d-grid" >
									<div className="collection-status p-2 p-3 br-3  d-grid">
										<span className="d-grid f18 tp2 mb-2" >Todays Debit</span>
										<span className="d-grid f16 ts2" >{collections.main.debit_amount_total}</span>
									</div>
								</Col>
								<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3 d-grid" >
									<div className="collection-status p-2 p-3 br-3  d-grid">
										<span className="d-grid f18 tp2 mb-2" >Wallet transaction</span>
										<span className="d-grid f16 ts2" >{collections.main.advance_day_total}</span>
									</div>
								</Col>
								<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3 d-grid" >
									<div className="collection-status p-2 p-3 br-3 d-grid">
										<span className="d-grid f18 tp2 mb-2" >Wallet payment</span>
										<span className="d-grid f16 ts2" >{collections.main.customer_wallet_total}</span>
									</div>
								</Col>
								<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3 d-grid" >
									<div className="collection-status p-2 p-3 br-3 d-grid">
										<span className="d-grid f18 tp2 mb-2" >Total</span>
										<span className="d-grid f16 ts2" >{collections.main.grand_day_total}</span>
									</div>
								</Col>
							</Row>
							</>
						):''
					):''
				}
				{
					collections!==undefined&&collections.wallet_values!==undefined?(
					<>
						<span className="d-grid f20 bgs1 bdr1 bdrb0 px-2 tp2 py-2" ><b>Wallet details</b></span>
			          	<Table bordered className="tbl-bg-blue" >
					      <thead>
					        <tr>
					          <th><span className="tp2" >Date</span></th>
					          <th><span className="tp2" >Customer</span></th>
					          <th><span className="tp2" >Staff</span></th>
					          <th><span className="tp2" >Payment Methods</span></th>
					          <th className="text-end" ><span className="tp2" >Total</span></th>
					        </tr>
					      </thead>
					      <tbody>
					      {
					      	Object.keys(collections.wallet_values).length>0?(
					      		Object.keys(collections.wallet_values).map((wc,wci)=>(
					      			<tr key={`wc_$wci`} className="tp2" >
					      				<td>{collections.wallet_values[wc].collected_date}
					      				</td>
					      				<td>
					      					<div className="d-grid">
								          		<span className="tp3 f16" >{collections.wallet_values[wc].customer_name}</span>
								          		<span className="tp3 f16" >{collections.wallet_values[wc].customer_phone}</span>								          		
								          	</div>
					      				</td>
					      				<td>
								          	<div class="grid-auto-100 bdrb2 mb-1 px-1" >
							          		  	<span className="tp3 f16" >Collected by {collections.wallet_values[wc].cash_collected}</span>
							          			<span className="tp3 f16 grid-justify-end" >{collections.wallet_values[wc].collected_time}</span>
							          		</div>
								        </td>
								        <td>
								          	<div className="d-grid">
								          	{
								          		collections.wallet_values[wc].ap_payments!==undefined?(
								          			Object.keys(collections.wallet_values[wc].ap_payments).length>0?(
								          				Object.keys(collections.wallet_values[wc].ap_payments).map((b_pay,b_pay_i)=>(
								          					<div class="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
											          		  	<span className="tp3 f16" >{collections.wallet_values[wc].ap_payments[b_pay].name}</span>
											          			<span className="tp3 f16 grid-justify-end" >{collections.wallet_values[wc].ap_payments[b_pay].amount}</span>
											          		</div>
								          				))
								          			):''
								          		):''
								          	}
								          	</div>
								          </td>
								          <td className="text-end" >
								          	{
							        			collections.wallet_values[wc].amount>0?(
							        				<span className="sucess-text">{collections.wallet_values[wc].amount}</span>
							        			):(
							        				<span className="error-text">{collections.wallet_values[wc].amount}</span>
							        			)
							        		}
								          </td>
					      			</tr>
					      		))
					      	):'Wallet Not found!'
					      }
					      {
					      	collections!==undefined?(
					      		collections.main.advance_total!==undefined?(
					      			Object.keys(collections.main.advance_total).length>0?(
					      				Object.keys(collections.main.advance_total).map((r_pay,r_pay_i)=>(
					      					collections.main.advance_total[r_pay].amount!==0?(
							      				<tr key={`r_pay_x${r_pay_i}`}>
										        	<td colSpan={3} ></td>
										        	<td className="text-end" ><span className="tp1 f16" >{collections.main.advance_total[r_pay].name}</span></td>
										        	<td className="text-end" >
										        		{
										        			
											        			collections.main.advance_total[r_pay].amount>0?(
											        				<span className="sucess-text">{collections.main.advance_total[r_pay].amount}</span>
											        			):(
											        				<span className="error-text">{collections.main.advance_total[r_pay].amount}</span>
											        			)
											        			
										        		}
										        	</td>
										        </tr>
									        ):''
								        ))
					      			):''
					      		):''
					      	):''
					      }
					      {
					      	collections!==undefined?(
					      		collections.main!==undefined?(
					      			<tr>
					      				<td colSpan={3} ></td>
							        	<td className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
							        	<td className="text-end bgy" >{collections.main.advance_day_total}</td>
							        </tr>
					      		):''
					      	):''
					      }	
					      </tbody>
						</Table>
					</>
					):'Wallet Not found!'
				}
				{
	          		collections!==undefined?(
	          			collections.debit_values!==undefined&&Object.keys(collections.debit_values).length>0?(
	          				<>
		          				<span className="d-grid f18 tp2 py-2" >Credit collection details</span>
					          	<Table bordered className="tbl-bg-blue" >
							      <thead>
							        <tr>
							          <th><span className="tp2" >Customer</span></th>
							          <th><span className="tp2" >Bill details</span></th>
							          <th><span className="tp2" >Times</span></th>
							          <th><span className="tp2" >Payment Methods</span></th>
							          <th className="text-end" ><span className="tp2" >Total</span></th>
							        </tr>
							      </thead>
							      <tbody>
							      {
							      	Object.keys(collections.debit_values).map((debit,deb_i)=>(
							      	<tr key={`debi_${deb_i}`} >
							      		<td>
							      			<span className="tp3 f16" >{collections.debit_values[debit].customer_name}</span>
							          		<span className="tp3 f16 d-grid mb-1" >{collections.debit_values[debit].customer_phone}</span>
							          		<span onClick={()=>{
															getBill(collections.debit_values[debit].bill_no);
															setViewBillModal(true);
														}} className="view-bill-link mx-0" >View bill</span>
							      		</td>
							      		<td>
								          	<div className="d-grid">
								          		<span className="tp1 f16" >Token No : {collections.debit_values[debit].token_no}</span>
								          		<span className="tp3 f16" >Bill No : {collections.debit_values[debit].bill_no}</span>
								          		<span className="tp3 f16" >Date : {collections.debit_values[debit].collected_date}</span>
								          	</div>
								        </td>
								        <td>
								          	<div class="grid-auto-100 bdrb2 mb-1 px-1" >
							          		  	<span className="tp3 f16" >Collected by {collections.debit_values[debit].cash_collected}</span>
							          			<span className="tp3 f16 grid-justify-end" >{collections.debit_values[debit].collected_time}</span>
							          		</div>
								        </td>
								        <td>
								          	<div className="d-grid">
								          	{
								          		collections.debit_values[debit].debit_payments!==undefined?(
								          			Object.keys(collections.debit_values[debit].debit_payments).length>0?(
								          				Object.keys(collections.debit_values[debit].debit_payments).map((b_pay,b_pay_i)=>(
								          					<div class="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
											          		  	<span className="tp3 f16" >{collections.debit_values[debit].debit_payments[b_pay].name}</span>
											          			<span className="tp3 f16 grid-justify-end" >{collections.debit_values[debit].debit_payments[b_pay].amount}</span>
											          		</div>
								          				))
								          			):''
								          		):''
								          	}
								          	</div>
								          </td>
								          <td className="text-end pos-rel" >
								          	<span className="tp1 f18" >{collections.debit_values[debit].debit_total}</span>
								          </td>
							      	</tr>
							      	))
							      }
							      {
							      	collections!==undefined?(
							      		collections.main.debit_total!==undefined?(
							      			Object.keys(collections.main.debit_total).length>0?(
							      				Object.keys(collections.main.debit_total).map((r_pay,r_pay_i)=>(
								      				collections.main.debit_total[r_pay].amount>0?(
									      				<tr key={`r_pay_${r_pay_i}`}>
												        	<td colSpan={3} ></td>
												        	<td className="text-end" ><span className="tp1 f16" >{collections.main.debit_total[r_pay].name}</span></td>
												        	<td className="text-end" >{collections.main.debit_total[r_pay].amount}</td>
												        </tr>
												    ):''
										        ))
							      			):''
							      		):''
							      	):''
							      }
							      {
							      	collections!==undefined?(
							      		collections.main!==undefined?(
							      			<tr>
							      				<td colSpan={3} ></td>
									        	<td className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
									        	<td className="text-end bgy" >{collections.main.debit_amount_total}</td>
									        </tr>
							      		):''
							      	):''
							      }		
							      </tbody>
							    </Table>
						    </>
					    ):''
	          		):''
	          	}
	          	<span className="d-grid f18 tp2 py-2" >Collection details</span>
				<Table bordered className="tbl-bg-blue" >
				      <thead>
				        <tr>
				          <th><span className="tp2" >Token</span></th>
				          <th><span className="tp2" >Services</span></th>
				          <th><span className="tp2" >Customer</span></th>
				          <th><span className="tp2" >Times</span></th>
				          <th><span className="tp2" >Payment Methods</span></th>
							          <th className="text-end tp2" ><span>TC</span></th>
							          <th className="text-end tp2" ><span>SC</span></th>
				          <th className="text-end" ><span className="tp2" >Total</span></th>
				        </tr>
				      </thead>
				      <tbody>
				      {
				      	collections!==undefined?(
				      		collections.bill_values!==undefined?(
				      			Object.keys(collections.bill_values).map((tkn,tkn_i)=>(
				      				<tr key={`trep_${tkn_i}`} >
							          <td>
							          	<span className="tp1 f18" >No : {collections.bill_values[tkn].token_no}</span>
							          	<span className="tp3 f16 d-grid mb-1" >Bill No : {collections.bill_values[tkn].bill_no}</span>
							          	<span onClick={()=>{
																getBill(collections.bill_values[tkn].bill_no);
																setViewBillModal(true);
															}} className="view-bill-link mx-0" >View bill</span>
							          </td>
							          <td>
							          	<div className="d-grid">
							          	{
							          		collections.bill_values[tkn].bill_services!==undefined?(
							          			Object.keys(collections.bill_values[tkn].bill_services).length>0?(
							          				Object.keys(collections.bill_values[tkn].bill_services).map((t_ser,t_ser_i)=>(
							          					<span className="tp3 f16" key={`tkrpt_${t_ser_i}`} >{collections.bill_values[tkn].bill_services[t_ser].service_name}{collections.bill_values[tkn].bill_services[t_ser].quantity!==undefined&&collections.bill_values[tkn].bill_services[t_ser].quantity>1?(<> ({collections.bill_values[tkn].bill_services[t_ser].quantity})</>):''}</span>
							          				))
							          			):''
							          		):''
							          	}
							          	</div>
							          </td>
							          <td>
							          	<div className="d-grid">
							          		<span className="tp3 f16" >{collections.bill_values[tkn].customer_name}</span>
							          		<span className="tp3 f16" >{collections.bill_values[tkn].customer_phone}</span>
							          	</div>
							          </td>
							          <td>
							          	<div className="d-grid">
							          		  <div className="grid-auto-100  bdrb2 mb-1 px-1">
							          		  	<span className="tp3 f16" >Created - {collections.bill_values[tkn].token_created_by}</span>
							          		  	<span className="tp3 f16 grid-justify-end" >{collections.bill_values[tkn].created_time}</span>
							          		  </div>
							          		  <div className="grid-auto-100  bdrb2 mb-1 px-1">
							          		  	<span className="tp3 f16" >Processed - {collections.bill_values[tkn].token_processed_by}</span>
							          		  	<span className="tp3 f16 grid-justify-end" >{collections.bill_values[tkn].open_time}</span>
							          		  </div>
							          		  <div className="grid-auto-100 bdrb2 mb-1 px-1">
							          		  	<span className="tp3 f16" >Closed - {collections.bill_values[tkn].token_processed_by}</span>
							          		  	<span className="tp3 f16 grid-justify-end" >{collections.bill_values[tkn].close_time}</span>
							          		  </div>
							          	</div>
							          </td>
							          <td>
							          	<div className="d-grid">
							          	{
							          		collections.bill_values[tkn].bill_payments!==undefined?(
							          			Object.keys(collections.bill_values[tkn].bill_payments).length>0?(
							          				Object.keys(collections.bill_values[tkn].bill_payments).map((b_pay,b_pay_i)=>(
							          					<div className="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
										          		  	<span className="tp3 f16" >{collections.bill_values[tkn].bill_payments[b_pay].name}</span>
										          			<span className="tp3 f16 grid-justify-end" >{collections.bill_values[tkn].bill_payments[b_pay].amount}</span>
										          		</div>
							          				))
							          			):''
							          		):''
							          	}
							          	</div>
							          </td>

							          <td className="text-end pos-rel" >
							          	<span className="tp1 f18" >{collections.bill_values[tkn].transaction_bill_total}</span>
							          </td>
							          <td className="text-end pos-rel" >
							          	<span className="tp1 f18" >{collections.bill_values[tkn].service_bill_total}</span>
							          </td>
							          <td className="text-end pos-rel" >
							          	<span className="tp1 f18" >{collections.bill_values[tkn].bill_total}</span>
							          </td>
							        </tr>
				      			))
				      		):''
				      	):''
				      }
				      {
				      	collections!==undefined?(
				      		collections.main!==undefined?(
				      			<tr>
							      	<td colSpan={4} ></td>
							      	<td className="text-end f16 tp2" ><span>Total</span></td>
							      	<td className="text-end f16 tp2" ><span>{collections.main.transaction_day_total}</span></td>
							      	<td className="text-end f16 tp2" ><span>{collections.main.service_day_total}</span></td>
							      	<td className="text-end f16 tp2" ><span></span></td>
							      </tr>
				      		):''
				      	):''
				      }
				      
				      {
				      	collections!==undefined?(
				      		collections.main.payments_total!==undefined?(
				      			Object.keys(collections.main.payments_total).length>0?(
				      				Object.keys(collections.main.payments_total).map((r_pay,r_pay_i)=>(
					      				collections.main.payments_total[r_pay].amount>0?(
						      				<tr key={`r_pay_${r_pay_i}`} >
									        	<td colSpan={4} ></td>
									        	<td colSpan={3} className="text-end" ><span className="tp1 f16" >{collections.main.payments_total[r_pay].name}</span></td>
									        	<td className="text-end" >{collections.main.payments_total[r_pay].amount}</td>
									        </tr>
									    ):''
							        ))
				      			):''
				      		):''
				      	):''
				      }
				      {
				      	collections!==undefined?(
				      		collections!==undefined?(
				      			<tr>
				      				<td colSpan={4} ></td>
						        	<td colSpan={3} className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
						        	<td className="text-end bgy" >{collections.main.bill_amount_total}</td>
						        </tr>
				      		):''
				      	):''
				      }											        
				      </tbody>
				</Table>
				{
			    	collections!==undefined&&collections.expense_values!==undefined&&Object.keys(collections.expense_values).length>0?(
			    		<>
			    			<span className="d-grid f18 tp2 py-2" >Expense details</span>
			    			<Table bordered className="tbl-bg-blue" >
							    <thead>
						        <tr>
						          <th className="tp2" >Particulars</th>
						          <th className="tp2" >Remarks</th>
						          <th className="tp2" >Staff</th>
						          <th className="tp2" >Payment method</th>
						          <th className="text-end tp2" >Amount</th>
						        </tr>
							    </thead>
							    <tbody>
							    {
							    	Object.keys(collections.expense_values).map((exp,expi)=>(
							    		<tr key={`expi_${expi}`} className="tc2" > 
		        						<td>
		        							<span className="d-grid" >{collections.expense_values[exp].particulars}</span>
		        						</td>
		        						<td>{collections.expense_values[exp].remarks}</td>
		        						<td>{collections.expense_values[exp].user}</td>
		        						<td>{collections.expense_values[exp].payment_type}</td>
		        						<td className="text-end">{collections.expense_values[exp].expense_total}</td>
		        					</tr>
							    	))
							    }
							    <tr>
										<td colSpan={3} className="tp2 text-end f20 f-400">Total</td>
										<td>
											{
												collections.main.expense_total!==undefined&&Object.keys(collections.main.expense_total).length>0?(
													Object.keys(collections.main.expense_total).map((ex,exi)=>(
														collections.main.expense_total[ex].amount>0?(
															<div class="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${exi}`} >
											          		  	<span className="tp2 f16" >{collections.main.expense_total[ex].name}</span>
											          			<span className="tp2 f16 grid-justify-end" >{collections.main.expense_total[ex].amount}</span>
											          		</div>
										          		):''
													))
												):''
											}
										</td>
										<td className="text-end f20 f-400">{collections.main!==undefined?collections.main.expense_day_total:''}</td>
									</tr>
							    </tbody>
							   </Table>
			    		</>
			    	):''
			    }
			</Container>
		)
}