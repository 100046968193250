import React, {useContext, useEffect, useState} from 'react';
import {Container, Col, Button, Form, Modal, Spinner} from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import { AppContext } from '../App';

export const PayWallet=()=>{
	const { accessToken, user, setAuthForm, link, postCall, getLoad, payWallet, setPayWallet  } = useContext(AppContext);

	const [ newPayModel, setNewPayModel ] = useState(false);
	useEffect(()=>{
		if (!newPayModel) {
			setSelectedPay();
		}
	},[newPayModel]);
	const [ editPay, setEditPay ] = useState(false)	
	const [ addLoad, setAddLoad ] = useState(false);
	const [ nameReqErr, setNameReqErr ] = useState(false);
	const addPaymentMethod=(e)=>{
		e.preventDefault();
		let pay=e.target.name.value;
		if(pay === ""){
			setNameReqErr(true);
			setTimeout(()=>{setNameReqErr(false)},5000);
		}
		if (pay!==""&&selectedPay===undefined) {
			setAddLoad(true);
			postCall('api/pw/add/wallet/',{name:pay}).then(res=>{
				setAddLoad(false);
				setPayWallet(res.data);
				setNewPayModel(false);
			}).catch(err=>{
				setAddLoad(false);
			});

		}else if (pay!==""&&selectedPay!==undefined){
			setAddLoad(true);
			postCall('api/pw/edit/wallet/',{name:pay, w_id:selectedPay.id.substring(1)}).then(res=>{
				setAddLoad(false);
				setPayWallet(res.data);
				setNewPayModel(false);
			}).catch(err=>{
				setAddLoad(false);
			});
		}
	}
	const [ selectedPay, setSelectedPay ] = useState();
	const selectedPayM=(payId)=>{
		setSelectedPay({id:payId,name:payWallet[payId].name});
		$('#pay_m_name').val(payWallet[payId].name);
		setNewPayModel(true);
	}
	const [ changeStatLoad, setChangeStatLoad ] = useState(false);
	const [changeStatPayId, setChangeStatPayId ] = useState();
	const changePayMStatus=(pyid,paystst)=>{
		setChangeStatLoad(true);
		setChangeStatPayId(pyid);
		postCall('api/pw/status/wallet/',{status:paystst, w_id:pyid.substring(1)}).then(res=>{
			setChangeStatLoad(false);
			setPayWallet(res.data);
		}).catch(err=>{
			setChangeStatLoad(false);
		});
	}
	return (
		<Container className="my-3" >

    		<Col  xs={12} sm={12} md={12} lg={12} className='bdr2 br-3' >
				<Col xs="12" sm="12" md="12" lg="12" className="staff-type-header bgs1 d-grid p-2 bdrb2" >
					<span>{getLoad?(<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>):'Payment Wallets'}</span>
					<Button onClick={()=>{
						setNewPayModel(true);
						setEditPay(false);
					}} className="btn-t1"  ><i className="bi bi-plus-circle"></i> Add new</Button>
				</Col>
				{
					payWallet!==undefined?(
						Object.keys(payWallet).length>0?(
							Object.keys(payWallet).map((payM, pay_i)=>(


							<Col xs="12" sm="12" md="12" lg="12" className="service-item d-grid p-2 bdrb2" >
								<span className="tp2 f16">{payWallet[payM].name}</span>
								<Button onClick={()=>{
									selectedPayM(payM);
									setEditPay(true);
								}} className="btn-t1-r" ><i className="bi bi-pencil-square"></i> Edit</Button>
								{
									changeStatPayId!==undefined&&changeStatLoad&&changeStatPayId===payM?(
		        						<Button className="please-wait-spinner" disabled><Spinner animation="border" size="sm" /></Button>
									):(
										payWallet[payM].status?(
											<Button onClick={()=>{changePayMStatus(payM,false)}} className="btn-disable" ><i className="bi bi-eye-slash"></i> Disable</Button>
										):(
											<Button onClick={()=>{changePayMStatus(payM,true)}}  className="btn-enable" ><i className="bi bi-eye-slash"></i> Enable</Button>
										)
									)
								}
							</Col>

							))
						):'Nothing created yet'
					):''
				}
				
			</Col>
			<Modal size="xl" show={newPayModel} backdrop="static" onHide={()=>{setNewPayModel(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>{editPay?'Update':'Add New'} Payment Method</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={addPaymentMethod} >
		        		<Form.Group className="mb-3">
		        			<Form.Label>Name {nameReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
		        			<Form.Control name='name' autoFocus="on" defaultValue={selectedPay!==undefined?selectedPay.name:''} className="shadow-none br-2" />
		        		</Form.Group>
		        		<div className='d-grid'>
		        		{
		        			addLoad?(
		        				<Button className="please-wait-spinner" disabled><Spinner animation="border" size="sm" /> Please wait...</Button>
		        			):(
		        				<Button type="submit" className="btn-t1">SAVE</Button>
		        			)
		        		}
		        		</div>
		        	</Form>
		        </Modal.Body>
		        <Modal.Footer></Modal.Footer>
		    </Modal>
		</Container>
		)
}