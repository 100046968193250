import React, {useContext, useState} from 'react';
import {Container, Row, Col, Table, Spinner, Form, InputGroup, Button, Modal} from 'react-bootstrap';
import { AppContext } from '../App';


export const SalaryCalcReport=()=>{
	const { getCreditReport, setSalaryCalcRepo, salaryCalcRepo, getDate, postCall, load, setLoad, setGetLoad, getLoad} = useContext(AppContext);
	const getReport=(e)=>{
		e.preventDefault();
		let start = e.target.start.value;
		let end = e.target.end.value;
		let staff = e.target.staff.value;
		if (start!==""&&end!=="") {
			setLoad(true);
			postCall('api/bnr/get/salary/calculation/report/',{start_date:start, end_date:end,staff_id:staff}).then(res=>{
				setSalaryCalcRepo(res.data);
				setLoad(false);
			}).catch(err=>{
				setLoad(false);
			});
		}
	}
	const [ activeId, setActiveId ] = useState();
	const [ staffSalaryCalc, setStaffSalaryCalc ] = useState();
	const [ salaryCalcModal, setSalaryCalcModal ] = useState(false);
	return(
			<Container className="py-3" >
				<Form onSubmit={getReport} >
					<Row className="mb-3" >
						<Col xs={12} sm={4} md={3} >
							<InputGroup  className="br-3">
						        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">From</InputGroup.Text>
						        <Form.Control name="start" type="date" defaultValue={getDate()}  className="br-3" />
						    </InputGroup>
						</Col>
						<Col xs={12} sm={4} md={3} >
							<InputGroup  className="br-3">
						        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">To</InputGroup.Text>
						        <Form.Control name="end" type="date" defaultValue={getDate()}  className="br-3" />
						    </InputGroup>
						</Col>						
						<Col xs={12} sm={4} md={3} >
							<Form.Select name="staff" className="shadow-none br-2">
						      <option value="" >All staffs</option>
						      {
						      	salaryCalcRepo!==undefined?(
						      		salaryCalcRepo.users!==undefined?(
						      			Object.keys(salaryCalcRepo.users).length>0?(
						      				Object.keys(salaryCalcRepo.users).map((staff,staff_i)=>(
						      					<option value={staff.substring(1)} key={`user_${staff_i}`} >{salaryCalcRepo.users[staff].name}</option>
						      				))
						      			):''
						      		):''
						      	):''
						      }
						    </Form.Select>
						</Col>
						<Col xs={12} sm={4} md={3} className="d-grid" >
							<Button type="submit" className="btn-t1" >Get Report</Button>
						</Col>
					</Row>
				</Form> 
				{
					load?(
						<div className="d-grid py-2">
							<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
						</div>
					):(
						salaryCalcRepo!==undefined&&salaryCalcRepo.salary_values!==undefined&&Object.keys(salaryCalcRepo.salary_values).length>0?(
							<Table bordered className="tbl-bg-blue" >
						    <thead>
						        <tr>
						          <th className="tp2" >Date</th>
						          <th className="tp2" >Staff Name</th>
						          <th className="tp2" >Created by</th>
						          <th className="tp2" >From</th>
						          <th className="tp2" >To</th>
						          <th className="tp2 text-end" >System Generated Salary</th>
						          <th  className="tp2 text-end" >Salary</th>
						        </tr>
						    </thead>
						    <tbody>
						    	{
						    		Object.keys(salaryCalcRepo.salary_values).map((item,i)=>(
						    			<tr key={`salary_${i}`} >
						    				<td>
						    					<div className="d-flex gap-2 mb-1">{salaryCalcRepo.salary_values[item].creation_date}, {salaryCalcRepo.salary_values[item].creation_time}</div>
						    					<Button onClick={()=>{
													setGetLoad(true);
													setActiveId(item);
													setSalaryCalcModal(true);
													postCall('api/sm/get/staff/salary/calculated/report/',{s_id:item.substring(1)}).then(res=>{
														setGetLoad(false);
														setStaffSalaryCalc(res.data);												
													}).catch(err=>{
														setGetLoad(false);
														setActiveId(item);
													});
												}} className="btn-t1" size="sm" ><i className="bi bi-calendar3"></i> View Report</Button>
						    				</td>
						    				<td>{salaryCalcRepo.salary_values[item].staff}</td>
						    				<td>{salaryCalcRepo.salary_values[item].created_by}</td>
						    				<td>{salaryCalcRepo.salary_values[item].from}</td>
						    				<td>{salaryCalcRepo.salary_values[item].to}</td>
						    				<td  className="tp2 text-end" >{salaryCalcRepo.salary_values[item].salary_gen}</td>
						    				<td  className="tp2 text-end" >{salaryCalcRepo.salary_values[item].salary}</td>
						    			</tr>	
						    		))
						    	}
							</tbody>
						</Table>
						):''
					)
				}
				<Modal size="xl" show={salaryCalcModal} backdrop="static" onHide={()=>{setSalaryCalcModal(false)}}>
		        	<Modal.Header closeButton>
			          <Modal.Title>{getLoad?(<span className="please-wait-spinner f18" ><Spinner animation="border" size="sm" /> Please wait..</span>):'Salary Calculation'}</Modal.Title>
				        </Modal.Header>
				        <Modal.Body>
				        	{
				        		staffSalaryCalc!==undefined&&staffSalaryCalc.attendance!==undefined&&Object.keys(staffSalaryCalc.attendance.attendance).length>0?(
			        				<>
				        				<span className="d-flex gap-2 f18 tp2 mb-1">System generated salary report from <b>{staffSalaryCalc.main.from}</b> to <b>{staffSalaryCalc.main.to}</b></span>
				        				<span className="d-flex gap-2 f18 tp2 mb-2">System Calculated salary : <span className="bg-total px-2 br-3">{parseInt(staffSalaryCalc.attendance.total.total_payable)+parseInt(staffSalaryCalc.additions.total.amount)}</span></span>
				        				<span className="d-flex gap-2 f18 tp2 mb-2">Salary added to wallet : <span className="bg-total px-2 br-3">{staffSalaryCalc.main.salary}</span></span>
				        				<span className="d-flex gap-2 f20 bgs1 bdr1 bdrb0 px-2 tp2 py-2" >Attendance of <b>{staffSalaryCalc.attendance.total.total_days}</b> days</span>
				        				<Table bordered className="tbl-bg-blue" >
										    <thead>
										        <tr className="tp2">
										          <th>Date</th>
										          <th>Updated by</th>
										          <th>Login time</th>
										          <th>Log out time</th>
										          <th>Overtime ( hours )</th>
										          <th>Attendance</th>
										        </tr>
										    </thead>			    
										    <tbody>	
										    {
										    	Object.keys(staffSalaryCalc.attendance.attendance).map((item,i)=>(
									    			<tr key={`att_${i}`} className="tp2" >
									    				<td>
									    					<span className="d-grid f16" >{staffSalaryCalc.attendance.attendance[item].date}</span>
									    					<span className="d-grid f14" >{staffSalaryCalc.attendance.attendance[item].day}</span>
									    				</td>
									    				<td>{staffSalaryCalc.attendance.attendance[item].updated_by}</td>
									    				<td>{staffSalaryCalc.attendance.attendance[item].login}</td>
									    				<td>{staffSalaryCalc.attendance.attendance[item].log_out}</td>
									    				<td>{staffSalaryCalc.attendance.attendance[item].over_time}</td>
									    				<td>{staffSalaryCalc.attendance.attendance[item].attendance}</td>
									    			</tr>
									    		))
										    }
										    {
										    	staffSalaryCalc.attendance.total!==undefined?(
										    		<tr>
										    			
										    			<td colSpan="3" >
										    				<div className="grid-split-2 al-center bdrb2">
									    						<span>Total Full day</span>
									    						<div className="d-flex just-end">
									    							<span>{staffSalaryCalc.attendance.total.total_full}</span>
									    						</div>
									    					</div>
									    					<div className="grid-split-2 al-center bdrb2">
									    						<span>Total Half day</span>
									    						<div className="d-flex just-end">
									    							<span>{staffSalaryCalc.attendance.total.total_half}</span>
									    						</div>
									    					</div>
									    					<div className="grid-split-2 al-center bdrb2">
									    						<span>Total leave</span>
									    						<div className="d-flex just-end">
									    							<span>{staffSalaryCalc.attendance.total.total_leave}</span>
									    						</div>
									    					</div>
									    					<div className="grid-split-2 al-center">
									    						<span>Total Overtime</span>
									    						<div className="d-flex just-end">
									    							<span>{staffSalaryCalc.attendance.total.total_over}</span>
									    						</div>
									    					</div>
										    			</td>
										    			<td colSpan="3" className="f18">
										    				<div className="grid-split-2 al-center bdrb2">
									    						<span>Salary</span>
									    						<div className="d-flex just-end">
									    							<span>{staffSalaryCalc.attendance.total.salary}</span>
									    						</div>
									    					</div>
									    					<div className="grid-split-2 al-center bdrb2">
									    						<span>Overtime</span>
									    						<div className="d-flex just-end">
									    							<span>{staffSalaryCalc.attendance.total.over_time_val}</span>
									    						</div>
									    					</div>
									    					<div className="grid-split-2 al-center bdrb2">
									    						<span>Leave deduction</span>
									    						<div className="d-flex just-end">
									    							<span>{staffSalaryCalc.attendance.total.leave_deduction}</span>
									    						</div>
									    					</div>
									    					<div className="grid-split-2 al-center">
									    						<span>Total Payable</span>
									    						<div className="d-flex just-end">
									    							<span>{staffSalaryCalc.attendance.total.total_payable}</span>
									    						</div>
									    					</div>
										    			</td>
										    		</tr>
										    	):''
										    }		    
										    </tbody>
										</Table>
									</>	
				        		):''
				        	}
				        	{
				        		staffSalaryCalc!==undefined&&staffSalaryCalc.additions!==undefined&&Object.keys(staffSalaryCalc.additions.addition).length>0?(
				        			<>
				        				<span className="d-grid f20 bgs1 bdr1 bdrb0 px-2 tp2 py-2" >Additions & Deductions</span>
				        				<Table bordered className="tbl-bg-blue" >
										    <thead>
										        <tr className="tp2">
										          <th>Date</th>
										          <th>Updated by</th>								          
										          <th>Reason</th>
										          <th>Amount</th>
										        </tr>
										    </thead>			    
										    <tbody>	
										    {
										    	Object.keys(staffSalaryCalc.additions.addition).map((item,i)=>(
									    			<tr key={`additions_${i}`} className="tp2" >
									    				<td>{staffSalaryCalc.additions.addition[item].date}</td>
									    				<td>{staffSalaryCalc.additions.addition[item].created_by}</td>
									    				<td>{staffSalaryCalc.additions.addition[item].particulars}</td>
									    				<td>
									    					{
										    					staffSalaryCalc.additions.addition[item].payment_type==='debit'?(
										    						<span className="sucess-text">+{staffSalaryCalc.additions.addition[item].amount}</span>
										    					):(
										    						<span className="error-text">-{staffSalaryCalc.additions.addition[item].amount}</span>
										    					)
										    				}							    				
									    				</td>
									    			</tr>
									    		))
										    }
										    {
										    	staffSalaryCalc.additions.total!==undefined?(
										    		<tr>
										    			<td colSpan="3"  className="text-end f18" >Total</td>
										    			<td colSpan="2"  className="f18">
										    			{
										    				parseInt(staffSalaryCalc.additions.total.amount)>0?(
										    					<span className="sucess-text" >{staffSalaryCalc.additions.total.amount}</span>
										    				):parseInt(staffSalaryCalc.additions.total.amount)<0?(
										    					<span className="error-text" >{staffSalaryCalc.additions.total.amount}</span>
										    				):(
										    					<span>{staffSalaryCalc.additions.total.amount}</span>
										    				)
										    			}</td>
										    		</tr>
										    	):''
										    }		    
										    </tbody>
										</Table>
				        			</>
				        		):''
				        	}
				        </Modal.Body>
				        <Modal.Footer></Modal.Footer>
				    </Modal>
			</Container>
		)
}