import React, {useState, useEffect, useContext} from 'react';
import {Container, Navbar, Nav, NavDropdown, Dropdown, Modal, Form, Row, Col, InputGroup, Button, Spinner, Accordion, Table } from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../App';


export const MainNavbar=()=>{
	const { setPage, user, link, getCall, postCall, setGetLoad, getPermissions, getStaffTypes, getStaffs, getServices, getCustomers, getEnquiry, getPayModes,
	getTokens, getTokenServices, getTokenCustomers, getPaymentMethods, closeDayState, setAuthForm, accessToken, dayClose, setExpense, setLoad, setReOpenRepo,
	setDayClose, getCollections, getNotifications, closeDayReport, setCloseDayReport, getSpecialTokens, account, setExpenseParti, setPettyExpense, setExpenseReport,
	setAccount, getDayCloseToVerify, getCredits, getQuickReport, getCustomerReport, getServiceReport, getStaffReport, payWalletReport, setPayWalletReport,
	getCollectionReport, getCreditReport, getDetailReport, getCancelReport, getHomePage, getSpecialBills, getQuickReportToday, setPayWallet, setWalletCus,
	getWorks, getSubmitted, stateSettle, stateSettleAll, getQuickReportYear, setStaffTokenReport, getDate, setDetailTreport, setIncompleteTokenReport,
	setSalaryStaff, setAttendence, setSmDiStaffs, setSalaryCalcRepo, setSalaryPayRepo } = useContext(AppContext);

	const [ dayCloseModal, setDayCloseModal ] = useState(false);
	const [ dayCloseState, setDayCloseState ] = useState('denomination');
	const [ addDenominationLoad, setAddDenominationLoad ] = useState(false);	
	const addDenominations=(e)=>{
		e.preventDefault();
		let n_500 = e.target.n_500.value;
		let n_200 = e.target.n_200.value;
		let n_100 = e.target.n_100.value;
		let n_50 = e.target.n_50.value;
		let n_20 = e.target.n_20.value;
		let n_10 = e.target.n_10.value;
		let n_5 = e.target.n_5.value;
		let n_2 = e.target.n_2.value;
		let n_1 = e.target.n_1.value;
		let open = e.target.opening.value;
		if (n_500!==""||n_200!==""||n_100!==""||n_50!==""||n_20!==""||n_10!==""||n_5!==""||n_2!==""||n_1!=="") {
			$.confirm({
				    title: 'Confirm Day Close!',
				    content: 'Are you sure you want to close day?',
				    type: 'blue',
				    typeAnimated: true,
				    buttons: {
				        confirm: {
				            text: 'Confirm',
				            btnClass: 'btn-blue',
				            action: function(){				            
								setAddDenominationLoad(true);
								axios.post(`${link}api/pnp/day/close/`,{
									n_500:n_500,
									n_200:n_200,
									n_100:n_100,
									n_50:n_50,
									n_20:n_20,
									n_10:n_10,
									n_5:n_5,
									n_2:n_2,
									n_1:n_1,
									opening_balance:open,
							        },{
							          headers:{
							            Authorization: 'Bearer '+accessToken
							          }
							        }).then((response)=>{
							        	setAddDenominationLoad(false);
							        	setDayClose(false);
							        	if (response.data.main.diffrence===0) {
							        		setDayCloseModal(false);
							        	}else{
							        		setDayCloseState('addReason');
							        		setCloseDayReport(response.data);
							        	}		        	
							        }).catch((err)=>{
							        	setAddDenominationLoad(false);
								        if(err.response.status === 401){
								            setAuthForm(true);
								        }else if(err.response.status === 404){
								            console.log('Close_Day- ',err.response.status,':',err.response.statusText);
								        }else if(err.response.status === 500){
								            console.log('Close_Day - ',err.response.status,':',err.response.statusText);
								        }else{
								            console.log('Close_Day - ',err.response.status,':',err.response.data);
								        }
							    });
					        }
				        },
				        cancel: function () {
				        }
				    }
			});
		}
	}
	const [ reasonSubmitLoad, setReasonSubmitLoad ] = useState(false);
	const submitReason=(e)=>{
		e.preventDefault();
		let reason = e.target.reason.value;
		if (reason!=="") {
			setReasonSubmitLoad(true);
			postCall('api/pnp/day/close/reason/',{ reason:reason }).then((response)=>{
      	setReasonSubmitLoad(false);
      	setDayCloseModal(false);		        	
      }).catch((err)=>{
      	setReasonSubmitLoad(false);
      });
		}
	}
	const [ dayCloseReportModal, setDayCloseReportModal ] = useState(false);
	const [ getDayReportLoad, setGetDayReportload ] = useState(false);
	const getDayReport=()=>{
		setDayCloseReportModal(true);
		setGetDayReportload(true);
	    axios.get(`${link}api/pnp/day/close/user/report/`,{
	        headers:{
	                Authorization: 'Bearer '+accessToken
	              }
	      }).then(response=>{
	        setGetDayReportload(false);
	        setCloseDayReport(response.data);
	      }).catch(err=>{
	        setGetDayReportload(false);
	        if(err.response.status === 401){
	              setAuthForm(true);
	          }else if(err.response.status === 404){
	              console.log('Get_day_close_report - ',err.response.status,':',err.response.statusText);
	          }else if(err.response.status === 500){
	              console.log('Get_day_close_report - ',err.response.status,':',err.response.statusText);
	          }else{
	              console.log('Get_day_close_report - ',err.response.status,':',err.response.data);
	          }
	      });
	}
	const [ profileModal, setProfileModal ] = useState(false);
	const [ profileUpdateLoad, setProfileUpdateLoad ] = useState(false);
	const [ nameReqErr, setNameReqErr ] = useState(false);
	const [ emailReqErr, setEmailReqErr ] = useState(false);
	const [ changeLogo, setChangeLogo ] = useState(false);
	useEffect(()=>{
		if (account!==undefined) {
			if (account.logo==="") {
				setChangeLogo(true);
			}else{
				setChangeLogo(false);
			}
		}
	},[account]);
	const [ logoReqErr, setLogoReqErr ] = useState(false);
	const [ addressReqErr, setAddressReqErr ] = useState(false);
	const [ contactReqErr, setContactReqErr ] = useState(false);
	const postProfile=(logo,name,address,contact,email)=>{
		setProfileUpdateLoad(true);
		postCall(`${link}api/pnp/update/account/`,{
        	file:logo,
        	name:name,
        	address:address,
        	phone:contact,
        	email:email,
        },{
          headers:{
            Authorization: 'Bearer '+accessToken,
            "Content-Type": "multipart/form-data",
          }
        }).then((response)=>{
          	setProfileUpdateLoad(false);
          	setAccount(response.data.account);
          	setProfileModal(false);
        }).catch((err)=>{
          	setProfileUpdateLoad(false);
          	if(err.response.status === 401){
	      		setAuthForm(true);
	      	}else if(err.response.status === 404){
	      	console.log('Update_account ',err.response.status,':',err.response.statusText);
	      	}else if(err.response.status === 500){
	      		console.log('Update_account - ',err.response.status,':',err.response.statusText);
	     	}else{
	            console.log('Update_account - ',err.response.status,':',err.response.data);
	        }          
    });
	}
 	const updateProfile=(e)=>{
		e.preventDefault();
		let name = e.target.name.value;
		let address = e.target.address.value;
		let contact = e.target.contact.value;
		let logo ='';
		if (changeLogo){
			logo = e.target.logo.files[0];
		}
		let email = e.target.email.value;
		if (name==="") {
			setNameReqErr(true);
			setTimeout(()=>{setNameReqErr(false)},5000);
		}
		if (email==="") {
			setEmailReqErr(true);
			setTimeout(()=>{setEmailReqErr(false)},5000);
		}
		if (changeLogo) {
			if (logo===undefined||logo==="") {
				setLogoReqErr(true);
				setTimeout(()=>{setLogoReqErr(false)},5000);
			}
		}			
		if (address==="") {
			setAddressReqErr(true);
			setTimeout(()=>{setAddressReqErr(false)},5000);
		}
		if (contact==="") {
			setContactReqErr(true);
			setTimeout(()=>{setContactReqErr(false)},5000);
		}
		if (changeLogo) {
			if(name!==""&&email!==""&&logo!==undefined&&logo!==""&&address!==""&&contact!==""){
				postProfile(logo,name,address,contact,email);
		    }
		}else{
			if(name!==""&&email!==""&&address!==""&&contact!==""){
				postProfile(logo,name,address,contact,email);
		    }
		}
	}
	const [ changePasswordModal, setChangePasswordModal ] = useState(false);
	const [ npassError, setNpassError ] = useState(false);
	const [ ncpassError, setNcpassError ] = useState(false);
	const [ npassEqualError, setNpassEqualError ] = useState(false);
	const [ changePassLoad, setChangePassLoad ] = useState(false);
	const [ alertPassChange, setAlertPassChange ] = useState('default');
	const changePassword=(e)=>{
		e.preventDefault();
		let pass = e.target.pass.value;
		let cpass = e.target.cpass.value;
		if(pass===""){
			setNpassError(true);
			setTimeout(()=>{setNpassError(false)},5000);
		}
		if(cpass===""){
			setNcpassError(true);
			setTimeout(()=>{setNcpassError(false)},5000);
		}
		if (pass!==""&&cpass!==""&&pass!==cpass) {
			setNpassEqualError(true);
			setTimeout(()=>{setNpassEqualError(false)},5000);
		}
		if (pass!==""&&cpass!==""&&pass===cpass) {
			setChangePassLoad(true);
			postCall('api/pnp/update/profile/password/',{ password:pass }).then((response)=>{
      	setChangePassLoad(false);
      	setAlertPassChange('sucess');
      	setTimeout(()=>{
      		setAlertPassChange('default');
      		setChangePasswordModal(false);
      	},3000);
      }).catch((err)=>{
      	setChangePassLoad(false);
      	setAlertPassChange('error');
      	setTimeout(()=>{
      		setAlertPassChange('default');
      	},3000);
      });
		}
	}
	const [ getSmsLoad, setGetSmsLoad ] = useState(false);
	const [ sms, setSms ] = useState();
	const [ showSms, setShowSmS ] = useState(false);
	const getSms=()=>{
		setShowSmS(true);
		setGetSmsLoad(true);
		axios.get(`${link}api/so/get/otp/`,{
          headers:{
            Authorization: 'Bearer '+accessToken,
          }
        }).then((response)=>{
			setGetSmsLoad(false);
			setSms(response.data);
        }).catch((err)=>{
			setGetSmsLoad(false);
          	if(err.response.status === 401){
	      		setAuthForm(true);
	      	}else if(err.response.status === 404){
	      	console.log('Update_account ',err.response.status,':',err.response.statusText);
	      	}else if(err.response.status === 500){
	      		console.log('Update_account - ',err.response.status,':',err.response.statusText);
	     	}else{
	            console.log('Update_account - ',err.response.status,':',err.response.data);
	        }          
    	});
	}
	const smsLog=(id)=>{
		postCall('api/so/create/log/',{message_id:id.substring(1)}).then((response)=>{}).catch((err)=>{});
	}
	return(
		<Navbar expand="lg" className="main-nav" >
		      <Container fluid>
		        <Navbar.Brand className="nav-brand" onClick={()=>{
		        	getHomePage();
		        	setPage('dashboard');
		        }} >AMS</Navbar.Brand>
		        <Navbar.Toggle aria-controls="navbarScroll" />
		        <Navbar.Collapse id="navbarScroll">
		          <Nav className="me-auto my-2 my-lg-0" navbarScroll >
		          	<Nav.Link onClick={()=>{
					            	getHomePage();
		        						setPage('dashboard');
					            }} >Home</Nav.Link>
		          	{
		            	(user!==undefined&&user.type==='admin')||(user!==undefined&&user.permission.token!==undefined&&Object.values(user.permission.token).indexOf(true)!==-1)?(
		            		<Nav.Link onClick={()=>{
					            	setPage('token');
					            	getTokens();
					              getTokenServices();
					              getTokenCustomers();
					              getPayModes();
					              getCall(setGetLoad,'api/pw/get/wallet/billing/',setPayWallet);
					            }} >Token</Nav.Link>
		            	):''
		            }
		            {
		            	(user!==undefined&&user.type==='admin')||(user!==undefined&&user.permission.token_settle!==undefined&&user.permission.token_settle.X)?(
		            		<Nav.Link onClick={()=>{
				            	setPage('cashier');
				              getPayModes();
				              getSubmitted();
				            }} >Cashier</Nav.Link>
		            	):''
		            }
		            {
		            	(user!==undefined&&user.type==='admin')||(user!==undefined&&user.permission.credit!==undefined&&Object.values(user.permission.credit).indexOf(true)!==-1)?(
		            		<Nav.Link onClick={()=>{
					            	setPage('credits');
					              	getTokenServices();
					              	getTokenCustomers();
					              	getCredits();
					              	getPayModes();
					            }} >Credits</Nav.Link>
		            	):''
		            }
		            
		            {
		            	user!==undefined?(
		            		user.type==='admin'?(
		            			<NavDropdown title="Staff" >
					            	<NavDropdown.Item onClick={()=>{
					            		setPage('staff_type');
					            		getPermissions();
					            		getStaffTypes();
					            	}} >Staff Types & Permissions</NavDropdown.Item>
					            	<NavDropdown.Item onClick={()=>{
					            		setPage('staffs');
					            		getStaffTypes();
					            		getStaffs();
					            	}} >Manage Staffs</NavDropdown.Item>
					            </NavDropdown>
		            			):(
		            				user.permission.staff!==undefined?(
		            					Object.values(user.permission.staff).indexOf(true)!==-1||Object.values(user.permission.staff_type).indexOf(true)!==-1?(
		            						<NavDropdown title="Staff" >
			            					{
			            						Object.values(user.permission.staff_type).indexOf(true)!==-1?(
			            							<NavDropdown.Item onClick={()=>{
									            		setPage('staff_type');
									            		getPermissions();
									            		getStaffTypes();
									            	}} >Staff Types & Permissions</NavDropdown.Item>
			            						):''
			            					}
			            					{
			            						Object.values(user.permission.staff).indexOf(true)!==-1?(
			            							<NavDropdown.Item onClick={()=>{
									            		setPage('staffs');
									            		getStaffTypes();
									            		getStaffs();
									            	}} >Manage Staffs</NavDropdown.Item>
			            						):''
			            					}
			            					</NavDropdown>
		            					):''
		            				):''
		            			)
		            		):''
		            }
		            {
		            	(user!==undefined&&user.type==='admin')||(user!==undefined&&user.permission.salary!==undefined&&Object.values(user.permission.salary).indexOf(true)!==-1)?(
				            <NavDropdown title="Salary Management" >
		        					<NavDropdown.Item onClick={()=>{
		        						setPage('salary-saff');
		        						getCall(setLoad,'api/sm/get/staffs/', setSalaryStaff);
		        					}} >Staffs</NavDropdown.Item>
		        					<NavDropdown.Item onClick={()=>{
		        						setPage('staff-attandence');
		        					}} >Attendance</NavDropdown.Item>
		        					<NavDropdown.Item onClick={()=>{
		        						setPage('staff-disabled');
		        						getCall(setLoad,'api/sm/get/disabled/staffs/',setSmDiStaffs);
		        					}} >Disabled Staffs</NavDropdown.Item>
		            		</NavDropdown>
	            		):''
								}
		            {
		            	(user!==undefined&&user.type==='admin')||(user!==undefined&&user.permission.service!==undefined&&Object.values(user.permission.service).indexOf(true)!==-1)?(
		            		<Nav.Link onClick={()=>{
				            	setPage('services');
				            	getServices();
				            }} >Services</Nav.Link>
						    	):''
								}
								{
		            	(user!==undefined&&user.type==='admin')||(user!==undefined&&user.permission.collection!==undefined&&Object.values(user.permission.collection).indexOf(true)!==-1)?(
		            		<Nav.Link onClick={()=>{
				            	setPage('collections');
				            	getCollections(getDate());
				            }} >Collection</Nav.Link>
		            	):''
		            }
		            
		            {
		            	(user!==undefined&&user.type==='admin')||(user!==undefined&&user.permission.enquiry!==undefined&&Object.values(user.permission.enquiry).indexOf(true)!==-1)?(
		            		<>
											<Nav.Link onClick={()=>{
					            	setPage('enquiry');
					            	getEnquiry();
					            	getCustomers();
					            }} >Enquiry</Nav.Link>
											<Nav.Link onClick={()=>{
					            	setPage('works');
												getWorks();
					            	getCustomers();
					            }} >Works</Nav.Link>
										</>
							    ):''
								}
						
								{
		            	(user!==undefined&&user.type==='admin')||(user!==undefined&&user.permission.notification!==undefined&&Object.values(user.permission.notification).indexOf(true)!==-1)?(
		            		<Nav.Link onClick={()=>{
												getNotifications();
												setPage('notification');
											}} >Notifications</Nav.Link>
		            	):''
		            }
					
		            {
		            	(user!==undefined&&user.type==='admin')||(user!==undefined&&user.permission.customer!==undefined&&Object.values(user.permission.customer).indexOf(true)!==-1)?(
		            		<Nav.Link onClick={()=>{
					            	setPage('customers');
					            	getCustomers();
					            }} >Customers</Nav.Link>
								  ):''
								}
								<Nav.Link onClick={()=>{
		            	setPage('c-wallet');
		            	getCustomers();
		            	getCall(setGetLoad,'api/ap/get/wallet/',setWalletCus);
		            	getPayModes();
		            }} >Wallet</Nav.Link>
		            <NavDropdown title="Expense" >
        					<NavDropdown.Item onClick={()=>{
			            	setPage('petty-exp');
			            	getPayModes();
			            	getCall(setGetLoad,'api/em/get/expense/particular/',setExpenseParti);
			            	getCall(setLoad,'api/em/get/petty/expense/',setPettyExpense);
			            }} >Petty Expense</NavDropdown.Item>
			            {
				          	(user!==undefined&&user.type==='admin')||(user!==undefined&&user.permission.expense!==undefined&&Object.values(user.permission.expense).indexOf(true)!==-1)?(
			           			<NavDropdown.Item onClick={()=>{
					            	setPage('exp-add');
					            	getPayModes();
					            	getCall(setGetLoad,'api/em/get/expense/particular/',setExpenseParti);
					            	getCall(setLoad,'api/em/get/expense/',setExpense);
					            }} >Expense</NavDropdown.Item>
				           	):''
				          }
        				</NavDropdown>			          
		            {
		            	(user!==undefined&&user.type==='admin')||(user!==undefined&&user.permission.report!==undefined&&Object.values(user.permission.report).indexOf(true)!==-1)?(
		            		<NavDropdown title="Reports">
		            				<NavDropdown.Item onClick={()=>{
		            					getQuickReport();
		            					setPage('quick_report');
		            					getQuickReportToday();
		            					getQuickReportYear();
		            				}} >Quick Report</NavDropdown.Item>
		            				<NavDropdown.Item onClick={()=>{
		            					getCollectionReport();
		            					setPage('collection_report');
		            				}} >Collection report</NavDropdown.Item>
		            				<NavDropdown.Item onClick={()=>{
		            					getCreditReport();
		            					setPage('credit_report');
		            				}} >Credit Collection report</NavDropdown.Item>
		            				<NavDropdown.Item onClick={()=>{
		            					getCancelReport();
		            					setPage('token_cancel_report');
		            				}} >Token Cancellation report</NavDropdown.Item>
		            				<NavDropdown.Item onClick={()=>{
		            					getCustomerReport();
		            					setPage('customer_report');
		            				}} >Customer wise invoice report</NavDropdown.Item>
		            				<NavDropdown.Item onClick={()=>{
		            					getStaffReport();
		            					setPage('staff_report');
		            				}} >Staff wise invoice report</NavDropdown.Item>
		            				<NavDropdown.Item onClick={()=>{
		            					getServiceReport();
		            					setPage('service_report');
		            				}} >Service wise invoice report</NavDropdown.Item>
		            				<NavDropdown.Item onClick={()=>{
		            					getDetailReport();
		            					setPage('detailed_report');
		            				}} >Detailed invoice report</NavDropdown.Item>
		            				<NavDropdown.Item onClick={()=>{
		            					setGetLoad(true)
		            					postCall('api/bnr/get/staff/token/report/',{start_date:getDate(), end_date:getDate()}).then(res=>{
		            						setStaffTokenReport(res.data);
		            						setGetLoad(false);
		            					}).catch(err=>{
		            						setGetLoad(false);
		            					});
		            					setPage('staff_token_report');
		            				}} >Staff token report</NavDropdown.Item>
		            				<NavDropdown.Item onClick={()=>{
		            					setGetLoad(true)
		            					postCall('api/bnr/get/detailed/token/report/',{start_date:getDate(), end_date:getDate(),staff_id:"",service_id:""}).then(res=>{
		            						setDetailTreport(res.data);
		            						setGetLoad(false);
		            					}).catch(err=>{
		            						setGetLoad(false);
		            					});
		            					setPage('detailed_token_report');
		            				}} >Detailed token report</NavDropdown.Item>
		            				<NavDropdown.Item onClick={()=>{
		            					setGetLoad(true)
		            					postCall('api/bnr/get/not/completed/token/report/',{start_date:getDate(), end_date:getDate()}).then(res=>{
		            						setIncompleteTokenReport(res.data);
		            						setGetLoad(false);
		            					}).catch(err=>{
		            						setGetLoad(false);
		            					});
		            					setPage('incomplete_token_report');
		            				}} >Incomplete token report</NavDropdown.Item>
		            				<NavDropdown.Item onClick={()=>{
		            					setGetLoad(true);
		            					getCall(setGetLoad,'api/pw/get/wallet/billing/',setPayWallet);
		            					getStaffs();
		            					postCall('api/bnr/get/payment/wallet/report/',{start_date:getDate(), end_date:getDate(),staff_id:'',wallet_id:''}).then(res=>{
		            						setPayWalletReport(res.data);
		            						setGetLoad(false);
		            					}).catch(err=>{
		            						setGetLoad(false);
		            					});
		            					setPage('pay_wallet_report');
		            				}} >Payment wallet report</NavDropdown.Item>
		            				<NavDropdown.Item onClick={()=>{
				            					setLoad(true);
				            					getCall(setGetLoad,'api/em/get/expense/particular/',setExpenseParti);
				            					postCall('api/bnr/get/expense/report/',{start_date:getDate(), end_date:getDate(),staff_id:'',p_id:''}).then(res=>{
				            						setExpenseReport(res.data);
				            						setLoad(false);
				            					}).catch(err=>{
				            						setLoad(false);
				            					});
				            					setPage('expense_report');
				            				}} >Expense report</NavDropdown.Item>
				            		<NavDropdown.Item onClick={()=>{
				            					setLoad(true);
				            					postCall('api/bnr/get/verify/reopen/report/',{start_date:getDate(), end_date:getDate(),staff_id:''}).then(res=>{
				            						setReOpenRepo(res.data);
				            						setLoad(false);
				            					}).catch(err=>{
				            						setLoad(false);
				            					});
				            					setPage('reopen_report');
				            				}} >Day close re-open report</NavDropdown.Item>
		            				<NavDropdown.Item onClick={()=>{
				            					setLoad(true);
				            					postCall('api/bnr/get/salary/calculation/report/',{start_date:getDate(), end_date:getDate(),staff_id:''}).then(res=>{
				            						setSalaryCalcRepo(res.data);
				            						setLoad(false);
				            					}).catch(err=>{
				            						setLoad(false);
				            					});
				            					setPage('report_salary_calc');
				            				}} >Salary calculation report</NavDropdown.Item>
		            				<NavDropdown.Item onClick={()=>{
				            					setLoad(true);
				            					postCall('api/bnr/get/salary/payment/report/',{start_date:getDate(), end_date:getDate(),staff_id:''}).then(res=>{
				            						setSalaryPayRepo(res.data);
				            						setLoad(false);
				            					}).catch(err=>{
				            						setLoad(false);
				            					});
				            					setPage('report_salary_pay');
				            				}} >Salary Payment report</NavDropdown.Item>
		            			</NavDropdown>
		            	):''
		            }
		            {
			            user!==undefined?(
			            	user.type==='admin'?(
				        			<NavDropdown title="More">
				        				<NavDropdown.Item onClick={()=>{
				        					setPage('verify_day_close');
				        					getDayCloseToVerify();
				        				}} >Verify Day close</NavDropdown.Item>					            	
						            	<NavDropdown.Item onClick={()=>{
						            		setPage('special_token');
						            		getTokenServices();
										    getTokenCustomers();
										    getSpecialTokens();
						            	}} >Special Tokens</NavDropdown.Item>
						            	<NavDropdown.Item onClick={()=>{
						            		getSpecialBills();
						            		getTokenServices();
						            		getTokenCustomers();
						            		setPage('special_bills');
						            	}} >Special Bills</NavDropdown.Item>
						            	<NavDropdown.Item onClick={()=>{
						            		getPaymentMethods();
						            		setPage('payment_methods');
						            	}} >Payment methods</NavDropdown.Item>
						            	<NavDropdown.Item onClick={()=>{
							            	setPage('pay_wallet');
							            	getCall(setGetLoad,'api/pw/get/wallet/',setPayWallet);
							            }} >Payment Wallet</NavDropdown.Item>
							            <NavDropdown.Item onClick={()=>{
							            	setPage('exp_parti');
							            	getCall(setGetLoad,'api/em/get/expense/particular/',setExpenseParti);
							            }} >Expense Particular</NavDropdown.Item>							            
						          </NavDropdown>    		
			            	):(
			            		<NavDropdown title="More">
				            		{
					            		user.permission.special_token!==undefined&&Object.values(user.permission.special_token).indexOf(true)!==-1&&user.permission.special_token.A?(
		            							<NavDropdown.Item onClick={()=>{
								            		setPage('special_token');
								            		getTokenServices();
												    		getTokenCustomers();
												    		getSpecialTokens();
								            	}} >Special Tokens</NavDropdown.Item>
				            			):''
			            			}
			            			{
					            		user.permission.special_bill!==undefined&&Object.values(user.permission.special_bill).indexOf(true)!==-1&&user.permission.special_bill.A?(
				            				<NavDropdown.Item onClick={()=>{
							            		getSpecialBills();
							            		getTokenServices();
							            		getTokenCustomers();
							            		setPage('special_bills');
							            	}} >Special Bills</NavDropdown.Item>
			            				):''
			            			}
			            			{
			            				user.permission.day_close_verify!==undefined&&Object.values(user.permission.day_close_verify).indexOf(true)!==-1&&user.permission.day_close_verify.E?(
			            					<NavDropdown.Item onClick={()=>{
							        					setPage('verify_day_close');
							        					getDayCloseToVerify();
							        			}} >Verify Day close</NavDropdown.Item>
			            				):''
			            			}
		            			</NavDropdown>
			            	)
			            ):''
			        	} 
		          </Nav>
		          <div className='sms-div px-1'  >
									<a href="https://calc.akshayakerala.com/" target="_blank"><span className='bgs2 px-2 py-1 bdr1 br-3 sms-icon' ><i className="bi bi-calculator"></i></span></a>
							</div>
				  {
			            user!==undefined?(
			            	user.type==='admin'?(
								<div className='sms-div'  >
									<span onClick={()=>{getSms()}} className='bgs2 px-2 py-1 bdr1 br-3 sms-icon' ><i className="bi bi-chat-left-text"></i></span>
									{
										showSms?(
										<div className='sms-list-container bgs1 bdr1 br-3' >
											<div className='sms-list-conteiner-header bdrb1 p-2'>
												{
													getSmsLoad?(
														<span className="please-wait-spinner px-1" ><Spinner animation="border" size="sm" /> Please wait...</span>
													):(
														<span onClick={()=>{getSms()}} className='refresh-sms px-1' ><i className="bi bi-arrow-clockwise"></i> Refresh</span>
													)
												}
												<span onClick={()=>{setShowSmS(false)}} className='bgs2 px-2 py-1 bdr1 br-3 sms-icon' ><i className="bi bi-x-circle"></i></span>
											</div>
											<div className='sms-list-conatainer-body p-2'>
												{
													sms!==undefined?(
														Object.keys(sms).length>0?(
															Object.keys(sms).map((sitem,si)=>(
																<Accordion key={`sms_${si}`} className='mb-1' >
																	<Accordion.Item eventKey="0">
																		<Accordion.Header onClick={()=>{smsLog(sitem)}} className='sms-head' >
																			<span className="tp1 f16 d-grid" >{sms[sitem].message_header}<br/>
																				<span className="ts2 f12 mt-1" >{sms[sitem].created_date} / {sms[sitem].created_time}</span>
																			</span>														
																		</Accordion.Header>
																		<Accordion.Body>
																		<Col>
																			<p className="p-multi-line ts1" >{sms[sitem].message_body}</p>
																		</Col>
																		</Accordion.Body>
																	</Accordion.Item>
																</Accordion>
															))
														):''
													):'SMS not found!'
												}
											</div>							
										</div>					  
										):''
									}
								</div>
							):(
								user.permission.otp!==undefined?(
									Object.values(user.permission.otp).indexOf(true)!==-1?(
										user.permission.otp.V?(
											<div className='sms-div'  >
												<span onClick={()=>{getSms()}} className='bgs2 px-2 py-1 bdr1 br-3 sms-icon' ><i className="bi bi-chat-left-text"></i></span>
												{
													showSms?(
													<div className='sms-list-container bgs1 bdr1 br-3' >
														<div className='sms-list-conteiner-header bdrb1 p-2'>
															{
																getSmsLoad?(
																	<span className="please-wait-spinner px-1" ><Spinner animation="border" size="sm" /> Please wait...</span>
																):(
																	<span onClick={()=>{getSms()}} className='refresh-sms px-1' ><i className="bi bi-arrow-clockwise"></i> Refresh</span>
																)
															}
															<span onClick={()=>{setShowSmS(false)}} className='bgs2 px-2 py-1 bdr1 br-3 sms-icon' ><i className="bi bi-x-circle"></i></span>
														</div>
														<div className='sms-list-conatainer-body p-2'>
															{
															sms!==undefined?(
																Object.keys(sms).length>0?(
																	Object.keys(sms).map((sitem,si)=>(
																		<Accordion key={`sms_${si}`} className='mb-1' >
																			<Accordion.Item eventKey="0">
																				<Accordion.Header onClick={()=>{smsLog(sitem)}} className='sms-head' >
																					<span className="tp1 f16 d-grid" >{sms[sitem].message_header}<br/>
																						<span className="ts2 f12 mt-1" >{sms[sitem].created_date} / {sms[sitem].created_time}</span>
																					</span>														
																				</Accordion.Header>
																				<Accordion.Body>
																				<Col>
																					<p className="p-multi-line ts1" >{sms[sitem].message_body}</p>
																				</Col>
																				</Accordion.Body>
																			</Accordion.Item>
																		</Accordion>
																	))
																):''
															):'SMS not found!'
															}
														</div>							
													</div>					  
													):''
												}
											</div>
										):''
									):''
								):''
							)
						):''
				  }
		          <Dropdown align='end' >
	                  <Dropdown.Toggle variant="outline-secondary" id="dropdown-menu-align-end" className="menu1" >
	                    {
	                    	user!==undefined?(
	                    		user.profile.first_name!==undefined?user.profile.first_name:'Username'
	                    		):'Username'
	                    }
	                  </Dropdown.Toggle>
	                  <Dropdown.Menu className="menu-one" >
		                	{
		                  	stateSettle!==undefined&&stateSettleAll!==undefined?(
		                  		stateSettle&&stateSettleAll?(	                  				
				                    dayClose?(
	                  				<Dropdown.Item onClick={()=>{setDayCloseModal(true)}} >
				                    	<span className="menu-icon"><i className="bi bi-door-closed-fill"></i></span>
				                    	<span className="menu-name">Close Day</span>
				                    </Dropdown.Item>
				                    ):(
				                    <Dropdown.Item onClick={()=>{getDayReport()}} >
				                    	<span className="menu-icon"><i className="bi bi-file-earmark-check"></i></span>
				                    	<span className="menu-name">Day report</span>
				                    </Dropdown.Item>
				                    )
	                  			):(
	                  				<Dropdown.Item disabled>
				                    	<span className="menu-icon"><i className="bi bi-door-closed-fill"></i></span>
				                    	<span className="menu-name">Close Day</span>
				                    </Dropdown.Item>
	                  			)
		                  	):''
		                	}
		                	{
						            user!==undefined?(
						            	user.type==='admin'?(
						            		<Dropdown.Item eventKey="1" >
						                      <span className="menu-icon" ><i className="bi bi-person-lines-fill"></i></span>
						                      <span className="menu-name" onClick={()=>{setProfileModal(true)}} >Update profile</span>
						                    </Dropdown.Item>
						           		):''
						           	):''
						        	}	                    
	                    <Dropdown.Item eventKey="5" onClick={()=>{setChangePasswordModal(true)}}  >
	                      <span className="menu-icon" ><i className="bi bi-key-fill"></i></span>
	                      <span className="menu-name" >Change password</span>
	                    </Dropdown.Item>
	                    <Dropdown.Item eventKey="4" onClick={()=>{
	                      localStorage.clear();
	                      window.location.reload();
	                      }} >
	                      <span className="menu-icon" ><i className="bi bi-box-arrow-left"></i></span>
	                      <span className="menu-name" >Log out</span>
	                    </Dropdown.Item>
	                  </Dropdown.Menu>
	            </Dropdown>
		        </Navbar.Collapse>
		      </Container>



		      <Modal show={profileModal} size="xl" onHide={()=>{setProfileModal(false)}}>
		      	<Modal.Header closeButton>
		          <Modal.Title>Update Profile</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={updateProfile} >
		        		<Form.Group className="mb-3" >
		        			<Form.Label>Center name {nameReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
		        			<Form.Control defaultValue={account!==undefined?account.name:''} className="br-2 shadow-none" type="text" name="name" placeholder="Akshaya center name" />
		        		</Form.Group>
		        		<Form.Group className="mb-3">
		        			<Form.Label>Email {emailReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
		        			<Form.Control defaultValue={account!==undefined?account.email:''} className="br-2 shadow-none" type="email" name="email" placeholder="Email Id"/>
		        		</Form.Group>
		        		
		        		{
		        			account!==undefined?(
		        				account.logo!==""&&account.logo!==null&&!changeLogo?(
		        					<div className="show-logo br-3 bdr2 p-1">
		        						<img src={account.logo} />
		        						<Button onClick={()=>{setChangeLogo(true)}} variant="secondary" className='mx-3 shadow-none br-2' >Change logo</Button>
		        					</div>
		        				):(
		        				<Form.Group className="mb-3">
				        			<Form.Label>Logo {
				        				account.logo!==""&&account.logo!==null&&changeLogo?(
		        							<Button onClick={()=>{setChangeLogo(false)}} variant="secondary" className='mx-3 shadow-none br-2' >Use old logo</Button>
				        				):''
				        			}{logoReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
				        			<Form.Control name="logo" type="file" className="br-2 shadow-none" accept=".png, .jpg, .jpeg" />
				        		</Form.Group>
		        				)
		        			):''
		        		}
		        			
		        		<Form.Group className="mb-3">
		        			<Form.Label>Address {addressReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
		        			<Form.Control defaultValue={account!==undefined?account.address:''} className="br-2 shadow-none" as="textarea" rows="3" name="address" placeholder="Address" /> 
		        		</Form.Group>
		        		<Form.Group className="mb-3">
		        			<Form.Label>Contact details {contactReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
		        			<Form.Control defaultValue={account!==undefined?account.phone_numbers:''} className="br-2 shadow-none" as="textarea" rows="3" name="contact" placeholder="Eg: phone:+91 1234567890, +91 9874563210, Email : example@mail.com" />
		        		</Form.Group>
		        		<div className="d-grid">
		        		{
		        			profileUpdateLoad?(
					    			<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
		        			):(
		        				<Button type="submit" className="br-2 shadow-none" className="btn-t1" >Update</Button>
		        			)
		        		}
		        		</div>
		        	</Form>
		        </Modal.Body>
		        <Modal.Footer>

		        </Modal.Footer>
		      </Modal>

		      <Modal show={dayCloseModal} backdrop="static" size="xl" onHide={()=>{setDayCloseModal(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Add Denomination  in hand</Modal.Title>
		        </Modal.Header>
		        {
		        	dayCloseState!==undefined?(
		        		dayCloseState==='denomination'?(
					        <Modal.Body>
					        	<Form onSubmit={addDenominations} >
					        		<Row >
					        			<Col xs={12} sm={12} md={12} className="mb-3">
												<InputGroup className="mb-3 br-2">
										        <InputGroup.Text >Opening Balance</InputGroup.Text>
										        <Form.Control name="opening" type="number" min="0" />
										    </InputGroup>
												</Col>
					        			<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
												<InputGroup className="mb-3 br-2">
										        <InputGroup.Text >500</InputGroup.Text>
										        <Form.Control name="n_500" type="number" min="0" />
										    </InputGroup>
												</Col>
												<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
													<InputGroup className="mb-3 br-2">
												        <InputGroup.Text >200</InputGroup.Text>
												        <Form.Control name="n_200" type="number" min="0" />
												    </InputGroup>
												</Col>
												<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
													<InputGroup className="mb-3 br-2">
												        <InputGroup.Text >100</InputGroup.Text>
												        <Form.Control name="n_100" type="number" min="0" />
												    </InputGroup>
												</Col>
												<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
													<InputGroup className="mb-3 br-2">
												        <InputGroup.Text >50</InputGroup.Text>
												        <Form.Control name="n_50" type="number" min="0" />
												    </InputGroup>
												</Col>
												<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
													<InputGroup className="mb-3 br-2">
												        <InputGroup.Text >20</InputGroup.Text>
												        <Form.Control name="n_20" type="number" min="0" />
												    </InputGroup>
												</Col>
												<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
													<InputGroup className="mb-3 br-2">
												        <InputGroup.Text >10</InputGroup.Text>
												        <Form.Control name="n_10" type="number" min="0" />
												    </InputGroup>
												</Col>
												<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
													<InputGroup className="mb-3 br-2">
												        <InputGroup.Text >5</InputGroup.Text>
												        <Form.Control name="n_5" type="number" min="0" />
												    </InputGroup>
												</Col>
												<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
													<InputGroup className="mb-3 br-2">
												        <InputGroup.Text >2</InputGroup.Text>
												        <Form.Control name="n_2" type="number" min="0" />
												    </InputGroup>
												</Col>
												<Col xs={12} sm={6} md={4} lg={3} className="mb-3">
													<InputGroup className="mb-3 br-2">
												        <InputGroup.Text >1</InputGroup.Text>
												        <Form.Control name="n_1" type="number" min="0" />
												    </InputGroup>
												</Col>
												<div className="d-grid">
												{
													addDenominationLoad?(
										    				<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
														):(
															<Button type='submit' className="btn-t1">CLOSE DAY</Button>
														)
												}
												</div>
					        		</Row>
					        	</Form>
					        </Modal.Body>
				        ):dayCloseState==='addReason'?(
				        	<Modal.Body>
				        		<Row>
				        			<Col xs={12} sm={6} md={3} lg={2} className="mb-3"  >
				        				<span className="f16 d-grid tp2 py-2 px-3 diff bdr1 br-3" >{closeDayReport!==undefined?`Diffrence = ${closeDayReport.main.diffrence}`:''}</span>
				        			</Col>
				        			<Col xs={12} sm={6} md={4} lg={3} className="mb-3"  >
						        		<span className="f16 d-grid tp2 py-2" >{closeDayReport!==undefined?`Closing total = ${closeDayReport.main.denomination_total}`:''}</span>
				        			</Col>
				        			<Col xs={12} sm={6} md={4} lg={3} className="mb-3"  >
				        				<span className="f16 d-grid tp2 py-2" >{closeDayReport!==undefined?`Total Cash Settled = ${closeDayReport.main.primary_total}`:''}</span>
				        			</Col>
				        		</Row>
				        		<Row>
				        			<span className="d-grid f18 tp2 py-2" >Submitted denominations</span>
				        			{
				        				closeDayReport!==undefined?(
				        					closeDayReport.deno_values!==undefined?(
				        					<>
				        					{
				        						closeDayReport.deno_values.n_500>0?(
				        							
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 500</span>
												        	<span className="denomin-val p-2">{closeDayReport.deno_values.n_500} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						closeDayReport.deno_values.n_200>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 200</span>
												        	<span className="denomin-val p-2">{closeDayReport.deno_values.n_200} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						closeDayReport.deno_values.n_100>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 100</span>
												        	<span className="denomin-val p-2">{closeDayReport.deno_values.n_100} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						closeDayReport.deno_values.n_50>0?(
				        							<Col xs={12} sm={6} md={4} lg={3} xl={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 50</span>
												        	<span className="denomin-val p-2">{closeDayReport.deno_values.n_50} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						closeDayReport.deno_values.n_20>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 20</span>
												        	<span className="denomin-val p-2">{closeDayReport.deno_values.n_20} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						closeDayReport.deno_values.n_10>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 10</span>
												        	<span className="denomin-val p-2">{closeDayReport.deno_values.n_10} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						closeDayReport.deno_values.n_5>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 5</span>
												        	<span className="denomin-val p-2">{closeDayReport.deno_values.n_5} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						closeDayReport.deno_values.n_2>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name  bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 2</span>
												        	<span className="denomin-val p-2">{closeDayReport.deno_values.n_2} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					{
				        						closeDayReport.deno_values.n_1>0?(
				        							<Col xs={12} sm={6} md={4} lg={3}>
					        							<Col className="mb-3 denomin bdr2 px-0">
												        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 1</span>
												        	<span className="denomin-val p-2">{closeDayReport.deno_values.n_1} <span className="ts3 f14" >Numbers</span></span>
												        </Col>
											        </Col>
				        						):''
				        					}
				        					</>
				        					):''
				        				):''
				        			}
							    	</Row>
				        		<Form className="mb-3" onSubmit={submitReason} >
				        			<Form.Group className="mb-3" >
				        				<Form.Label className="f16 tp2" >Please enter the reason for diffrence in cash.</Form.Label>
				        				<Form.Control as="textarea" className="shadow-none br-2" name="reason" />
				        			</Form.Group>
				        			<div className="d-grid">
				        			{
				        				reasonSubmitLoad?(
								    		<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
				        				):(
				        					<Button type="submit" className="btn-t1">Submit</Button>
				        				)
				        			}
				        			</div>
				        		</Form>
				        		<Row>
				        			<Col>
				        				<Accordion>
										      <Accordion.Item eventKey="0">
										        <Accordion.Header><span className="tp1 f18" >Bill details</span></Accordion.Header>
										        <Accordion.Body>
										          <Col>
										          	{
										          		closeDayReport!==undefined?(
										          			closeDayReport!==undefined&&closeDayReport.wallet_values!==undefined?(
																				<>
																					<span className="d-grid f20 bgs1 bdr1 bdrb0 px-2 tp2 py-2" ><b>Wallet details</b></span>
																		          	<Table bordered className="tbl-bg-blue" >
																				      <thead>
																				        <tr>
																				          <th><span className="tp2" >Date</span></th>
																				          <th><span className="tp2" >Customer</span></th>
																				          <th><span className="tp2" >Staff</span></th>
																				          <th><span className="tp2" >Payment Methods</span></th>
																				          <th className="text-end" ><span className="tp2" >Total</span></th>
																				        </tr>
																				      </thead>
																				      <tbody>
																				      {
																				      	Object.keys(closeDayReport.wallet_values).length>0?(
																				      		Object.keys(closeDayReport.wallet_values).map((wc,wci)=>(
																				      			<tr key={`wc_$wci`} className="tp2" >
																				      				<td>{closeDayReport.wallet_values[wc].collected_date}
																				      				</td>
																				      				<td>
																				      					<div className="d-grid">
																							          		<span className="tp3 f16" >{closeDayReport.wallet_values[wc].customer_name}</span>
																							          		<span className="tp3 f16" >{closeDayReport.wallet_values[wc].customer_phone}</span>								          		
																							          	</div>
																				      				</td>
																				      				<td>
																							          	<div class="grid-auto-100 bdrb2 mb-1 px-1" >
																						          		  	<span className="tp3 f16" >Collected by {closeDayReport.wallet_values[wc].cash_collected}</span>
																						          			<span className="tp3 f16 grid-justify-end" >{closeDayReport.wallet_values[wc].collected_time}</span>
																						          		</div>
																							        </td>
																							        <td>
																							          	<div className="d-grid">
																							          	{
																							          		closeDayReport.wallet_values[wc].ap_payments!==undefined?(
																							          			Object.keys(closeDayReport.wallet_values[wc].ap_payments).length>0?(
																							          				Object.keys(closeDayReport.wallet_values[wc].ap_payments).map((b_pay,b_pay_i)=>(
																							          					<div class="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
																										          		  	<span className="tp3 f16" >{closeDayReport.wallet_values[wc].ap_payments[b_pay].name}</span>
																										          			<span className="tp3 f16 grid-justify-end" >{closeDayReport.wallet_values[wc].ap_payments[b_pay].amount}</span>
																										          		</div>
																							          				))
																							          			):''
																							          		):''
																							          	}
																							          	</div>
																							          </td>
																							          <td className="text-end" >
																							          	{
																						        			closeDayReport.wallet_values[wc].amount>0?(
																						        				<span className="sucess-text">{closeDayReport.wallet_values[wc].amount}</span>
																						        			):(
																						        				<span className="error-text">{closeDayReport.wallet_values[wc].amount}</span>
																						        			)
																						        		}
																							          </td>
																				      			</tr>
																				      		))
																				      	):'Wallet Not found!'
																				      }
																				      {
																				      	closeDayReport!==undefined?(
																				      		closeDayReport.main.advance_total!==undefined?(
																				      			Object.keys(closeDayReport.main.advance_total).length>0?(
																				      				Object.keys(closeDayReport.main.advance_total).map((r_pay,r_pay_i)=>(
																				      					closeDayReport.main.advance_total[r_pay].amount!==0?(
																						      				<tr key={`r_pay_x${r_pay_i}`}>
																									        	<td colSpan={3} ></td>
																									        	<td className="text-end" ><span className="tp1 f16" >{closeDayReport.main.advance_total[r_pay].name}</span></td>
																									        	<td className="text-end" >
																									        		{
																									        			closeDayReport.main.advance_total[r_pay].amount>0?(
																									        				<span className="sucess-text">{closeDayReport.main.advance_total[r_pay].amount}</span>
																									        			):(
																									        				<span className="error-text">{closeDayReport.main.advance_total[r_pay].amount}</span>
																									        			)
																									        		}
																									        	</td>
																									        </tr>
																								        ):''
																							        ))
																				      			):''
																				      		):''
																				      	):''
																				      }
																				      {
																				      	closeDayReport!==undefined?(
																				      		closeDayReport.main!==undefined?(
																				      			<tr>
																				      				<td colSpan={3} ></td>
																						        	<td className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
																						        	<td className="text-end bgy" >{closeDayReport.main.advance_day_total}</td>
																						        </tr>
																				      		):''
																				      	):''
																				      }	
																				      </tbody>
																					</Table>
																				</>
																				):'Wallet Not found!'
										          		):''
										          	}
										          	{
										          		closeDayReport!==undefined?(
										          			closeDayReport.debit_values!==undefined&&Object.keys(closeDayReport.debit_values).length>0?(
										          				<>
											          				<span className="d-grid f18 tp2 py-2" >Credit details</span>
														          	<Table bordered className="tbl-bg-blue" >
																      <thead>
																        <tr>
																          <th><span className="tp2" >Customer</span></th>
																          <th><span className="tp2" >Bill details</span></th>
																          <th><span className="tp2" >Times</span></th>
																          <th><span className="tp2" >Payment Methods</span></th>
																          <th className="text-end" ><span className="tp2" >Total</span></th>
																        </tr>
																      </thead>
																      <tbody>
																      {
																      	Object.keys(closeDayReport.debit_values).map((debit,deb_i)=>(
																      	<tr key={`debi_${deb_i}`} >
																      		<td>
																      			<div className="d-grid">
																	          		<span className="tp3 f16" >{closeDayReport.debit_values[debit].customer_name}</span>
																	          		<span className="tp3 f16" >{closeDayReport.debit_values[debit].customer_phone}</span>
																	          	</div>
																      		</td>
																      		<td>
																	          	<div className="d-grid">
																	          		<span className="tp1 f16" >Token No : {closeDayReport.debit_values[debit].token_no}</span>
																	          		<span className="tp3 f16" >Bill No : {closeDayReport.debit_values[debit].bill_no}</span>
																	          		<span className="tp3 f16" >Date : {closeDayReport.debit_values[debit].collected_date}</span>
																	          	</div>
																	        </td>
																	        <td>
																	          	<div className="grid-auto-100 bdrb2 mb-1 px-1" >
																          		  	<span className="tp3 f16" >Collected</span>
																          			<span className="tp3 f16 grid-justify-end" >{closeDayReport.debit_values[debit].collected_time}</span>
																          		</div>
																	        </td>
																	        <td>
																	          	<div className="d-grid">
																	          	{
																	          		closeDayReport.debit_values[debit].debit_payments!==undefined?(
																	          			Object.keys(closeDayReport.debit_values[debit].debit_payments).length>0?(
																	          				Object.keys(closeDayReport.debit_values[debit].debit_payments).map((b_pay,b_pay_i)=>(
																	          					<div className="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
																				          		  	<span className="tp3 f16" >{closeDayReport.debit_values[debit].debit_payments[b_pay].name}</span>
																				          			<span className="tp3 f16 grid-justify-end" >{closeDayReport.debit_values[debit].debit_payments[b_pay].amount}</span>
																				          		</div>
																	          				))
																	          			):''
																	          		):''
																	          	}
																	          	</div>
																	          </td>
																	          <td className="text-end pos-rel" >
																	          	<span className="tp1 f18" >{closeDayReport.debit_values[debit].debit_total}</span>
																	          </td>
																      	</tr>
																      	))
																      }
																      {
																      	closeDayReport!==undefined?(
																      		closeDayReport.main.debit_total!==undefined?(
																      			Object.keys(closeDayReport.main.debit_total).length>0?(
																      				Object.keys(closeDayReport.main.debit_total).map((r_pay,r_pay_i)=>(
																	      				closeDayReport.main.debit_total[r_pay].amount>0?(
																		      				<tr key={`r_pay_${r_pay_i}`}>
																					        	<td colSpan={3} ></td>
																					        	<td className="text-end" ><span className="tp1 f16" >{closeDayReport.main.debit_total[r_pay].name}</span></td>
																					        	<td className="text-end" >{closeDayReport.main.debit_total[r_pay].amount}</td>
																					        </tr>
																					    ):''
																			        ))
																      			):''
																      		):''
																      	):''
																      }
																      {
																      	closeDayReport!==undefined?(
																      		closeDayReport.main!==undefined?(
																      			<tr>
																      				<td colSpan={3} ></td>
																		        	<td className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
																		        	<td className="text-end bgy" >{closeDayReport.main.debit_amount_total}</td>
																		        </tr>
																      		):''
																      	):''
																      }		
																      </tbody>
																    </Table>
																	    </>
																    ):''
										          		):''
											        	}
										          	<span className="d-grid f18 tp2 py-2" >Collection details</span>
										          	<Table bordered className="tbl-bg-blue" >
														      <thead>
														        <tr>
														          <th><span className="tp2" >Token</span></th>
														          <th><span className="tp2" >Services</span></th>
														          <th><span className="tp2" >Customer</span></th>
														          <th><span className="tp2" >Times</span></th>
														          <th><span className="tp2" >Payment Methods</span></th>
														          <th className="text-end" ><span className="tp2" >Total</span></th>
														        </tr>
														      </thead>
														      <tbody>
														      {
														      	closeDayReport!==undefined?(
														      		closeDayReport.bill_values!==undefined?(
														      			Object.keys(closeDayReport.bill_values).map((tkn,tkn_i)=>(
														      				<tr key={`trep_${tkn_i}`} >
																	          <td>
																	          	<div className="d-grid">
																	          		<span className="tp1 f18" >No : {closeDayReport.bill_values[tkn].token_no}</span>
																	          		<span className="tp3 f16" >Bill No : {closeDayReport.bill_values[tkn].bill_no}</span>
																	          	</div>
																	          </td>
																	          <td>
																	          	<div className="d-grid">
																	          	{
																	          		closeDayReport.bill_values[tkn].bill_services!==undefined?(
																	          			Object.keys(closeDayReport.bill_values[tkn].bill_services).length>0?(
																	          				Object.keys(closeDayReport.bill_values[tkn].bill_services).map((t_ser,t_ser_i)=>(
																	          					<span className="tp3 f16" key={`tkrpt_${t_ser_i}`} >{closeDayReport.bill_values[tkn].bill_services[t_ser].service_name}{closeDayReport.bill_values[tkn].bill_services[t_ser].quantity!==undefined&&closeDayReport.bill_values[tkn].bill_services[t_ser].quantity>1?(<> ( {closeDayReport.bill_values[tkn].bill_services[t_ser].quantity} )</>):''}</span>
																	          				))
																	          			):''
																	          		):''
																	          	}
																	          	</div>
																	          </td>
																	          <td>
																	          	<div className="d-grid">
																	          		<span className="tp3 f16" >{closeDayReport.bill_values[tkn].customer_name}</span>
																	          		<span className="tp3 f16" >{closeDayReport.bill_values[tkn].customer_phone}</span>
																	          	</div>
																	          </td>
																	          <td>
																	          	<div className="d-grid">
																	          		  <div className="grid-auto-100  bdrb2 mb-1 px-1">
																	          		  	<span className="tp3 f16" >Open</span>
																	          		  	<span className="tp3 f16 grid-justify-end" >{closeDayReport.bill_values[tkn].open_time}</span>
																	          		  </div>
																	          		  <div className="grid-auto-100 bdrb2 mb-1 px-1">
																	          		  	<span className="tp3 f16" >Close</span>
																	          		  	<span className="tp3 f16 grid-justify-end" >{closeDayReport.bill_values[tkn].close_time}</span>
																	          		  </div>
																	          	</div>
																	          </td>
																	          <td>
																	          	<div className="d-grid">
																	          	{
																	          		closeDayReport.bill_values[tkn].bill_payments!==undefined?(
																	          			Object.keys(closeDayReport.bill_values[tkn].bill_payments).length>0?(
																	          				Object.keys(closeDayReport.bill_values[tkn].bill_payments).map((b_pay,b_pay_i)=>(
																	          					<div className="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
																				          		  	<span className="tp3 f16" >{closeDayReport.bill_values[tkn].bill_payments[b_pay].name}</span>
																				          			<span className="tp3 f16 grid-justify-end" >{closeDayReport.bill_values[tkn].bill_payments[b_pay].amount}</span>
																				          		</div>
																	          				))
																	          			):''
																	          		):''
																	          	}
																	          	</div>
																	          </td>
																	          <td className="text-end pos-rel" >
																	          	<span className="tp1 f18" >{closeDayReport.bill_values[tkn].bill_total}</span>
																	          </td>
																	        </tr>
														      			))
														      		):''
														      	):''
														      }
														      {
														      	closeDayReport!==undefined?(
														      		closeDayReport.main.payments_total!==undefined?(
														      			Object.keys(closeDayReport.main.payments_total).length>0?(
														      				Object.keys(closeDayReport.main.payments_total).map((r_pay,r_pay_i)=>(
														      					closeDayReport.main.payments_total[r_pay].amount>0?(
																      				<tr key={`r_pay_${r_pay_i}`}>
																			        	<td colSpan={4} ></td>
																			        	<td className="text-end" ><span className="tp1 f16" >{closeDayReport.main.payments_total[r_pay].name}</span></td>
																			        	<td className="text-end" >{closeDayReport.main.payments_total[r_pay].amount}</td>
																			        </tr>
																		        ):''
																	        ))
														      			):''
														      		):''
														      	):''
														      }
														      {
														      	closeDayReport!==undefined?(
														      		closeDayReport.main!==undefined?(
														      			<tr>
														      				<td colSpan={4} ></td>
																        	<td className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
																        	<td className="text-end bgy" >{closeDayReport.main.bill_amount_total}</td>
																        </tr>
														      		):''
														      	):''
														      }											        
														      </tbody>
														    </Table>
														    {
														    	closeDayReport!==undefined&&closeDayReport.expense_values!==undefined&&Object.keys(closeDayReport.expense_values).length>0?(
														    		<>
														    			<span className="d-grid f18 tp2 py-2" >Expense details</span>
														    			<Table bordered className="tbl-bg-blue" >
																		    <thead>
																	        <tr>
																	          <th className="tp2" >Particulars</th>
																	          <th className="tp2" >Remarks</th>
																	          <th className="tp2" >Staff</th>
																	          <th className="tp2" >Payment method</th>
																	          <th className="text-end tp2" >Amount</th>
																	        </tr>
																		    </thead>
																		    <tbody>
																		    {
																		    	Object.keys(closeDayReport.expense_values).map((exp,expi)=>(
																		    		<tr key={`expi_${expi}`} className="tc2" > 
													        						<td>
													        							<span className="d-grid" >{closeDayReport.expense_values[exp].particulars}</span>
													        						</td>
													        						<td>{closeDayReport.expense_values[exp].remarks}</td>
													        						<td>{closeDayReport.expense_values[exp].user}</td>
													        						<td>{closeDayReport.expense_values[exp].payment_type}</td>
													        						<td className="text-end">{closeDayReport.expense_values[exp].expense_total}</td>
													        					</tr>
																		    	))
																		    }
																		    <tr>
																					<td colSpan={3} className="tp2 text-end f20 f-400">Total</td>
																					<td>
																						{
																							closeDayReport.main.expense_total!==undefined&&Object.keys(closeDayReport.main.expense_total).length>0?(
																								Object.keys(closeDayReport.main.expense_total).map((ex,exi)=>(
																									closeDayReport.main.expense_total[ex].amount>0?(
																										<div class="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${exi}`} >
																			          		  	<span className="tp2 f16" >{closeDayReport.main.expense_total[ex].name}</span>
																			          			<span className="tp2 f16 grid-justify-end" >{closeDayReport.main.expense_total[ex].amount}</span>
																			          		</div>
																		          		):''
																								))
																							):''
																						}
																					</td>
																					<td className="text-end f20 f-400">{closeDayReport.main!==undefined?closeDayReport.main.expense_day_total:''}</td>
																				</tr>
																		    </tbody>
																		   </Table>
														    		</>
														    	):''
														    }
										          </Col>
										        </Accordion.Body>
										      </Accordion.Item>
										    </Accordion>
				        			</Col>
				        		</Row>
				        	</Modal.Body>
				        ):''
		        	):''
		        }
		        <Modal.Footer>
		        </Modal.Footer>
		      </Modal>

		      <Modal show={dayCloseReportModal} size="xl" onHide={()=>{setDayCloseReportModal(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Day Close report {getDayReportLoad?(<span className="please-wait-spinner f18 text-primary" ><Spinner animation="border" size="sm" /> Please wait...</span>):''}</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Row>
	        			<Col xs={12} sm={6} md={3} lg={2} className="mb-3"  >
	        				<span className="f16 d-grid tp2 py-2 px-3 diff bdr1 br-3" >{closeDayReport!==undefined?`Diffrence = ${closeDayReport.main.diffrence}`:''}</span>
	        			</Col>
	        			<Col xs={12} sm={6} md={4} lg={3} className="mb-3"  >
			        		<span className="f16 d-grid tp2 py-2" >{closeDayReport!==undefined?`Closing total = ${closeDayReport.main.denomination_total}`:''}</span>
	        			</Col>
	        			<Col xs={12} sm={6} md={4} lg={3} className="mb-3"  >
	        				<span className="f16 d-grid tp2 py-2" >{closeDayReport!==undefined?`Total Cash Settled = ${closeDayReport.main.primary_total}`:''}</span>
	        			</Col>
	        		</Row>
	        		<Row >
	        			<span className="d-grid f18 tp2 py-2" >Submitted denominations</span>
	        			{
	        				closeDayReport!==undefined?(
	        					closeDayReport.deno_values!==undefined?(
	        					<>
	        					{
	        						closeDayReport.deno_values.n_500>0?(
	        							<Col xs={12} sm={6} md={4} lg={3} >
	        								<Col className="mb-3 denomin bdr2 px-0">
								        		<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 500</span>
								        		<span className="denomin-val p-2">{closeDayReport.deno_values.n_500} <span className="ts3 f14" >Numbers</span></span>
								        	</Col>
								        </Col>
	        						):''
	        					}
	        					{
	        						closeDayReport.deno_values.n_200>0?(
	        							<Col xs={12} sm={6} md={4} lg={3} >
	        								<Col className="mb-3 denomin bdr2 px-0">
								        		<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 200</span>
								        		<span className="denomin-val p-2">{closeDayReport.deno_values.n_200} <span className="ts3 f14" >Numbers</span></span>
								        	</Col>
								        </Col>
	        						):''
	        					}
	        					{
	        						closeDayReport.deno_values.n_100>0?(
	        							<Col xs={12} sm={6} md={4} lg={3}>
	        								<Col className="mb-3 denomin bdr2 px-0">
								        		<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 100</span>
								        		<span className="denomin-val p-2">{closeDayReport.deno_values.n_100} <span className="ts3 f14" >Numbers</span></span>
								        	</Col>
								        </Col>
	        						):''
	        					}
	        					{
	        						closeDayReport.deno_values.n_50>0?(
	        							<Col xs={12} sm={6} md={4} lg={3} xl={3}>
		        							<Col className="mb-3 denomin bdr2 px-0">
									        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 50</span>
									        	<span className="denomin-val p-2">{closeDayReport.deno_values.n_50} <span className="ts3 f14" >Numbers</span></span>
									        </Col>
								        </Col>
	        						):''
	        					}
	        					{
	        						closeDayReport.deno_values.n_20>0?(
	        							<Col xs={12} sm={6} md={4} lg={3} >
	        								<Col className="mb-3 denomin bdr2 px-0">
								        		<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 20</span>
								        		<span className="denomin-val p-2">{closeDayReport.deno_values.n_20} <span className="ts3 f14" >Numbers</span></span>
								        	</Col>
								        </Col>
	        						):''
	        					}
	        					{
	        						closeDayReport.deno_values.n_10>0?(
	        							<Col xs={12} sm={6} md={4} lg={3} >
	        								<Col className="mb-3 denomin bdr2 px-0">
								        		<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 10</span>
								        		<span className="denomin-val p-2">{closeDayReport.deno_values.n_10} <span className="ts3 f14" >Numbers</span></span>
								        	</Col>
								        </Col>
	        						):''
	        					}
	        					{
	        						closeDayReport.deno_values.n_5>0?(
	        							<Col xs={12} sm={6} md={4} lg={3} >
		        							<Col className="mb-3 denomin bdr2 px-0">
									        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 5</span>
									        	<span className="denomin-val p-2">{closeDayReport.deno_values.n_5} <span className="ts3 f14" >Numbers</span></span>
									        </Col>
								        </Col>
	        						):''
	        					}
	        					{
	        						closeDayReport.deno_values.n_2>0?(
	        							<Col xs={12} sm={6} md={4} lg={3} >
		        							<Col className="mb-3 denomin bdr2 px-0">
									        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 2</span>
									        	<span className="denomin-val p-2">{closeDayReport.deno_values.n_2} <span className="ts3 f14" >Numbers</span></span>
									        </Col>
								        </Col>
	        						):''
	        					}
	        					{
	        						closeDayReport.deno_values.n_1>0?(
	        							<Col xs={12} sm={6} md={4} lg={3} >
		        							<Col className="mb-3 denomin bdr2 px-0">
									        	<span className="denomin-name bgs1 p-2" ><i className="bi bi-currency-rupee"></i> 1</span>
									        	<span className="denomin-val p-2">{closeDayReport.deno_values.n_1} <span className="ts3 f14" >Numbers</span></span>
									        </Col>
								        </Col>
	        						):''
	        					}
	        					</>
	        					):''
	        				):''
	        			}
				    </Row>
		        	<Row>
				        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >	
				        		{
				          		closeDayReport!==undefined?(
				          			closeDayReport!==undefined&&closeDayReport.wallet_values!==undefined?(
														<>
															<span className="d-grid f20 bgs1 bdr1 bdrb0 px-2 tp2 py-2" ><b>Wallet details</b></span>
												          	<Table bordered className="tbl-bg-blue" >
														      <thead>
														        <tr>
														          <th><span className="tp2" >Date</span></th>
														          <th><span className="tp2" >Customer</span></th>
														          <th><span className="tp2" >Staff</span></th>
														          <th><span className="tp2" >Payment Methods</span></th>
														          <th className="text-end" ><span className="tp2" >Total</span></th>
														        </tr>
														      </thead>
														      <tbody>
														      {
														      	Object.keys(closeDayReport.wallet_values).length>0?(
														      		Object.keys(closeDayReport.wallet_values).map((wc,wci)=>(
														      			<tr key={`wc_$wci`} className="tp2" >
														      				<td>{closeDayReport.wallet_values[wc].collected_date}
														      				</td>
														      				<td>
														      					<div className="d-grid">
																	          		<span className="tp3 f16" >{closeDayReport.wallet_values[wc].customer_name}</span>
																	          		<span className="tp3 f16" >{closeDayReport.wallet_values[wc].customer_phone}</span>								          		
																	          	</div>
														      				</td>
														      				<td>
																	          	<div class="grid-auto-100 bdrb2 mb-1 px-1" >
																          		  	<span className="tp3 f16" >Collected by {closeDayReport.wallet_values[wc].cash_collected}</span>
																          			<span className="tp3 f16 grid-justify-end" >{closeDayReport.wallet_values[wc].collected_time}</span>
																          		</div>
																	        </td>
																	        <td>
																	          	<div className="d-grid">
																	          	{
																	          		closeDayReport.wallet_values[wc].ap_payments!==undefined?(
																	          			Object.keys(closeDayReport.wallet_values[wc].ap_payments).length>0?(
																	          				Object.keys(closeDayReport.wallet_values[wc].ap_payments).map((b_pay,b_pay_i)=>(
																	          					<div class="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
																				          		  	<span className="tp3 f16" >{closeDayReport.wallet_values[wc].ap_payments[b_pay].name}</span>
																				          			<span className="tp3 f16 grid-justify-end" >{closeDayReport.wallet_values[wc].ap_payments[b_pay].amount}</span>
																				          		</div>
																	          				))
																	          			):''
																	          		):''
																	          	}
																	          	</div>
																	          </td>
																	          <td className="text-end" >
																	          	{
																        			closeDayReport.wallet_values[wc].amount>0?(
																        				<span className="sucess-text">{closeDayReport.wallet_values[wc].amount}</span>
																        			):(
																        				<span className="error-text">{closeDayReport.wallet_values[wc].amount}</span>
																        			)
																        		}
																	          </td>
														      			</tr>
														      		))
														      	):'Wallet Not found!'
														      }
														      {
														      	closeDayReport!==undefined?(
														      		closeDayReport.main.advance_total!==undefined?(
														      			Object.keys(closeDayReport.main.advance_total).length>0?(
														      				Object.keys(closeDayReport.main.advance_total).map((r_pay,r_pay_i)=>(
														      					closeDayReport.main.advance_total[r_pay].amount!==0?(
																      				<tr key={`r_pay_x${r_pay_i}`}>
																			        	<td colSpan={3} ></td>
																			        	<td className="text-end" ><span className="tp1 f16" >{closeDayReport.main.advance_total[r_pay].name}</span></td>
																			        	<td className="text-end" >
																			        		{
																			        			closeDayReport.main.advance_total[r_pay].amount>0?(
																			        				<span className="sucess-text">{closeDayReport.main.advance_total[r_pay].amount}</span>
																			        			):(
																			        				<span className="error-text">{closeDayReport.main.advance_total[r_pay].amount}</span>
																			        			)
																			        		}
																			        	</td>
																			        </tr>
																		        ):''
																	        ))
														      			):''
														      		):''
														      	):''
														      }
														      {
														      	closeDayReport!==undefined?(
														      		closeDayReport.main!==undefined?(
														      			<tr>
														      				<td colSpan={3} ></td>
																        	<td className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
																        	<td className="text-end bgy" >{closeDayReport.main.advance_day_total}</td>
																        </tr>
														      		):''
														      	):''
														      }	
														      </tbody>
															</Table>
														</>
														):'Wallet Not found!'
				          		):''
				          	}			          	
				          	{
				          		closeDayReport!==undefined?(
				          			closeDayReport.debit_values!==undefined&&Object.keys(closeDayReport.debit_values).length>0?(
					          				<>
						          				<span className="d-grid f18 tp2 py-2" >Credit details</span>
									          	<Table bordered className="tbl-bg-blue" >
												      <thead>
												        <tr>
												          <th><span className="tp2" >Customer</span></th>
												          <th><span className="tp2" >Bill details</span></th>
												          <th><span className="tp2" >Times</span></th>
												          <th><span className="tp2" >Payment Methods</span></th>
												          <th className="text-end" ><span className="tp2" >Total</span></th>
												        </tr>
												      </thead>
												      <tbody>
												      {
												      	Object.keys(closeDayReport.debit_values).map((debit,deb_i)=>(
												      	<tr key={`debi_${deb_i}`} >
												      		<td>
												      			<div className="d-grid">
													          		<span className="tp3 f16" >{closeDayReport.debit_values[debit].customer_name}</span>
													          		<span className="tp3 f16" >{closeDayReport.debit_values[debit].customer_phone}</span>
													          	</div>
												      		</td>
												      		<td>
													          	<div className="d-grid">
													          		<span className="tp1 f16" >Token No : {closeDayReport.debit_values[debit].token_no}</span>
													          		<span className="tp3 f16" >Bill No : {closeDayReport.debit_values[debit].bill_no}</span>
													          		<span className="tp3 f16" >Date : {closeDayReport.debit_values[debit].collected_date}</span>
													          	</div>
													        </td>
													        <td>
													          	<div className="grid-auto-100 bdrb2 mb-1 px-1" >
												          		  	<span className="tp3 f16" >Collected</span>
												          			<span className="tp3 f16 grid-justify-end" >{closeDayReport.debit_values[debit].collected_time}</span>
												          		</div>
													        </td>
													        <td>
													          	<div className="d-grid">
													          	{
													          		closeDayReport.debit_values[debit].debit_payments!==undefined?(
													          			Object.keys(closeDayReport.debit_values[debit].debit_payments).length>0?(
													          				Object.keys(closeDayReport.debit_values[debit].debit_payments).map((b_pay,b_pay_i)=>(
													          					<div className="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
																          		  	<span className="tp3 f16" >{closeDayReport.debit_values[debit].debit_payments[b_pay].name}</span>
																          			<span className="tp3 f16 grid-justify-end" >{closeDayReport.debit_values[debit].debit_payments[b_pay].amount}</span>
																          		</div>
													          				))
													          			):''
													          		):''
													          	}
													          	</div>
													          </td>
													          <td className="text-end pos-rel" >
													          	<span className="tp1 f18" >{closeDayReport.debit_values[debit].debit_total}</span>
													          </td>
												      	</tr>
												      	))
												      }
												      {
												      	closeDayReport!==undefined?(
												      		closeDayReport.main.debit_total!==undefined?(
												      			Object.keys(closeDayReport.main.debit_total).length>0?(
												      				Object.keys(closeDayReport.main.debit_total).map((r_pay,r_pay_i)=>(
													      				closeDayReport.main.debit_total[r_pay].amount>0?(
														      				<tr key={`r_pay_${r_pay_i}`}>
																	        	<td colSpan={3} ></td>
																	        	<td className="text-end" ><span className="tp1 f16" >{closeDayReport.main.debit_total[r_pay].name}</span></td>
																	        	<td className="text-end" >{closeDayReport.main.debit_total[r_pay].amount}</td>
																	        </tr>
																	    ):''
															        ))
												      			):''
												      		):''
												      	):''
												      }
												      {
												      	closeDayReport!==undefined?(
												      		closeDayReport.main!==undefined?(
												      			<tr>
												      				<td colSpan={3} ></td>
														        	<td className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
														        	<td className="text-end bgy" >{closeDayReport.main.debit_amount_total}</td>
														        </tr>
												      		):''
												      	):''
												      }		
												      </tbody>
												    </Table>
											    </>
										    ):''
				          		):''
				          	}
				          	<span className="d-grid f18 tp2 py-2" >Collection details</span>
				          	<Table bordered className="tbl-bg-blue" >
								      <thead>
								        <tr>
								          <th><span className="tp2" >Token</span></th>
								          <th><span className="tp2" >Services</span></th>
								          <th><span className="tp2" >Customer</span></th>
								          <th><span className="tp2" >Times</span></th>
								          <th><span className="tp2" >Payment Methods</span></th>
								          <th className="text-end" ><span className="tp2" >Total</span></th>
								        </tr>
								      </thead>
								      <tbody>
								      {
								      	closeDayReport!==undefined?(
								      		closeDayReport.bill_values!==undefined?(
								      			Object.keys(closeDayReport.bill_values).map((tkn,tkn_i)=>(
								      				<tr key={`trep_${tkn_i}`} >
											          <td>
											          	<div className="d-grid">
											          		<span className="tp1 f18" >No : {closeDayReport.bill_values[tkn].token_no}</span>
											          		<span className="tp3 f16" >Bill No : {closeDayReport.bill_values[tkn].bill_no}</span>
											          	</div>
											          </td>
											          <td>
											          	<div className="d-grid">
											          	{
											          		closeDayReport.bill_values[tkn].bill_services!==undefined?(
											          			Object.keys(closeDayReport.bill_values[tkn].bill_services).length>0?(
											          				Object.keys(closeDayReport.bill_values[tkn].bill_services).map((t_ser,t_ser_i)=>(
											          					<span className="tp3 f16" key={`tkrpt_${t_ser_i}`} >{closeDayReport.bill_values[tkn].bill_services[t_ser].service_name}{closeDayReport.bill_values[tkn].bill_services[t_ser].quantity!==undefined&&closeDayReport.bill_values[tkn].bill_services[t_ser].quantity>1?(<> ( {closeDayReport.bill_values[tkn].bill_services[t_ser].quantity} )</>):''}</span>
											          				))
											          			):''
											          		):''
											          	}
											          	</div>
											          </td>
											          <td>
											          	<div className="d-grid">
											          		<span className="tp3 f16" >{closeDayReport.bill_values[tkn].customer_name}</span>
											          		<span className="tp3 f16" >{closeDayReport.bill_values[tkn].customer_phone}</span>
											          	</div>
											          </td>
											          <td>
											          	<div className="d-grid">
											          		  <div className="grid-auto-100  bdrb2 mb-1 px-1">
											          		  	<span className="tp3 f16" >Open</span>
											          		  	<span className="tp3 f16 grid-justify-end" >{closeDayReport.bill_values[tkn].open_time}</span>
											          		  </div>
											          		  <div className="grid-auto-100 bdrb2 mb-1 px-1">
											          		  	<span className="tp3 f16" >Close</span>
											          		  	<span className="tp3 f16 grid-justify-end" >{closeDayReport.bill_values[tkn].close_time}</span>
											          		  </div>
											          	</div>
											          </td>
											          <td>
											          	<div className="d-grid">
											          	{
											          		closeDayReport.bill_values[tkn].bill_payments!==undefined?(
											          			Object.keys(closeDayReport.bill_values[tkn].bill_payments).length>0?(
											          				Object.keys(closeDayReport.bill_values[tkn].bill_payments).map((b_pay,b_pay_i)=>(
											          					<div className="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
														          		  	<span className="tp3 f16" >{closeDayReport.bill_values[tkn].bill_payments[b_pay].name}</span>
														          			<span className="tp3 f16 grid-justify-end" >{closeDayReport.bill_values[tkn].bill_payments[b_pay].amount}</span>
														          		</div>
											          				))
											          			):''
											          		):''
											          	}
											          	</div>
											          </td>
											          <td className="text-end pos-rel" >
											          	<span className="tp1 f18" >{closeDayReport.bill_values[tkn].bill_total}</span>
											          </td>
											        </tr>
								      			))
								      		):''
								      	):''
								      }
								      {
								      	closeDayReport!==undefined?(
								      		closeDayReport.main.payments_total!==undefined?(
								      			Object.keys(closeDayReport.main.payments_total).length>0?(
								      				Object.keys(closeDayReport.main.payments_total).map((r_pay,r_pay_i)=>(
									      				closeDayReport.main.payments_total[r_pay].amount>0?(
										      				<tr key={`r_pay_${r_pay_i}`}>
													        	<td colSpan={4} ></td>
													        	<td className="text-end" ><span className="tp1 f16" >{closeDayReport.main.payments_total[r_pay].name}</span></td>
													        	<td className="text-end" >{closeDayReport.main.payments_total[r_pay].amount}</td>
													        </tr>
													    ):''
											        ))
								      			):''
								      		):''
								      	):''
								      }
								      {
								      	closeDayReport!==undefined?(
								      		closeDayReport.main!==undefined?(
								      			<tr>
								      				<td colSpan={4} ></td>
										        	<td className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
										        	<td className="text-end bgy" >{closeDayReport.main.bill_amount_total}</td>
										        </tr>
								      		):''
								      	):''
								      }											        
								      </tbody>
								    </Table>
								    {
								    	closeDayReport!==undefined&&closeDayReport.expense_values!==undefined&&Object.keys(closeDayReport.expense_values).length>0?(
								    		<>
								    			<span className="d-grid f18 tp2 py-2" >Expense details</span>
								    			<Table bordered className="tbl-bg-blue" >
												    <thead>
											        <tr>
											          <th className="tp2" >Particulars</th>
											          <th className="tp2" >Remarks</th>
											          <th className="tp2" >Staff</th>
											          <th className="tp2" >Payment method</th>
											          <th className="text-end tp2" >Amount</th>
											        </tr>
												    </thead>
												    <tbody>
												    {
												    	Object.keys(closeDayReport.expense_values).map((exp,expi)=>(
												    		<tr key={`expi_${expi}`} className="tc2" > 
							        						<td>
							        							<span className="d-grid" >{closeDayReport.expense_values[exp].particulars}</span>
							        						</td>
							        						<td>{closeDayReport.expense_values[exp].remarks}</td>
							        						<td>{closeDayReport.expense_values[exp].user}</td>
							        						<td>{closeDayReport.expense_values[exp].payment_type}</td>
							        						<td className="text-end">{closeDayReport.expense_values[exp].expense_total}</td>
							        					</tr>
												    	))
												    }
												    <tr>
															<td colSpan={3} className="tp2 text-end f20 f-400">Total</td>
															<td>
																{
																	closeDayReport.main.expense_total!==undefined&&Object.keys(closeDayReport.main.expense_total).length>0?(
																		Object.keys(closeDayReport.main.expense_total).map((ex,exi)=>(
																			closeDayReport.main.expense_total[ex].amount>0?(
																				<div class="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${exi}`} >
													          		  	<span className="tp2 f16" >{closeDayReport.main.expense_total[ex].name}</span>
													          			<span className="tp2 f16 grid-justify-end" >{closeDayReport.main.expense_total[ex].amount}</span>
													          		</div>
												          		):''
																		))
																	):''
																}
															</td>
															<td className="text-end f20 f-400">{closeDayReport.main!==undefined?closeDayReport.main.expense_day_total:''}</td>
														</tr>
												    </tbody>
												   </Table>
								    		</>
								    	):''
								    }
				        </Col>
	        		</Row>
		        </Modal.Body>
		        <Modal.Footer>
		        </Modal.Footer>
		      </Modal>

		      <Modal show={changePasswordModal} size="md" onHide={()=>{setChangePasswordModal(false)}}>
		      	<Modal.Header closeButton>
		          <Modal.Title>
		          	{
		        		alertPassChange!==undefined?(
		        				alertPassChange === 'default'?(
		        					'Change password'
		        					):alertPassChange==='error'?(
		        					<span className='error-alert'>Sothing went wrong</span>
		        					):alertPassChange==='sucess'?(
		        					<span className="sucess-alert">Sucess</span>
		        					):''
		        			):'Change password'
		        	}
		          </Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={changePassword} >
		        		<Form.Group className="mb-3" >
		        			<Form.Label>New Password {npassError?(<span className="error-message" >This field is required</span>):''} {npassEqualError?(<span className="error-message" >Password and Confirm password do not mach</span>):''}</Form.Label>
		        			<Form.Control className="shadow-none br-2" type="password" name="pass" placeholder="New Password" />
		        		</Form.Group>
		        		<Form.Group className="mb-3" >
		        			<Form.Label>Confirm Password {ncpassError?(<span className="error-message" >This field is required</span>):''}</Form.Label>
		        			<Form.Control className="shadow-none br-2" type="password" name="cpass" placeholder="Confirm Password" />
		        		</Form.Group>
		        		<div className="d-grid">
		        			{
						    	changePassLoad?(
						    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
						    	):(
						    	<Button type="submit" className="btn-t1">UPDATE</Button>
						    	)
						    }
		        		</div>
		        	</Form>
		        </Modal.Body>
		      </Modal>

		    </Navbar>
		)
}