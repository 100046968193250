import React, {useContext} from 'react';
import {Container, Row, Col, Table, Spinner, Form, InputGroup, Button} from 'react-bootstrap';
import { AppContext } from '../App';


export const CollectionReports=()=>{
	const { getCollectionReport, collectionReport, collectionReportLoad, getDate, getBill, setViewBillModal } = useContext(AppContext);
	const getReport=(e)=>{
		e.preventDefault();
		let start=e.target.start.value;
		let end = e.target.end.value;
		if (start!==""&&end!=="") {
			getCollectionReport(start,end);
		}
	}
	return(
			<Container className="py-3" >
				<Form onSubmit={getReport} >
					<Row className="mb-3" >
						<Col xs={12} sm={4} md={4} >
							<InputGroup  className="br-3">
						        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">From</InputGroup.Text>
						        <Form.Control name="start" type="date" defaultValue={getDate()}  className="br-3" />
						    </InputGroup>
						</Col>
						<Col xs={12} sm={4} md={4} >
							<InputGroup  className="br-3">
						        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">To</InputGroup.Text>
						        <Form.Control name="end" type="date" defaultValue={getDate()}  className="br-3" />
						    </InputGroup>
						</Col>
						<Col xs={12} sm={4} md={4} className="d-grid" >
							<Button type="submit" className="btn-t1" >Get Report</Button>
						</Col>
					</Row>
				</Form> 
				{
					collectionReportLoad?(
						<div className="d-grid py-2">
							<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
						</div>
						):''
				}
				{
					collectionReport!==undefined&&collectionReport.wallet_values!==undefined?(
					<>
						<span className="d-grid f20 bgs1 bdr1 bdrb0 px-2 tp2 py-2" ><b>Wallet details</b></span>
			          	<Table bordered className="tbl-bg-blue" >
					      <thead>
					        <tr>
					          <th><span className="tp2" >Date</span></th>
					          <th><span className="tp2" >Customer</span></th>
					          <th><span className="tp2" >Staff</span></th>
					          <th><span className="tp2" >Payment Methods</span></th>
					          <th className="text-end" ><span className="tp2" >Total</span></th>
					        </tr>
					      </thead>
					      <tbody>
					      {
					      	Object.keys(collectionReport.wallet_values).length>0?(
					      		Object.keys(collectionReport.wallet_values).map((wc,wci)=>(
					      			<tr key={`wc_$wci`} className="tp2" >
					      				<td>{collectionReport.wallet_values[wc].collected_date}
					      				</td>
					      				<td>
					      					<div className="d-grid">
								          		<span className="tp3 f16" >{collectionReport.wallet_values[wc].customer_name}</span>
								          		<span className="tp3 f16" >{collectionReport.wallet_values[wc].customer_phone}</span>								          		
								          	</div>
					      				</td>
					      				<td>
								          	<div class="grid-auto-100 bdrb2 mb-1 px-1" >
							          		  	<span className="tp3 f16" >Collected by {collectionReport.wallet_values[wc].cash_collected}</span>
							          			<span className="tp3 f16 grid-justify-end" >{collectionReport.wallet_values[wc].collected_time}</span>
							          		</div>
								        </td>
								        <td>
								          	<div className="d-grid">
								          	{
								          		collectionReport.wallet_values[wc].ap_payments!==undefined?(
								          			Object.keys(collectionReport.wallet_values[wc].ap_payments).length>0?(
								          				Object.keys(collectionReport.wallet_values[wc].ap_payments).map((b_pay,b_pay_i)=>(
								          					<div class="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
											          		  	<span className="tp3 f16" >{collectionReport.wallet_values[wc].ap_payments[b_pay].name}</span>
											          			<span className="tp3 f16 grid-justify-end" >{collectionReport.wallet_values[wc].ap_payments[b_pay].amount}</span>
											          		</div>
								          				))
								          			):''
								          		):''
								          	}
								          	</div>
								          </td>
								          <td className="text-end" >
								          	{
							        			collectionReport.wallet_values[wc].amount>0?(
							        				<span className="sucess-text">{collectionReport.wallet_values[wc].amount}</span>
							        			):(
							        				<span className="error-text">{collectionReport.wallet_values[wc].amount}</span>
							        			)
							        		}
								          </td>
					      			</tr>
					      		))
					      	):'Wallet Not found!'
					      }
					      {
					      	collectionReport!==undefined?(
					      		collectionReport.main.advance_total!==undefined?(
					      			Object.keys(collectionReport.main.advance_total).length>0?(
					      				Object.keys(collectionReport.main.advance_total).map((r_pay,r_pay_i)=>(
					      					collectionReport.main.advance_total[r_pay].amount!==0?(
							      				<tr key={`r_pay_x${r_pay_i}`}>
										        	<td colSpan={3} ></td>
										        	<td className="text-end" ><span className="tp1 f16" >{collectionReport.main.advance_total[r_pay].name}</span></td>
										        	<td className="text-end" >
										        		{
										        			collectionReport.main.advance_total[r_pay].amount>0?(
										        				<span className="sucess-text">{collectionReport.main.advance_total[r_pay].amount}</span>
										        			):(
										        				<span className="error-text">{collectionReport.main.advance_total[r_pay].amount}</span>
										        			)
										        		}
										        	</td>
										        </tr>
									        ):''
								        ))
					      			):''
					      		):''
					      	):''
					      }
					      {
					      	collectionReport!==undefined?(
					      		collectionReport.main!==undefined?(
					      			<tr>
					      				<td colSpan={3} ></td>
							        	<td className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
							        	<td className="text-end bgy" >{collectionReport.main.advance_day_total}</td>
							        </tr>
					      		):''
					      	):''
					      }	
					      </tbody>
						</Table>
					</>
					):'Wallet Not found!'
				}
				{
	          		collectionReport!==undefined?(
	          			collectionReport.debit_values!==undefined&&Object.keys(collectionReport.debit_values).length>0?(
	          				<>
		          				<span className="d-grid f20 bgs1 bdr1 bdrb0 px-2 tp2 py-2" ><b>Credit collection details</b></span>
					          	<Table bordered className="tbl-bg-blue" >
							      <thead>
							        <tr>
							          <th><span className="tp2" >Bill details</span></th>
							          <th><span className="tp2" >Customer</span></th>
							          <th><span className="tp2" >Times</span></th>
							          <th><span className="tp2" >Payment Methods</span></th>
							          <th className="text-end" ><span className="tp2" >Total</span></th>
							        </tr>
							      </thead>
							      <tbody>
							      {
							      	Object.keys(collectionReport.debit_values).map((debit,deb_i)=>(
							      	<tr key={`debi_${deb_i}`} >
							      		<td>
							          		<span className="tp3 f16 d-grid mb-1" >Invoice : {collectionReport.debit_values[debit].bill_no} </span>
							          		<span onClick={()=>{
																getBill(collectionReport.debit_values[debit].bill_no);
																setViewBillModal(true);
															}} className="view-bill-link mx-0" >View bill</span>
							          		<span className="tp3 f14 d-grid mt-1" >{collectionReport.debit_values[debit].collected_date}, {collectionReport.debit_values[debit].collected_time}</span>

							          		
								        </td>
							      		<td>
							      			<div className="d-grid">
								          		<span className="tp3 f16" >{collectionReport.debit_values[debit].customer_name}</span>
								          		<span className="tp3 f16" >{collectionReport.debit_values[debit].customer_phone}</span>								          		
								          	</div>
							      		</td>
								        <td>
								          	<div class="grid-auto-100 bdrb2 mb-1 px-1" >
							          		  	<span className="tp3 f16" >Collected by {collectionReport.debit_values[debit].cash_collected}</span>
							          			<span className="tp3 f16 grid-justify-end" >{collectionReport.debit_values[debit].collected_time}</span>
							          		</div>
								        </td>
								        <td>
								          	<div className="d-grid">
								          	{
								          		collectionReport.debit_values[debit].debit_payments!==undefined?(
								          			Object.keys(collectionReport.debit_values[debit].debit_payments).length>0?(
								          				Object.keys(collectionReport.debit_values[debit].debit_payments).map((b_pay,b_pay_i)=>(
								          					<div class="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
											          		  	<span className="tp3 f16" >{collectionReport.debit_values[debit].debit_payments[b_pay].name}</span>
											          			<span className="tp3 f16 grid-justify-end" >{collectionReport.debit_values[debit].debit_payments[b_pay].amount}</span>
											          		</div>
								          				))
								          			):''
								          		):''
								          	}
								          	</div>
								          </td>
								          <td className="text-end pos-rel" >
								          	<span className="tp1 f18" >{collectionReport.debit_values[debit].debit_total}</span>
								          </td>
							      	</tr>
							      	))
							      }
							      {
							      	collectionReport!==undefined?(
							      		collectionReport.main.debit_total!==undefined?(
							      			Object.keys(collectionReport.main.debit_total).length>0?(
							      				Object.keys(collectionReport.main.debit_total).map((r_pay,r_pay_i)=>(
								      				collectionReport.main.debit_total[r_pay].amount>0?(
									      				<tr key={`r_pay_${r_pay_i}`}>
												        	<td colSpan={3} ></td>
												        	<td className="text-end" ><span className="tp1 f16" >{collectionReport.main.debit_total[r_pay].name}</span></td>
												        	<td className="text-end" >{collectionReport.main.debit_total[r_pay].amount}</td>
												        </tr>
												    ):''
										        ))
							      			):''
							      		):''
							      	):''
							      }
							      {
							      	collectionReport!==undefined?(
							      		collectionReport.main!==undefined?(
							      			<tr>
							      				<td colSpan={3} ></td>
									        	<td className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
									        	<td className="text-end bgy" >{collectionReport.main.debit_amount_total}</td>
									        </tr>
							      		):''
							      	):''
							      }		
							      </tbody>
							    </Table>
						    </>
					    ):''
	          		):''
	          	}
	          	<span className="d-grid f18 tp2 py-2 f20 bgs1 bdr1 bdrb0 px-2" ><b>Collection details</b></span>
				<Table bordered className="tbl-bg-blue" >
				      <thead>
				        <tr>
				          <th><span className="tp2" >Bill details</span></th>
				          <th><span className="tp2" >Services</span></th>
				          <th><span className="tp2" >Customer</span></th>
				          <th><span className="tp2" >Times</span></th>
				          <th><span className="tp2" >Payment Methods</span></th>
						  <th className="text-end tp2" ><span>TC</span></th>
						  <th className="text-end tp2" ><span>SC</span></th>
				          <th className="text-end" ><span className="tp2" >Total</span></th>
				        </tr>
				      </thead>
				      <tbody>
				      {
				      	collectionReport!==undefined?(
				      		collectionReport.bill_values!==undefined?(
				      			Object.keys(collectionReport.bill_values).map((tkn,tkn_i)=>(
				      				<tr key={`trep_${tkn_i}`} >
							          <td>
							          	<span className="tp3 f16 d-grid mb-1" >Invoice : {collectionReport.bill_values[tkn].bill_no}</span>
							          		<span onClick={()=>{
																	getBill(collectionReport.bill_values[tkn].bill_no);
																	setViewBillModal(true);
																}} className="view-bill-link mx-0" >View bill</span>
							          </td>
							          <td>
							          	<div className="d-grid">
							          	{
							          		collectionReport.bill_values[tkn].bill_services!==undefined?(
							          			Object.keys(collectionReport.bill_values[tkn].bill_services).length>0?(
							          				Object.keys(collectionReport.bill_values[tkn].bill_services).map((t_ser,t_ser_i)=>(
							          					<span className="tp3 f16" key={`tkrpt_${t_ser_i}`} >{collectionReport.bill_values[tkn].bill_services[t_ser].service_name}{collectionReport.bill_values[tkn].bill_services[t_ser].quantity!==undefined&&collectionReport.bill_values[tkn].bill_services[t_ser].quantity>1?(<> ({collectionReport.bill_values[tkn].bill_services[t_ser].quantity})</>):''}</span>
							          				))
							          			):''
							          		):''
							          	}
							          	</div>
							          </td>
							          <td>
							          	<div className="d-grid">
							          		<span className="tp3 f16" >{collectionReport.bill_values[tkn].customer_name}</span>
							          		<span className="tp3 f16" >{collectionReport.bill_values[tkn].customer_phone}</span>
							          	</div>
							          </td>
							          <td>
							          	<div className="d-grid">
							          		  <div className="grid-auto-100  bdrb2 mb-1 px-1">
							          		  	<span className="tp3 f16" >Created - {collectionReport.bill_values[tkn].token_created_by}</span>
							          		  	<span className="tp3 f16 grid-justify-end" >{collectionReport.bill_values[tkn].created_time}</span>
							          		  </div>
							          		  <div className="grid-auto-100  bdrb2 mb-1 px-1">
							          		  	<span className="tp3 f16" >Processed - {collectionReport.bill_values[tkn].token_processed_by}</span>
							          		  	<span className="tp3 f16 grid-justify-end" >{collectionReport.bill_values[tkn].open_time}</span>
							          		  </div>
							          		  <div className="grid-auto-100 bdrb2 mb-1 px-1">
							          		  	<span className="tp3 f16" >Closed - {collectionReport.bill_values[tkn].token_processed_by}</span>
							          		  	<span className="tp3 f16 grid-justify-end" >{collectionReport.bill_values[tkn].close_time}</span>
							          		  </div>
							          	</div>
							          </td>
							          <td>
							          	<div className="d-grid">
							          	{
							          		collectionReport.bill_values[tkn].bill_payments!==undefined?(
							          			Object.keys(collectionReport.bill_values[tkn].bill_payments).length>0?(
							          				Object.keys(collectionReport.bill_values[tkn].bill_payments).map((b_pay,b_pay_i)=>(
							          					<div className="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
										          		  	<span className="tp3 f16" >{collectionReport.bill_values[tkn].bill_payments[b_pay].name}</span>
										          			<span className="tp3 f16 grid-justify-end" >{collectionReport.bill_values[tkn].bill_payments[b_pay].amount}</span>
										          		</div>
							          				))
							          			):''
							          		):''
							          	}
							          	</div>
							          </td>

							          <td className="text-end pos-rel" >
							          	<span className="tp1 f18" >{collectionReport.bill_values[tkn].transaction_bill_total}</span>
							          </td>
							          <td className="text-end pos-rel" >
							          	<span className="tp1 f18" >{collectionReport.bill_values[tkn].service_bill_total}</span>
							          </td>
							          <td className="text-end pos-rel" >
							          	<span className="tp1 f18" >{collectionReport.bill_values[tkn].bill_total}</span>
							          </td>
							        </tr>
				      			))
				      		):''
				      	):''
				      }
				      {
						collectionReport!==undefined?(
							collectionReport.main!==undefined?(
						      <tr>
						      	<td colSpan={4} ></td>
						      	<td className="text-end f16 tp2" ><span>Total</span></td>
						      	<td className="text-end f16 tp2" ><span>{collectionReport.main.transaction_day_total}</span></td>
						      	<td className="text-end f16 tp2" ><span>{collectionReport.main.service_day_total}</span></td>
						      	<td className="text-end f16 tp2" ><span></span></td>
						      </tr>
				      		):''
				      	):''
				      }

				      {
				      	collectionReport!==undefined?(
				      		collectionReport.main.payments_total!==undefined?(
				      			Object.keys(collectionReport.main.payments_total).length>0?(
				      				Object.keys(collectionReport.main.payments_total).map((r_pay,r_pay_i)=>(
					      				collectionReport.main.payments_total[r_pay].amount>0?(
						      				<tr key={`r_pay_${r_pay_i}`} >
									        	<td colSpan={4} ></td>
									        	<td colSpan={3} className="text-end" ><span className="tp1 f16" >{collectionReport.main.payments_total[r_pay].name}</span></td>
									        	<td className="text-end" >{collectionReport.main.payments_total[r_pay].amount}</td>
									        </tr>
									    ):''
							        ))
				      			):''
				      		):''
				      	):''
				      }
				      {
				      	collectionReport!==undefined?(
				      		collectionReport!==undefined?(
				      			<tr>
				      				<td colSpan={4} ></td>
						        	<td colSpan={3} className="text-end bgy" ><span className="tp1 f18" >Total</span></td>
						        	<td className="text-end bgy" >{collectionReport.main.bill_amount_total}</td>
						        </tr>
				      		):''
				      	):''
				      }											        
				      </tbody>
				    </Table>
				    {
				    	collectionReport!==undefined&&collectionReport.expense_values!==undefined&&Object.keys(collectionReport.expense_values).length>0?(
				    		<>
				    			<span className="d-grid f18 tp2 py-2" >Expense details</span>
				    			<Table bordered className="tbl-bg-blue" >
								    <thead>
							        <tr>
							          <th className="tp2" >Particulars</th>
							          <th className="tp2" >Remarks</th>
							          <th className="tp2" >Staff</th>
							          <th className="tp2" >Payment method</th>
							          <th className="text-end tp2" >Amount</th>
							        </tr>
								    </thead>
								    <tbody>
								    {
								    	Object.keys(collectionReport.expense_values).map((exp,expi)=>(
								    		<tr key={`expi_${expi}`} className="tc2" > 
			        						<td>
			        							<span className="d-grid" >{collectionReport.expense_values[exp].particulars}</span>
			        						</td>
			        						<td>{collectionReport.expense_values[exp].remarks}</td>
			        						<td>{collectionReport.expense_values[exp].user}</td>
			        						<td>{collectionReport.expense_values[exp].payment_type}</td>
			        						<td className="text-end">{collectionReport.expense_values[exp].expense_total}</td>
			        					</tr>
								    	))
								    }
								    <tr>
											<td colSpan={3} className="tp2 text-end f20 f-400">Total</td>
											<td>
												{
													collectionReport.main.expense_total!==undefined&&Object.keys(collectionReport.main.expense_total).length>0?(
														Object.keys(collectionReport.main.expense_total).map((ex,exi)=>(
															collectionReport.main.expense_total[ex].amount>0?(
																<div class="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${exi}`} >
												          		  	<span className="tp2 f16" >{collectionReport.main.expense_total[ex].name}</span>
												          			<span className="tp2 f16 grid-justify-end" >{collectionReport.main.expense_total[ex].amount}</span>
												          		</div>
											          		):''
														))
													):''
												}
											</td>
											<td className="text-end f20 f-400">{collectionReport.main!==undefined?collectionReport.main.expense_day_total:''}</td>
										</tr>
								    </tbody>
								   </Table>
				    		</>
				    	):''
				    }
			</Container>
		)
}