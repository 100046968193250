import React, {useState, useEffect, useContext} from 'react';
import {Container, Row, Col, Button, Form, Modal, Spinner, Table} from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import 'jquery-confirm';
import { AppContext } from '../App';


export const SalaryStaffsDisabled=()=>{
	const { user, load, setLoad, postCall, getCall, setGetLoad, smDiStaff, setSmDiStaffs, getDate } = useContext(AppContext);
	const [ staffAddModel, setStaffAddModel ] = useState(false);
	const [ staffAddLoad, setStaffAddLoad ] = useState(false);
	const [ editable, setEditable ] = useState();
	const [ changeStatLoad, setChangeStatLoad ] = useState(false);
	const [ activeId, setActiveId ] = useState();
	const changeStatus=(sid,status)=>{
		if (sid!==""&&status!=="") {
			setActiveId(sid);
			setChangeStatLoad(true);
			postCall('api/sm/change/status/staff/',{s_id:sid.substring(1),status:status,from_enabled:false}).then(res=>{
				setChangeStatLoad(false);
				setSmDiStaffs(res.data);
				setActiveId();
			}).catch(err=>{
				setChangeStatLoad(false);
			});
		}
	}
	const [ additionModal, setAdditionModal ] = useState(false);
	const [ dedModal, setDedModal ] = useState(false);
	const [ payModal, setPayModal ] = useState(false);
	const [ addLoad, setAddLoad ] = useState(false);
	const fnPost=(e,link,modal)=>{
		e.preventDefault();
		let parti = e.target.partic.value;
		let amt = e.target.amt.value;
		if (parti!==""&&amt>0&&activeId!==undefined&&link!=="") {
			setAddLoad(true);
			postCall(link,{s_id:activeId.substring(1),particular:parti,amount:amt,from_enabled:false}).then(res=>{
				setAddLoad(false);
				modal(false);
				setSmDiStaffs(res.data);
			}).catch(err=>{
				setAddLoad(false);
			});
		}
	}
	return(
		<Container className="my-3 bdr2 br-3" >
			<Row className='' >
				<Col xs="12" sm="12" md="12" lg="12" className="staff-type-header d-grid p-2 bdrb2 bgs1" >
					<span className="f16 tp2" >{load?(<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>):'Staffs'}</span>					
				</Col>
				{
					smDiStaff!==undefined&&Object.keys(smDiStaff).length>0?(
						Object.keys(smDiStaff).map((item,i)=>(
							<div className="grid-split-2 al-center p-2 bdrb2 bgs1-lite" key={`sal_staff_${i}`} >
								<div className="d-grid">
									<div className="d-flex al-center gap-2">
										<span className="f18 tp2" >{`${smDiStaff[item].first_name} ${smDiStaff[item].last_name} `}</span>
										<span className="f16 tp2" >{`[ ${smDiStaff[item].username} / ${smDiStaff[item].designation} ]`}</span></div>
									<div className='d-flex'>
										<span className="f14" >Staff id : {smDiStaff[item].staff_u_id}, Basic pay : {smDiStaff[item].base_pay}, Overtime Charge : {smDiStaff[item].over_time_pay}, Leave cut : {smDiStaff[item].leave_cut}</span>
									</div>
								</div>
								<div className="d-grid just-end al-center">
									<div className="d-flex gap-1 just-end al-center">
										<span className="bg-total px-2 py-1 br-3 mb-1">Unsettled : {smDiStaff[item].unsettled}</span>
										<span className="bg-total px-2 py-1 br-3 mb-1">Wallet : {smDiStaff[item].balance}</span>
									</div>
									<div className="d-flex just-end gap-1">
										<Button onClick={()=>{
											setActiveId(item);
											setPayModal(true);
										}} className="btn-t1" size="sm" ><i className="bi bi-credit-card-2-back"></i> Payment</Button>
										{
											activeId!==undefined&&activeId===item&&changeStatLoad?(
												<Button className="btn-enable" size="sm" ><Spinner animation="border" size="sm" /> Wait...</Button>
											):(
												smDiStaff[item].status?(
													<Button onClick={()=>{changeStatus(item,false)}}  className="btn-disable" size="sm" ><i className="bi bi-eye-slash"></i> Disable</Button>
												):(
													<Button onClick={()=>{changeStatus(item,true)}} className="btn-enable" size="sm" ><i className="bi bi-eye-slash"></i> Enable</Button>
												)
											)
										}
									</div>
								</div>
							</div>
						))
					):''
				}							
			</Row>
		    <Modal size="xl" show={payModal} backdrop="static" onHide={()=>{setPayModal(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Payment</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={e=>fnPost(e,'api/sm/payment/',setPayModal)} >	
		        		<Form.Group className="mb-3">
				    		<Form.Label>Particular</Form.Label>
				    		<Form.Select type="text" name="partic" className="shadow-none br-2" placeholder="Particular" required>
				    			<option value="salary" >Salary</option>
				    			<option value="advance" >Salary Advance</option>
				    		</Form.Select>
				    	</Form.Group>				    
				    	<Form.Group className="mb-3">
			    			<Form.Label>Amount</Form.Label>
				    		<Form.Control type="number" min="1" step=".01" name="amt" className="shadow-none br-2" placeholder="Amount" required/>
				    	</Form.Group>					    
					    <div  className="d-grid">
					    {
					    	addLoad?(
					    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
					    	):(
					    	<Button type="submit" className="btn-t1">SAVE</Button>
					    	)
					    }
					    </div>
		        	</Form>
		        </Modal.Body>
		        <Modal.Footer></Modal.Footer>
		    </Modal>
		</Container>
		)
}