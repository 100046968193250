import React, { useState, useContext} from 'react';
import {Container, Table, Spinner, Form, Row, Col, InputGroup, Button, Accordion } from 'react-bootstrap';
import { AppContext } from '../App';


export const CustomerReport=()=>{
	const { getCustomerReportLoad, customerReport, getDate, getCustomerReport, getBill, setViewBillModal } = useContext(AppContext);
	const getReport=(e)=>{
		e.preventDefault();
		let start=e.target.start.value;
		let end = e.target.end.value;
		if (start!==""&&end!=="") {
			getCustomerReport(start,end);
		}
	}
	const [ searchResult, setSearchResult ] = useState();
	const search=(s_name)=>{
		if(customerReport!==undefined){
		    if(Object.keys(customerReport).length>0){
		    	setSearchResult(prev=>{});
		    	let count = 0;
		    	Object.keys(customerReport).map(cred=>{
		    		if (customerReport[cred].customer_name.toLowerCase().includes(s_name.toLowerCase())||customerReport[cred].customer_phone.includes(s_name)) {
		    			if (count<250) {
		    				setSearchResult(prev=>({...prev,[cred]:customerReport[cred]}));
		    				count+=1;
		    			}
		    		}
		    	});
		    }
		}
	}
	return(
			<Container className="py-3">
			
			<Form onSubmit={getReport} >
				<Row className="mb-3" >
					<Col xs={12} sm={4} md={4} >
						<InputGroup  className="br-3">
					        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">From</InputGroup.Text>
					        <Form.Control name="start" type="date" defaultValue={getDate()}  className="br-3" />
					    </InputGroup>
					</Col>
					<Col xs={12} sm={4} md={4} >
						<InputGroup  className="br-3">
					        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">To</InputGroup.Text>
					        <Form.Control name="end" type="date" defaultValue={getDate()}  className="br-3" />
					    </InputGroup>
					</Col>
					<Col xs={12} sm={4} md={4} className="d-grid" >
						<Button type="submit" className="btn-t1" >Get Report</Button>
					</Col>
				</Row>
			</Form> 
			<Col xs={12} sm={12} md={12} lg={12} className="service-search mb-3" >
    			<Form onSubmit={(e)=>{e.preventDefault()}} >
    				<InputGroup>
    					<Form.Control onChange={(e)=>{search(e.target.value)}} id="credit_search_box" className="br-2" size="lg" type="text" placeholder="Search here by number or name" />
    					<InputGroup.Text className="br-3 btn-t1 px-4" id="basic-addon2"><i className="bi bi-search"></i></InputGroup.Text>
    				</InputGroup>
    			</Form>
    		</Col>
			
			{
				getCustomerReportLoad?(
					<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
				):(
					customerReport!==undefined?(
						Object.keys(customerReport).length>0?(
							searchResult!==undefined&&Object.keys(searchResult).length>0?(
								Object.keys(searchResult).map((cus,cus_i)=>(
									<Accordion key={`custo_${cus_i}`} className="mb-2" >
								      <Accordion.Item eventKey="0">
								        <Accordion.Header><span className="tp1 f18" >{searchResult[cus].customer_name}, {customerReport[cus].customer_phone}</span></Accordion.Header>
								        <Accordion.Body>
								          <Table bordered className="tbl-bg-blue" >
										    <thead>
										        <tr>
										          <th><span className="tp2" >Date</span></th>
										          <th><span className="tp2" >Time</span></th>
										          <th><span className="tp2" >Invoice</span></th>
										          <th className="text-end tp2" ><span>TC</span></th>
										          <th className="text-end tp2" ><span>SC</span></th>
										          <th className="text-end tp2" ><span>Credit</span></th>
										          <th className="text-end tp2" >Total</th>
										        </tr>
										    </thead>
										    <tbody>
										    {
										    	searchResult[cus].bills!==undefined?(
										    		Object.keys(searchResult[cus].bills).length>0?(
										    			Object.keys(searchResult[cus].bills).map((bill,bill_i)=>(
										    				<tr key={`bill_${bill_i}`}>
													    		<td><span className="tp2">{searchResult[cus].bills[bill].bill_date} <span onClick={()=>{
																	getBill(searchResult[cus].bills[bill].bill_id);
																	setViewBillModal(true);
																}} className="view-bill-link" >View bill</span></span></td>
													    		<td><span className="tp2">{searchResult[cus].bills[bill].bill_time}</span></td>
													    		<td><span className="tp2">{searchResult[cus].bills[bill].bill_id}</span></td>
													    		<td className="tp2 text-end"><span>{searchResult[cus].bills[bill].transaction_total}</span></td>
													    		<td className="tp2 text-end"><span>{searchResult[cus].bills[bill].service_amount_total}</span></td>
													    		<td className="tp2 text-end">												    			
													    			{
													    				searchResult[cus].bills[bill].credit_amount!==null&&searchResult[cus].bills[bill].credit_amount!==""&&customerReport[cus].bills[bill].credit_amount>0?(
													    					searchResult[cus].bills[bill].credit_status?(
													    						<span className="sucess-tag mx-2"><i className="bi bi-check-circle"></i> Paid</span>
													    					):(

													    						<span className="error-message mx-2"><i className="bi bi-x-circle"></i> Pending</span>
													    					)	
													    				):''
													    			}
													    			<span>{searchResult[cus].bills[bill].credit_amount}</span>
													    		</td>
													    		<td className="tp2 text-end"><span>{searchResult[cus].bills[bill].bill_total}</span></td>
													    	</tr>
										    			))
										    		):''
										    	):''
										    }
										    <tr>
										    	<td></td>
										    	<td colSpan={2} className="f16 text-end bgy">Total</td>
										    	<td className="text-end bgy" ><span>{searchResult[cus].trans_bills_total}</span></td>
										    	<td className="text-end bgy" ><span>{searchResult[cus].serv_bills_total}</span></td>
										    	<td className="text-end bgy" ><span>{searchResult[cus].credit_bills_total}</span></td>
										    	<td className="text-end bgy" ><span>{searchResult[cus].bills_total}</span></td>
										    </tr>										    			        
										    </tbody>
										</Table>
								        </Accordion.Body>
								      </Accordion.Item>
								    </Accordion>
								))
							):(
								Object.keys(customerReport).map((cus,cus_i)=>(
									<Accordion key={`custo_${cus_i}`} className="mb-2" >
								      <Accordion.Item eventKey="0">
								        <Accordion.Header><span className="tp1 f18" >{customerReport[cus].customer_name}, {customerReport[cus].customer_phone}</span></Accordion.Header>
								        <Accordion.Body>
								          <Table bordered className="tbl-bg-blue" >
										    <thead>
										        <tr>
										          <th><span className="tp2" >Date</span></th>
										          <th><span className="tp2" >Time</span></th>
										          <th><span className="tp2" >Invoice</span></th>
										          <th className="text-end tp2" ><span>TC</span></th>
										          <th className="text-end tp2" ><span>SC</span></th>
										          <th className="text-end tp2" ><span>Credit</span></th>
										          <th className="text-end tp2" >Total</th>
										        </tr>
										    </thead>
										    <tbody>
										    {
										    	customerReport[cus].bills!==undefined?(
										    		Object.keys(customerReport[cus].bills).length>0?(
										    			Object.keys(customerReport[cus].bills).map((bill,bill_i)=>(
										    				<tr key={`bill_${bill_i}`}>
													    		<td><span className="tp2">{customerReport[cus].bills[bill].bill_date} <span onClick={()=>{
																	getBill(customerReport[cus].bills[bill].bill_id);
																	setViewBillModal(true);
																}} className="view-bill-link" >View bill</span></span></td>
													    		<td><span className="tp2">{customerReport[cus].bills[bill].bill_time}</span></td>
													    		<td><span className="tp2">{customerReport[cus].bills[bill].bill_id}</span></td>
													    		<td className="tp2 text-end"><span>{customerReport[cus].bills[bill].transaction_total}</span></td>
													    		<td className="tp2 text-end"><span>{customerReport[cus].bills[bill].service_amount_total}</span></td>
													    		<td className="tp2 text-end">												    			
													    			{
													    				customerReport[cus].bills[bill].credit_amount!==null&&customerReport[cus].bills[bill].credit_amount!==""&&customerReport[cus].bills[bill].credit_amount>0?(
													    					customerReport[cus].bills[bill].credit_status?(
													    						<span className="sucess-tag mx-2"><i className="bi bi-check-circle"></i> Paid</span>
													    					):(

													    						<span className="error-message mx-2"><i className="bi bi-x-circle"></i> Pending</span>
													    					)	
													    				):''
													    			}
													    			<span>{customerReport[cus].bills[bill].credit_amount}</span>
													    		</td>
													    		<td className="tp2 text-end"><span>{customerReport[cus].bills[bill].bill_total}</span></td>
													    	</tr>
										    			))
										    		):''
										    	):''
										    }
										    <tr>
										    	<td></td>
										    	<td colSpan={2} className="f16 text-end bgy">Total</td>
										    	<td className="text-end bgy" ><span>{customerReport[cus].trans_bills_total}</span></td>
										    	<td className="text-end bgy" ><span>{customerReport[cus].serv_bills_total}</span></td>
										    	<td className="text-end bgy" ><span>{customerReport[cus].credit_bills_total}</span></td>
										    	<td className="text-end bgy" ><span>{customerReport[cus].bills_total}</span></td>
										    </tr>		        
										    </tbody>
										</Table>
								        </Accordion.Body>
								      </Accordion.Item>
								    </Accordion>
								))
							)
						):'Not found!'					
					):''
				)
			}
			</Container>
		)
}