import React, {useContext} from 'react';
import {Container, Row, Col, Table, Spinner, Form, InputGroup, Button, Accordion} from 'react-bootstrap';
import { AppContext } from '../App';


export const IncompleteTokenReport=()=>{
	const { incompleteTokenReport, setIncompleteTokenReport, getDate, postCall, getLoad, setGetLoad } = useContext(AppContext);
	const getReport=(e)=>{
		e.preventDefault();
		let start=e.target.start.value;
		let end = e.target.end.value;
		if (start!==""&&end!=="") {
			setGetLoad(true)
			postCall('api/bnr/get/not/completed/token/report/',{start_date:start, end_date:end}).then(res=>{
				setIncompleteTokenReport(res.data);
				setGetLoad(false);
			}).catch(err=>{
				setGetLoad(false);
			});
		}
	}
	return(
			<Container className="py-3" >
				<Form onSubmit={getReport} >
					<Row className="mb-3" >
						<Col xs={12} sm={4} md={4} >
						<InputGroup  className="br-3">
					        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">From</InputGroup.Text>
					        <Form.Control name="start" type="date" defaultValue={getDate()}  className="br-3" />
					    </InputGroup>
						</Col>
						<Col xs={12} sm={4} md={4} >
							<InputGroup  className="br-3">
						        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">To</InputGroup.Text>
						        <Form.Control name="end" type="date" defaultValue={getDate()}  className="br-3" />
						    </InputGroup>
						</Col>
						<Col xs={12} sm={4} md={4} className="d-grid" >
							<Button type="submit" className="btn-t1" >Get Report</Button>
						</Col>
					</Row>
				</Form> 
				{
					getLoad?(
						<div className="d-grid py-2">
							<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
						</div>
					):(
						incompleteTokenReport!==undefined&&Object.keys(incompleteTokenReport).length>0?(							
								<Table bordered className="tbl-bg-blue" >
								    <thead>
								        <tr className="tp2">
								          <th>Token</th>
								          <th>Created time</th>
								          <th>Customer</th>
								          <th>Staff</th>
								          <th>Services</th>
								        </tr>
								    </thead>
									<tbody>
									    {
									    	Object.keys(incompleteTokenReport).map((tkn,s_i)=>(
									    		<tr key={`incomplete_${s_i}`} className="tp2">
										    		<td>{incompleteTokenReport[tkn].token_no}</td>
										    		<td>{incompleteTokenReport[tkn].created_time}</td>
										    		<td>
										    			<span className="d-grid">{incompleteTokenReport[tkn].customer_name}</span>
										    			<span className="d-grid">{incompleteTokenReport[tkn].customer_phone}</span>
										    			<span className="d-grid">{incompleteTokenReport[tkn].customer_address}</span>
										    		</td>
										    		<td>{incompleteTokenReport[tkn].token_created_by}</td>
										    		<td>
										    			{
										    				incompleteTokenReport[tkn].service!==undefined&&Object.keys(incompleteTokenReport[tkn].service).length>0?(
										    					Object.keys(incompleteTokenReport[tkn].service).map((ser,seri)=>(
										    						<span className="d-grid">{incompleteTokenReport[tkn].service[ser].service}</span>
										    					))
										    				):''
										    			}
										    		</td>
										    	</tr>
											))
									    }		        
								    </tbody>
								</Table>
						):'Report Not found!'
					)
				}		    	
			</Container>
		)
}