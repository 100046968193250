import React, {useContext} from 'react';
import {Container, Row, Col, Table, Spinner, Form, InputGroup, Button} from 'react-bootstrap';
import { AppContext } from '../App';


export const CreditReport=()=>{
	const { getCreditReport, creditReport, creditReportLoad, getDate, getBill, setViewBillModal } = useContext(AppContext);
	const getReport=(e)=>{
		e.preventDefault();
		let start = e.target.start.value;
		let end = e.target.end.value;
		let staff = e.target.staff.value;
		if (start!==""&&end!=="") {
			getCreditReport(start,end,staff);
		}
	}
	return(
			<Container className="py-3" >
				<Form onSubmit={getReport} >
					<Row className="mb-3" >
						<Col xs={12} sm={4} md={3} >
							<InputGroup  className="br-3">
						        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">From</InputGroup.Text>
						        <Form.Control name="start" type="date" defaultValue={getDate()}  className="br-3" />
						    </InputGroup>
						</Col>
						<Col xs={12} sm={4} md={3} >
							<InputGroup  className="br-3">
						        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">To</InputGroup.Text>
						        <Form.Control name="end" type="date" defaultValue={getDate()}  className="br-3" />
						    </InputGroup>
						</Col>						
						<Col xs={12} sm={4} md={3} >
							<Form.Select name="staff" className="shadow-none br-2">
						      <option value="" >All staffs</option>
						      {
						      	creditReport!==undefined?(
						      		creditReport.users!==undefined?(
						      			Object.keys(creditReport.users).length>0?(
						      				Object.keys(creditReport.users).map((staff,staff_i)=>(
						      					<option value={staff.substring(1)} key={`user_${staff_i}`} >{creditReport.users[staff].username}</option>
						      				))
						      			):''
						      		):''
						      	):''
						      }
						    </Form.Select>
						</Col>
						<Col xs={12} sm={4} md={3} className="d-grid" >
							<Button type="submit" className="btn-t1" >Get Report</Button>
						</Col>
					</Row>
				</Form> 
				{
					creditReportLoad?(
						<div className="d-grid py-2">
							<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
						</div>
					):(
						creditReport!==undefined?(
							creditReport.bills!==undefined?(
								
									<Table bordered className="tbl-bg-blue" >
									    <thead>
									        <tr>
									          <th><span className="tp3" >Invoice</span></th>
									          <th><span className="tp3" >Customer</span></th>
									          <th className="text-end tp3" ><span>Payment</span></th>
									          <th className="text-end tp3" ><span>Payment methods</span></th>
									          <th className="text-end tp3" >Credit</th>
									        </tr>
									    </thead>
									    <tbody>
									    {
									    	Object.keys(creditReport.bills).map((cred,cr_i)=>(
									    		<tr key={`cred_${cr_i}`} >
											    	<td>
											    		<span className="tp3 d-grid mb-1" >Invoice : {creditReport.bills[cred].bill_id}</span>
											    		<span onClick={()=>{
																	getBill(creditReport.bills[cred].bill_id);
																	setViewBillModal(true);
																}} className="view-bill-link mx-0" >View bill</span>
														<span className="tp3 f16 d-grid mt-1" >Created by {creditReport.bills[cred].created_by}</span>
											    		<span className="tp3 f14 d-grid mt-1" >{creditReport.bills[cred].bill_date}, {creditReport.bills[cred].bill_time}</span>
											    			
											    	</td>
											    	<td>
											    		<span className="tp3 d-grid mb-1" >{creditReport.bills[cred].customer_name}</span>
											    		<span className="tp3 d-grid mb-1" >{creditReport.bills[cred].customer_phone}</span>
											    	</td>
											    	<td>
											    		<div className="grid-auto-150  bdrb2 mb-1 px-1">
									          		  		<span className="tp3 f16" >Payment status</span>
									          		  		{
									          		  			creditReport.bills[cred].credit_status?(
												    					<span className="sucess-tag"><i className="bi bi-check-circle"></i> Paid</span>
												    				):(
									    								<span className="error-message"><i className="bi bi-x-circle"></i> Pending</span>
									    							)
									    					}
									          		  	</div>
									          		  	<div className="grid-auto-150  bdrb2 mb-1 px-1">
									          		  		<span className="tp3 f16" >Settle by</span>
									          		  		<span className="tp3 f16" >{creditReport.bills[cred].settle_by}</span>
									          		  	</div>
									          		  	<div className="grid-auto-150  bdrb2 mb-1 px-1">
									          		  		<span className="tp3 f16" >Settle date</span>
									          		  		<span className="tp3 f16" >{creditReport.bills[cred].settle_date}</span>
									          		  	</div>
									          		  	<div className="grid-auto-150  bdrb2 mb-1 px-1">
									          		  		<span className="tp3 f16" >Settle time</span>
									          		  		<span className="tp3 f16" >{creditReport.bills[cred].settle_time}</span>
									          		  	</div>
											    	</td>
											    	<td>
											    		<div className="d-grid">
											          	{
											          		creditReport.bills[cred].debits_payments!==undefined?(
											          			Object.keys(creditReport.bills[cred].debits_payments).length>0?(
											          				Object.keys(creditReport.bills[cred].debits_payments).map((b_pay,b_pay_i)=>(
											          					<div className="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${b_pay_i}`} >
														          		  	<span className="tp3 f16" >{creditReport.bills[cred].debits_payments[b_pay].name}</span>
														          			<span className="tp3 f16 grid-justify-end" >{creditReport.bills[cred].debits_payments[b_pay].amount}</span>
														          		</div>
											          				))
											          			):''
											          		):''
											          	}
											          	</div>
											    	</td>
											    	<td className="text-end tp3" ><span>{creditReport.bills[cred].credit_amount}</span></td>
											    </tr>
									    	))
									    }
									    <tr>
									    	<td colSpan={3} ></td>
									    	<td className="tp2 text-end bgy f18" ><span>Total</span></td>
									    	<td className="tp2 text-end bgy f18" ><span>{creditReport.credit_total}</span></td>
									    </tr>									    										    			        
									    </tbody>
									</Table>
								
							):'Nothing found!'
						):''
					)
				}
				
			</Container>
		)
}