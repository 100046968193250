import React, {useContext} from 'react';
import {Container, Row, Col, Table, Spinner, Form, InputGroup, Button} from 'react-bootstrap';
import { AppContext } from '../App';


export const ExpenseReport=()=>{
	const { postCall,setGetLoad, getLoad, load, setLoad, getDate, expenseReport, setExpenseReport, expenseParti } = useContext(AppContext);
	const getReport=(e)=>{
		e.preventDefault();
		let start=e.target.start.value;
		let end = e.target.end.value;
		let staff = e.target.staff.value;
		let partic = e.target.partic.value;
		if (start!==""&&end!=="") {
			setLoad(true)
			postCall('api/bnr/get/expense/report/',{start_date:start, end_date:end,staff_id:staff,p_id:partic}).then(res=>{
				setExpenseReport(res.data);
				setLoad(false);
			}).catch(err=>{
				setLoad(false);
			});
		}
	}
	return(
			<Container className="py-3" >
				<Form onSubmit={getReport} >
					<Row className="mb-3" >
						<Col xs={12} sm={4} md={2} >
							<InputGroup  className="br-3">
						        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">From</InputGroup.Text>
						        <Form.Control name="start" type="date" defaultValue={getDate()}  className="br-3" />
						    </InputGroup>
						</Col>
						<Col xs={12} sm={4} md={2} >
							<InputGroup  className="br-3">
						        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">To</InputGroup.Text>
						        <Form.Control name="end" type="date" defaultValue={getDate()}  className="br-3" />
						    </InputGroup>
						</Col>						
						<Col xs={12} sm={4} md={3} >
							<Form.Select name="staff" className="shadow-none br-2">
						      <option value="" >All staffs</option>
						      {
						      	expenseReport!==undefined?(
						      		expenseReport.users!==undefined?(
						      			Object.keys(expenseReport.users).length>0?(
						      				Object.keys(expenseReport.users).map((staff,staff_i)=>(
						      					<option value={staff.substring(1)} key={`user_${staff_i}`} >{expenseReport.users[staff].username}</option>
						      				))
						      			):''
						      		):''
						      	):''
						      }
						    </Form.Select>
						</Col>
						<Col xs={12} sm={4} md={3} >
							<Form.Select name="partic" className="shadow-none br-2">
						    	<option value="" >All Particulars</option>
						    	{
		        					expenseParti!==undefined&&Object.keys(expenseParti).length>0?(
		        						Object.keys(expenseParti).map((parti,pi)=>(
		        							<option value={parti.substring(1)} key={`part_${pi}`} >{expenseParti[parti].name}</option>
		        						))
		        					):''
		        				}
						    </Form.Select>
						</Col>
						<Col xs={12} sm={4} md={2} className="d-grid" >
							<Button type="submit" className="btn-t1" >Get Report</Button>
						</Col>
					</Row>
				</Form> 
				{
					load?(
						<div className="d-grid py-2">
							<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
						</div>
					):(
						<Table bordered className="tbl-bg-blue" >
						    <thead>
						        <tr>
						          <th className="tp2" >Particulars</th>
						          <th className="tp2" >Remarks</th>
						          <th className="tp2" >Staff</th>
						          <th className="tp2" >Payment method</th>
						          <th className="text-end tp2" >Amount</th>
						        </tr>
						    </thead>
						    <tbody>
						    {
						    	expenseReport!==undefined&&Object.keys(expenseReport.expense_values).length>0?(
							    	Object.keys(expenseReport.expense_values).map((exp,exi)=>(
										<tr key={`exp_${exi}`} className="tc2" > 
			        						<td>
			        							<span className="d-grid" >{expenseReport.expense_values[exp].particulars}</span>
			        						</td>
			        						<td>{expenseReport.expense_values[exp].remarks}</td>
			        						<td>{expenseReport.expense_values[exp].created_by}</td>
			        						<td>{expenseReport.expense_values[exp].payment_type}</td>
			        						<td className="text-end">{expenseReport.expense_values[exp].amount}</td>
			        					</tr>
									))
								):''
							}
							<tr>
								<td colSpan={3} className="tp2 text-end f20 f-400">Total</td>
								<td>
									{
										expenseReport!==undefined&&expenseReport.main.expense_total!==undefined&&Object.keys(expenseReport.main.expense_total).length>0?(
											Object.keys(expenseReport.main.expense_total).map((ex,exi)=>(
												<div class="grid-auto-100 bdrb2 mb-1 px-1" key={`bpay_${exi}`} >
								          		  	<span className="tp2 f16" >{ex}</span>
								          			<span className="tp2 f16 grid-justify-end" >{expenseReport.main.expense_total[ex]}</span>
								          		</div>
											))
										):''
									}
								</td>
								<td className="text-end f20 f-400">{expenseReport!==undefined&&expenseReport.main!==undefined?expenseReport.main.total:''}</td>
							</tr>
							</tbody>
						</Table>
					)
				}				
			</Container>
		)
}