import React, {useContext} from 'react';
import {Container, Row, Col, Table, Spinner, Form, InputGroup, Button} from 'react-bootstrap';
import { AppContext } from '../App';


export const DetailReport=()=>{
	const { getDetailReport, detailReport, detailReportLoad, getDate, getBill, setViewBillModal } = useContext(AppContext);
	const getReport=(e)=>{
		e.preventDefault();
		let start=e.target.start.value;
		let end = e.target.end.value;
		let staff = e.target.staff.value;
		let service = e.target.service.value;
		if (start!==""&&end!=="") {
			getDetailReport(start,end,staff,service);
		}
	}
	return(
			<Container className="py-3" >
				<Form onSubmit={getReport} >
					<Row className="mb-3" >
						<Col xs={12} sm={4} md={2} >
							<InputGroup  className="br-3">
						        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">From</InputGroup.Text>
						        <Form.Control name="start" type="date" defaultValue={getDate()}  className="br-3" />
						    </InputGroup>
						</Col>
						<Col xs={12} sm={4} md={2} >
							<InputGroup  className="br-3">
						        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">To</InputGroup.Text>
						        <Form.Control name="end" type="date" defaultValue={getDate()}  className="br-3" />
						    </InputGroup>
						</Col>						
						<Col xs={12} sm={4} md={3} >
							<Form.Select name="staff" className="shadow-none br-2">
						      <option value="" >All staffs</option>
						      {
						      	detailReport!==undefined?(
						      		detailReport.users!==undefined?(
						      			Object.keys(detailReport.users).length>0?(
						      				Object.keys(detailReport.users).map((staff,staff_i)=>(
						      					<option value={staff.substring(1)} key={`user_${staff_i}`} >{detailReport.users[staff].username}</option>
						      				))
						      			):''
						      		):''
						      	):''
						      }
						    </Form.Select>
						</Col>
						<Col xs={12} sm={4} md={3} >
							<Form.Select name="service" className="shadow-none br-2">
						      <option value="" >All services</option>
						      {
						      	detailReport!==undefined?(
						      		detailReport.services!==undefined?(
						      			Object.keys(detailReport.services).length>0?(
						      				Object.keys(detailReport.services).map((staff,staff_i)=>(
						      					<option value={staff.substring(1)} key={`serv_${staff_i}`} >{detailReport.services[staff].name}</option>
						      				))
						      			):''
						      		):''
						      	):''
						      }
						    </Form.Select>
						</Col>
						<Col xs={12} sm={4} md={2} className="d-grid" >
							<Button type="submit" className="btn-t1" >Get Report</Button>
						</Col>
					</Row>
				</Form> 
				{
					detailReportLoad?(
						<div className="d-grid py-2">
							<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
						</div>
					):(
						<Table bordered className="tbl-bg-blue" >
						    <thead>
						        <tr>
						          <th><span className="tp2" >Invoice</span></th>
						          <th className="tp2" ><span>Service</span></th>						          
						          <th className="tp2" ><span>Times</span></th>
						          <th className="tp2" >Staff</th>
						          <th className="tp2" ><span>Customer</span></th>
						          <th className="text-end tp2" ><span>TC</span></th>
						          <th className="text-end tp2" ><span>SC</span></th>
						          <th className="text-end tp2" >Total</th>
						        </tr>
						    </thead>
						    <tbody>
						    {
						    	detailReport!==undefined?(
						    		detailReport.bill_values!==undefined?(
						    			Object.keys(detailReport.bill_values).length>0?(
						    				Object.keys(detailReport.bill_values).map((b_val,b_i)=>(
						    					<tr key={`b_val_${b_i}`} >
						    						<td>
						    							<span className="tp3 d-grid mb-1" >Invoice : {detailReport.bill_values[b_val].bill_no}</span>
											    		<span onClick={()=>{
																	getBill(detailReport.bill_values[b_val].bill_no);
																	setViewBillModal(true);
																}} className="view-bill-link mx-0" >View bill</span>
											    		<span className="tp3 f14 d-grid mt-1" >{detailReport.bill_values[b_val].close_date}, {detailReport.bill_values[b_val].close_time}</span>
						    						</td>
						    						<td>
						    							{
						    								detailReport.bill_values[b_val].bill_services!==undefined?(
						    									Object.keys(detailReport.bill_values[b_val].bill_services).length>0?(
						    										Object.keys(detailReport.bill_values[b_val].bill_services).map((bserv,bs_i)=>(
						    											<span className="tp3 d-grid mb-1" key={`bserv_${bs_i}`}  >{detailReport.bill_values[b_val].bill_services[bserv].service_name}</span>
						    										))
						    									):''
						    								):''
						    							}
						    						</td>
						    						<td>
						    							<div class="grid-auto-100 bdrb2 mb-1 px-1" >
										          		  	<span className="tp3 f16" >{`Created - ${detailReport.bill_values[b_val].token_created_by}`}</span>
										          			<span className="tp3 f16 grid-justify-end" >{detailReport.bill_values[b_val].created_time}</span>
										          		</div>
										          		<div class="grid-auto-100 bdrb2 mb-1 px-1" >
										          		  	<span className="tp3 f16" >{`Processed - ${detailReport.bill_values[b_val].token_processed_by}`}</span>
										          			<span className="tp3 f16 grid-justify-end" >{detailReport.bill_values[b_val].open_time}</span>
										          		</div>
										          		<div class="grid-auto-100 bdrb2 mb-1 px-1" >
										          		  	<span className="tp3 f16" >{`Closed - ${detailReport.bill_values[b_val].token_created_by}`}</span>
										          			<span className="tp3 f16 grid-justify-end" >{detailReport.bill_values[b_val].close_time}</span>
										          		</div>
						    						</td>
						    						<td>
						    							<span className="tp3 d-grid mb-1" >{detailReport.bill_values[b_val].token_processed_by}</span>
						    						</td>
						    						<td>
						    							<span className="tp3 d-grid mb-1" >{detailReport.bill_values[b_val].customer_name}</span>
						    							<span className="tp3 d-grid mb-1" >{detailReport.bill_values[b_val].customer_phone}</span>						    							
						    						</td>
						    						<td className="tp3 text-end" >
						    							<span>{detailReport.bill_values[b_val].transaction_bill_total}</span>
						    						</td>
						    						<td className="tp3 text-end" >
						    							<span>{detailReport.bill_values[b_val].service_bill_total}</span>
						    						</td>
						    						<td className="tp3 text-end" >
						    							<span>{detailReport.bill_values[b_val].bill_total}</span>
						    						</td>
						    					</tr>
						    				))
						    			):''
						    		):''
						    	):''
						    }
						    {
						    	detailReport!==undefined?(
						    		detailReport.main!==undefined?(
						    			<tr>
								    		<td colSpan={3} ></td>
											<td colSpan={2} className="text-end f18 tp2 bgy" ><span>Total</span></td>
											<td className="text-end tp2 bgy">{detailReport.main.transaction_day_total}</td>
											<td className="text-end tp2 bgy">{detailReport.main.service_day_total}</td>
											<td className="text-end tp2 bgy">{detailReport.main.grand_day_total}</td>
										</tr>
						    		):''
						    	):''						    	
							}
						    </tbody>
						</Table>
					)
				}				
			</Container>
		)
}