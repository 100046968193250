import React, {useState, useContext} from 'react';
import {Container, Row, Col, Button, Form, Spinner, Alert } from 'react-bootstrap';
import axios from "axios";
import { AppContext } from '../App';


export const Authentication=()=>{
	const { authForm, setAuthForm, setAccessToken, link, setUser } = useContext(AppContext);

	const [ loginError, setLoginError ] = useState(false);
	const [ load, setLoad ] = useState(false);
	const getUser=(token)=>{
		axios.get(`${link}api/pnp/get/user/`,{
			headers:{
              Authorization: 'Bearer '+token
            }
		}).then(response=>{
			setUser(response.data);
		}).catch(err=>{
			setAuthForm(true);
			if(err.response.status === 401){
		        setAuthForm(true);
		    }else if(err.response.status === 404){
		        console.log('Get_user - ',err.response.status,':',err.response.statusText);
		    }else if(err.response.status === 500){
		        console.log('Get_user - ',err.response.status,':',err.response.statusText);
		    }else{
		        console.log('Get_user - ',err.response.status,':',err.response.data);
		    }
		});
  	}
	const requestUser=(event)=>{
		setLoad(true);
		event.preventDefault();
		if (event.target.username.value!==""&&event.target.password.value!=="") {
		axios.post(`${link}api/token/`,{
	   		username : event.target.username.value,
	   		password : event.target.password.value,
	   	}).then(function(response){
	   		setLoad(false);
	   		if(response.status === 200){
	   			setAccessToken(response.data.access);
	   			getUser(response.data.access);
	   			setAuthForm(false);
	   		}else{
	   			setLoginError(true);
	   			setTimeout(()=>{setLoginError(false)},5000);
	   		}
	   	}).catch(function (err){
	   		setLoad(false);
	   		setLoginError(true);
	   		setTimeout(()=>{setLoginError(false)},5000);
	   	});
		}
	}


	return (
		<Container className="wrapper" >
			<Row className="login-window br-4" >
				<Col xs={12} md={7} lg={8} className="p-5 grid-center" >
					<h3 className="pb-3 tp3" >Welcome to Akshaya Management System</h3>
					<h6 className="tp3" >Version 2.0</h6>
					<p className="ts2" >Akshaya Management System is a complete package especially targeting Akshaya centres across Kerala. It provides content management, token management and billing with ease of control to managers to monitor workflow and gets detailed reports.</p>
				</Col>
				<Col xs={12} md={5} lg={4} className="p-5 bgp1 grid-center" >
					{
						loginError!==undefined&&loginError?(
							<Alert variant="warning" className="t-red br-3" >Invalid credentials!</Alert>
						):''
					}
					{
						authForm!==undefined&&authForm?(
							<Form onSubmit={requestUser} className="login-form" >
						      <Form.Group className="mb-3 text-white">
						        <Form.Control type="text" name="username" required className="shadow-none" placeholder="Username" />
						      </Form.Group>
						      <Form.Group className="mb-3  text-white">
						        <Form.Control type="password" name="password" required className="shadow-none"  placeholder="Password" />
						      </Form.Group>
						      <div className="d-grid gap-2">
						      {
						      	load?(
							      	<Button variant="outline-primary" disabled>
							      	 <span className="spinner-white text-white" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
							      ):(
							      	<Button type="submit" className="login-btn" >LOGIN</Button>
							      )
							  }
							  </div>
					    	</Form>
					    	):(
					    	<span><Spinner animation="border" size="sm" /> Fetching information..</span>
					    	)
					}
				</Col>
			</Row>
		</Container>
		);
}