import React, {useContext, useEffect, useState} from 'react';
import {Container, Col, Button, Form, Modal, Spinner} from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import { AppContext } from '../App';

export const PaymentMethods=()=>{
	const { accessToken, user, setAuthForm, link, payMethods, setPayMethods, getPayMethodLoad  } = useContext(AppContext);

	const [ newPayModel, setNewPayModel ] = useState(false);
	useEffect(()=>{
		if (!newPayModel) {
			setSelectedPay();
		}
	},[newPayModel]);
	const [ editPay, setEditPay ] = useState(false)
	
	const [ addPaymentLoad, setAddpaymentLoad ] = useState(false);
	const [ nameReqErr, setNameReqErr ] = useState(false);
	const addPaymentMethod=(e)=>{
		e.preventDefault();
		let pay=e.target.name.value;
		if(pay === ""){
			setNameReqErr(true);
			setTimeout(()=>{setNameReqErr(false)},5000);
		}
		if (pay!==""&&selectedPay===undefined) {
			setAddpaymentLoad(true);
			axios.post(`${link}api/pm/add/payment/method/`,{
					name:pay,
		        },{
		          headers:{
		            Authorization: 'Bearer '+accessToken
		          }
		        }).then((response)=>{
		        	setAddpaymentLoad(false);
		        	setPayMethods(response.data);
		        	setNewPayModel(false);
		        }).catch((err)=>{
		        	setAddpaymentLoad(false);
			        if(err.response.status === 401){
			            setAuthForm(true);
			        }else if(err.response.status === 404){
			            console.log('Add_Payment_method - ',err.response.status,':',err.response.statusText);
			        }else if(err.response.status === 500){
			            console.log('Add_Payment_method - ',err.response.status,':',err.response.statusText);
			        }else{
			            console.log('Add_Payment_method - ',err.response.status,':',err.response.data);
			        }
		    });

		}else if (pay!==""&&selectedPay!==undefined){
			setAddpaymentLoad(true);
			axios.post(`${link}api/pm/edit/payment/method/`,{
					name:pay,
					payment_id:selectedPay.id.substring(1),
		        },{
		          headers:{
		            Authorization: 'Bearer '+accessToken
		          }
		        }).then((response)=>{
		        	setAddpaymentLoad(false);
		        	setPayMethods(response.data);
		        	setNewPayModel(false);
		        	setSelectedPay();
		        }).catch((err)=>{
		        	setAddpaymentLoad(false);
			        if(err.response.status === 401){
			            setAuthForm(true);
			        }else if(err.response.status === 404){
			            console.log('Edit_Payment_method - ',err.response.status,':',err.response.statusText);
			        }else if(err.response.status === 500){
			            console.log('Edit_Payment_method - ',err.response.status,':',err.response.statusText);
			        }else{
			            console.log('Edit_Payment_method - ',err.response.status,':',err.response.data);
			        }
		    });
		}
	}
	const [ selectedPay, setSelectedPay ] = useState();
	const selectedPayM=(payId)=>{
		setSelectedPay({id:payId,name:payMethods[payId].name});
		$('#pay_m_name').val(payMethods[payId].name);
		setNewPayModel(true);
	}
	const [ changeStatLoad, setChangeStatLoad ] = useState(false);
	const [changeStatPayId, setChangeStatPayId ] = useState();
	const changePayMStatus=(pyid,paystst)=>{
		setChangeStatLoad(true);
		setChangeStatPayId(pyid);
		axios.post(`${link}api/pm/edit/payment/method/status/`,{
				status:paystst,
				payment_id:pyid.substring(1),
	        },{
	          headers:{
	            Authorization: 'Bearer '+accessToken
	          }
	        }).then((response)=>{
	        	setChangeStatLoad(false);
	        	setPayMethods(response.data);
	        	setChangeStatPayId();
	        }).catch((err)=>{
	        	setChangeStatLoad(false);
	        	setChangeStatPayId();
		        if(err.response.status === 401){
		            setAuthForm(true);
		        }else if(err.response.status === 404){
		            console.log('Edit_Payment_method - ',err.response.status,':',err.response.statusText);
		        }else if(err.response.status === 500){
		            console.log('Edit_Payment_method - ',err.response.status,':',err.response.statusText);
		        }else{
		            console.log('Edit_Payment_method - ',err.response.status,':',err.response.data);
		        }
	    });
	}
	return (
		<Container className="my-3" >

    		<Col  xs={12} sm={12} md={12} lg={12} className='bdr2 br-3' >
				<Col xs="12" sm="12" md="12" lg="12" className="staff-type-header bgs1 d-grid p-2 bdrb2" >
					<span>{getPayMethodLoad?(<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>):'Payment Methods'}</span>
					<Button onClick={()=>{
						setNewPayModel(true);
						setEditPay(false);
					}} className="btn-t1"  ><i className="bi bi-plus-circle"></i> Add new</Button>
				</Col>
				{
					payMethods!==undefined?(
						Object.keys(payMethods).length>0?(
							Object.keys(payMethods).map((payM, pay_i)=>(


							<Col xs="12" sm="12" md="12" lg="12" className="service-item d-grid p-2 bdrb2" >
								<span className="tp2 f16">{payMethods[payM].name}</span>
								<Button onClick={()=>{
									selectedPayM(payM);
									setEditPay(true);
								}} className="btn-t1-r" ><i className="bi bi-pencil-square"></i> Edit</Button>
								{
									changeStatPayId!==undefined&&changeStatLoad&&changeStatPayId===payM?(
		        						<Button className="please-wait-spinner" disabled><Spinner animation="border" size="sm" /></Button>
									):(
										payMethods[payM].status?(
											<Button onClick={()=>{changePayMStatus(payM,false)}} className="btn-disable" ><i className="bi bi-eye-slash"></i> Disable</Button>
										):(
											<Button onClick={()=>{changePayMStatus(payM,true)}}  className="btn-enable" ><i className="bi bi-eye-slash"></i> Enable</Button>
										)
									)
								}
							</Col>

							))
						):'Nothing created yet'
					):''
				}
				
			</Col>
			<Modal size="xl" show={newPayModel} backdrop="static" onHide={()=>{setNewPayModel(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>{editPay?'Update':'Add New'} Payment Method</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={addPaymentMethod} >
		        		<Form.Group className="mb-3">
		        			<Form.Label>Name {nameReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
		        			<Form.Control name='name' autoFocus="on" defaultValue={selectedPay!==undefined?selectedPay.name:''} className="shadow-none br-2" />
		        		</Form.Group>
		        		<div className='d-grid'>
		        		{
		        			addPaymentLoad?(
		        				<Button className="please-wait-spinner" disabled><Spinner animation="border" size="sm" /> Please wait...</Button>
		        			):(
		        				<Button type="submit" className="btn-t1">SAVE</Button>
		        			)
		        		}
		        		</div>
		        	</Form>
		        </Modal.Body>
		        <Modal.Footer></Modal.Footer>
		    </Modal>
		</Container>
		)
}