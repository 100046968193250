import React, {useState, useContext} from 'react';
import {Container, Row, Col, Spinner, Button, Accordion, Table, Form, Alert, Carousel, InputGroup} from 'react-bootstrap';
import axios from "axios";
import $ from 'jquery';
import { AppContext } from '../App';


export const HomePage=()=>{
	const { home, homeLoad, user, link, accessToken, setAuthForm, } = useContext(AppContext);
	const [ contactLoad, setContactLoad ] = useState(false);
	const [ nameReqErr, setNameReqErr ] = useState(false);
	const [ phoneReqErr, setPhoneReqErr ] = useState(false);
	const [ respoState, setRespoState ] = useState();
	const  contactUs=(e)=>{
		e.preventDefault();
		let name = e.target.name.value;
		let phone = e.target.phone.value;
		let query = e.target.query.value;
		if (name==="") {
			setNameReqErr(true);
			setTimeout(()=>{setNameReqErr(false)},5000);
		}
		if (phone==="") {
			setPhoneReqErr(true);
			setTimeout(()=>{setPhoneReqErr(true)},5000);
		}
		if (name!==""&&phone!=="") {
			setContactLoad(true);
		    axios.post(`${link}api/hp/contact/us/`,{
		    	name:name,
		    	phone:phone,
		    	query:query,
		    },{
		      headers:{
		              Authorization: 'Bearer '+accessToken
		            }
		    }).then(response=>{
		      setContactLoad(false);
		      setRespoState(response.data);
		      setTimeout(()=>{setRespoState()},15000);
		    }).catch(err=>{
		      setContactLoad(false);
		      setRespoState(false);
		      setTimeout(()=>{setRespoState()},15000);
		      if (err!==undefined&&err.response!==undefined) {
		        if(err.response.status === 401){
		            setAuthForm(true);
		        }else if(err.response.status === 404){
		            console.log('Get_home_page - ',err.response.status,':',err.response.statusText);
		        }else if(err.response.status === 500){
		            console.log('Get_home_page - ',err.response.status,':',err.response.statusText);
		        }else{
		            console.log('Get_home_page - ',err.response.status,':',err.response.data);
		        }
		      }else{
		        $.dialog({
		          title: "Can't reach server!",
		          content: 'Please check internet connection!',
		        });
		      }
		    });
		}
	}
	const [ searchResult, setSearchResult ] = useState();
	const search=(key)=>{
		if (key!=="") {
			if(home!==undefined&&home.quick_links!==undefined){
			    if(Object.keys(home.quick_links).length>0){
			    	setSearchResult(prev=>{});
			    	let count=0;
			    	Object.keys(home.quick_links).map(name=>{
			    		if (count<50) {
				    		if (home.quick_links[name].name.toLowerCase().includes(key.toLowerCase())||home.quick_links[name].website.includes(key)) {
				    			setSearchResult(prev=>({...prev,[name]:home.quick_links[name]}));
				    			count+=1;
				    		}
				    	}
			    	});
			    }
			}
		}else{
			setSearchResult(prev=>{});
		}
	}
	return(
			<Container className="py-2">
			{
				home!==undefined&&home.ads!==undefined?(
					Object.keys(home.ads).length>0?(
						<Carousel data-bs-theme="dark">
							{
								Object.keys(home.ads).map((item,index)=>(
									<Carousel.Item key={`slide_${index}`} >
								        <img className="d-block w-100" src={home.ads[item].url} />
								    </Carousel.Item>
								))
							}
						</Carousel>
					):''
				):''
			}
			{
				homeLoad?(
						<div className="d-grid py-2">
							<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
						</div>
					):(
						user!==undefined?(
		            		user.type==='admin'?(
								home!==undefined?(
									home.main!==undefined?(
										<Row className="pt-2" >
											<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3" >
												<div className="collection-status p-2 p-3 br-3">
													<span className="d-grid f18 tp2 mb-2" >Todays TC</span>
													<span className="d-grid f16 ts2" >{home.main.transaction_day_total}</span>
												</div>
											</Col>
											<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3" >
												<div className="collection-status p-2 p-3 br-3">
													<span className="d-grid f18 tp2 mb-2" >Todays SC</span>
													<span className="d-grid f16 ts2" >{home.main.service_day_total}</span>
												</div>
											</Col>
											<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3" >
												<div className="collection-status p-2 p-3 br-3">
													<span className="d-grid f18 tp2 mb-2" >Todays Credit</span>
													<span className="d-grid f16 ts2" >{home.main.credit_day_total}</span>
												</div>
											</Col>
											<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3" >
												<div className="collection-status p-2 p-3 br-3">
													<span className="d-grid f18 tp2 mb-2" >Todays Debit</span>
													<span className="d-grid f16 ts2" >{home.main.debit_amount_total}</span>
												</div>
											</Col>
											<Col xs={12} sm={6} md={4} lg={3} xl={2} className="pb-3" >
												<div className="collection-status p-2 p-3 br-3">
													<span className="d-grid f18 tp2 mb-2" >Total</span>
													<span className="d-grid f16 ts2" >{home.main.grand_day_total}</span>
												</div>
											</Col>
										</Row>
									):''
								):''
							):''
						):''
					)
			}
			{
				home!==undefined?(
					home.quick_links!==undefined?(
						<Accordion className="mb-2" >
					      <Accordion.Item eventKey="0">
					        <Accordion.Header><span className="tp1 f18" >Quick links</span></Accordion.Header>
					        <Accordion.Body className="quick-link-wrap" >
					        <Col xs={12} sm={12} md={12} lg={12} className="service-search mb-3" >
					        <Form onSubmit={(e)=>{e.preventDefault()}}>
			    				<InputGroup>
			    					<Form.Control id="credit_search_box"  onChange={(e)=>{search(e.target.value)}} className="br-2" size="lg" type="text" placeholder="Search here" />
			    					<InputGroup.Text className="br-3 btn-t1 px-4" id="basic-addon2"><i className="bi bi-search"></i></InputGroup.Text>
			    				</InputGroup>
			    			</Form>
			    			</Col>
					        {
					        	Object.keys(home.quick_links).length>0?(
					        		<Table bordered className="tbl-bg-blue" >
								      <thead>
								        <tr>
								          <th><span className="tp2" >Service</span></th>
								          <th><span className="tp2" >Website</span></th>
								          <th><span className="tp2" >Link</span></th>
								        </tr>
								      </thead>
								      <tbody>
								      {
								      	searchResult!==undefined&&Object.keys(searchResult).length>0?(
								      		Object.keys(searchResult).map((qlink,qli_i)=>(
							        			<tr key={`q_link_${qli_i}`} >
							        				<td ><span className="ts1 f16"><b>{searchResult[qlink].name}</b></span></td>
							        				<td ><span className="ts2 f16">{searchResult[qlink].website}</span></td>
							        				<td className="quick-link d-grid">
							        					<a href={searchResult[qlink].link} target="_blank" className="d-grid" >
								        					<Button className="d-grid btn-t1 link-btn f14" >
								        						<span>Open <i className="bi bi-box-arrow-up-right"></i></span>
								        					</Button>
							        					</a>
							        				</td>					        				
							        			</tr>
						        			))
								      	):(
									      	Object.keys(home.quick_links).map((qlink,qli_i)=>(
							        			<tr key={`q_link_${qli_i}`} >
							        				<td ><span className="ts1 f16"><b>{home.quick_links[qlink].name}</b></span></td>
							        				<td ><span className="ts2 f16">{home.quick_links[qlink].website}</span></td>
							        				<td className="quick-link d-grid">
							        					<a href={home.quick_links[qlink].link} target="_blank" className="d-grid" >
								        					<Button className="d-grid btn-t1 link-btn f14" >
								        						<span>Open <i className="bi bi-box-arrow-up-right"></i></span>
								        					</Button>
							        					</a>
							        				</td>					        				
							        			</tr>
						        			))
						        		)
								      }
								      </tbody>
								    </Table>
					        		
					        	):''
					        }
					        </Accordion.Body>
					      </Accordion.Item>
					    </Accordion>
					):''
				):''
			}
			{
				user!==undefined?(
		            user.type==='admin'?(
						home!==undefined?(
							home.upcoming_updates!==undefined?(
								<Accordion className="mb-2" >
							      <Accordion.Item eventKey="0">
							        <Accordion.Header><span className="tp1 f18" >Updates</span></Accordion.Header>
							        <Accordion.Body>
							        {
							        	Object.keys(home.upcoming_updates).length>0?(
							        		Object.keys(home.upcoming_updates).map((update,up_i)=>(
							        			<div className="d-grid bdr1 mb-2 br-2" key={`update_${up_i}`} >
							        				<span className="bgs1 tp2 bdrb1 p-2" >{home.upcoming_updates[update].version}</span>
							        				<p className="ts2 p-multi-line p-2" >{home.upcoming_updates[update].updates}</p>
							        			</div>
							        		))
							        	):''
							        }
							        </Accordion.Body>
							      </Accordion.Item>
							    </Accordion>
							):''
						):''
					):''
				):''
			}
			{
				user!==undefined?(
		            user.type==='admin'?(
						home!==undefined?(
							home.validity!==undefined?(
								<Accordion className="mb-2" >
							      <Accordion.Item eventKey="0">
							        <Accordion.Header>
							        	<span className="tp1 f18" >Expiry Details</span>
							        	{
							        		home.validity.days_remaining<=0?(
							        			<span className="error-message mx-2">Expired</span>
							        		):home.validity.days_remaining<30?(
							        			<span className="error-message mx-2">Expire in {home.validity.days_remaining} days.</span>
							        		):''
							        	}
							        </Accordion.Header>
							        <Accordion.Body>
							       		<span className="tp2 d-grid mb-2" >Expiry date : {home.validity.expiry_date}</span>
					        			<span className="ts2 d-grid mb-2" >Remaining days : {home.validity.days_remaining}</span>
					        			<Button className="btn-t1">Renew Now</Button>
							        </Accordion.Body>
							      </Accordion.Item>
							    </Accordion>
							):''
						):''
					):''
				):''
			}
			<Accordion className="mb-2" >
		      <Accordion.Item eventKey="0">
		        <Accordion.Header><span className="tp1 f18" >Enquiry Form</span></Accordion.Header>
		        <Accordion.Body>		        	
		        	{
		        		respoState!==undefined?(
		        			respoState?(
		        				<Alert  variant="info" className="bg-ss">
		        					<span className="d-grid f20 mb-1 sucess-text">We appreciate you contacting us.</span>
		        					<span className="d-grid f16 sucess-text">We will get back to you shortly.</span>		        					
		        				</Alert>
		        			):(
		        				<Alert  variant="danger" >
		        					<span className="error-alert f20">Something went wrong! please try again.</span>
		        				</Alert>

		        			)
		        		):''
		        	}
		        	<Form onSubmit={contactUs}>
		        		<Row>
		        			<Col xs={12} sm={12} md={6} lg={6} >
		        				<Form.Group className="mb-3" >
		        					<Form.Label>Name {nameReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
		        					<Form.Control type="text" name="name" className="shadow-none br-2" placeholder="Contact name" />
		        				</Form.Group>
		        			</Col>
		        			<Col xs={12} sm={12} md={6} lg={6} >
		        				<Form.Group className="mb-3">
		        					<Form.Label>Contact number {phoneReqErr?(<span className="error-message" >This field is required</span>):''}</Form.Label>
		        					<Form.Control type="text" name="phone" className="shadow-none br-2" placeholder="Contact number" />
		        				</Form.Group>
		        			</Col>
		        		</Row>
		        		<Form.Group className="mb-3">
		        			<Form.Label>Query</Form.Label>
		        			<Form.Control  as="textarea" name="query" className="shadow-none br-2" placeholder="Your query" />
		        		</Form.Group>
		        		<div className="d-grid">
		        		{
		        			contactLoad?(
		        				<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Sending...</span></Button>
		        			):(
		        				<Button type="submit" className="btn-t1">SUBMIT</Button>
		        			)
		        		}
		        		</div>
		        	</Form>
		        </Accordion.Body>
		      </Accordion.Item>
		    </Accordion>
			</Container>
		)
}
