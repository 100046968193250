import React, {useState, useEffect, useContext} from 'react';
import {Container, Row, Col, Button, Form, Modal, Spinner} from 'react-bootstrap';
import axios from "axios";
import { AppContext } from '../App';


export const Staff=()=>{
	const { accessToken, user, setAuthForm, link, staffs, setStaffs, getStaffsLoad, staffTypes } = useContext(AppContext);
	const [ staffAddModel, setStaffAddModel ] = useState(false);
	useEffect(()=>{
		if (!staffAddModel) {
			setInvalidEmail();
			setInvalidUsername();
		}
	},[staffAddModel]);
	const [ fNameError, setFnameError ] = useState(false);
	const [ staffTypeError, setStaffTypeError ] = useState(false);
	const [ usernameError, setUsernameError ] = useState(false);
	const [ passError, setPassError ] = useState(false);
	const [ cpassError, setCpassError] = useState(false);
	const [ passEqualError, setPassEqualError ] = useState(false);
	const [ emailError, setEmailError ] = useState(false);
	const [ staffAddLoad, setStaffAddLoad ] = useState(false);
	const [ invalidUsername, setInvalidUsername ] = useState();
	const [ checkUsernameLoad, setCheckUsernameLoad ] = useState(false);
	const checkUsername=(usr)=>{
		if (usr!=="") {
			setCheckUsernameLoad(true);
			axios.post(`${link}api/pnp/get/username/`,{
					username:usr,
		        },{
		          headers:{
		            Authorization: 'Bearer '+accessToken
		          }
		        }).then((response)=>{
		        	setCheckUsernameLoad(false);
		        	setInvalidUsername(response.data);
		        }).catch((err)=>{
		        	setCheckUsernameLoad(false);
			        if(err.response.status === 401){
			            setAuthForm(true);
			        }else if(err.response.status === 404){
			            console.log('Get_username - ',err.response.status,':',err.response.statusText);
			        }else if(err.response.status === 500){
			            console.log('Get_username - ',err.response.status,':',err.response.statusText);
			        }else{
			            console.log('Get_username - ',err.response.status,':',err.response.data);
			        }
		    });
		    }else{
		    	setInvalidUsername();
		    }
	}
	const [ invalidEmail, setInvalidEmail ] = useState();
	const [ checkEmailLoad, setCheckEmailLoad ] = useState(false);
	const checkEmail=(mail)=>{
		if (mail!=="") {
			setCheckEmailLoad(true);
			axios.post(`${link}api/pnp/get/emailid/`,{
					email:mail,
		        },{
		          headers:{
		            Authorization: 'Bearer '+accessToken
		          }
		        }).then((response)=>{
		        	setCheckEmailLoad(false);
		        	setInvalidEmail(response.data);
		        }).catch((err)=>{
		        	setCheckEmailLoad(false);
			        if(err.response.status === 401){
			            setAuthForm(true);
			        }else if(err.response.status === 404){
			            console.log('Get_username - ',err.response.status,':',err.response.statusText);
			        }else if(err.response.status === 500){
			            console.log('Get_username - ',err.response.status,':',err.response.statusText);
			        }else{
			            console.log('Get_username - ',err.response.status,':',err.response.data);
			        }
		    });
		    }else{
		    	setInvalidEmail();
		    }
	}
	const addStaff=(e)=>{
		e.preventDefault();
		let first_name = e.target.first_name.value;
		let last_name = e.target.last_name.value;
		let staff_type = e.target.type.value;		
		let status = e.target.visibility.checked;
		let username = e.target.username.value;
		let password = e.target.password.value;
		let password2 = e.target.password2.value;
		let email = e.target.email.value;


		if(first_name===""){
			setFnameError(true);
			setTimeout(()=>{setFnameError(false)},5000);
		}
		if (staff_type==="") {
			setStaffTypeError(true);
			setTimeout(()=>{setStaffTypeError(false)},5000);
		}
		if (username==="") {
			setUsernameError(true);
			setTimeout(()=>{setUsernameError(false)},5000);
		}
		if (password==="") {
			setPassError(true);
			setTimeout(()=>{setPassError(false)},5000);
		}
		if (password2==="") {
			setCpassError(true);
			setTimeout(()=>{setCpassError(false)},5000);
		}
		if(password!==""&&password2!==""&&password!==password2){
			setPassEqualError(true);
			setTimeout(()=>{setPassEqualError(false)},5000);
		}
		if (email===""){
			setEmailError(true);
			setTimeout(()=>{setEmailError(false)},5000)
		}
		if (first_name!==""&&staff_type!==""&&username!==""&&password!==""&&password2!==""&&password===password2&&email!==""&&!invalidUsername&&!invalidEmail) {
			setStaffAddLoad(true);
			axios.post(`${link}api/pnp/add/staff/`,{
					first_name:first_name,
					last_name:last_name,
					staff_type:staff_type,
					status:status,
					username:username,
					email:email,
					password:password,
		        },{
		          headers:{
		            Authorization: 'Bearer '+accessToken
		          }
		        }).then((response)=>{
		        	setStaffAddLoad(false);
		        	setStaffs(response.data);
		        	setStaffAddModel(false);
		        }).catch((err)=>{
		        	setStaffAddLoad(false);
			        if(err.response.status === 401){
			            setAuthForm(true);
			        }else if(err.response.status === 404){
			            console.log('Add_staff_type - ',err.response.status,':',err.response.statusText);
			        }else if(err.response.status === 500){
			            console.log('Add_staff_type - ',err.response.status,':',err.response.statusText);
			        }else{
			            console.log('Add_staff_type - ',err.response.status,':',err.response.data);
			        }
		    });
		}

	}
	const [ changePasswordModel, setChangePasswordModel ] = useState(false);
	const [ staffId, setStaffId ] = useState();
	const [ npassError, setNpassError ] = useState(false);
	const [ ncpassError, setNcpassError ] = useState(false);
	const [ npassEqualError, setNpassEqualError ] = useState(false);
	const [ changePassLoad, setChangePassLoad ] = useState(false);
	const [ alertPassChange, setAlertPassChange ] = useState('default');
	const changePassword=(e)=>{
		e.preventDefault();
		let pass = e.target.password.value;
		let cpass = e.target.password2.value;
		if(pass===""){
			setNpassError(true);
			setTimeout(()=>{setNpassError(false)},5000);
		}
		if(cpass===""){
			setNcpassError(true);
			setTimeout(()=>{setNcpassError(false)},5000);
		}
		if (pass!==""&&cpass!==""&&pass!==cpass) {
			setNpassEqualError(true);
			setTimeout(()=>{setNpassEqualError(false)},5000);
		}
		if (pass!==""&&cpass!==""&&pass===cpass) {
			setChangePassLoad(true);
			axios.post(`${link}api/pnp/change/staff/password/`,{
					staff_id:staffId,
					password:pass,
		        },{
		          headers:{
		            Authorization: 'Bearer '+accessToken
		          }
		        }).then((response)=>{
		        	setChangePassLoad(false);
		        	setAlertPassChange('sucess');
		        	setTimeout(()=>{
		        		setAlertPassChange('default');
		        		setChangePasswordModel(false);
		        	},3000);
		        }).catch((err)=>{
		        	setChangePassLoad(false);
		        	setAlertPassChange('error');
		        	setTimeout(()=>{
		        		setAlertPassChange('default');
		        	},3000);
			        if(err.response.status === 401){
			            setAuthForm(true);
			        }else if(err.response.status === 404){
			            console.log('Change_staff_password - ',err.response.status,':',err.response.statusText);
			        }else if(err.response.status === 500){
			            console.log('Change_staff_password - ',err.response.status,':',err.response.statusText);
			        }else{
			            console.log('Change_staff_password - ',err.response.status,':',err.response.data);
			        }
		    });
		}
	}

	const [ changeStaffStatusChangeLoad, setStaffStatusChangeLoad ] = useState(false);
	const changeStaffStatus=(sid,status) =>{
		setStaffStatusChangeLoad(true);
		axios.post(`${link}api/pnp/edit/staff/status/`,{
				staff_id:sid,
				status:status,
	        },{
	          headers:{
	            Authorization: 'Bearer '+accessToken
	          }
	        }).then((response)=>{
	        	setStaffStatusChangeLoad(false);
	        	setStaffs(response.data);
	        }).catch((err)=>{
	        	setStaffStatusChangeLoad(false);
		        if(err.response.status === 401){
		            setAuthForm(true);
		        }else if(err.response.status === 404){
		            console.log('Change_staff_status - ',err.response.status,':',err.response.statusText);
		        }else if(err.response.status === 500){
		            console.log('Change_staff_status - ',err.response.status,':',err.response.statusText);
		        }else{
		            console.log('Change_staff_status - ',err.response.status,':',err.response.data);
		        }
	    });
	}
	const [ staffEditModel, setStaffEditModel ] = useState(false);
	const [ editStaffId, setEditStaffId ] = useState();
	const [ staffEditFname, setStaffEditFname ] = useState('');
	const [ staffEditLname, setStaffEditLname ] = useState('');
	const [ stffEditDesig, setStaffEditDesig ] = useState('');
	const updateStaff=(e)=>{
		e.preventDefault();
		let first_name = e.target.first_name.value;
		let last_name = e.target.last_name.value;
		let staff_type = e.target.type.value;
		if(first_name===""){
			setFnameError(true);
			setTimeout(()=>{setFnameError(false)},5000);
		}
		if (staff_type==="") {
			setStaffTypeError(true);
			setTimeout(()=>{setStaffTypeError(false)},5000);
		}
		if (first_name!==""&&staff_type!=="") {
			setStaffAddLoad(true);
			axios.post(`${link}api/pnp/edit/staff/`,{
					staff_id:editStaffId,
					first_name:first_name,
					last_name:last_name,
					staff_type:staff_type,
		        },{
		          headers:{
		            Authorization: 'Bearer '+accessToken
		          }
		        }).then((response)=>{
		        	setStaffAddLoad(false);
		        	setStaffs(response.data);
		        	setStaffEditModel(false);
		        }).catch((err)=>{
		        	setStaffAddLoad(false);
			        if(err.response.status === 401){
			            setAuthForm(true);
			        }else if(err.response.status === 404){
			            console.log('Edit_staff - ',err.response.status,':',err.response.statusText);
			        }else if(err.response.status === 500){
			            console.log('Edit_staff - ',err.response.status,':',err.response.statusText);
			        }else{
			            console.log('Edit_staff - ',err.response.status,':',err.response.data);
			        }
		    });
		}

	}
	return(
		<Container className="my-3 bdr2 br-3" >
			<Row className='bgs1' >
				<Col xs="12" sm="12" md="12" lg="12" className="staff-type-header d-grid p-2 bdrb2" >
					<span className="f16 tp2" >{getStaffsLoad?(<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>):'Staffs'}</span>
					{
						user!==undefined?(
							user.type==='admin'?(
								<Button onClick={()=>{setStaffAddModel(true)}} className="btn-t1" ><i className="bi bi-plus-circle"></i> Add new</Button>
							):(
							user.permission!==undefined?(
									user.permission.staff!==undefined&&user.permission.staff.A?(
											<Button onClick={()=>{setStaffAddModel(true)}} className="btn-t1" ><i className="bi bi-plus-circle"></i> Add new</Button>
										):''
								):''
							)
						):''
					}
				</Col>
			</Row>

			<Row>
			{
				staffs!==undefined&&Object.keys(staffs).length>0?(
					Object.keys(staffs).map((staff,staff_i)=>(
							<Col xs="12" sm="12" md="12" lg="12" className="staff-item d-grid p-2 bdrb2" key={`staff_${staff_i}`} >
								<span className="f16 tp2" >{`${staffs[staff].first_name} ${staffs[staff].last_name}`}, ( Username : {staffs[staff].username} ) - {staffs[staff].designation}</span>
								{
									user!==undefined?(
										user.type==='admin'?(
										<>
											<Button className="btn-t1-r" onClick={()=>{
												setStaffId(staffs[staff].staff_id);
												setChangePasswordModel(true);
											}} ><i className="bi bi-lock"></i> Change password</Button>
											<Button onClick={()=>{
												setStaffEditModel(true);
												setEditStaffId(staff);
												setStaffEditFname(staffs[staff].first_name);
												setStaffEditLname(staffs[staff].last_name);
												setStaffEditDesig(staffs[staff].designation);
											}} className="btn-t1-r"><i className="bi bi-pencil-square"></i> Edit</Button>
											{
												staffId!==undefined&&staffId===staffs[staff].staff_id&&changeStaffStatusChangeLoad?(
								    				<Button className="btn-t1" disabled><Spinner animation="border" size="sm" /></Button>
												):(
													staffs[staff].status?(
														<Button onClick={()=>{
															changeStaffStatus(staff,false);
															setStaffId(staffs[staff].staff_id);
														}} className="btn-disable" ><i className="bi bi-eye-slash"></i> Disable</Button>
													):(
														<Button onClick={()=>{
															changeStaffStatus(staff,true);
															setStaffId(staffs[staff].staff_id);
														}} className="btn-enable" ><i className="bi bi-eye-slash"></i> Enable</Button>
													)
												)
											}
										</>
										):(
											user.permission!==undefined?(
												user.permission.staff!==undefined&&user.permission.staff.E?(
												<>
													<Button className="btn-t1-r" onClick={()=>{
														setStaffId(staffs[staff].staff_id);
														setChangePasswordModel(true);
													}} ><i className="bi bi-lock"></i> Change password</Button>
													<Button onClick={()=>{
														setStaffEditModel(true);
														setEditStaffId(staff);
														setStaffEditFname(staffs[staff].first_name);
														setStaffEditLname(staffs[staff].last_name);
														setStaffEditDesig(staffs[staff].designation);
													}} className="btn-t1-r"><i className="bi bi-pencil-square"></i> Edit</Button>
													{
														staffId!==undefined&&staffId===staffs[staff].staff_id&&changeStaffStatusChangeLoad?(
										    				<Button className="btn-t1" disabled><Spinner animation="border" size="sm" /></Button>
														):(
															staffs[staff].status?(
																<Button onClick={()=>{
																	changeStaffStatus(staff,false);
																	setStaffId(staffs[staff].staff_id);
																}} className="btn-disable" ><i className="bi bi-eye-slash"></i> Disable</Button>
															):(
																<Button onClick={()=>{
																	changeStaffStatus(staff,true);
																	setStaffId(staffs[staff].staff_id);
																}} className="btn-enable" ><i className="bi bi-eye-slash"></i> Enable</Button>
															)
														)
													}
												</>
												):''
											):''
										)
									):''
								}

								
							</Col>
						))
					):''
			}				
			</Row>
			<Modal size="xl" show={staffAddModel} backdrop="static" onHide={()=>{setStaffAddModel(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Add staff</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={addStaff} >
		        		<Form.Group className="mb-3">
					    	<Form.Label>First name {fNameError?(<span className="error-message" >This field is required</span>):''}</Form.Label>
					    	<Row>
						    	<Col xs={12} sm={12} md={6} >
						    		<Form.Control type="text" name="first_name" className="shadow-none br-2" placeholder="First name" />
						    	</Col>
						    	<Col xs={12} sm={12} md={6} >
						    		<Form.Control type="text" name="last_name" className="shadow-none br-2" placeholder="Last name" />
						    	</Col>
					    	</Row>
					    </Form.Group>
					    <Form.Group className="mb-3">
					    	<Row>
						    	<Col xs={12} sm={12} md={6} >
						    		<Form.Label>Type / Designation {staffTypeError?(<span className="error-message" >This field is required</span>):''}</Form.Label>
						    		<Form.Select name="type"  className="shadow-none br-2">
						    			{
						    				staffTypes!==undefined&&Object.keys(staffTypes).length>0?(
						    					Object.keys(staffTypes).map((stf,stf_i)=>(
						    						<option value={staffTypes[stf].id} key={`stf_${stf_i}`} >{stf}</option>
						    						))
						    					):''
						    			}
						    		</Form.Select>
						    	</Col>
						    	<Col xs={12} sm={12} md={6} >
						    		<Form.Label>Enable staff login</Form.Label>
						    		<Form.Check type="checkbox" name="visibility" className="shadow-none br-2 form-control bdr-none" defaultChecked={true} label="Enable"/>
						    	</Col>
					    	</Row>
					    </Form.Group>
		        		<Form.Group className="mb-3">
		        			<Row>
						    	<Col xs={12} sm={12} md={6} >
		        					<Form.Label>Username 
		        					{usernameError?(<span className="error-message" >This field is required</span>):''}
		        					{
		        						checkUsernameLoad?(
		        							<span className="please-wait-spinner p-2" ><Spinner animation="border" size="sm" /> Checking availability...</span>
		        						):(
				        					invalidUsername!==undefined?(
				        						invalidUsername?(
				        						<span className="error-text p-2" ><i className="bi bi-exclamation-circle-fill"></i> Already taken</span>
				        						):(
				        						<span className="sucess-text p-2"><i className="bi bi-check-circle-fill"></i> Available</span>
				        						)
				        					):''
			        					)
			        				}
		        					</Form.Label>
					    			<Form.Control onBlur={(e)=>{checkUsername(e.target.value)}} type="text" name="username"  className="shadow-none br-2" placeholder="Username" />
					    		</Col>
					    		<Col xs={12} sm={12} md={6} >
					    			<Form.Label>Email id 
					    			{emailError?(<span className="error-message" >This field is required</span>):''}
					    			{
		        						checkEmailLoad?(
		        							<span className="please-wait-spinner p-2" ><Spinner animation="border" size="sm" /> Checking availability...</span>
		        						):(
				        					invalidEmail!==undefined?(
				        						invalidEmail?(
				        						<span className="error-text p-2" ><i className="bi bi-exclamation-circle-fill"></i> Already taken</span>
				        						):(
				        						<span className="sucess-text p-2"><i className="bi bi-check-circle-fill"></i> Available</span>
				        						)
				        					):''
			        					)
			        				}
					    			</Form.Label>
					    			<Form.Control onBlur={(e)=>{checkEmail(e.target.value)}} type="email" name="email"  className="shadow-none br-2" placeholder="Email id" />
					    		</Col>
					    	</Row>
					    </Form.Group>
					    <Form.Group className="mb-3">
		        			<Form.Label>Password {passError?(<span className="error-message" >This field is required</span>):''} {passEqualError?(<span className="error-message" >Password and Confirm password do not mach</span>):''}</Form.Label>
					    	<Form.Control type="password" name="password"  className="shadow-none br-2" placeholder="Password" />
					    </Form.Group>
					    <Form.Group className="mb-3">
		        			<Form.Label>Confirm Password {cpassError?(<span className="error-message" >This field is required</span>):''}</Form.Label>
					    	<Form.Control type="password" name="password2" className="shadow-none br-2" placeholder="Confirm Password" />
					    </Form.Group>
					    <div  className="d-grid">
					    {
					    	staffAddLoad?(
					    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
					    	):(
					    	<Button type="submit" className="btn-t1">SAVE</Button>
					    	)
					    }
					    </div>
		        	</Form>
		        </Modal.Body>
		        <Modal.Footer>
		        	
		        </Modal.Footer>
		    </Modal>

		    <Modal size="lg" show={staffEditModel} backdrop="static" onHide={()=>{setStaffEditModel(false)}}>
		        <Modal.Header closeButton>
		          <Modal.Title>Update staff details</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<Form onSubmit={updateStaff} >
		        		<Form.Group className="mb-3">
					    	<Form.Label>First name {fNameError?(<span className="error-message" >This field is required</span>):''}</Form.Label>
					    	<Form.Control type="text" name="first_name" defaultValue={staffEditFname} className="shadow-none br-2" placeholder="First name" />
					    </Form.Group>
					    <Form.Group className="mb-3">
					    	<Form.Label>Last Name</Form.Label>
						    <Form.Control type="text" name="last_name" defaultValue={staffEditLname} className="shadow-none br-2" placeholder="Last name" />
					    </Form.Group>
					    <Form.Group className="mb-3">
					    	<Form.Label>Type / Designation {staffTypeError?(<span className="error-message" >This field is required</span>):''}</Form.Label>
				    		<Form.Select name="type"  className="shadow-none br-2">
				    			{
				    				staffTypes!==undefined&&Object.keys(staffTypes).length>0?(
				    					Object.keys(staffTypes).map((stf,stf_i)=>(
				    						stffEditDesig!==undefined?(
				    							stffEditDesig===stf?(
				    								<option value={staffTypes[stf].id} key={`stf_${stf_i}`} selected>{stf}</option>
				    								):(
				    								<option value={staffTypes[stf].id} key={`stf_${stf_i}`} >{stf}</option>
				    								)
				    							):''
				    						))
				    					):''
				    			}
				    		</Form.Select>
					    </Form.Group>
					    <div  className="d-grid">
					    {
					    	staffAddLoad?(
					    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
					    	):(
					    	<Button type="submit" className="btn-t1">Update</Button>
					    	)
					    }
					    </div>
		        	</Form>
		        </Modal.Body>
		        <Modal.Footer>
		        	
		        </Modal.Footer>
		    </Modal>

		    <Modal size="xl" show={changePasswordModel} backdrop="static" onHide={()=>{setChangePasswordModel(false)}}>
		        <Modal.Header closeButton>
		        	{
		        		alertPassChange!==undefined?(
		        				alertPassChange === 'default'?(
		        					'Change password'
		        					):alertPassChange==='error'?(
		        					<span className='error-alert'>Sothing went wrong</span>
		        					):alertPassChange==='sucess'?(
		        					<span className="sucess-alert">Sucess</span>
		        					):''
		        			):'Change password'
		        	}
		        	</Modal.Header>		        	
		        <Modal.Body>
		        	<Form onSubmit={changePassword} >
					    <Form.Group className="mb-3">
		        			<Form.Label>New Password {npassError?(<span className="error-message" >This field is required</span>):''} {npassEqualError?(<span className="error-message" >Password and Confirm password do not mach</span>):''}</Form.Label>
					    	<Form.Control type="password" name="password"  className="shadow-none br-2" placeholder="New Password" />
					    </Form.Group>
					    <Form.Group className="mb-3">
		        			<Form.Label>Confirm Password {ncpassError?(<span className="error-message" >This field is required</span>):''}</Form.Label>
					    	<Form.Control type="password" name="password2" className="shadow-none br-2" placeholder="Confirm Password" />
					    </Form.Group>
					    <div  className="d-grid">
					    {
					    	changePassLoad?(
					    	<Button className="btn-t1" disabled><span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span></Button>
					    	):(
					    	<Button type="submit" className="btn-t1">SAVE</Button>
					    	)
					    }
					    </div>
		        	</Form>
		        </Modal.Body>
		        <Modal.Footer></Modal.Footer>
		    </Modal>
		</Container>
		)
}