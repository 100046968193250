import React, {useContext} from 'react';
import {Container, Table, Spinner } from 'react-bootstrap';
import { AppContext } from '../App';


export const QuickReport=()=>{
	const { getQuickReportLoad, quickReport, quickReportToday, getQuickReportTodayLoad, quickReportYear, getQuickReportYearLoad } = useContext(AppContext);
	return(
			<Container className="py-3">
			{
				getQuickReportTodayLoad?(
					<div className="d-grid py-2">
					<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
					</div>
				):(
					quickReportToday!==undefined?(
					<>
						<span className="f18 tp2 d-grid bdr1 bgs2 bdrb0 px-2 py-1" >Today</span>
						<Table bordered className="tbl-bg-blue" >
						    <thead>
						        <tr>
						          <th><span className="tp2" >Tokens</span></th>
						          <th><span className="tp2" >Bills</span></th>
						          <th><span className="tp2" >Services</span></th>
						          <th><span className="tp2" >TC</span></th>
						          <th><span className="tp2" >SC</span></th>
						          <th><span className="tp2" >Credit</span></th>
						          <th><span className="tp2" >Debit</span></th>
						          <th className="text-end tp2" >Total</th>
						        </tr>
						    </thead>
						    <tbody>
						    	<tr>
						    		<td><span className="tp2">{quickReportToday.today.tokens_created}</span></td>
						    		<td><span className="tp2">{quickReportToday.today.bills_generated}</span></td>
						    		<td><span className="tp2">{quickReportToday.today.services_completed}</span></td>
						    		<td><span className="tp2">{quickReportToday.today.total_transaction_amount}</span></td>
						    		<td><span className="tp2">{quickReportToday.today.total_service_amount}</span></td>
						    		<td><span className="tp2">{quickReportToday.today.total_credits}</span></td>
						    		<td><span className="tp2">{quickReportToday.today.total_debits}</span></td>
						    		<td className="tp2 text-end"><span>{quickReportToday.today.total_amount}</span></td>
						    	</tr>		        
						    </tbody>
						</Table>
					</>
					):''
				)
			}
			{
				getQuickReportLoad?(
					<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
				):(
				quickReport!==undefined?(
					<>
						
						<span className="f18 tp2 d-grid bdr1 bgs2 bdrb0 px-2 py-1" >Yesterday</span>
						<Table bordered className="tbl-bg-blue" >
						    <thead>
						        <tr>
						          <th><span className="tp2" >Tokens</span></th>
						          <th><span className="tp2" >Bills</span></th>
						          <th><span className="tp2" >Services</span></th>
						          <th><span className="tp2" >TC</span></th>
						          <th><span className="tp2" >SC</span></th>
						          <th><span className="tp2" >Credit</span></th>
						          <th><span className="tp2" >Debit</span></th>
						          <th className="text-end tp2" >Total</th>
						        </tr>
						    </thead>
						    <tbody>
						    	<tr>
						    		<td><span className="tp2">{quickReport.yesterday.tokens_created}</span></td>
						    		<td><span className="tp2">{quickReport.yesterday.bills_generated}</span></td>
						    		<td><span className="tp2">{quickReport.yesterday.services_completed}</span></td>
						    		<td><span className="tp2">{quickReport.yesterday.total_transaction_amount}</span></td>
						    		<td><span className="tp2">{quickReport.yesterday.total_service_amount}</span></td>
						    		<td><span className="tp2">{quickReport.yesterday.total_credits}</span></td>
						    		<td><span className="tp2">{quickReport.yesterday.total_debits}</span></td>
						    		<td className="tp2 text-end"><span>{quickReport.yesterday.total_amount}</span></td>
						    	</tr>		        
						    </tbody>
						</Table>
						<span className="f18 tp2 d-grid bdr1 bgs2 bdrb0 px-2 py-1" >This Week</span>
						<Table bordered className="tbl-bg-blue" >
						    <thead>
						        <tr>
						          <th><span className="tp2" >Tokens</span></th>
						          <th><span className="tp2" >Bills</span></th>
						          <th><span className="tp2" >Services</span></th>
						          <th><span className="tp2" >TC</span></th>
						          <th><span className="tp2" >SC</span></th>
						          <th><span className="tp2" >Credit</span></th>
						          <th><span className="tp2" >Debit</span></th>
						          <th className="text-end tp2" >Total</th>
						        </tr>
						    </thead>
						    <tbody>
						    	<tr>
						    		<td><span className="tp2">{quickReport.this_week.tokens_created}</span></td>
						    		<td><span className="tp2">{quickReport.this_week.bills_generated}</span></td>
						    		<td><span className="tp2">{quickReport.this_week.services_completed}</span></td>
						    		<td><span className="tp2">{quickReport.this_week.total_transaction_amount}</span></td>
						    		<td><span className="tp2">{quickReport.this_week.total_service_amount}</span></td>
						    		<td><span className="tp2">{quickReport.this_week.total_credits}</span></td>
						    		<td><span className="tp2">{quickReport.this_week.total_debits}</span></td>
						    		<td className="tp2 text-end"><span>{quickReport.this_week.total_amount}</span></td>
						    	</tr>		        
						    </tbody>
						</Table>
						<span className="f18 tp2 d-grid bdr1 bgs2 bdrb0 px-2 py-1" >Last Week</span>
						<Table bordered className="tbl-bg-blue" >
						    <thead>
						        <tr>
						          <th><span className="tp2" >Tokens</span></th>
						          <th><span className="tp2" >Bills</span></th>
						          <th><span className="tp2" >Services</span></th>
						          <th><span className="tp2" >TC</span></th>
						          <th><span className="tp2" >SC</span></th>
						          <th><span className="tp2" >Credit</span></th>
						          <th><span className="tp2" >Debit</span></th>
						          <th className="text-end tp2" >Total</th>
						        </tr>
						    </thead>
						    <tbody>
						    	<tr>
						    		<td><span className="tp2">{quickReport.last_week.tokens_created}</span></td>
						    		<td><span className="tp2">{quickReport.last_week.bills_generated}</span></td>
						    		<td><span className="tp2">{quickReport.last_week.services_completed}</span></td>
						    		<td><span className="tp2">{quickReport.last_week.total_transaction_amount}</span></td>
						    		<td><span className="tp2">{quickReport.last_week.total_service_amount}</span></td>
						    		<td><span className="tp2">{quickReport.last_week.total_credits}</span></td>
						    		<td><span className="tp2">{quickReport.last_week.total_debits}</span></td>
						    		<td className="tp2 text-end"><span>{quickReport.last_week.total_amount}</span></td>
						    	</tr>		        
						    </tbody>
						</Table>
						<span className="f18 tp2 d-grid bdr1 bgs2 bdrb0 px-2 py-1" >This Month</span>
						<Table bordered className="tbl-bg-blue" >
						    <thead>
						        <tr>
						          <th><span className="tp2" >Tokens</span></th>
						          <th><span className="tp2" >Bills</span></th>
						          <th><span className="tp2" >Services</span></th>
						          <th><span className="tp2" >TC</span></th>
						          <th><span className="tp2" >SC</span></th>
						          <th><span className="tp2" >Credit</span></th>
						          <th><span className="tp2" >Debit</span></th>
						          <th className="text-end tp2" >Total</th>
						        </tr>
						    </thead>
						    <tbody>
						    	<tr>
						    		<td><span className="tp2">{quickReport.this_month.tokens_created}</span></td>
						    		<td><span className="tp2">{quickReport.this_month.bills_generated}</span></td>
						    		<td><span className="tp2">{quickReport.this_month.services_completed}</span></td>
						    		<td><span className="tp2">{quickReport.this_month.total_transaction_amount}</span></td>
						    		<td><span className="tp2">{quickReport.this_month.total_service_amount}</span></td>
						    		<td><span className="tp2">{quickReport.this_month.total_credits}</span></td>
						    		<td><span className="tp2">{quickReport.this_month.total_debits}</span></td>
						    		<td className="tp2 text-end"><span>{quickReport.this_month.total_amount}</span></td>
						    	</tr>		        
						    </tbody>
						</Table>
						<span className="f18 tp2 d-grid bdr1 bgs2 bdrb0 px-2 py-1" >Last Month</span>
						<Table bordered className="tbl-bg-blue" >
						    <thead>
						        <tr>
						          <th><span className="tp2" >Tokens</span></th>
						          <th><span className="tp2" >Bills</span></th>
						          <th><span className="tp2" >Services</span></th>
						          <th><span className="tp2" >TC</span></th>
						          <th><span className="tp2" >SC</span></th>
						          <th><span className="tp2" >Credit</span></th>
						          <th><span className="tp2" >Debit</span></th>
						          <th className="text-end tp2" >Total</th>
						        </tr>
						    </thead>
						    <tbody>
						    	<tr>
						    		<td><span className="tp2">{quickReport.last_month.tokens_created}</span></td>
						    		<td><span className="tp2">{quickReport.last_month.bills_generated}</span></td>
						    		<td><span className="tp2">{quickReport.last_month.services_completed}</span></td>
						    		<td><span className="tp2">{quickReport.last_month.total_transaction_amount}</span></td>
						    		<td><span className="tp2">{quickReport.last_month.total_service_amount}</span></td>
						    		<td><span className="tp2">{quickReport.last_month.total_credits}</span></td>
						    		<td><span className="tp2">{quickReport.last_month.total_debits}</span></td>
						    		<td className="tp2 text-end"><span>{quickReport.last_month.total_amount}</span></td>
						    	</tr>		        
						    </tbody>
						</Table>
						</>
					):'Not found!'
				)
			}
			{
				getQuickReportYearLoad?(
					<div className="d-grid py-2">
					<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
					</div>
				):(
					quickReportYear!==undefined?(
					<>
						<span className="f18 tp2 d-grid bdr1 bgs2 bdrb0 px-2 py-1" >This Year</span>
						<Table bordered className="tbl-bg-blue" >
						    <thead>
						        <tr>
						          <th><span className="tp2" >Tokens</span></th>
						          <th><span className="tp2" >Bills</span></th>
						          <th><span className="tp2" >Services</span></th>
						          <th><span className="tp2" >TC</span></th>
						          <th><span className="tp2" >SC</span></th>
						          <th><span className="tp2" >Credit</span></th>
						          <th><span className="tp2" >Debit</span></th>
						          <th className="text-end tp2" >Total</th>
						        </tr>
						    </thead>
						    <tbody>
						    	<tr>
						    		<td><span className="tp2">{quickReportYear.this_year.tokens_created}</span></td>
						    		<td><span className="tp2">{quickReportYear.this_year.bills_generated}</span></td>
						    		<td><span className="tp2">{quickReportYear.this_year.services_completed}</span></td>
						    		<td><span className="tp2">{quickReportYear.this_year.total_transaction_amount}</span></td>
						    		<td><span className="tp2">{quickReportYear.this_year.total_service_amount}</span></td>
						    		<td><span className="tp2">{quickReportYear.this_year.total_credits}</span></td>
						    		<td><span className="tp2">{quickReportYear.this_year.total_debits}</span></td>
						    		<td className="tp2 text-end"><span >{quickReportYear.this_year.total_amount}</span></td>
						    	</tr>		        
						    </tbody>
						</Table>
					</>
					):''
				)
			}
			</Container>
		)
}