import React, {useContext} from 'react';
import {Container, Row, Col, Table, Spinner, Form, InputGroup, Button} from 'react-bootstrap';
import { AppContext } from '../App';


export const CancelTokenReport=()=>{
	const { getCancelReport, cancelReport, cancelReportLoad, getDate, getBill, setViewBillModal } = useContext(AppContext);
	const getReport=(e)=>{
		e.preventDefault();
		let start=e.target.start.value;
		let end = e.target.end.value;
		let staff = e.target.staff.value;
		if (start!==""&&end!=="") {
			getCancelReport(start,end,staff);
		}
	}
	return(
			<Container className="py-3" >
				<Form onSubmit={getReport} >
					<Row className="mb-3" >
						<Col xs={12} sm={4} md={3} >
							<InputGroup  className="br-3">
						        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">From</InputGroup.Text>
						        <Form.Control name="start" type="date" defaultValue={getDate()}  className="br-3" />
						    </InputGroup>
						</Col>
						<Col xs={12} sm={4} md={3} >
							<InputGroup  className="br-3">
						        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">To</InputGroup.Text>
						        <Form.Control name="end" type="date" defaultValue={getDate()}  className="br-3" />
						    </InputGroup>
						</Col>						
						<Col xs={12} sm={4} md={3} >
							<Form.Select name="staff" className="shadow-none br-2">
						      <option value="" >All staffs</option>
						      {
						      	cancelReport!==undefined?(
						      		cancelReport.users!==undefined?(
						      			Object.keys(cancelReport.users).length>0?(
						      				Object.keys(cancelReport.users).map((staff,staff_i)=>(
						      					<option value={staff.substring(1)} key={`user_${staff_i}`} >{cancelReport.users[staff].username}</option>
						      				))
						      			):''
						      		):''
						      	):''
						      }
						    </Form.Select>
						</Col>
						<Col xs={12} sm={4} md={3} className="d-grid" >
							<Button type="submit" className="btn-t1" >Get Report</Button>
						</Col>
					</Row>
				</Form> 
				{
					cancelReportLoad?(
						<div className="d-grid py-2">
							<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
						</div>
					):(
						<Table bordered className="tbl-bg-blue" >
						    <thead>
						        <tr>
						          <th className="tp2" ><span>Token</span></th>
						          <th className="tp2" ><span>Services</span></th>
						          <th className="tp2" ><span>Customer</span></th>
						          <th className="tp2" ><span>Cancellation Reason</span></th>
						        </tr>
						    </thead>
						    <tbody>
						    {
						    	cancelReport!==undefined?(
						    		cancelReport.token_values!==undefined?(
						    			Object.keys(cancelReport.token_values).length>0?(
						    				Object.keys(cancelReport.token_values).map((tkn,tnk_i)=>(
						    				<tr key={`cancel_tkn_${tnk_i}`} >
						    					<td className="tp2" >
						    						<span className="mb-1 f18" >No : {cancelReport.token_values[tkn].token_no}
						    							{
						    								cancelReport.token_values[tkn].token_status==='cancel'?(
						    									<span className="error-message mx-2"><i className="bi bi-x-circle"></i> Cancel</span>
						    								):cancelReport.token_values[tkn].token_status==='close'?(
												    			<span className="sucess-tag mx-2"><i className="bi bi-check-circle"></i> Completed</span>
						    								):cancelReport.token_values[tkn].token_status==='open'?(
												    			<span className="process-tag mx-2"><i className="bi bi-activity"></i> Processing</span>						    									
						    								):''
						    							}
						    						</span>
						    						<span className="d-grid mb-1" >Created by {cancelReport.token_values[tkn].token_created_by}</span>
						    						<span className="d-grid mb-1 f14" >{cancelReport.token_values[tkn].open_date}, {cancelReport.token_values[tkn].created_time}</span>						    						
						    					</td>
						    					<td className="tp2" >
						    					{
						    						cancelReport.token_values[tkn].token_services!==undefined?(
						    							Object.keys(cancelReport.token_values[tkn].token_services).length>0?(
						    								Object.keys(cancelReport.token_values[tkn].token_services).map((tser,ts_i)=>(
						    									<span className="d-grid mb-1" key={`tser_${ts_i}`} >{cancelReport.token_values[tkn].token_services[tser].service_name} 
						    										{
						    											cancelReport.token_values[tkn].token_services[tser].quantity>1?(
						    											<> ({cancelReport.token_values[tkn].token_services[tser].quantity})</>
						    											):''
						    										}
						    									</span>
						    								))
						    							):''
						    						):''
						    					}
						    					</td>
						    					<td className="tp2">
						    						<span className="d-grid mb-1">{cancelReport.token_values[tkn].customer_name}</span>
						    						<span className="d-grid mb-1">{cancelReport.token_values[tkn].customer_phone}</span>						    						
						    					</td>
						    					<td className="tp2">
						    						{
						    							cancelReport.token_values[tkn].token_cancel!==undefined?(
						    								Object.keys(cancelReport.token_values[tkn].token_cancel).length>0?(
						    									Object.keys(cancelReport.token_values[tkn].token_cancel).map((tknc,tknc_i)=>(
						    									<div className="tnk-cancel_reason bdrb1 pb-2 mb-2" key={`tknc_${tknc_i}`} >
						    										<span className="d-grid">Cancelled by {cancelReport.token_values[tkn].token_cancel[tknc].cancelled_by}</span>
						    										<span className="d-grid">Time : {cancelReport.token_values[tkn].token_cancel[tknc].cancel_time}</span>
						    										<span className="d-grid">Reason : {cancelReport.token_values[tkn].token_cancel[tknc].reason}</span>
						    									</div>
						    									))
						    								):''
						    							):''
						    						}
						    					</td>
						    				</tr>
						    				))
						    			):''
						    		):''
						    	):''
						    }
						    </tbody>
						</Table>
					)
				}				
			</Container>
		)
}