import React, {useContext} from 'react';
import {Container, Row, Col, Table, Spinner, Form, InputGroup, Button} from 'react-bootstrap';
import { AppContext } from '../App';


export const DayCloseReopenReport=()=>{
	const { getCreditReport, reOpenRepo, setReOpenRepo, getDate, postCall, load, setLoad} = useContext(AppContext);
	const getReport=(e)=>{
		e.preventDefault();
		let start = e.target.start.value;
		let end = e.target.end.value;
		let staff = e.target.staff.value;
		if (start!==""&&end!=="") {
			setLoad(true);
			postCall('api/bnr/get/verify/reopen/report/',{start_date:start, end_date:end,staff_id:staff}).then(res=>{
				setReOpenRepo(res.data);
				setLoad(false);
			}).catch(err=>{
				setLoad(false);
			});
		}
	}
	return(
			<Container className="py-3" >
				<Form onSubmit={getReport} >
					<Row className="mb-3" >
						<Col xs={12} sm={4} md={3} >
							<InputGroup  className="br-3">
						        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">From</InputGroup.Text>
						        <Form.Control name="start" type="date" defaultValue={getDate()}  className="br-3" />
						    </InputGroup>
						</Col>
						<Col xs={12} sm={4} md={3} >
							<InputGroup  className="br-3">
						        <InputGroup.Text  className="br-3" id="inputGroup-sizing-default">To</InputGroup.Text>
						        <Form.Control name="end" type="date" defaultValue={getDate()}  className="br-3" />
						    </InputGroup>
						</Col>						
						<Col xs={12} sm={4} md={3} >
							<Form.Select name="staff" className="shadow-none br-2">
						      <option value="" >All staffs</option>
						      {
						      	reOpenRepo!==undefined?(
						      		reOpenRepo.users!==undefined?(
						      			Object.keys(reOpenRepo.users).length>0?(
						      				Object.keys(reOpenRepo.users).map((staff,staff_i)=>(
						      					<option value={staff.substring(1)} key={`user_${staff_i}`} >{reOpenRepo.users[staff].username}</option>
						      				))
						      			):''
						      		):''
						      	):''
						      }
						    </Form.Select>
						</Col>
						<Col xs={12} sm={4} md={3} className="d-grid" >
							<Button type="submit" className="btn-t1" >Get Report</Button>
						</Col>
					</Row>
				</Form> 
				{
					load?(
						<div className="d-grid py-2">
							<span className="please-wait-spinner" ><Spinner animation="border" size="sm" /> Please wait...</span>
						</div>
					):(
						reOpenRepo!==undefined&&reOpenRepo.reopen_values!==undefined&&Object.keys(reOpenRepo.reopen_values).length>0?(
							<Table bordered className="tbl-bg-blue" >
						    <thead>
						        <tr>
						          <th className="tp2" >Date</th>
						          <th className="tp2" >Staff</th>
						          <th className="tp2" >Re opened by</th>
						          <th className="tp2" >Remarks</th>
						        </tr>
						    </thead>
						    <tbody>
						    {
								Object.keys(reOpenRepo.reopen_values).map((reop,reopi)=>(
									<tr key={`reop_${reopi}`} className="tp2"  >
										<td>{reOpenRepo.reopen_values[reop].reopen_date}</td>
										<td>{reOpenRepo.reopen_values[reop].user}</td>
										<td>{reOpenRepo.reopen_values[reop].reopened_by}</td>
										<td>{reOpenRepo.reopen_values[reop].remarks}</td>
									</tr>
								))
							}
							</tbody>
						</Table>
						):''
					)
				}
				
			</Container>
		)
}